import { Box, Text, VStack, List, ListItem, Flex } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import { DrawerFundContext } from "@screens/dashboard/transfer/drawerFund/DrawerFundContext"
import { useTranslation } from "react-i18next"

export const USWireDisclaimers: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { usWireDisclaimers, setUSWireDisclaimers, setErrorMessage } = DrawerFundContext.useContainer()

    const handleCheckboxChange = (name: keyof typeof usWireDisclaimers, isChecked: boolean) => {
        setErrorMessage("")
        setUSWireDisclaimers((prev) => ({ ...prev, [name]: isChecked }))
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithWire", { ns: "transfer" })}</Text>
            <Box my={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("fund.acceptDisclaimers", { ns: "transfer" })}</Text>
            </Box>
            <VStack spacing={4} justifyContent={"flex-start"} align="flex-start" display={"flex"}>
                <Checkbox
                    text={t("fund.usWireDisclaimers.disclaimer1", {
                        ns: "transfer",
                    })}
                    name="disclaimer1"
                    isChecked={usWireDisclaimers.disclaimer1}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer1", isChecked)}
                />
                <Checkbox
                    text={t("fund.usWireDisclaimers.disclaimer2", { ns: "transfer" })}
                    name="disclaimer2"
                    isChecked={usWireDisclaimers.disclaimer2}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer2", isChecked)}
                />
                <Checkbox
                    text={t("fund.usWireDisclaimers.disclaimer3", { ns: "transfer" })}
                    name="disclaimer3"
                    isChecked={usWireDisclaimers.disclaimer3}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer3", isChecked)}
                />
            </VStack>
            <Text textStyle={"ManropeSemiboldBody"} mt={6} mb={3}>
                {t("fund.doNotAccept", { ns: "transfer" })}
            </Text>
            <List spacing={1}>
                <ListItem>
                    <Flex alignItems="start">
                        <Text mr={2}>•</Text>
                        <Text textStyle={"InterRegularBody"}>
                            {t("fund.doNotAcceptOptions.checks", { ns: "transfer" })}
                        </Text>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex alignItems="start">
                        <Text mr={2}>•</Text>
                        <Text textStyle={"InterRegularBody"}>
                            {t("fund.doNotAcceptOptions.bankDrafts", { ns: "transfer" })}
                        </Text>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex alignItems="start">
                        <Text mr={2}>•</Text>
                        <Text textStyle={"InterRegularBody"}>
                            {t("fund.doNotAcceptOptions.cash", { ns: "transfer" })}
                        </Text>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex alignItems="start">
                        <Text mr={2}>•</Text>
                        <Text textStyle={"InterRegularBody"}>
                            {t("fund.doNotAcceptOptions.unknownWire", { ns: "transfer" })}
                        </Text>
                    </Flex>
                </ListItem>
            </List>
        </Box>
    )
}
