import { Box, Flex, useBreakpointValue } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import Tabs from "@components/ui/tabs"
import { ENUM_TAB_VARIANTS } from "@components/ui/tabs/types"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { StakingInfo } from "@redux/stake/types"
import { Transaction } from "@redux/transactions/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ActivityTab from "./Activity"
import HoldingsTab from "./Holdings"
import OpenOrdersTab from "./OpenOrders"
import StakedTab from "./Staked"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"

export interface TransactionTableProps {
    asset: AssetDetailsFull
    activity: Transaction[]
    openOrders: Transaction[]
    openOrderTotalAmountSells: number
    openOrdersTotalQuantitySells: number
    frozenQuantity: number
    frozenAmount: number
    availableQuantity: number
    availableAmount: number
    staked: number
    stakingInfo?: StakingInfo
    setSelectedTx: (_: Transaction) => void
    setSelectedOrder: (_: Transaction) => void
    isDrawerOpen: boolean
}

export const AssetStatsTable: React.FC<TransactionTableProps> = ({
    asset,
    staked,
    activity,
    openOrders,
    openOrderTotalAmountSells,
    openOrdersTotalQuantitySells,
    frozenAmount,
    frozenQuantity,
    availableQuantity,
    availableAmount,
    stakingInfo,
    setSelectedOrder,
    setSelectedTx,
    isDrawerOpen,
}) => {
    const { navigate } = useRestrictedNavigation()
    const { t } = useTranslation(["app", "common"])
    const isMdBreakpoint = useBreakpointValue({ base: false, md: true })
    const { data: accountData } = useGetAccountDetailsQuery(undefined)

    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [isCurrencyOrderSwapped, toggleCurrencyOrderSwapped] = useState(false)

    const isShowingStakingTab = useMemo(
        () => !!stakingInfo && accountData?.country === "CA",
        [stakingInfo, accountData]
    )

    const tabHeaders = useMemo(() => {
        return {
            HOLDINGS: isMdBreakpoint && !isDrawerOpen ? t("common:myHoldings") : t("common:holdings"),
            OPENORDERS: isMdBreakpoint && !isDrawerOpen ? t("common:openOrders") : t("common:orders"),
            STAKING: isMdBreakpoint && !isDrawerOpen ? t("common:stakedOrders") : t("common:staked"),
            ACTIVITY: t("common:activity"),
        }
    }, [isMdBreakpoint, isDrawerOpen])

    const shownOrderTabs = useMemo(() => {
        const arr = Object.values(tabHeaders)
        return isShowingStakingTab ? arr : arr.filter((tab) => tab !== tabHeaders.STAKING)
    }, [isShowingStakingTab, tabHeaders])

    const stakedAmount = useMemo(() => {
        const str = (Number(asset.price) * staked).toFixed(2)
        return Number(str)
    }, [asset.price, staked])

    const totalAmount = useMemo(() => {
        const openOrdersTotalAmountSells = (openOrdersTotalQuantitySells ?? 0) * Number(asset.price)
        return availableAmount + openOrdersTotalAmountSells + stakedAmount + frozenAmount
    }, [availableAmount, stakedAmount, openOrdersTotalQuantitySells, asset.price, frozenAmount])

    const totalQuantity = useMemo(() => {
        return totalAmount / Number(asset.price)
    }, [totalAmount, asset.price])

    const TabPanels = useMemo(() => {
        return [
            <HoldingsTab
                asset={asset}
                canStake={asset.restrictions.can_stake && isShowingStakingTab}
                frozenAmount={frozenAmount}
                frozenQuantity={frozenQuantity}
                balanceAmount={totalAmount}
                balanceQuantity={totalQuantity}
                availableAmount={availableAmount}
                availableQuantity={availableQuantity}
                stakedQuantity={staked}
                stakedAmount={stakedAmount}
                openOrdersTotalAmountSells={openOrderTotalAmountSells}
                openOrdersTotalQuantitySells={openOrdersTotalQuantitySells}
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                toggleCurrencyOrderSwapped={toggleCurrencyOrderSwapped}
                dayPerf={{ amount: 0, percentage: 0 }} // TODO
            />,
            <OpenOrdersTab
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                arr={openOrders}
                setSelectedArr={setSelectedOrder}
            />,
            isShowingStakingTab ? (
                <StakedTab
                    isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                    toggleCurrencyOrderSwapped={toggleCurrencyOrderSwapped}
                    availableAmount={availableAmount}
                    availableQuantity={availableQuantity}
                    asset={asset}
                    stakedAmount={stakedAmount}
                    stakedQuantity={staked}
                    stakedInfo={stakingInfo as StakingInfo}
                />
            ) : null,
            <ActivityTab
                isCurrencyOrderSwapped={isCurrencyOrderSwapped}
                arr={activity}
                setSelectedArr={setSelectedTx}
            />,
        ].filter(Boolean) as JSX.Element[]
    }, [
        asset,
        totalQuantity,
        openOrders,
        staked,
        stakingInfo,
        activity,
        isCurrencyOrderSwapped,
        toggleCurrencyOrderSwapped,
        stakedAmount,
        totalAmount,
        tabHeaders,
    ])

    const handleNavigation = (tab: string) => {
        if (tab === tabHeaders.ACTIVITY) {
            navigate(buildRouteWithParams(ROUTES.ASSET_TRANSACTIONS, { asset: asset.symbol || "" }))
        } else if (tab === tabHeaders.OPENORDERS) {
            navigate(buildRouteWithParams(ROUTES.ASSET_OPEN_ORDERS, { asset: asset.symbol || "" }))
        }
    }

    return (
        <Flex flexDir={"column"} h="full" w="full">
            <Box w="full" alignSelf={{ base: "unset", lg: "flex-end" }} h="full">
                <Tabs
                    tabIndex={currentTabIndex}
                    setTabIndex={setCurrentTabIndex}
                    name={"market-details"}
                    variant={ENUM_TAB_VARIANTS.SECONDARY}
                    tabs={shownOrderTabs}
                    tabPanels={TabPanels}
                    p="0"
                    h="fit-content"
                />
                {((shownOrderTabs[currentTabIndex] === tabHeaders.OPENORDERS && openOrders.length) ||
                    (shownOrderTabs[currentTabIndex] === tabHeaders.ACTIVITY && activity.length)) && (
                    <Flex justifyContent="center" my={4}>
                        <StandardButton
                            type="button"
                            variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                            onClick={() => handleNavigation(shownOrderTabs[currentTabIndex])}
                            textStyle="ManropeSemiboldBodySmall"
                            size="sm"
                        >
                            {shownOrderTabs[currentTabIndex] === tabHeaders.OPENORDERS
                                ? t("marketDetails.viewOpenOrders", { asset: asset.symbol.toUpperCase() })
                                : t("marketDetails.viewActivity", { asset: asset.symbol.toUpperCase() })}
                        </StandardButton>
                    </Flex>
                )}
            </Box>
        </Flex>
    )
}
