import {
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    InputRightElement,
    Text,
    TextProps,
    useTheme,
} from "@chakra-ui/react"
import { TextStyles } from "@theme/textStyles"
import { Control, Controller, FieldValues, ControllerProps, useController } from "react-hook-form"
import { MdInfoOutline } from "react-icons/md"

export type FormFieldProps = {
    leftElement?: React.ReactElement
    rightElement?: React.ReactElement
    label?: string
    labelProps?: TextProps
    labelElement?: React.ReactElement
    errorMessage?: string
} & InputProps

export const FormField: React.FC<FormFieldProps> = ({
    size,
    label,
    labelProps,
    isRequired,
    labelElement,
    leftElement,
    rightElement,
    isDisabled,
    isInvalid,
    errorMessage,
    isReadOnly,
    ...rest
}) => {
    const theme = useTheme()

    return (
        <>
            {label && (
                <Flex marginBottom={1}>
                    <Text textStyle={"ManropeMediumBodySmall"} {...labelProps}>
                        {label}
                    </Text>
                    {isRequired && (
                        <Text color={"blue.100"} textStyle={"InterRegularBodySmall"} marginX={0.5} lineHeight={"20px"}>
                            *
                        </Text>
                    )}
                    {labelElement}
                </Flex>
            )}
            <InputGroup
                borderColor={
                    isInvalid || errorMessage
                        ? "red.light.100"
                        : isDisabled || isReadOnly
                          ? "grey.light.20"
                          : "grey.light.10"
                }
                borderWidth={isInvalid || errorMessage ? "2px" : "1px"}
                height={"48px"}
                borderRadius={"6px"}
                _focusWithin={isDisabled || isReadOnly ? {} : { borderColor: "blue.100", borderWidth: "2px" }}
            >
                {leftElement && (
                    <InputLeftElement width={"auto"} marginLeft={4} pointerEvents="none" height={"100%"}>
                        {leftElement}
                    </InputLeftElement>
                )}
                <Input
                    height={"100%"}
                    paddingStart={leftElement ? 10 : 4}
                    paddingEnd={4}
                    //Chakra UI has issues rendering the correct text style https://github.com/chakra-ui/chakra-ui/discussions/5367
                    sx={{ ...TextStyles.ManropeMediumBody }}
                    borderWidth={0}
                    color={isDisabled ? "grey.light.50" : "black"}
                    errorBorderColor="transparent"
                    focusBorderColor="transparent"
                    isInvalid={isInvalid || errorMessage !== undefined}
                    {...rest}
                />
                {rightElement && (
                    <InputRightElement width={"auto"} marginRight={4} pointerEvents="none" height={"100%"}>
                        {rightElement}
                    </InputRightElement>
                )}
            </InputGroup>
            {errorMessage && (
                <Flex alignItems="center" marginTop={1}>
                    <MdInfoOutline
                        style={{ color: theme.colors.red.light["100"], verticalAlign: "middle", width: 14, height: 14 }}
                    />
                    <Text textStyle={"ManropeMediumXSmall"} color="black" marginLeft={2}>
                        {errorMessage}
                    </Text>
                </Flex>
            )}
        </>
    )
}

export type FormFieldControllerProps = {
    formFieldProps: Omit<FormFieldProps, "value" | "onChange" | "errorMessage">
} & Omit<ControllerProps, "render">

export const FormFieldController: React.FC<FormFieldControllerProps> = ({ control, formFieldProps, name, rules }) => {
    return (
        <Controller
            control={control}
            rules={{
                required: true,
                ...rules,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField {...formFieldProps} value={value} onChange={onChange} errorMessage={error?.message} />
            )}
            name={name}
        />
    )
}
