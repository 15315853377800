import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Image, Skeleton, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import MarketChange from "@components/ui/marketChange"
import { ErrorModal } from "@components/ui/modals/errorModal"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { TUserState } from "@redux/account/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { ROUTES } from "@routing/routes"
import { addCommasToNumber, removeTrailingZeros } from "@util/stringFormatting"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdAdd, MdRemove } from "react-icons/md"
import { useParams } from "react-router"
import DrawerOpenOrder from "../openOrders/drawerOpenOrders"
import DrawerCoinTrade, { BUYSELL_TAB, SIDE_DRAWER_RESPONSIVE_WIDTH } from "../trade/drawerCoinTrade"
import DrawerTransactionDetails from "../transactions/drawerTransactionDetails"
import AssetStats from "./assetStats"
import MarketDetailsGraph from "@tradingView/graph"
import { imageMap } from "@assets/svgs/coins"
import { SIDEBAR_WIDTH_LARGE, SIDEBAR_WIDTH_SMALL } from "@components/sidebar"
import { BreakPoints } from "@theme/breakpoints"

export default function MarketDetails() {
    const { t } = useTranslation(["marketDetails", "common", "app"])
    const { asset } = useParams()
    const { navigate, evaluateRestriction, isEvaluationState, userState } = useRestrictedNavigation()
    const isXlBreakpoint = useBreakpointValue({ base: false, xl: true })
    const { data: assetDetailsData, isLoading } = useGetAssetsDetailsQuery(undefined)
    const accountCurrency = useAppSelector(selectAccountDetails)?.currency
    const image = imageMap[asset?.toLowerCase() as keyof typeof imageMap]

    const [isDrawerOpen, toggleDrawerOpen] = useState(false)
    const [tab, setTab] = useState<BUYSELL_TAB>(BUYSELL_TAB.BUY)
    const [selectedOrder, setSelectedOrder] = useState<Transaction | null>(null)
    const [selectedTx, setSelectedTx] = useState<Transaction | null>(null)
    const [dialogError, setDialogError] = useState<{ title: string; description: string } | undefined>(undefined)
    const { isOpen: isModalOpen, onOpen, onClose: onModalClose } = useDisclosure()
    const { onbSupportEmail } = useSupport()

    const openDrawer = (tab: BUYSELL_TAB) => {
        const isRestricted = evaluateRestriction(RESTRICTED_FLOWS.TRADE, undefined, { returnBoolean: true })
        if (isRestricted) {
            return popupError()
        }
        setTab(tab)
        toggleDrawerOpen(true)
    }

    const closeDrawer = () => {
        toggleDrawerOpen(false)
    }

    const popupError = () => {
        if (isEvaluationState) {
            setDialogError({
                title: t("app:buysell.error.evaluation.title"),
                description: t("app:buysell.error.evaluation.message", { email: onbSupportEmail }),
            })
        } else if (userState === TUserState.VIEW_ONLY) {
            setDialogError({
                title: t("app:buysell.error.viewOnly.title"),
                description: t("app:buysell.error.viewOnly.message"),
            })
        }
        onOpen()
    }
    const closePopup = () => {
        setDialogError(undefined)
        onModalClose()
    }

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const marketColor = useMemo(() => {
        const val: number | undefined = data?.["24h_percent"]
        if (val === undefined || val === 0) return "#636366"

        return val > 0 ? "#1B881F" : "#C62828"
    }, [data])

    const marketChangeComponent = useMemo(() => {
        return (
            <Flex alignItems="center">
                <MarketChange hideBackground percent={data?.["24h_percent"] ?? 0} size="lg" />
                <Text ml="0.25rem" as="span" textStyle={"ManropeSemiboldBodySmall"} color={marketColor}>{`(24H)`}</Text>
            </Flex>
        )
    }, [marketColor, data])

    return (
        <Flex h="full" w="full">
            <Flex w="full" overflowY={"scroll"} mt={{ base: "1rem", lg: "1.5rem" }} className="hide-scroll">
                <Flex
                    flex={1}
                    className="hide-scroll"
                    maxW={isDrawerOpen ? "unset" : `${parseFloat(BreakPoints["2xl"]) * 16 - SIDEBAR_WIDTH_LARGE}px`}
                    m={isDrawerOpen ? "unset" : "0 auto"}
                >
                    <Flex
                        flexDir="column"
                        flex={1}
                        maxW={
                            isDrawerOpen
                                ? {
                                      base: "full",
                                      md: `calc(100% - ${SIDE_DRAWER_RESPONSIVE_WIDTH.md})`,
                                      lg: `calc(100% - ${SIDE_DRAWER_RESPONSIVE_WIDTH.lg})`,
                                      xl: `calc(100% - ${SIDE_DRAWER_RESPONSIVE_WIDTH.xl})`,
                                  }
                                : "100%"
                        }
                    >
                        <Flex flexDir="column" mb={{ base: "1rem", lg: "1.5rem" }} flex={1}>
                            <BreadCrumbGroup
                                mb="0.5rem"
                                breadCrumbs={[
                                    { text: "Trade", onClick: () => navigate(ROUTES.DASHBOARD_TRADE) },
                                    { text: data?.name ?? "" },
                                ]}
                                px={{ base: "1rem", lg: "1.5rem" }}
                            />
                            <Box
                                display="flex"
                                justifyContent={"space-between"}
                                alignItems={{ base: undefined, md: "flex-end" }}
                                flexDir={{ base: "column", md: "row" }}
                                pb={"2rem"}
                                rowGap="1rem"
                                position={"sticky"}
                                top={0}
                                bgColor="white"
                                borderBottomWidth={1}
                                borderBottomColor={"grey.light.10"}
                                px={{ base: "1rem", lg: "1.5rem" }}
                                zIndex={3}
                            >
                                <Flex flex={1} align="center">
                                    {image && (
                                        <Image
                                            mr="0.75rem"
                                            src={image}
                                            alt={"coin-" + asset}
                                            height="60px"
                                            width="60px"
                                        />
                                    )}
                                    <Box display="flex" flexDir={"column"}>
                                        <Text textStyle="ManropeSemiboldBody" whiteSpace={"nowrap"}>
                                            {data?.name}&nbsp;
                                            <Text textStyle="ManropeSemiboldBodySmall" as="span" color="grey.light.50">
                                                {data?.symbol}
                                            </Text>
                                        </Text>
                                        <Skeleton
                                            isLoaded={!isLoading}
                                            display="flex"
                                            alignItems="baseline"
                                            flexWrap={"wrap"}
                                        >
                                            <Text textStyle="ManropeMedium2xLarge" mr="0.75rem">
                                                ${addCommasToNumber(removeTrailingZeros(data?.price ?? "0"))}&nbsp;
                                                <Text as="span" textStyle="ManropeBoldBody">
                                                    {accountCurrency}
                                                </Text>
                                            </Text>
                                            <Box display={{ base: "none", xl: "inline-block" }}>
                                                {marketChangeComponent}
                                            </Box>
                                        </Skeleton>
                                        <Box display={{ base: "block", xl: "none" }}>{marketChangeComponent}</Box>
                                    </Box>
                                </Flex>
                                <Box
                                    display={isDrawerOpen ? "none" : "flex"}
                                    flex={1}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                    columnGap="0.5rem"
                                >
                                    <StandardButton
                                        flexGrow={1}
                                        type="button"
                                        leftIcon={MdAdd}
                                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                        onClick={() => {
                                            openDrawer(BUYSELL_TAB.BUY)
                                        }}
                                        alignSelf="flex-end"
                                        size="xl"
                                    >
                                        {t("buy", { ns: "common" })}
                                    </StandardButton>
                                    <StandardButton
                                        flexGrow={1}
                                        type="button"
                                        leftIcon={MdRemove}
                                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                        onClick={() => {
                                            openDrawer(BUYSELL_TAB.SELL)
                                        }}
                                        alignSelf="flex-end"
                                        size="xl"
                                    >
                                        {t("sell", { ns: "common" })}
                                    </StandardButton>
                                </Box>
                            </Box>
                            <MarketDetailsGraph asset={asset} isDrawerOpen={isDrawerOpen} />
                            <Box p={6} borderTopWidth={1} borderTopColor={"grey.light.10"}>
                                {data && (
                                    <AssetStats
                                        isDrawerOpen={isDrawerOpen}
                                        asset={data}
                                        setSelectedTx={setSelectedTx}
                                        setSelectedOrder={setSelectedOrder}
                                    />
                                )}
                            </Box>
                        </Flex>
                        <DashboardFooter />
                    </Flex>
                </Flex>
            </Flex>
            {/* shoves over the main content by the width of the open drawer (open drawer is pos absolute) */}
            {isDrawerOpen && (
                <Box h="full" w={"fit-content"}>
                    {data && (
                        <DrawerCoinTrade
                            initialAsset={data}
                            isOpen={isDrawerOpen}
                            toggleSideDrawerOpen={closeDrawer}
                            tab={tab}
                            setTab={setTab}
                        />
                    )}
                </Box>
            )}
            <DrawerTransactionDetails
                transaction={selectedTx}
                isOpen={!!selectedTx}
                toggleSideDrawerOpen={() => setSelectedTx(null)}
            />
            <DrawerOpenOrder
                order={selectedOrder}
                isOpen={!!selectedOrder}
                toggleSideDrawerOpen={() => setSelectedOrder(null)}
            />
            <ErrorModal
                title={dialogError?.title}
                description={dialogError?.description}
                isOpen={isModalOpen}
                onClose={closePopup}
            />
        </Flex>
    )
}
