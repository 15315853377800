import { Box, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import useRegion from "@hooks/useRegion"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdInfoOutline } from "react-icons/md"
import Fund from "../fund"
import { CryptoDisclaimers } from "../fund/cryptoDeposit/cryptoDisclaimers"
import { CryptoSelection } from "../fund/cryptoDeposit/cryptoSelection"
import { CryptoWallet } from "../fund/cryptoDeposit/wallet"
import { InteracDisclaimers } from "../fund/interacDeposit/interacDisclaimers"
import { InteracInstructions } from "../fund/interacDeposit/interacInstructions"
import { CAWireDisclaimers } from "../fund/wireDeposit/caWireDeposit/wireDisclaimers"
import { CAWireInstructions } from "../fund/wireDeposit/caWireDeposit/wireInstructions"
import { USWireDisclaimers } from "../fund/wireDeposit/usWireDeposit/wireDisclaimers"
import { USWireInstructions } from "../fund/wireDeposit/usWireDeposit/wireInstructions"
import { DrawerFundContext, FundType, InitialState } from "./DrawerFundContext"

export namespace FundTabs {
    export enum INTERAC {
        ROOT = 0,
        INTERAC_DISCLAIMERS = 1,
        FUND_INTERAC = 2,
    }

    export enum WIRE {
        ROOT = 0,
        WIRE_DISCLAIMERS = 1,
        FUND_WIRE = 2,
    }

    export enum CRYPTO {
        ROOT = 0,
        CRYPTO_DISCLAIMERS = 1,
        CRYPTO_SELECTION = 2,
        CRYPTO_WALLET = 3,
    }
}

const maxViews = {
    [FundType.INTERAC]: Math.max(
        ...(Object.values(FundTabs.INTERAC).filter((value) => typeof value === "number") as number[])
    ),
    [FundType.WIRE]: Math.max(
        ...(Object.values(FundTabs.WIRE).filter((value) => typeof value === "number") as number[])
    ),
    [FundType.CRYPTO]: Math.max(
        ...(Object.values(FundTabs.CRYPTO).filter((value) => typeof value === "number") as number[])
    ),
}

export interface DrawerFundProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    fundType?: FundType
    availableFundTypes?: FundType[]
}

const FundDrawer: React.FC<DrawerFundProps> = ({
    isOpen,
    toggleSideDrawerOpen,
    fundType,
    availableFundTypes = [FundType.CRYPTO, FundType.INTERAC, FundType.WIRE],
}: DrawerFundProps) => {
    const { t } = useTranslation(["app", "common"])
    const {
        currentFundType,
        setCurrentFundType,
        cryptoDisclaimers,
        interacDisclaimers,
        selectedCoin,
        caWireDisclaimers,
        errorMessage,
        setErrorMessage,
        selectedCoinData,
        usWireDisclaimers,
        resetState,
        view,
        setView,
    } = DrawerFundContext.useContainer()

    const { isCAUser } = useRegion()

    useEffect(() => {
        if (fundType) {
            resetState()
            setView(1)
        }
        setCurrentFundType(fundType)
    }, [fundType])

    const ComponentMap: Record<string, JSX.Element> = {
        [0]: <Fund availableFundTypes={availableFundTypes} />,
        [`${FundType.CRYPTO}_${FundTabs.CRYPTO.ROOT}`]: <Fund availableFundTypes={availableFundTypes} />,
        [`${FundType.CRYPTO}_${FundTabs.CRYPTO.CRYPTO_DISCLAIMERS}`]: <CryptoDisclaimers />,
        [`${FundType.CRYPTO}_${FundTabs.CRYPTO.CRYPTO_SELECTION}`]: <CryptoSelection />,
        [`${FundType.CRYPTO}_${FundTabs.CRYPTO.CRYPTO_WALLET}`]: <CryptoWallet />,

        [`${FundType.INTERAC}_${FundTabs.INTERAC.ROOT}`]: <Fund availableFundTypes={availableFundTypes} />,
        [`${FundType.INTERAC}_${FundTabs.INTERAC.INTERAC_DISCLAIMERS}`]: <InteracDisclaimers />,
        [`${FundType.INTERAC}_${FundTabs.INTERAC.FUND_INTERAC}`]: <InteracInstructions />,

        [`${FundType.WIRE}_${FundTabs.WIRE.ROOT}`]: <Fund availableFundTypes={availableFundTypes} />,
        [`${FundType.WIRE}_${FundTabs.WIRE.WIRE_DISCLAIMERS}`]: isCAUser ? (
            <CAWireDisclaimers />
        ) : (
            <USWireDisclaimers />
        ),
        [`${FundType.WIRE}_${FundTabs.WIRE.FUND_WIRE}`]: isCAUser ? <CAWireInstructions /> : <USWireInstructions />,
    }

    const toggleDrawerOpen = () => {
        toggleSideDrawerOpen()
    }

    const handleCryptoDepositNav = () => {
        if (view === maxViews[FundType.CRYPTO]) {
            toggleDrawerOpen()
            return
        }

        if (view === FundTabs.CRYPTO.CRYPTO_DISCLAIMERS) {
            const allDisclaimersAccepted = Object.values(cryptoDisclaimers).every((disclaimer) => disclaimer)
            if (!allDisclaimersAccepted) {
                setErrorMessage(t("fund.errors.allBoxes", { ns: "transfer" }))
                return
            }
        }

        if (view === FundTabs.CRYPTO.CRYPTO_SELECTION && !selectedCoin) {
            setErrorMessage(t("fund.errors.selectCryptoCurrency", { ns: "transfer" }))
            return
        }

        setView((prev) => prev + 1)
    }

    const handleInteracDepositNav = () => {
        if (view === maxViews[FundType.INTERAC]) {
            toggleDrawerOpen()
            return
        }

        if (view === FundTabs.INTERAC.INTERAC_DISCLAIMERS) {
            const allDisclaimersAccepted = Object.values(interacDisclaimers).every((disclaimer) => disclaimer)
            if (!allDisclaimersAccepted) {
                setErrorMessage(t("fund.errors.allBoxes", { ns: "transfer" }))
                return
            }
        }

        setView((prev) => prev + 1)
    }

    const handleWireDepositNav = () => {
        if (view === maxViews[FundType.WIRE]) {
            toggleDrawerOpen()
            return
        }

        if (view === FundTabs.WIRE.WIRE_DISCLAIMERS && isCAUser) {
            const allDisclaimersAccepted = Object.values(caWireDisclaimers).every((disclaimer) => disclaimer)
            if (!allDisclaimersAccepted) {
                setErrorMessage(t("fund.errors.allBoxes", { ns: "transfer" }))
                return
            }
        } else if (view === FundTabs.WIRE.WIRE_DISCLAIMERS && !isCAUser) {
            const allDisclaimersAccepted = Object.values(usWireDisclaimers).every((disclaimer) => disclaimer)
            if (!allDisclaimersAccepted) {
                setErrorMessage(t("fund.errors.allBoxes", { ns: "transfer" }))
                return
            }
        }

        setView((prev) => prev + 1)
    }

    const handleButtonClick = async () => {
        setErrorMessage("")

        if (!currentFundType) {
            setErrorMessage(t("fund.errors.fundingMethod", { ns: "transfer" }))
            return
        }

        switch (currentFundType) {
            case FundType.CRYPTO:
                handleCryptoDepositNav()
                break
            case FundType.INTERAC:
                handleInteracDepositNav()
                break
            case FundType.WIRE:
                handleWireDepositNav()
                break
        }
    }

    const isButtonLoading = useMemo(() => {
        return false
    }, [])

    const RenderView = () => {
        if (!currentFundType) {
            return ComponentMap[0]
        }

        const key = `${currentFundType}_${view}`
        return ComponentMap[key]
    }

    const ButtonText = useMemo(() => {
        if (!currentFundType) {
            return t("common:continue")
        }

        if (view === maxViews[currentFundType]) {
            return t("common:done")
        }

        return t("common:continue")
    }, [view])

    const handleBackNavigation = () => {
        setView((prev) => prev - 1)
        setErrorMessage("")
    }

    const showPrefundingWarning = useMemo(() => {
        if (
            selectedCoinData &&
            view === FundTabs.CRYPTO.CRYPTO_SELECTION &&
            currentFundType === FundType.CRYPTO &&
            selectedCoinData?.warnings?.prefunding
        ) {
            return true
        }
        return false
    }, [selectedCoinData, view, currentFundType])

    const toggleOpen = () => {
        setView(fundType ? 1 : 0)
        resetState()
        toggleSideDrawerOpen()
    }

    return (
        <SideDrawer
            name="fund"
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleOpen}
            size="md"
            header={
                <StandardDrawerHeader onBack={view === 0 ? undefined : handleBackNavigation} onClose={toggleOpen} />
            }
            footerButton={{
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                onClick: handleButtonClick,
                children: ButtonText,
                isLoading: isButtonLoading,
                type: "button",
            }}
            overrideBodyOverflow={view === FundTabs.CRYPTO.CRYPTO_SELECTION}
            extraFooter={
                <Box display={"flex"} justifyContent={"center"}>
                    {showPrefundingWarning && (
                        <Box mb={6} w="100%">
                            <InfoAlert
                                variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                                icon={{
                                    icon: MdInfoOutline,
                                }}
                            >
                                {selectedCoinData?.warnings?.prefunding || ""}
                            </InfoAlert>
                        </Box>
                    )}
                    {errorMessage && (
                        <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3}>
                            {errorMessage}
                        </Text>
                    )}
                </Box>
            }
        >
            {RenderView()}
        </SideDrawer>
    )
}

const FundDrawerHOC: React.FC<DrawerFundProps> = (props) => (
    <DrawerFundContext.Provider initialState={{ ...props, ...InitialState }}>
        <FundDrawer {...props} />
    </DrawerFundContext.Provider>
)

export default FundDrawerHOC
