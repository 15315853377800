import { Text, Box, Stack, Checkbox as Check, FormControl, FormControlProps } from "@chakra-ui/react"
import { CheckboxGroup } from "@chakra-ui/react"
import { StringOrNumber } from "@chakra-ui/utils"
import ErrorMessage from "@components/ui/errorMessage"
import { Option } from "@components/ui/types"
import { UseControllerProps, Controller } from "react-hook-form"
import { CheckboxStyles } from ".."
// import Checkbox from "#/components/ui/checkbox";

type _Props = {
    options: Option<any>[]
    size?: "sm" | "md" | "lg"
    // defaultChecked?: string[]; // not implemented
}

type Props = _Props & UseControllerProps<any> & FormControlProps

export default function Checkboxes({
    name,
    options,
    control,
    rules,
    // defaultChecked,
    size = "lg",
    disabled,
    ...rest
}: Props) {
    const mapToValues = (opts: Option<any>[]): string[] => opts.map((opt: any) => opt.value.toString())
    const mapToOptions = (values: StringOrNumber[]) => values.map((val: any) => options.find((opt) => opt.value == val))

    return (
        <FormControl {...rest}>
            <Controller
                name={name}
                control={control}
                defaultValue={[]}
                rules={rules}
                render={({ field: { onChange: fieldOnChange, value: fieldValue }, fieldState: { error } }) => (
                    <Box>
                        <CheckboxGroup
                            colorScheme="white"
                            size={size}
                            value={mapToValues(fieldValue)}
                            onChange={(checkBoxValues) => fieldOnChange(mapToOptions(checkBoxValues))}
                        >
                            <Stack spacing="1.5rem">
                                {options.map((option) => (
                                    <Check
                                        {...CheckboxStyles}
                                        value={option.value.toString()}
                                        key={option.value.toString()}
                                    >
                                        <Text textStyle="InterRegularBody">{option.label}</Text>
                                    </Check>
                                ))}
                            </Stack>
                        </CheckboxGroup>
                        {!!error && <ErrorMessage>{error?.message}</ErrorMessage>}
                    </Box>
                )}
            ></Controller>
        </FormControl>
    )
}
