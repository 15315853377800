import { Flex, Radio, RadioGroup, useDisclosure, Text, Stack } from "@chakra-ui/react"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import SideDrawer from "@components/sideDrawer"
import FilterDrawerHeader from "@components/sideDrawer/headers/filterDrawerHeader"
import { filterOptions } from "./constants"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import MobileFilterButton from "@components/ui/select/mobileFilterButton"
import DatePicker from "@components/ui/datePicker"

interface Props {
    cardType: string
    setCardType: Dispatch<SetStateAction<string>>
    startDate: Date | null
    setStartDate: Dispatch<SetStateAction<Date | null>>
    endDate: Date | null
    setEndDate: Dispatch<SetStateAction<Date | null>>
}

const MobileFilterDrawer: React.FC<Props> = ({
    cardType: initialCardType,
    setCardType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {
    const { t: ct } = useTranslation("common")
    const { isOpen, onOpen, onToggle } = useDisclosure()
    const filterOptionsValues = filterOptions()

    const [localCardType, setLocalCardType] = useState("")
    const [localStartDate, setLocalStartDate] = useState<Date | null>(null)
    const [localEndDate, setLocalEndDate] = useState<Date | null>(null)

    const saveFilters = () => {
        setCardType(localCardType)
        setStartDate(localStartDate)
        setEndDate(localEndDate)
        onToggle()
    }

    useEffect(() => {
        setLocalCardType(initialCardType)
        setLocalStartDate(startDate || null)
        setLocalEndDate(endDate || null)
    }, [startDate, endDate, initialCardType])

    const handleClear = () => {
        setLocalCardType(filterOptionsValues[0].value)
        setLocalStartDate(null)
        setLocalEndDate(null)
    }

    return (
        <Flex display={{ base: "flex", md: "none" }}>
            <MobileFilterButton setIsOpen={onOpen} text={ct("filters")} />

            <SideDrawer
                name="mobileFilterDrawer"
                isOpen={isOpen}
                size="full"
                contentPadding={4}
                toggleSideDrawerOpen={onToggle}
                header={
                    <FilterDrawerHeader
                        title={ct("filterBy")}
                        onClose={onToggle}
                        clearButtonLabel={ct("clear")}
                        onClear={handleClear}
                    />
                }
                footerButton={{
                    children: ct("save"),
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID,
                    type: "button",
                    size: "md",
                    onClick: saveFilters,
                }}
            >
                <Flex gap={3} mt={2} flexDir="column">
                    <RadioGroup onChange={setLocalCardType} value={localCardType}>
                        <Stack gap={0}>
                            {filterOptionsValues.map(({ label, value }) => (
                                <Radio value={value} key={value} gap={1} py={2} px={4}>
                                    <Text textStyle="ManropeMediumBodySmall">{label}</Text>
                                </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>

                    <Flex px={4} py={3} borderTop={"1px solid"} borderColor={"grey.light.10"} mt={3} pt={6}>
                        <Text textStyle="ManropeSemiboldBodySmall">{ct("customDateRange")}</Text>
                    </Flex>

                    <Flex gap={2} alignItems="center" px={4} pb={3}>
                        <DatePicker
                            selectedDate={localStartDate}
                            label={ct("startDate")}
                            maxDate={localEndDate}
                            onDateChange={(date) => setLocalStartDate(date)}
                        />

                        <Text textStyle="ManropeMediumBodySmall">-</Text>

                        <DatePicker
                            selectedDate={localEndDate}
                            minDate={localStartDate}
                            label={ct("endDate")}
                            onDateChange={(date) => setLocalEndDate(date)}
                        />
                    </Flex>
                </Flex>
            </SideDrawer>
        </Flex>
    )
}

export default MobileFilterDrawer
