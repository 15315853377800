import { Flex, Skeleton, Text } from "@chakra-ui/react"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

function isZero(value: number): boolean {
    return value === 0
}

export default function CryptoToCashRatio() {
    const { t } = useTranslation("app")
    const { fiatValue, cryptoValue, isLoadingBalances, isLoadingPrices } = useCalculateBalances()

    const [cryptoPercentage, cashPercentage] = useMemo(() => {
        const totalValue = cryptoValue + fiatValue
        if (totalValue === 0) return [0, 0]

        const cryptoPct = Number(((cryptoValue / totalValue) * 100).toFixed(2))
        const cashPct = Number(((fiatValue / totalValue) * 100).toFixed(2))

        return [cryptoPct, cashPct]
    }, [cryptoValue, fiatValue])

    const isBothZero = useMemo(
        () => isZero(cryptoPercentage) && isZero(cashPercentage),
        [cryptoPercentage, cashPercentage]
    )

    const isLoading = useMemo(() => isLoadingBalances || isLoadingPrices, [isLoadingBalances, isLoadingPrices])

    return (
        <Flex direction="column" width="100%" gap={3}>
            <Flex justify="space-between" align="center" pt={1} width="100%">
                <Flex direction="column" gap={0.5}>
                    <Text textStyle="ManropeSemiboldBodySmall">{t("dashboard.crypto")}</Text>
                    <Skeleton minW={"40px"} h={"12px"} isLoaded={!isLoading}>
                        <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                            {cryptoPercentage}%
                        </Text>
                    </Skeleton>
                </Flex>
                <Flex direction="column" alignItems={"flex-end"} textAlign={"end"} gap={0.5}>
                    <Text textStyle="ManropeSemiboldBodySmall">{t("dashboard.cash")}</Text>
                    <Skeleton minW={"40px"} h={"12px"} isLoaded={!isLoading}>
                        <Text color="grey.light.50" textStyle="ManropeSemiboldXSmall">
                            {cashPercentage}%
                        </Text>
                    </Skeleton>
                </Flex>
            </Flex>

            <Flex align="center" gap={isBothZero ? 0 : 1} width="100%">
                {isBothZero || isLoading ? (
                    <Flex width="100%" bg="grey.light.10" height="6px" borderRadius="8px" />
                ) : (
                    <>
                        <Flex height="6px" borderRadius={"8px"} bg="blue.100" width={`${cryptoPercentage}%`} />
                        <Flex height="6px" borderRadius={"8px"} bg="darkBlue.100" width={`${cashPercentage}%`} />
                    </>
                )}
            </Flex>
        </Flex>
    )
}
