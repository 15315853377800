import { Text } from "@chakra-ui/react"
import { useRef, useState } from "react"
import PlainModal from "@components/ui/modals/plain"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import useGenericToast from "@hooks/useGenericToast"
import { useDeleteAuthorizedDeviceMutation } from "@redux/account/apiSlice"
import { AuthorizedDevice } from "../authorizedDeviceTable"

type Props = {
    isOpen: boolean
    toggleOpen: () => void
    setFocusedDevice: React.Dispatch<React.SetStateAction<AuthorizedDevice | null>>
    focusedDevice: AuthorizedDevice | null
}

export default function DeleteAuthDevice({ isOpen, toggleOpen, focusedDevice, setFocusedDevice }: Props) {
    const { t } = useTranslation("settings")
    const cancelRef = useRef<HTMLButtonElement>(null)
    const { successToast } = useGenericToast()
    const [deleteAuthorizedDevice, { isLoading: deleteLoading }] = useDeleteAuthorizedDeviceMutation()

    const [err, setErr] = useState("")

    async function handleDelete() {
        await deleteAuthorizedDevice(focusedDevice?.deviceID as string)
            .unwrap()
            .then(() => {
                toggleOpen()
                successToast(t("authorizedDevices.deleted"))
                setFocusedDevice(null)
            })
            .catch((e) => {
                const err = e.data?.errors ? Object.values(e.data?.errors)[0] : undefined
                setErr((err as string) ?? t("authorizedDevices.error.delete"))
            })
    }

    return (
        <PlainModal
            name="deleteAuthDevice"
            isOpen={isOpen}
            title={t("authorizedDevices.deleteDeviceTitle")}
            cancelRef={cancelRef}
            onClose={toggleOpen}
            err={err}
            showErrIcon
            buttons={[
                {
                    children: t("authorizedDevices.deleteDeviceConfirmation"),
                    onClick: handleDelete,
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    isLoading: deleteLoading,
                },
            ]}
        >
            <Text textStyle="InterRegularBody">{t("authorizedDevices.deleteDeviceDescription")}</Text>
        </PlainModal>
    )
}
