import { Box, Button, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuList, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Option } from "../../types"

import { MdChevronRight, MdOutlineSearch } from "react-icons/md"
import { CoinOption } from "./coinOption"

interface CoinSelectProps<T extends string | number> {
    options: Option<T>[]
    selectedOption?: Option<T>
    setSelectedOption: (options: Option<T>) => void
    search?: boolean
}

const CoinSelect = <T extends string | number>({
    search,
    options,
    selectedOption,
    setSelectedOption: setSelectedOptionProps,
}: CoinSelectProps<T>) => {
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false)

    const setSelectedOption = (option: Option<T>) => {
        setSelectedOptionProps(option)
        setIsOpen(false)
    }

    const handleCancel = () => {
        setIsOpen(false)
    }

    const filteredOptions = options.filter((option) => {
        const term = searchTerm.trim().toLowerCase()
        return option.label.toLowerCase().includes(term) || option.value.toString().toLowerCase().includes(term)
    })

    return (
        <Box>
            <Menu matchWidth={true} isOpen={isOpen} onClose={() => handleCancel()}>
                <MenuButton
                    height={"66px"}
                    as={Button}
                    textStyle={"ManropeSemiboldBody"}
                    fontWeight={500}
                    rightIcon={
                        <MdChevronRight
                            color="black"
                            style={{ transform: `rotate(${isOpen ? 270 : 90}deg)` }}
                            size={18}
                        />
                    }
                    width="100%"
                    textAlign="left"
                    variant="outline"
                    borderColor="grey.light.10"
                    onClick={() => setIsOpen(!isOpen)}
                    _active={{ bg: "grey.light.5 !important" }}
                    _hover={{ bg: "unset", bgColor: "unset" }}
                    sx={{
                        "& > span": {
                            display: "inline-block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            "& > div": {
                                padding: 0,
                            },
                        },
                    }}
                >
                    {selectedOption && <CoinOption option={selectedOption} onClick={() => {}} selected={false} />}
                </MenuButton>
                <MenuList padding={0}>
                    <Box paddingBottom={3}>
                        {search && (
                            <InputGroup py="0.75rem" ml="0.5rem">
                                <InputLeftElement pointerEvents="none" alignItems="flex-end">
                                    <MdOutlineSearch color="black" size={18} />
                                </InputLeftElement>
                                <Input
                                    variant={"flushed"}
                                    placeholder="Search"
                                    _placeholder={{ color: "grey.light.40" }}
                                    fontSize="14px"
                                    fontWeight={500}
                                    fontFamily="Manrope"
                                    lineHeight={"150%"}
                                    letterSpacing={"0.15px"}
                                    fontStyle="normal"
                                    value={searchTerm}
                                    border="none !important"
                                    boxShadow={"none !important"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                                />
                            </InputGroup>
                        )}

                        <VStack
                            align="stretch"
                            spacing={0}
                            maxHeight="400px"
                            overflowY="auto"
                            borderColor="grey.light.10"
                            borderTopWidth={"1px"}
                            pt="10px"
                        >
                            {filteredOptions.map((option) => (
                                <CoinOption
                                    key={option.value}
                                    option={option}
                                    onClick={() => setSelectedOption(option)}
                                    selected={selectedOption?.value === option.value}
                                />
                            ))}
                        </VStack>
                    </Box>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default CoinSelect
