import { selectAccountDetails } from "@redux/account/selectors"
import { createSelector } from "@reduxjs/toolkit"
import { getPrices } from "./apiSlice"
import { IPricesResponse } from "./types"

export const getPriceForAsset = (prices: IPricesResponse, asset: string, currency: string) => {
    const currencyPair = `${asset}:${currency}`
    if (prices[currencyPair]) {
        return prices[currencyPair]
    }
    return null
}

const pricesResult = getPrices.select(undefined)

export const selectAssetPrice = createSelector(
    [
        pricesResult,
        selectAccountDetails,
        (_state, assetCurrency: string, fiatCurrency?: string) => ({
            assetCurrency,
            fiatCurrency,
        }),
    ],
    (prices, accountDetails, assetFiatPair) => {
        const baseCurrency = assetFiatPair.fiatCurrency || accountDetails?.currency || "CAD"
        if (!prices.data) {
            return null
        }
        return getPriceForAsset(prices.data, assetFiatPair.assetCurrency.toUpperCase(), baseCurrency)
    }
)
