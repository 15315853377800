import { Text } from "@chakra-ui/react"
import { ColorKeys } from "@theme/chakra"
import { TERMS_OF_SERVICE_URL_CA, TERMS_OF_SERVICE_URL_US } from "@util/config"
import { useTranslation } from "react-i18next"

export default function Footer({ linkColor, textColor }: { linkColor: ColorKeys; textColor: ColorKeys }) {
    const { t } = useTranslation(["legal", "common"])

    function openTermsOfService() {
        window.open(TERMS_OF_SERVICE_URL_CA, "_blank")
    }

    function openPrivacyPolicy() {
        window.open(TERMS_OF_SERVICE_URL_US, "_blank")
    }

    return (
        <Text data-testid="footer" color={textColor} textAlign="center" textStyle="InterRegularNano" mt="1.5rem">
            {`${t("footer.footer-main")}`}&nbsp;
            <Text as="span" color={linkColor}>
                <Text as="span" onClick={openTermsOfService} cursor="pointer">
                    {t("terms", { ns: "common" })}&nbsp;
                </Text>
                <Text as="span" color="bluePurple.30">
                    |&nbsp;
                </Text>
                <Text as="span" onClick={openPrivacyPolicy} cursor="pointer">
                    {t("privacyPolicy", { ns: "common" })}
                </Text>
            </Text>
        </Text>
    )
}
