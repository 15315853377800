import TransactionsRow from "@components/transactions/transactionsRow"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { TransactionAction } from "@redux/transactions/types"
import { isFiatCurrency } from "@util/currencyHelpers"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { TransactionCardProps } from ".."

export const Reward: React.FC<TransactionCardProps> = ({ transaction, viewMore, isLast, forcedSizeVariant }, props) => {
    const { increase_currency, increase_amount, asset, created_at_pst, action, rate, counter_asset } = transaction || {}

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const incAmount = useMemo(() => {
        if (isFiatCurrency(increase_currency)) {
            return formatDollar(increase_amount)
        }

        const precisionToUse = data?.precision

        return formatNumberFixedPrecision(increase_amount, Number(precisionToUse), true)
    }, [increase_amount, increase_currency, data])

    const isIssuedKohoReward = action === TransactionAction.KohoReward

    return (
        <TransactionsRow
            forcedSizeVariant={forcedSizeVariant}
            transaction={{
                date: created_at_pst,
                description: `${isIssuedKohoReward ? "Netcoins Pay Rewards Earned" : "Rewards Earned"}`,
                status: "rewards",
                mainCurrency: increase_currency,
                mainAmount: incAmount,
                secAmount: formatDollar(String(Number(rate) * Number(increase_amount))),
                secCurrency: counter_asset,
            }}
            isReward
            handleViewMore={viewMore}
            isLast={isLast}
            {...props}
        />
    )
}
