import { useAppSelector } from "@/store/hooks"
import { selectAuthUser } from "@redux/auth/selectors"

export const useAuth = () => {
    const user = useAppSelector(selectAuthUser)
    const isAuthenticated = Boolean(!user?.expired)

    return {
        isAuthenticated,
    }
}
