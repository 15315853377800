import { useAppSelector } from "@/store/hooks"
import { useGetLegacyAccountQuery } from "@redux/account/apiSlice"
import {
    selectAccountDetails,
    selectIsAccountEvaluationState,
    selectIsAccountRestricted,
} from "@redux/account/selectors"
import { AccountType, SurveyType } from "@redux/account/types"
import { isSixty } from "@screens/onboarding/whenBorn"
import { useMemo } from "react"

export enum InternalUserStatus {
    REQUIRES_UPDATE = "REQUIRES_UPDATE",
    PENDING_REVIEW = "PENDING_REVIEW",
    RESTRICTED = "RESTRICTED",
    FULL_ACCESS = "FULL_ACCESS",
}

export const useAssessUserStatus = () => {
    const account = useAppSelector(selectAccountDetails)
    const isEvaluationState = useAppSelector(selectIsAccountEvaluationState)
    const isRestricted = useAppSelector(selectIsAccountRestricted)
    const { data: legacyAccount, isLoading: isLoadingLegacyAccount } = useGetLegacyAccountQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })

    const status = useMemo(() => {
        if (isLoadingLegacyAccount || !account) {
            return undefined
        }
        if (!account.verification.verified) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (account.disclaimers.terms_of_service?.accepted_at === null) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (account.verification.verified && !account.identity.occupation && account.type !== AccountType.BUSINESS) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (account.verification.verified && !account.identity.citizenship) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (isSixty(account?.identity.dob) && account.disclaimers.overage_third_party?.accepted_at === null) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (legacyAccount?.verification.details.promptToUpdateKyc) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        const purposeAccountSurvey = account.verification?.surveys?.find((surv) => {
            return surv.name === SurveyType.PURPOSE_OF_ACCOUNT
        })
        if (purposeAccountSurvey && purposeAccountSurvey.passed !== true) {
            return InternalUserStatus.REQUIRES_UPDATE
        }
        if (isEvaluationState) {
            return InternalUserStatus.PENDING_REVIEW
        }
        if (isRestricted) {
            return InternalUserStatus.RESTRICTED
        }

        return InternalUserStatus.FULL_ACCESS
    }, [account, legacyAccount, isLoadingLegacyAccount])

    return {
        status,
    }
}
