import { IAccountDetails } from "@redux/account/types"
import { REG_SCREENS } from "./types"
import React, { FunctionComponent, useMemo } from "react"
import Surveys from "./surveys"
import AccountType from "./accountType"
import WhereIncorporated from "./whereIncorporated"
import WhereReside from "./whereReside"
import { AccountType as AccountTypeEnum } from "@redux/account/types"
import { SCREEN_MAPPING } from "./mappings"
import useGenericToast from "@hooks/useGenericToast"
import { TypedMutationTrigger } from "@reduxjs/toolkit/dist/query/react"
import OnMailingList from "./onMailingList"
import PendingApproval from "./pendingApproval"
import { SurveyData } from "./surveys/types"
import NotNA from "./whereIncorporated/notNA"
import { useLazyGetSurveyQuery } from "@redux/survey/apiSlice"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import SurveyError from "./surveys/surveyError"
import WhenBorn from "./whenBorn"

export const BIG_MAX_WIDTH_SCREENS = [REG_SCREENS.Surveys, REG_SCREENS.RiskStatement]
export const NO_BACK_BUTTON_SCREENS_BOTH = [REG_SCREENS.HowDidYouHear, REG_SCREENS.RiskStatement]
export const NO_BACK_BUTTON_SCREENS_INDIVIDUAL = [REG_SCREENS.WhereReside, REG_SCREENS.WhereLive]
export const NO_BACK_BUTTON_SCREENS_BUSINESS = [REG_SCREENS.BeInTouch, REG_SCREENS.WhereIncorporated]

export type SubScreenProps = {
    advancePhase: () => void
    submitSubForm: <T, U>(
        mutation: TypedMutationTrigger<T, U, any>,
        values: U,
        analyticsEvent?: () => void
    ) => Promise<void>
    isLoading: boolean
    userObj: IAccountDetails
    setIsBackVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export default function useOnboarding() {
    const { serverErrorToast } = useGenericToast()

    const [getSurvey] = useLazyGetSurveyQuery()
    const userObj = useAppSelector(selectAccountDetails)

    const [isBackVisible, setIsBackVisible] = React.useState(false)
    const [surveyErrorCode, setSurveyErrorCode] = React.useState<"501" | "502">()
    const [isLoading, setIsLoading] = React.useState(false)
    const [phases, setPhases] = React.useState<REG_SCREENS[]>([])
    const [surveys, setSurveys] = React.useState<{ name: string; data: SurveyData }[]>([])
    const [currentPhase, setCurrentPhase] = React.useState<{
        name?: REG_SCREENS
        num?: number
    }>({
        name: undefined,
        num: undefined,
    })

    // certain screens are a point-of-no-return
    React.useEffect(() => {
        if (currentPhase?.name && currentPhase.num !== 0) {
            setIsBackVisible(!NO_BACK_BUTTON_SCREENS.includes(currentPhase.name))
        }
    }, [currentPhase])

    const NO_BACK_BUTTON_SCREENS = React.useMemo(() => {
        if (userObj?.type === AccountTypeEnum.INDIVIDUAL) {
            return NO_BACK_BUTTON_SCREENS_BOTH.concat(NO_BACK_BUTTON_SCREENS_INDIVIDUAL)
        } else if (userObj?.type === AccountTypeEnum.BUSINESS) {
            return NO_BACK_BUTTON_SCREENS_BOTH.concat(NO_BACK_BUTTON_SCREENS_BUSINESS)
        } else {
            return NO_BACK_BUTTON_SCREENS_BOTH
        }
    }, [userObj?.type])

    function countSurveyScreens(surveys: { name: string; data: SurveyData }[]): number {
        let counter = 0
        for (let x = 0; x < surveys.length; x++) {
            counter += Object.keys(surveys[x].data.categories).length
        }
        return counter
    }

    // used for each subform
    async function submitSubForm<T, U>(
        mutation: TypedMutationTrigger<T, U, any>,
        values: U,
        analyticsEvent?: () => void
    ) {
        setIsLoading(true)
        await mutation(values)
            .unwrap()
            .then(() => {
                analyticsEvent && analyticsEvent()
                advancePhase()
            })
            .catch((e: any) => {
                // Survey failed or failed so bad they're banned
                if (e.code === "501" || e.code === "502") {
                    setSurveyErrorCode(e.code)
                } else {
                    serverErrorToast()
                }
            })
            .finally(() => setIsLoading(false))
    }

    // Variety of scenarios that result in a screen that's not part of normal onboarding
    const renderBlockedFromOnboardingScreen = React.useMemo(() => {
        if (userObj?.userState === "Pending Evaluation") {
            return <PendingApproval isCanada={userObj?.country === "CA"} />
        } else if (userObj?.country === "XX") {
            return <NotNA />
        } else if (userObj?.verification?.status === "on_mailing_list") {
            return <OnMailingList />
        } else if (userObj?.verification?.surveys.some((survey) => !!survey.failed_at)) {
            const arr = userObj?.verification.surveys
            if (arr) {
                for (let i = 0; i < arr.length; i++) {
                    if (!!arr[i].failed_at) {
                        const supportEmail = userObj?.country ? arr[i]?.support_emails?.[userObj?.country] : undefined
                        return (
                            <SurveyError
                                errorMessages={arr[i].error_messages}
                                errorCode={arr[i].error_code}
                                errorHeader={arr[i].error_header}
                                supportEmail={supportEmail}
                            />
                        )
                    }
                }
            }
            return null
        } else {
            return null
        }
    }, [userObj])

    // HOC for passing onboarding props to each subform
    function withOnboardingProps<T>(Component: FunctionComponent<T & SubScreenProps>) {
        return (props: T) => (
            <Component
                {...props}
                isLoading={isLoading}
                advancePhase={advancePhase}
                submitSubForm={submitSubForm}
                userObj={userObj as IAccountDetails}
                setIsBackVisible={setIsBackVisible}
            />
        )
    }

    async function doSurveyData(_data: IAccountDetails): Promise<{ name: string; data: SurveyData }[]> {
        const surveyFetches = _data.verification.surveys.reduce(
            (arr, surveyData, i) => {
                if (surveyData.passed === false) {
                    arr.push(
                        getSurvey(surveyData.name)
                            .unwrap()
                            .then((res) => ({ name: surveyData.name, data: res }))
                    )
                }
                return arr
            },
            [] as Promise<{ name: string; data: SurveyData }>[]
        )

        let _surveys: { name: string; data: SurveyData }[] = []

        await Promise.all(surveyFetches)
            .then((res: { name: string; data: SurveyData }[]) => {
                // Filter out categories with no questions (includes filtering out the mobile-only categories)
                const surveys = res.map((survey) => ({
                    name: survey.name,
                    data: {
                        ...survey.data,
                        categories: survey.data.categories.filter((category) =>
                            survey.data.questions.some((question) => question.category === category.name)
                        ),
                    },
                }))
                setSurveys(surveys)
                _surveys = surveys
            })
            .catch(serverErrorToast)
        return _surveys
    }

    const renderedScreen = React.useMemo(() => {
        switch (currentPhase.name) {
            case REG_SCREENS.WhereReside: {
                return withOnboardingProps(WhereReside)({
                    setIsLoading,
                    countSurveyScreens,
                    phases,
                    setCurrentPhase,
                    doSurveyData,
                    setPhases,
                })
            }
            case REG_SCREENS.WhenBorn: {
                return withOnboardingProps(WhenBorn)({ setPhases, setSurveys })
            }
            case REG_SCREENS.WhereIncorporated: {
                return withOnboardingProps(WhereIncorporated)({
                    setIsLoading,
                    countSurveyScreens,
                    phases,
                    setCurrentPhase,
                    doSurveyData,
                    setPhases,
                })
            }
            case REG_SCREENS.AccountType: {
                return withOnboardingProps(AccountType)({
                    setIsLoading,
                    phases,
                    setCurrentPhase,
                    setPhases,
                })
            }
            case REG_SCREENS.Surveys:
                return withOnboardingProps(Surveys)({
                    surveys,
                    surveyErrorCode,
                    setSurveyErrorCode,
                    setCurrentPhase,
                    setIsBackVisible,
                })
            default:
                return currentPhase?.name ? withOnboardingProps(SCREEN_MAPPING[currentPhase.name])({}) : <></>
        }
    }, [
        currentPhase,
        isLoading,
        surveyErrorCode,
        surveys,
        phases,
        setPhases,
        userObj,
        countSurveyScreens,
        setIsLoading,
    ])

    const progressValue = useMemo(() => {
        // At this point we're unable to determine the phases length. A fake value is returned instead
        const lenIs2 = phases.length === 2
        const firstIsAccountType = phases[0] === REG_SCREENS.AccountType
        const secondIsWhere = phases[1] === REG_SCREENS.WhereReside || phases[1] === REG_SCREENS.WhereIncorporated
        if (lenIs2 && firstIsAccountType && secondIsWhere) {
            return 8
        }
        return currentPhase.num ? Math.round((currentPhase.num / phases.length) * 100) : 0
    }, [currentPhase, phases])

    function advancePhase() {
        // onboarding is completed. navigate to dashboard
        if (currentPhase.num === phases.length - 1) {
            window.open("/dashboard", "_self")
        }

        setCurrentPhase((prev) => {
            if (typeof prev.num !== "number" || prev.num >= phases.length - 1) return prev
            return { name: phases[prev.num + 1], num: prev.num + 1 }
        })
    }

    return {
        isBackVisible,
        progressValue,
        renderedScreen,
        currentPhase,
        setPhases,
        phases,
        setCurrentPhase,
        doSurveyData,
        countSurveyScreens,
        renderBlockedFromOnboardingScreen,
        setIsBackVisible,
    }
}
