import { Box, Flex, HStack, keyframes, Skeleton, Text, useBreakpointValue, useToken, VStack } from "@chakra-ui/react"
import { TextStyles } from "@theme/textStyles"
import * as d3 from "d3"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

type DataItem = {
    name: string
    symbol: string
    value: number
}
type ChartProps = {
    data: DataItem[]
    height?: number
    width?: number
    onMouseEnter: (data: DataItem) => void
    onMouseLeave: () => void
    selectedData: DataItem | null
    isLoading?: boolean
}

type DonutChartProps = {
    data: DataItem[]
    isLoading?: boolean
    height?: number
    width?: number
}

const colors = [
    "#5551C5",
    "#F5C754",
    "#3DA76B",
    "#E24037",
    "#60CEE6",
    "#FF8B3D",
    "#C19DEE",
    "#ABD451",
    "#E87D9E",
    "#47A7FF",
]
const colors12 = [
    "#5551C5",
    "#F5C754",
    "#3DA76B",
    "#3D65F6",
    "#E24037",
    "#60CEE6",
    "#FF8B3D",
    "#C19DEE",
    "#1A40A2",
    "#ABD451",
    "#E87D9E",
    "#47A7FF",
]

const arcGenerator = d3.arc()

const animationStyle = {
    animationDuration: "10s",
    transitionDuration: "10s",
    fadeDuration: 10,
    speed: 0.8,
}

const Donut = ({
    data,
    height = 200,
    width = 200,
    onMouseLeave,
    onMouseEnter,
    selectedData,
    isLoading,
}: ChartProps) => {
    const selectedColors = data.length > 10 ? colors12 : colors
    const [grey5, grey10] = useToken("colors", ["grey.light.5", "grey.light.10"])
    const { t } = useTranslation("common")
    const hasOthers = data.findIndex((item) => item.name === "Others") !== -1

    var bgFade = keyframes({
        from: {
            borderColor: grey5,
            background: grey10,
        },
        to: {
            borderColor: grey5,
            background: grey10,
        },
    })

    const pie = useMemo(() => {
        if (data.length === 0 || isLoading) {
            // Create a single full circle for empty state
            return [
                {
                    startAngle: 0,
                    endAngle: 2 * Math.PI,
                    value: 1,
                    data: { name: "", symbol: "", value: 0 },
                },
            ]
        }

        // Calculate minimum value for visual representation (1% of total)
        const total = data.reduce((sum, item) => sum + item.value, 0)
        const minVisibleValue = total * 0.01

        const adjustedData = data.map((item) => ({
            ...item,
            value: item.value < minVisibleValue ? minVisibleValue : item.value,
        }))

        const pieGenerator = d3
            .pie<any, DataItem>()
            .value((d) => d.value)
            .sortValues(null)

        return pieGenerator(adjustedData)
    }, [data, isLoading])
    return (
        <svg width={width} height={height} style={{ display: "inline-block" }}>
            <g transform={`translate(${width / 2}, ${height / 2})`}>
                {pie.map((slice, i) => {
                    const isSelected = selectedData?.name === data[i]?.name

                    const arc = arcGenerator({
                        innerRadius: 60,
                        outerRadius: 75,
                        padAngle: data.length === 0 ? 0 : 0.02,
                        startAngle: slice.startAngle,
                        endAngle: slice.endAngle,
                    })

                    return (
                        <path
                            key={i}
                            d={arc || ""}
                            fill={data.length === 0 || isLoading ? grey10 : selectedColors[i]}
                            opacity={selectedData ? (isSelected ? 1 : 0.3) : 1}
                            onMouseEnter={() => data.length > 0 && onMouseEnter(data[i])}
                            onMouseLeave={onMouseLeave}
                            style={{
                                transform: isSelected ? `scale(1.1)` : `scale(1)`,
                                transformOrigin: "center",
                                transformBox: "fill-box",
                                transition: "all 0.3s ease",
                                animation: isLoading
                                    ? `${animationStyle.speed}s linear infinite alternate ${bgFade}`
                                    : undefined,
                            }}
                        />
                    )
                })}
                <text textAnchor="middle" dominantBaseline="middle" style={{ ...TextStyles.ManropeMedium4xLarge }}>
                    {!isLoading && data.length}
                    {hasOthers && "+"}
                </text>
                <text
                    textAnchor="middle"
                    dominantBaseline="middle"
                    dy="20"
                    style={{ ...TextStyles.ManropeSemiboldBodySmall }}
                >
                    {!isLoading && t(data.length > 1 ? "assets" : "asset")}
                </text>
            </g>
        </svg>
    )
}

const LoadingLegendItem = () => {
    return (
        <Flex flex="1" gap={2}>
            <Skeleton w={"8px"} />

            <Flex direction="column" flex={1} gap={2}>
                <Skeleton w={"150px"} h={"18px"} />
                <Skeleton w={"50px"} h={"18px"} />
            </Flex>
        </Flex>
    )
}

const LegendItem = ({
    item,
    color,
    isSelected,
    hasSelection,
    size = "md",
}: {
    item: DataItem
    color: string
    isSelected: boolean
    hasSelection: boolean
    size?: "sm" | "md"
}) => (
    <Flex flex="1">
        <Box
            w="8px"
            bg={color}
            mr={2}
            borderRadius="2px"
            opacity={hasSelection ? (isSelected ? 1 : 0.3) : 1}
            transition="opacity 0.3s ease"
        />
        <Flex direction="column" flex={1} minW="0">
            <Flex alignItems="flex-end" width="full">
                <Text
                    color="black"
                    noOfLines={1}
                    textStyle={size === "md" ? "ManropeRegularBodySmall" : "ManropeSemiboldXSmall"}
                    opacity={hasSelection ? (isSelected ? 1 : 0.3) : 1}
                    transition="opacity 0.3s ease"
                >
                    {item.name}
                </Text>
                <Text
                    color="grey.light.50"
                    textStyle={size === "md" ? "ManropeSemiboldXSmall" : "ManropeSemiboldNano"}
                    ml={1}
                    opacity={hasSelection ? (isSelected ? 1 : 0.3) : 1}
                    transition="opacity 0.3s ease"
                >
                    {item.symbol}
                </Text>
            </Flex>
            <Text
                flexDir="row"
                textStyle={size === "md" ? "ManropeSemiboldBody" : "ManropeRegularBodySmall"}
                opacity={hasSelection ? (isSelected ? 1 : 0.3) : 1}
                transition="opacity 0.3s ease"
            >
                {item.value < 1 ? "<1" : item.value}
                <Text
                    as="span"
                    display="inline"
                    color="grey.light.50"
                    textStyle={size === "md" ? "ManropeSemiboldXSmall" : "ManropeSemiboldNano"}
                    opacity={hasSelection ? (isSelected ? 1 : 0.3) : 1}
                    transition="opacity 0.3s ease"
                >
                    {" "}
                    %
                </Text>
            </Text>
        </Flex>
    </Flex>
)

const Legend = ({
    data,
    selectedData,
    isLoading,
}: {
    data: DataItem[]
    selectedData: DataItem | null
    isLoading?: boolean
}) => {
    const isMobile = useBreakpointValue({ base: true, sm: false })
    const rowSize = data.length <= 6 ? "md" : "sm"
    const { t } = useTranslation("app")

    const rows = useMemo(() => {
        const sortedData = [...data].sort((a, b) => b.name.length - a.name.length)
        const result: DataItem[][] = []

        if (data.length <= 6) {
            // For 6 or fewer items, use 2 columns (3 rows max)
            for (let i = 0; i < sortedData.length; i += 2) {
                let row = sortedData.slice(i, i + 2)
                // Sort within row so longest name is last
                row = row.sort((a, b) => a.name.length - b.name.length)
                result.push(row)
            }
        } else {
            // For more than 6 items, use 3 columns
            // If exactly 12 items, first row should have 3 items
            if (data.length === 12) {
                result.push(sortedData.slice(0, 3))

                // Remaining rows - 3 items each
                for (let i = 3; i < sortedData.length; i += 3) {
                    let row = sortedData.slice(i, i + 3)
                    // Reverse the order within each row so longest name is last
                    row = row.sort((a, b) => a.name.length - b.name.length)
                    if (row.length > 0) result.push(row)
                }
            } else {
                // For all other cases > 6 items, first row has 2 items if they're the longest
                const firstRow = sortedData.slice(0, 2).sort((a, b) => a.name.length - b.name.length)
                result.push(firstRow)

                // Remaining rows - 3 items each
                const remaining = sortedData.slice(2)
                for (let i = 0; i < remaining.length; i += 3) {
                    let row = remaining.slice(i, i + 3)
                    // Reverse the order within each row so longest name is last
                    row = row.sort((a, b) => a.name.length - b.name.length)
                    if (row.length > 0) result.push(row)
                }
            }
        }

        return result.slice(0, 4) // Limit to 4 rows maximum
    }, [data])

    const total = data.reduce((acc, item) => acc + item.value, 0)
    const selectedColors = data.length > 10 ? colors12 : colors

    return (
        <VStack spacing={4} align="stretch" width="full">
            {isLoading ? (
                [
                    [0, 1],
                    [2, 3],
                ].map((row, index) => (
                    <HStack key={index} gap={2} justify="flex-start" width="full">
                        {row.map((idx) => (
                            <Flex key={idx}>
                                <LoadingLegendItem key={idx} />
                            </Flex>
                        ))}
                    </HStack>
                ))
            ) : (
                <>
                    {data.length === 0 ? (
                        <Text color="grey.light.50" textStyle="ManropeRegularBodySmall" mt={isMobile ? 4 : 0}>
                            {t("dashboard.youHaveNoAssets")}
                        </Text>
                    ) : (
                        rows.map((rowItems, rowIndex) => (
                            <HStack key={rowIndex} spacing={0} justify="flex-start" width="full">
                                {rowItems.map((item, itemIndex) => {
                                    // For first row (2 items), first item gets 33%, second gets the rest
                                    const isFirstRowTwoItems = rowIndex === 0 && data.length !== 12
                                    const boxWidth = isFirstRowTwoItems
                                        ? itemIndex === 0
                                            ? "33%"
                                            : itemIndex === 1
                                              ? "67%"
                                              : "33%"
                                        : "33%"

                                    return (
                                        <Box
                                            key={itemIndex}
                                            width={boxWidth}
                                            pr={4}
                                            display={
                                                rowIndex === 0 && data.length !== 12 && itemIndex === 2
                                                    ? "none"
                                                    : "block"
                                            }
                                        >
                                            {((rowIndex === 0 && data.length !== 12 && itemIndex < 2) ||
                                                (rowIndex === 0 && data.length === 12) ||
                                                rowIndex > 0) && (
                                                <LegendItem
                                                    key={itemIndex}
                                                    size={rowSize}
                                                    item={{
                                                        ...item,
                                                        value: Number(((item.value / total) * 100).toFixed(2)),
                                                    }}
                                                    color={selectedColors[data.indexOf(item)]}
                                                    isSelected={selectedData?.name === item.name}
                                                    hasSelection={selectedData !== null}
                                                />
                                            )}
                                        </Box>
                                    )
                                })}
                            </HStack>
                        ))
                    )}
                </>
            )}
        </VStack>
    )
}

export const DonutChart: React.FC<DonutChartProps> = ({ data, height, width, isLoading }) => {
    const [selectedData, setSelectedData] = useState<DataItem | null>(null)
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Flex flexDir={isMobile ? "column" : "row"} align="center">
            <Box mx={10}>
                <Donut
                    isLoading={isLoading}
                    data={data}
                    height={height}
                    width={width}
                    onMouseEnter={(data) => setSelectedData(data)}
                    onMouseLeave={() => setSelectedData(null)}
                    selectedData={selectedData}
                />
            </Box>
            <Legend isLoading={isLoading} data={data} selectedData={selectedData} />
        </Flex>
    )
}
