import { Box, Flex, Skeleton, Td, Text, Tr } from "@chakra-ui/react"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import IconMenu from "@components/ui/menus/icon"
import { useStableBreakpoint } from "@hooks/useStableBreakpoint"
import { isProcessingState } from "@screens/dashboard/transactions/helper"
import { formatShortDate } from "@util/dateFormating"
import { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface TransactionRowProps {
    transaction: {
        date: string | Date
        description: string
        status?: string
        secAmount?: string
        mainCurrency?: string
        secCurrency?: string
        mainAmount: string
    }
    isReward?: boolean
    handleViewMore: () => void
    isLast: boolean
    forcedSizeVariant?: "large" | "medium" | "small" | "extra-small"
}

export const TransactionRowSkeleton = memo(({ forcedSizeVariant }: Pick<TransactionRowProps, "forcedSizeVariant">) => {
    const rowVariant = forcedSizeVariant || useStableBreakpoint()
    if (rowVariant === "small") {
        return (
            <>
                <Tr w="full">
                    <Td pt={{ base: "16px" }} pb={"8px"} px={2} borderBottom={"none !important"}>
                        <Skeleton height={"12px"} width={"105px"} />
                    </Td>
                </Tr>
                <Tr w="full" borderBottom={"1px solid"} borderColor={"grey.light.20"}>
                    <Td py={{ base: "0.5rem" }} px={2}>
                        <Flex direction="column">
                            <Skeleton height={"12px"} width={"105px"} mb={2} />
                            <Skeleton height={"12px"} width={"105px"} />
                        </Flex>
                    </Td>
                    <Td py={{ base: "0.5rem" }} px={{ base: "0rem" }} textAlign="right" pr={0}>
                        <Box display="inline-block" verticalAlign="middle" gap={2}>
                            <Skeleton height={"12px"} width={"105px"} mb={2} />
                            <Skeleton height={"12px"} width={"105px"} />
                        </Box>
                        <Box display="inline-block" verticalAlign="middle" ml={2}>
                            <IconMenu buttonProps={{ isDisabled: true }} items={[]} />
                        </Box>
                    </Td>
                </Tr>
            </>
        )
    }
    return null
})

const TransactionRow: React.FC<TransactionRowProps> = ({
    transaction,
    handleViewMore,
    isLast,
    isReward,
    forcedSizeVariant,
}) => {
    const { t } = useTranslation("common")
    const borderBottom = useMemo(() => {
        return isLast ? "none !important" : "1px solid"
    }, [isLast])

    const processing = isProcessingState(transaction?.status || "")

    const chipVariant = useMemo(() => {
        if (isReward) {
            return ENUM_CHIP_VARIANTS.PURPLE
        }

        if (processing) {
            return ENUM_CHIP_VARIANTS.YELLOW
        }

        return undefined
    }, [processing, isReward])

    const displayedStatus = useMemo(() => {
        if (processing) {
            return "Processing"
        }
        if (isReward) {
            return "Rewards"
        }
    }, [processing, isReward])

    const formattedDate = formatShortDate(new Date(transaction.date))

    const rowVariant = forcedSizeVariant || useStableBreakpoint()

    const renderAmountsCol = useCallback(
        () => (
            <>
                <Box display="inline-block" verticalAlign="middle">
                    {transaction.mainAmount && (
                        <Text textStyle={"ManropeSemiboldBodySmall"}>
                            {transaction.mainAmount} {transaction.mainCurrency}
                        </Text>
                    )}
                    {transaction.secAmount && (
                        <Text textStyle={"ManropeMediumXSmall"} color="grey.light.60">
                            {transaction.secAmount} {transaction.secCurrency}
                        </Text>
                    )}
                </Box>
                <Box display="inline-block" verticalAlign="middle" ml={2}>
                    <IconMenu items={[{ text: t("viewMore"), onClick: handleViewMore }]} />
                </Box>
            </>
        ),
        [
            transaction.mainAmount,
            transaction.mainCurrency,
            transaction.secAmount,
            transaction.secCurrency,
            handleViewMore,
        ]
    )

    const renderLargeRow = useCallback(
        () => (
            <Tr w="full" transition="background-color 0.2s">
                <Td
                    py={{ base: "1rem" }}
                    px={2}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.light.60" textStyle={"ManropeSemiboldXSmall"}>
                        {formattedDate}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} px={2} borderBottom={borderBottom} borderColor={"grey.light.20"}>
                    <Box>
                        <Box display="inline-block" w={{ base: "70%" }}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        <Box display="inline-block">
                            {displayedStatus && chipVariant && (
                                <Box mt={2}>
                                    {displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderMediumRow = useCallback(
        () => (
            <Tr w="full">
                <Td
                    py={{ base: "1rem" }}
                    px={2}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.light.60" textStyle={"ManropeSemiboldXSmall"}>
                        {formattedDate}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.light.20"} px={2}>
                    <Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        {displayedStatus && chipVariant && (
                            <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                        )}
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderSmallRow = useCallback(
        () => (
            <Tr w="full">
                <Td
                    py={{ base: "0.5rem" }}
                    px={2}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                    whiteSpace={"normal"}
                >
                    <Box>
                        <Box>
                            <Text color="grey.light.60" textStyle={"ManropeSemiboldXSmall"}>
                                {formattedDate}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        {displayedStatus && chipVariant && (
                            <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                        )}
                    </Box>
                </Td>
                <Td
                    py={{ base: "0.5rem" }}
                    px={{ base: "0rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderExtraSmallRow = useCallback(
        () => (
            <Tr w="full">
                <Td
                    py={{ base: "0.25rem" }}
                    px={2}
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                    whiteSpace={"normal"}
                >
                    <Text color="grey.light.60" textStyle={"ManropeSemiboldXSmall"}>
                        {formattedDate}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                    {displayedStatus && chipVariant && (
                        <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                    )}
                </Td>
                <Td
                    py={{ base: "0.25rem" }}
                    px={2}
                    textAlign="right"
                    borderBottom={borderBottom}
                    borderColor={"grey.light.20"}
                    whiteSpace={"nowrap"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )
    if (rowVariant === "extra-small") {
        return renderExtraSmallRow()
    } else if (rowVariant === "small") {
        return renderSmallRow()
    } else if (rowVariant === "medium") {
        return renderMediumRow()
    } else if (rowVariant === "large") {
        return renderLargeRow()
    }

    return null
}

export default memo(TransactionRow)
