import TransactionsRow from "@components/transactions/transactionsRow"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { TransactionCardProps } from ".."

export const Buy: React.FC<TransactionCardProps> = ({ transaction, viewMore, isLast, forcedSizeVariant }, props) => {
    const { created_at_pst, decrease_currency, status, asset, increase_amount, increase_currency, total } =
        transaction || {}

    const fiatValue = formatDollar(total)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const formattedCryptoAmount = formatNumberFixedPrecision(increase_amount, Number(data?.precision), true)

    return (
        <TransactionsRow
            forcedSizeVariant={forcedSizeVariant}
            transaction={{
                date: created_at_pst,
                description: `Bought ${asset}`,
                status: status,
                secAmount: fiatValue,
                mainCurrency: increase_currency,
                secCurrency: decrease_currency,
                mainAmount: formattedCryptoAmount,
            }}
            handleViewMore={viewMore}
            isLast={isLast}
            {...props}
        />
    )
}
