import TokenEyesImage from "@assets/images/brands/token-eyes.png"
import { Flex, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const TokenEyesLink = "https://shorturl.at/1UA9d"

export const TokenEyesBanner = () => {
    const { t } = useTranslation("app")

    const onClick = () => {
        window.open(TokenEyesLink, "_blank")
    }

    return (
        <Flex
            px={6}
            py={1}
            flexDir="column"
            width="100%"
            height="169px"
            bg="linear-gradient(99deg, rgba(57, 57, 88, 0.56) 10.72%, rgba(123, 123, 190, 0.00) 82.34%), #07072E"
        >
            <Flex alignItems="center" justifyContent="center" textAlign="center" mt={2}>
                <Text color="white" textStyle="ManropeSemiboldBodySmall">
                    {t("dashboard.checkWalletRisk")}
                </Text>
            </Flex>

            <Flex gap={2} width="100%" h={"100%"} alignItems="center" justifyContent="center">
                <Flex
                    width="101px"
                    minWidth="101px"
                    height="72px"
                    alignItems="center"
                    justifyContent="center"
                    bg="#F7F7F8"
                    borderRadius="4px"
                >
                    <Image src={TokenEyesImage} width="100%" height="100%" alt="Token eyes" objectFit="contain" />
                </Flex>

                <Flex flexDir="column">
                    <Text color="white" textStyle="InterRegularXSmall">
                        {t("dashboard.freeOneYear")}
                    </Text>

                    <Flex
                        alignItems="center"
                        cursor="pointer"
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                        onClick={onClick}
                    >
                        <Text color="blue.70" textStyle="InterRegularXSmall">
                            {t("dashboard.clickToLearnMore")}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
