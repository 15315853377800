import {
    Box,
    Checkbox as ChakraCheckbox,
    CheckboxProps,
    FormControl,
    FormControlProps,
    HStack,
    Text,
} from "@chakra-ui/react"
import Link from "../link"
import React from "react"
import { UseControllerProps, useController } from "react-hook-form"
import { isFormProps } from "../textInput"

// Used to ensure that checkbox CSS styles are identical across slightly different implementations of the Checkbox component
export const CheckboxStyles = {
    iconColor: "blue.100",
    colorScheme: "white",
    borderColor: "grey.light.50",
    _checked: {
        borderColor: "blue.100",
    },
    _groupHover: {
        borderColor: "blue.70",
    },
    _hover: {
        borderColor: "blue.70",
    },
    _groupActive: {
        borderColor: "blue.70",
        "* svg": {
            color: "blue.70",
        },
    },
    sx: {
        ".chakra-checkbox__control": {
            margin: 0,
            padding: 0,
        },
    },
}

type BaseProps = { name: string } & Partial<{
    text: string
    children: React.ReactNode
    defaultChecked: boolean
    disabled: boolean
    isInvalid: boolean
    isRightSide: boolean
    link: {
        label: string
        href: string
        hasIcon?: boolean
    }
}>

export interface CheckboxControlledProps extends BaseProps {
    isChecked: boolean
    setValue?: (isChecked: boolean) => void
}

type CheckboxFormProps = BaseProps & UseControllerProps<any> & FormControlProps & CheckboxProps

type Props = CheckboxFormProps | CheckboxControlledProps

// NOTE error state is not implemented in this component. There should always be a string above a CTA button somewhere else

export default function Checkbox(props: Props) {
    const {
        text,
        isChecked,
        defaultChecked,
        isInvalid,
        disabled = false,
        link,
        children,
        isRightSide = false,
        ...rest
    } = props

    const formControl = isFormProps(props)
        ? useController<any>({
              name: props.name,
              control: props.control,
              rules: props.rules,
          })
        : null

    const { inputProps, formProps } = React.useMemo(() => {
        if (formControl) {
            const { field } = formControl
            const { onChange, value, ref, ...restField } = field
            const { control, name, rules, ...formRest } = props as CheckboxFormProps

            return {
                inputProps: {
                    onChange,
                    value: value ?? "",
                    ref,
                    ...restField,
                },
                formProps: {
                    ...formRest,
                },
            }
        }

        const { isChecked, setValue, ...formRest } = props as CheckboxControlledProps
        return {
            inputProps: {
                onChange: (e: any) => {
                    setValue?.(e.target.checked)
                },
                isChecked: isChecked ?? defaultChecked ?? false,
            },
            formProps: {
                ...formRest,
            },
        }
    }, [props, formControl])

    return (
        <FormControl {...formProps}>
            <HStack
                spacing={"10px"}
                align="flex-start"
                flex="1"
                minW="0"
                wrap="wrap"
                _hover={{
                    cursor: "pointer",
                }}
                role="group"
            >
                <ChakraCheckbox
                    data-test-id={`checkbox-${name}`}
                    iconSize={18}
                    marginTop={1}
                    {...CheckboxStyles}
                    {...rest}
                    {...inputProps}
                    order={isRightSide ? 1 : 0}
                />
                <Box flex="1" minW="0">
                    <HStack spacing={1} wrap="wrap">
                        {React.isValidElement(children) ? (
                            children
                        ) : (
                            <>
                                <Text textStyle="InterRegularBody">{text}</Text>
                                {link && <Link text={link.label} href={link.href} hasIcon={link.hasIcon} />}
                            </>
                        )}
                    </HStack>
                </Box>
            </HStack>
        </FormControl>
    )
}
