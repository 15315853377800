import { Box, Text, VStack } from "@chakra-ui/react"
import CardCheckbox from "@components/ui/checkbox/cardCheckbox"
import { useTranslation } from "react-i18next"
import { DrawerFundContext, FundType } from "../drawerFund/DrawerFundContext"
import useRegion from "@hooks/useRegion"

export default function Fund({ availableFundTypes }: { availableFundTypes: FundType[] }) {
    const { t } = useTranslation(["app", "common"])

    const { currentFundType, setCurrentFundType, setErrorMessage } = DrawerFundContext.useContainer()

    const { isCAUser } = useRegion()

    const updateFundType = (fundType: FundType) => {
        setCurrentFundType(fundType)
        setErrorMessage("")
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundingMethod", { ns: "transfer" })}</Text>
            <Box pt={7}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("dollars", { ns: "common" })}</Text>
                <Text textStyle={"InterRegularBodySmall"}>{t("fund.fundAccountWithDollars", { ns: "transfer" })}</Text>
            </Box>
            <VStack spacing={3} mt={3}>
                {availableFundTypes?.includes(FundType.INTERAC) && isCAUser && (
                    <CardCheckbox
                        checked={currentFundType === FundType.INTERAC}
                        label={t("fund.interac.title", { ns: "transfer" })}
                        primaryText={t("fund.interac.description", { ns: "transfer" })}
                        chip={{
                            text: "Recommended",
                        }}
                        onPress={() => updateFundType(FundType.INTERAC)}
                    />
                )}
                {availableFundTypes.includes(FundType.WIRE) && (
                    <CardCheckbox
                        checked={currentFundType === FundType.WIRE}
                        label={t("fund.wire.title", { ns: "transfer" })}
                        primaryText={
                            isCAUser
                                ? t("fund.wire.description", { ns: "transfer" })
                                : t("fund.wire.usDescription", { ns: "transfer" })
                        }
                        onPress={() => updateFundType(FundType.WIRE)}
                    />
                )}
            </VStack>
            {availableFundTypes.includes(FundType.CRYPTO) && (
                <Box mt={6}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("crypto", { ns: "common" })}</Text>
                    <Text textStyle={"InterRegularBodySmall"}>
                        {t("fund.fundAccountWithCrypto", { ns: "transfer" })}
                    </Text>
                    <Box mt={3}>
                        <CardCheckbox
                            checked={currentFundType === FundType.CRYPTO}
                            label={t("fund.crypto.title", { ns: "transfer" })}
                            primaryText={t("fund.crypto.description", { ns: "transfer" })}
                            onPress={() => updateFundType(FundType.CRYPTO)}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
