import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { FaCheck } from "react-icons/fa6"

export default function RegistrationComplete() {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })

    return (
        <Flex flexDir="column" gap={7} mt={7}>
            <Flex w="full" justifyContent="space-between" alignItems="center">
                <Text textStyle={"ManropeSemiboldXLarge"}>{t("registrationComplete")}</Text>

                <Flex
                    height="48px"
                    width="48px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    bg="green.light.100"
                >
                    <ReactIcon icon={FaCheck} size={24} color="white" />
                </Flex>
            </Flex>

            <Flex flexDir="column" gap={6} height="100%">
                <Text textStyle="ManropeSemiboldBody">{t("congratulationsOnNetcoinsCard")}</Text>

                <Text textStyle="InterRegularBody">{t("ifYouSelectedVirtualCard")}</Text>
                <Text textStyle="InterRegularBody">{t("ifYouSelectedPhysicalCard")}</Text>
            </Flex>
        </Flex>
    )
}
