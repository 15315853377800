import React, { ReactElement, useEffect, useRef, useState } from "react"
import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Flex,
    IconButton,
    IconButtonProps,
    Spinner,
    Text,
    useColorModeValue,
} from "@chakra-ui/react"
import TradingView from "./charting_library/charting_library.standalone"
import { ResolutionString, widget } from "@tradingView/charting_library"
import { resolutions, resolutionToPeriod, createDatafeed } from "@tradingView/tradingViewDatafeed"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { MdBarChart, MdFullscreen, MdSettings } from "react-icons/md"
import { useTranslation } from "react-i18next"
import TabsSliding from "@components/ui/tabs/sliding"
import { ENUM_SLIDING_TAB_VARIANT } from "@components/ui/tabs/sliding/types"

declare global {
    interface Window {
        tvWidget: any
    }
}

type Props = {
    asset?: string
    isDrawerOpen?: boolean
}

const GraphIcon = (props: IconButtonProps) => {
    return (
        <IconButton
            bg={"transparent"}
            _active={{
                bg: "grey.light.5",
            }}
            _focus={{
                bg: "grey.light.5",
            }}
            _hover={{
                bg: "grey.light.5",
            }}
            {...props}
        />
    )
}

export default function MarketDetailsGraph({ asset, isDrawerOpen = false }: Props) {
    const [chartType, setChartType] = useState<"line" | "candle">("candle")
    const [timeFrame, setTimeFrame] = useState("10")
    const { t } = useTranslation("common")
    const chartContainerRef = useRef<HTMLDivElement>(null)
    const widgetRef = useRef<any>()
    const currency = useAppSelector(selectAccountDetails)?.currency
    const { data: assetDetails, isLoading } = useGetAssetsDetailsQuery(undefined)
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    useEffect(() => {
        if (!chartContainerRef.current || !assetDetails) return
        const Datafeed = createDatafeed(assetDetails || {})
        const script = document.createElement("script")
        script.type = "text/jsx"
        script.src = "/public/charting_library/charting_library.js"
        document.head.appendChild(script)

        //@ts-ignore
        const widget = (widgetRef.current = new TradingView.widget({
            symbol: `${asset}/${currency}`,
            interval: "10",
            container: chartContainerRef.current,
            datafeed: Datafeed,
            library_path: "/charting_library/",
            timezone: userTimezone,
            locale: "en",
            disabled_features: [
                "header_widget",
                "create_volume_indicator_by_default",
                "create_volume_indicator_by_default_once",
                "display_market_status",
                "show_interval_dialog_on_key_press",
                "symbol_search_hot_key",
                "header_symbol_search",
                "header_resolutions",
                "timeframes_toolbar",
                "edit_buttons_in_legend",
                "symbol_info",
            ],
            enabled_features: ["shift_visible_range_on_new_bar", "side_toolbar_in_fullscreen_mode"],
            autosize: true,
            studies_access: {
                type: "black",
                tools: [
                    {
                        name: "52 Week High/Low",
                    },
                    {
                        name: "Volume",
                    },
                    {
                        name: "Volume Oscillator",
                    },
                    {
                        name: "Net Volume",
                    },
                    {
                        name: "Volume Profile Fixed Range",
                    },
                    {
                        name: "Volume Profile Visible Range",
                    },
                    {
                        name: "Price Volume Trend",
                    },
                    {
                        name: "On Balance Volume",
                    },
                ],
            },
        }))

        widgetRef.current.onChartReady(() => {
            const priceScale = widgetRef.current.activeChart().getPanes()[0].getMainSourcePriceScale()
            priceScale.setAutoScale(true)
            widgetRef.current.chart().setChartType(1)
        })

        widgetRef.current.onChartReady(() => {
            widgetRef.current.applyOverrides({
                "paneProperties.gridLinesMode": "none",
                "paneProperties.legendProperties.backgroundTransparency": 100,
            })
        })

        return () => {
            script.remove()
            widget.remove()
        }
    }, [assetDetails])

    useEffect(() => {
        if (!widgetRef.current) return
        widgetRef.current.onChartReady(() => {
            widgetRef.current.chart().setChartType(chartType === "line" ? 3 : 1)
        })
    }, [chartType])

    useEffect(() => {
        if (!widgetRef.current) return
        widgetRef.current.onChartReady(() => {
            widgetRef.current.activeChart().setResolution(timeFrame)
        })
    }, [timeFrame])

    const handleFullScreen = () => {
        if (!widgetRef.current) return
        widgetRef.current.startFullscreen()
    }

    const openIndicatorMenu = () => {
        if (!widgetRef.current) return
        widgetRef.current.activeChart().executeActionById("insertIndicator")
    }

    const openChartSettings = () => {
        if (!widgetRef.current) return
        widgetRef.current.activeChart().executeActionById("chartProperties")
    }

    return (
        <Box py={8} px={6} pb={0}>
            <Flex
                justify="space-between"
                align={{ base: "flex-start", sm: "center" }}
                mb={8}
                w="full"
                flexDirection={{
                    base: "column",
                    sm: isDrawerOpen ? "column" : "row",
                    md: isDrawerOpen ? "column" : "row",
                    lg: "row",
                }}
            >
                <Box
                    display={{ base: "flex", sm: isDrawerOpen ? "flex" : "block", md: "block" }}
                    alignItems={"center"}
                    justifyContent={{
                        base: "space-between",
                        sm: isDrawerOpen ? "space-between" : "center",
                        md: "center",
                    }}
                    width={"full"}
                >
                    <Box
                        w={{
                            base: "200px",
                            sm: "140px",
                            md: "200px",
                            lg: isDrawerOpen ? "140px" : "300px",
                        }}
                    >
                        <TabsSliding
                            options={[
                                { label: "Line", value: "line" },
                                { label: "Candle", value: "candle" },
                            ]}
                            activeOption={chartType}
                            setActiveOption={(value) => {
                                setChartType(value as "line" | "candle")
                            }}
                            variant={ENUM_SLIDING_TAB_VARIANT.SECONDARY}
                            size="md"
                        />
                    </Box>
                    <Flex
                        display={{ base: "flex", sm: isDrawerOpen ? "flex" : "none", md: "none" }}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <GraphIcon
                            icon={<MdBarChart size={24} />}
                            aria-label="graph-button"
                            onClick={openIndicatorMenu}
                        />
                        <GraphIcon
                            icon={<MdSettings size={24} />}
                            aria-label="graph-settings"
                            onClick={openChartSettings}
                        />
                    </Flex>
                </Box>

                <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={{ base: "full", sm: isDrawerOpen ? "full" : "auto", lg: "auto" }}
                    mt={{ base: 6, sm: isDrawerOpen ? 6 : 0, lg: 0 }}
                >
                    <ButtonGroup size="sm" spacing={{ base: 1, md: 2 }} variant="ghost" w="full" alignItems={"center"}>
                        {resolutions.map((interval) => (
                            <Button
                                key={interval}
                                onClick={() => setTimeFrame(interval)}
                                fontWeight="medium"
                                isActive={timeFrame === interval}
                                _active={{
                                    bg: "grey.light.5",
                                }}
                                _focus={{
                                    bg: "grey.light.5",
                                }}
                                _hover={{
                                    bg: "grey.light.5",
                                }}
                                p={{ base: 3, sm: 2, md: isDrawerOpen ? 2 : 3 }}
                            >
                                {resolutionToPeriod[interval]}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Box display={{ base: "none", sm: isDrawerOpen ? "none" : "flex", lg: "flex" }} ml={2}>
                        <GraphIcon
                            icon={<MdFullscreen size={24} />}
                            aria-label="fullscreen-icon"
                            onClick={handleFullScreen}
                            display={{ base: "none", md: isDrawerOpen ? "none" : "flex", lg: "flex" }}
                        />
                        <GraphIcon
                            icon={<MdBarChart size={24} />}
                            aria-label="graph-button"
                            onClick={openIndicatorMenu}
                        />
                        <GraphIcon
                            icon={<MdSettings size={24} />}
                            aria-label="graph-settings"
                            onClick={openChartSettings}
                        />
                    </Box>
                </Flex>
            </Flex>
            <Box
                h={{ base: "250px", sm: "300px", md: "350px", lg: "450px", xl: "500px" }}
                mb="3rem"
                ref={chartContainerRef}
            >
                {isLoading && (
                    <Center height={"100%"}>
                        <Spinner size={"lg"} />
                    </Center>
                )}
            </Box>
        </Box>
    )
}
