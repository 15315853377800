import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import CoinSelect from "@components/ui/select/coinSelect"
import { Option } from "@components/ui/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { selectAccountDetails } from "@redux/account/selectors"
import { useAppSelector } from "@/store/hooks"
import RadioSelect, { RadioSelectOption } from "@components/ui/radio/radioSelect"
import { imageMap } from "@assets/svgs/coins"
import ErrorMessage from "@components/ui/errorMessage"

type Props = {
    selectedOption: RadioSelectOption | null
    setSelectedOption: (_: RadioSelectOption | null) => void
}

export default function SelectCoin({ selectedOption, setSelectedOption }: Props) {
    const { t } = useTranslation("settings")
    const accountDetails = useAppSelector(selectAccountDetails)
    const { data } = useGetAssetsDetailsQuery({ currency: accountDetails?.currency })

    const options = useMemo(() => {
        if (!data) return []

        return Object.values(data).map((asset) => ({
            symbol: asset.symbol,
            name: asset.name,
            imageUrl: imageMap[asset.symbol.toLowerCase() as keyof typeof imageMap],
        })) as RadioSelectOption[]
    }, [data])

    function handleSelect(option: RadioSelectOption | null) {
        setSelectedOption(option as RadioSelectOption)
    }

    return (
        <Box flex={1} overflow={"auto"} display={"flex"} flexDirection={"column"} height={"100%"}>
            <Text textStyle="ManropeSemiboldBody">{t("savedWalletAddress.selectCrypto")}</Text>
            <RadioSelect options={options} onSelect={handleSelect} selectedOption={selectedOption} />
        </Box>
    )
}
