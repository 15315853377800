import React from "react"
import { useForm } from "react-hook-form"
import { Box, VStack, Text } from "@chakra-ui/react"
import Select from "@/components/ui/select"
import TextArea from "@/components/ui/textArea"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "../useOnboarding"
import { Option } from "@components/ui/types"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { useUpdateAccountMutation } from "@redux/account/apiSlice"
import { useLazyGetSurveyDetailsOptionsQuery } from "@redux/options/apiSlice"
import useGenericToast from "@hooks/useGenericToast"

type FormData = {
    onboarding_survey: Option<string>
    onboarding_survey_details: string
}

export default function YourName({ submitSubForm, userObj, isLoading }: SubScreenProps) {
    const { handleSubmit, control, watch, setValue } = useForm<FormData>(formatDefaultValues())
    const { t } = useTranslation(["onboarding", "common"])
    const { serverErrorToast } = useGenericToast()
    const [updateAccount] = useUpdateAccountMutation()
    const [getSurveyDetailsOptions] = useLazyGetSurveyDetailsOptionsQuery()

    const [surveyOptions, setSurveyOptions] = React.useState<Option<string>[]>([] as Option<string>[])

    // only sets default value of 1 of the 2 fields here. The other field is set optionally in setDefaultValue()
    function formatDefaultValues() {
        return {
            defaultValues: {
                onboarding_survey_details: userObj?.onboarding_survey_details || "",
            },
        }
    }

    React.useEffect(() => {
        if (userObj.country) {
            ;(async () => {
                await getSurveyDetailsOptions({ country: userObj.country, region: userObj.region })
                    .unwrap()
                    .then((res) => {
                        const arr: Option<string>[] = []
                        for (const key in res) {
                            if (key !== "success") {
                                const value = res[key].source
                                arr.push({ value, label: value })
                            }
                        }
                        setSurveyOptions(arr)
                        setDefaultValue(arr)
                    })
                    .catch(serverErrorToast)
            })()
        }
    }, [userObj.country, userObj.region])

    // Setting default values is done differently here than the other screens because the referral options are fetched from the server
    function setDefaultValue(arr: Option<string>[]) {
        if (!userObj?.onboarding_survey) return

        const _option = arr.find((occ) => {
            const val = userObj?.onboarding_survey
            return val === occ.value
        })

        if (_option) {
            setValue("onboarding_survey", { value: _option.value, label: _option.label })
        }
    }

    async function onSubmit(values: FormData) {
        const onboarding_survey = values.onboarding_survey.value
        const onboarding_survey_details = values.onboarding_survey_details
        await submitSubForm(updateAccount, { onboarding_survey, onboarding_survey_details }, () => {
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.HowDidYouHear, REGISTRATION_STEP.REFERRAL)
        })
    }

    return (
        <Box w="full">
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack rowGap="1rem" alignItems={"flex-start"}>
                    <Text as="span" textStyle="ManropeMediumXLarge">
                        {t("howDidYouHear.header")}
                    </Text>
                    <Select
                        name="onboarding_survey"
                        options={surveyOptions}
                        control={control}
                        placeholder={surveyOptions.length ? undefined : `${t("loading", { ns: "common" })}...`}
                        disabled={!surveyOptions.length}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                        size="lg"
                    />
                    <TextArea
                        name="onboarding_survey_details"
                        control={control}
                        maxLength={150}
                        currentLength={watch("onboarding_survey_details")?.length}
                        label={t("howDidYouHear.referralDetails")}
                    />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        mt="1.5rem"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </VStack>
            </form>
        </Box>
    )
}
