import { RootState } from "@/store/reducer"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ACTION_TYPES, SLICE_NAME } from "./const"
import Cookies from "js-cookie"
import { ACCESS_TOKEN_TTL_COOKIE_NAME, REFRESH_TOKEN_TTL_COOKIE_NAME } from "@util/auth/cookies"
import { LOGOUT_ROUTE, REDIRECT_URL, REFRESH_URL } from "@util/auth/const"

type SerializedUser = {
    device_id: string
    expires_at: string // iso8601 string
    expired: boolean
    refresh_expires_at: string // iso8601 string
    url_state: string // TODO
}

type AuthState = {
    user: SerializedUser | null
    isRefreshing: boolean
    loading: boolean
    error: string | null
}

const initialState: AuthState = {
    user: null,
    isRefreshing: false,
    loading: false,
    error: null,
}

export const resolveUserOrLogin = createAsyncThunk(ACTION_TYPES.RESOLVE_USER_OR_LOGIN, async (_, { dispatch }) => {
    const accessTokenTTL = Cookies.get(ACCESS_TOKEN_TTL_COOKIE_NAME)
    const refreshTokenTTL = Cookies.get(REFRESH_TOKEN_TTL_COOKIE_NAME)

    // Valid auth still present
    if (accessTokenTTL && new Date(accessTokenTTL) > new Date()) {
        const user = {
            device_id: "",
            expires_at: accessTokenTTL,
            refresh_expires_at: refreshTokenTTL as string,
            // url_state:  TODO
        }

        return Object.defineProperty(user, "expired", {
            get() {
                return !this.expires_at || new Date(this.expires_at) < new Date()
            },
        }) as SerializedUser
    }

    // Valid refresh token only, refresh
    if (refreshTokenTTL && new Date(refreshTokenTTL) > new Date()) {
        dispatch(renewToken())
        return null
    }

    // Redirect to start auth flow
    window.location.href = REDIRECT_URL + window.location.search
    return null
})

export const renewToken = createAsyncThunk(ACTION_TYPES.RENEW_TOKEN, async (_, { getState, dispatch }) => {
    const state = getState() as RootState
    if (state.auth.isRefreshing) {
        return new Promise<SerializedUser | null>((resolve) => {
            const interval = setInterval(() => {
                if (!state.auth.isRefreshing) {
                    clearInterval(interval)
                    resolve(state.auth.user)
                }
            }, 100)
        })
    }

    dispatch(setIsRefreshing(true))
    await fetch(REFRESH_URL)
    dispatch(setIsRefreshing(false))
    return await dispatch(resolveUserOrLogin()).unwrap()
})

export const logout = createAsyncThunk(ACTION_TYPES.LOGOUT, async (params?: Record<string, string>) => {
    window.location.href = LOGOUT_ROUTE
    return null
})

const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setIsRefreshing(state, action: PayloadAction<boolean>) {
            state.isRefreshing = action.payload
        },
        setUser(state, action: PayloadAction<SerializedUser | null>) {
            state.user = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resolveUserOrLogin.pending, (state) => {
                state.error = null
                state.loading = true
            })
            .addCase(resolveUserOrLogin.fulfilled, (state, action) => {
                state.user = action.payload
                state.loading = false
            })
            .addCase(resolveUserOrLogin.rejected, (state, action) => {
                state.error = action.error.message || "Failed to authenticate"
                state.loading = false
            })
            .addCase(renewToken.fulfilled, (state, action) => {
                state.user = action.payload
            })
    },
})

export const { setIsRefreshing, setUser, setError } = authSlice.actions
export default authSlice.reducer
