import { useAppSelector } from "@/store/hooks"
import { getMedianCryptoPriceForPrice } from "@hooks/useCryptoMedianConversion"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import { useGetPricesQuery } from "@redux/prices/apiSlice"
import { getPriceForAsset } from "@redux/prices/selectors"
import Decimal from "decimal.js"
import { useCallback, useMemo } from "react"

export const useCalculateBalances = () => {
    const { data: balances, isLoading: isLoadingBalances } = useGetBalancesQuery(undefined)
    const { data: prices, isLoading: isLoadingPrices } = useGetPricesQuery(undefined)
    const currency = useAppSelector(selectAccountDetails)?.currency || "CAD"

    const valueForAsset = useCallback(
        (symbol: string, balance: string) => {
            if (symbol === "CAD" || symbol === "USD") {
                return new Decimal(balance).toNumber()
            }
            if (!prices) {
                return 0
            }
            const price = getPriceForAsset(prices, symbol, currency)
            if (!price) {
                return 0
            }
            const median = getMedianCryptoPriceForPrice(price)
            return median.mul(balance).toNumber()
        },
        [prices, currency]
    )

    const valueForBalance = useCallback(
        (balance: Record<string, string>) => {
            if (!prices) {
                return 0
            }
            return Object.entries(balance)
                .reduce((acc, [symbol, value]) => {
                    if (symbol === "CAD" || symbol === "USD") {
                        return acc.plus(new Decimal(value))
                    }
                    const price = getPriceForAsset(prices, symbol, currency)
                    if (!price) {
                        return acc
                    }
                    const median = getMedianCryptoPriceForPrice(price)
                    return acc.plus(median.mul(value))
                }, new Decimal(0))
                .toNumber()
        },
        [prices, currency]
    )
    const allBalances = useMemo(() => {
        let finalBalances: Record<string, string> = { ...balances?.balances }

        const sumBalanceType = (balanceList: Record<string, string>) => {
            for (const key in balanceList) {
                if (finalBalances[key]) {
                    finalBalances[key] = Decimal.add(finalBalances[key], balanceList[key]).toString()
                } else {
                    finalBalances[key] = balanceList[key]
                }
            }
        }
        if (balances?.orders) {
            sumBalanceType(balances?.orders)
        }
        if (balances?.frozen) {
            sumBalanceType(balances?.frozen)
        }
        if (balances?.stakes) {
            sumBalanceType(balances?.stakes)
        }

        for (const key in finalBalances) {
            if (finalBalances[key] === "0") {
                delete finalBalances[key]
            }
        }

        return finalBalances
    }, [balances])

    const cryptoBalances = useMemo(() => {
        const cryptoBalances = { ...allBalances }
        if ("CAD" in allBalances) {
            delete cryptoBalances.CAD
        }
        if ("USD" in allBalances) {
            delete cryptoBalances.USD
        }
        return cryptoBalances
    }, [allBalances])

    const fiatBalances = useMemo(() => {
        const fiatBalances: Record<string, string> = {}
        if ("CAD" in allBalances) {
            fiatBalances.CAD = allBalances.CAD
        }
        if ("USD" in allBalances) {
            fiatBalances.USD = allBalances.USD
        }
        return fiatBalances
    }, [allBalances])

    const fiatValue = useMemo(() => {
        if (!prices || !fiatBalances) {
            return 0
        }
        return valueForBalance(fiatBalances)
    }, [prices, fiatBalances])

    const cryptoValue = useMemo(() => {
        if (!prices || !cryptoBalances) {
            return 0
        }
        return valueForBalance(cryptoBalances)
    }, [prices, cryptoBalances])

    const availableValue = useMemo(() => {
        if (!prices || !balances?.balances) {
            return 0
        }
        return valueForBalance(balances?.balances)
    }, [allBalances, prices])

    const restrictedAmountBalance = useCallback(
        (
            balances: Record<string, string>,
            quantity: number,
            options: { groupRest?: boolean; minThreshold?: number } = { groupRest: true, minThreshold: 0.1 }
        ) => {
            const { groupRest = true, minThreshold = 0.1 } = options
            const resBalances = Object.entries(balances)
                .map(([symbol, balance]) => ({ symbol, balance, value: valueForAsset(symbol, balance) }))
                .filter((balance) => balance.value > minThreshold)
                .sort((a, b) => Decimal.sub(b.value, a.value).toNumber())
            const res: { symbol: string; balance: string }[] = []
            let cur = 0

            while (cur < Math.min(quantity, resBalances.length)) {
                res.push(resBalances[cur])
                cur++
            }

            if (groupRest && cur < resBalances.length) {
                res.push({
                    symbol: "Others",
                    balance: resBalances
                        .slice(cur)
                        .reduce((acc, cur) => Decimal.add(acc, cur.balance), new Decimal(0))
                        .toString(),
                })
            }
            return res
        },
        [allBalances, prices, currency]
    )

    return {
        isLoadingBalances,
        isLoadingPrices,
        allBalances,
        cryptoBalances,
        fiatBalances,
        fiatValue,
        cryptoValue,
        availableValue,
        valueForAsset,
        restrictedAmountBalance,
    }
}
