import { useTranslation } from "react-i18next"
import { Box } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { IWallet } from "@redux/account/types"
import ErrorMessage from "@components/ui/errorMessage"
import EnterWalletDetails, { EnterWalletDetailsFormData } from "./enterWalletDetails"
import { DepositAddressResponse } from "@redux/fund/types"
import { useForm } from "react-hook-form"
import { useEffect, useRef, useState } from "react"
import { debounce } from "lodash"
import useValidateAddr from "./useValidateAddr"

type Props = {
    wallet: IWallet
    isOpen: boolean
    toggleOpen: () => void
    onSubmit: (_: EnterWalletDetailsFormData) => void
    isLoading: boolean
    err: string
    setErr: (_: string) => void
    depositData?: DepositAddressResponse
}

export default function EditCryptoWalletModal({
    isOpen,
    toggleOpen,
    wallet,
    onSubmit,
    isLoading,
    err,
    setErr,
    depositData,
}: Props) {
    const { t } = useTranslation("settings")
    const { t: ct } = useTranslation("common")
    const {
        isValidatingAddr,
        isValidAddr,
        isWarningChecked,
        setWarningChecked,
        debounceValidateAddr,
        isValidatingFetching,
    } = useValidateAddr()

    const { trigger, control, getValues, watch } = useForm<EnterWalletDetailsFormData>({
        defaultValues: {
            address: wallet.address,
            label: wallet.label,
            memo: wallet.memo ?? "",
        },
    })

    useEffect(() => {
        const val = watch("address")
        if (val) debounceValidateAddr(val, wallet.coin.toUpperCase())
    }, [watch("address")])

    async function handleSubmit() {
        setErr("")
        if (!trigger()) return

        if (!isValidAddr && !isWarningChecked) {
            setErr(t("savedWalletAddress.error.confirmAddr"))
            return
        }

        if (isValidatingAddr || isValidatingFetching) {
            setErr(t("savedWalletAddress.error.pleaseWait"))
            return
        }

        onSubmit({
            address: getValues("address"),
            label: getValues("label"),
            memo: getValues("memo"),
        })
    }

    return (
        <SideDrawer
            name="edit-wallet"
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleOpen}
            header={<StandardDrawerHeader title={t("savedWalletAddress.editWalletDetails")} onClose={toggleOpen} />}
            extraFooter={
                err && (
                    <Box mb="10px">
                        <ErrorMessage>{err}</ErrorMessage>
                    </Box>
                )
            }
            footerButton={{
                children: ct("saveChanges"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                onClick: handleSubmit,
                isLoading,
            }}
        >
            <EnterWalletDetails
                wallet={wallet}
                depositData={depositData}
                control={control}
                isValidAddr={isValidAddr}
                isValidatingAddr={isValidatingAddr}
                isWarningChecked={isWarningChecked}
                setWarningChecked={setWarningChecked}
                isValidatingFetching={isValidatingFetching}
            />
        </SideDrawer>
    )
}
