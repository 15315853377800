import NetcoinsPayImage from "@assets/images/netcoins/netcoins-pay-sm.png"
import { Flex, Image, Text } from "@chakra-ui/react"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { useTranslation } from "react-i18next"

export const NPayBanner = () => {
    const { navigate } = useRestrictedNavigation()

    const onClick = () => {
        navigate(ROUTES.DASHBOARD_NETCOINS_PAY)
    }

    return <Banner onClick={onClick} />
}

type BannerProps = {
    onClick: () => void
}
export const Banner = ({ onClick }: BannerProps) => {
    const { t } = useTranslation("app")

    return (
        <Flex
            px={6}
            width="100%"
            height="169px"
            bg="linear-gradient(99deg, rgba(57, 57, 88, 0.56) 10.72%, rgba(123, 123, 190, 0.00) 82.34%), #07072E"
            alignItems="center"
            gap={2}
            position="relative"
        >
            <Flex position="absolute" top="0px" left="0px">
                <Image src={NetcoinsPayImage} width="100%" height="100%" alt="Netcoins Pay" />
            </Flex>

            <Flex flexDir="column" ml="130px">
                <Flex height="42px" alignItems="center">
                    <Text color="white" textStyle="ManropeSemiboldBodySmall">
                        {t("dashboard.getNetcoinsPay")}
                    </Text>
                </Flex>

                <Text color="white" textStyle="InterRegularXSmall">
                    {t("dashboard.earnBack")}
                </Text>

                <Flex
                    alignItems="center"
                    cursor="pointer"
                    sx={{
                        "&:hover": {
                            textDecorationColor: "blue.100 !important",
                            textDecoration: "underline",
                        },
                        "&:active": {
                            textDecorationColor: "blue.70 !important",
                            textDecoration: "underline",
                        },
                    }}
                    onClick={onClick}
                >
                    <Text color="blue.70" textStyle="InterRegularXSmall">
                        {t("dashboard.registerNow")}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
