import { Flex, Icon, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import CoinBalanceTableRow, { CoinBalanceTableRowSkeleton } from "@components/dashboard/coinbalance/coinBalanceTableRow"
import ThSorting from "@components/ui/thSorting"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useGetDailyInsightsQuery } from "@redux/insights/apiSlice"
import { ROUTES } from "@routing/routes"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaChevronRight } from "react-icons/fa"

export default function CoinBalanceTable() {
    const { navigate } = useRestrictedNavigation()
    const { cryptoBalances: balances, isLoadingBalances, valueForAsset } = useCalculateBalances()
    const { data: dailyInsights, isLoading: isLoadingInsights } = useGetDailyInsightsQuery(undefined)
    const { data: allAssetDetails, isLoading: isLoadingAssetDetails } = useGetAssetsDetailsQuery({ currency: "CAD" })
    const onBrowseCoinsClick = () => {
        navigate(ROUTES.DASHBOARD_TRADE)
    }
    const coins = useMemo(
        () =>
            Object.entries(balances).map(([symbol, balance]) => ({
                symbol,
                balance,
                value: valueForAsset(symbol, balance),
            })),
        [balances]
    )
    const isLoading = useMemo(
        () => isLoadingAssetDetails || isLoadingInsights || isLoadingBalances,
        [isLoadingAssetDetails, isLoadingInsights, isLoadingBalances]
    )

    return (
        <CoinBalanceTableComponent
            onBrowseCoinsClick={onBrowseCoinsClick}
            coins={coins}
            allAssetDetails={allAssetDetails}
            dailyInsights={dailyInsights}
            isLoading={isLoading}
        />
    )
}

type TableProps = {
    coins: { symbol: string; balance: string; value: number }[]
    allAssetDetails?: Record<string, any>
    dailyInsights?: Record<string, any>
    onBrowseCoinsClick: () => void
    isLoading?: boolean
}

enum TotalBalanceSort {
    ASC = 1,
    DESC = -1,
}

export const CoinBalanceTableComponent = ({
    coins,
    allAssetDetails,
    dailyInsights,
    isLoading,
    onBrowseCoinsClick,
}: TableProps) => {
    const { t } = useTranslation("app")
    const [sortOrder, setSortOrder] = useState<TotalBalanceSort>(TotalBalanceSort.DESC)
    const sortedData = useMemo(() => coins.sort((a, b) => (a.value - b.value) * sortOrder), [coins, sortOrder])

    return (
        <Flex flexDir="column" w="100%">
            <Table>
                <Thead bg="grey.light.5">
                    <Tr
                        sx={{
                            "& th": {
                                textTransform: "none",
                                height: {
                                    base: 8,
                                    sm: 10,
                                },
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                        borderBottom="1px solid #636366"
                        pr={{ base: "0.5rem", md: "1rem" }}
                        pl={0}
                    >
                        <Th px={0} pl={2}>
                            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                {t("dashboard.cryptoAssets")}
                            </Text>
                        </Th>

                        <Th px={0} textAlign="right" display={{ base: "none", sm: "table-cell" }}>
                            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                {t("dashboard.marketPrice")}
                            </Text>
                        </Th>

                        <Th px={0} textAlign="right" display={{ base: "none", sm: "table-cell" }}>
                            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                {t("dashboard.24hPerformance")}
                            </Text>
                        </Th>

                        <ThSorting
                            isSelected={false}
                            isAsc={sortOrder === TotalBalanceSort.ASC}
                            isDesc={sortOrder === TotalBalanceSort.DESC}
                            px={0}
                            text={t("dashboard.totalPerformance")}
                            textAlign="right"
                            onClick={() => setSortOrder((prev) => prev * -1)}
                        />
                    </Tr>
                </Thead>

                <Tbody w="full" py={1}>
                    {isLoading ? (
                        [1, 2, 3].map((i) => <CoinBalanceTableRowSkeleton key={i} />)
                    ) : (
                        <>
                            {dailyInsights && allAssetDetails && coins.length > 0 ? (
                                sortedData.map(
                                    ({ symbol, balance, value }) =>
                                        symbol in allAssetDetails &&
                                        symbol in dailyInsights && (
                                            <CoinBalanceTableRow
                                                key={symbol}
                                                assetDetails={allAssetDetails[symbol]}
                                                assetInsights={dailyInsights[symbol]}
                                                balance={balance}
                                                value={value}
                                            />
                                        )
                                )
                            ) : (
                                <Tr borderWidth="1px" borderColor="grey.light.5" borderRadius="0px 0px 6px 6px">
                                    <Flex flexDir="column" px={6} pt={{ base: 6, sm: 9 }} pb={6} w="full" gap={6}>
                                        <Text color="grey.light.50" textStyle="ManropeSemiboldBodySmall">
                                            {t("dashboard.noAssets")}
                                        </Text>

                                        <Flex
                                            alignItems="center"
                                            cursor="pointer"
                                            gap={1}
                                            sx={{
                                                "&:hover": {
                                                    textDecorationColor: "blue.100 !important",
                                                    textDecoration: "underline",
                                                },
                                                "&:active": {
                                                    textDecorationColor: "blue.70 !important",
                                                    textDecoration: "underline",
                                                },
                                            }}
                                            onClick={onBrowseCoinsClick}
                                        >
                                            <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                                                {t("dashboard.browseCoins")}
                                            </Text>
                                            <Icon as={FaChevronRight} size={16} color="blue.100" />
                                        </Flex>
                                    </Flex>
                                </Tr>
                            )}
                        </>
                    )}
                </Tbody>
            </Table>
        </Flex>
    )
}
