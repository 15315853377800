import marketDetails from "./marketDetails.json"
import trade from "./trade.json"
import openOrders from "./openOrders.json"
import transactions from "./transactions.json"
import buysell from "./buysell.json"
import transfers from "./transfers.json"
import userRestrictions from "./userRestrictions.json"
import nav from "./nav.json"
import netcoinsPay from "./netcoinsPay.json"
import dashboard from "./dashboard.json"

export default {
    trade,
    marketDetails,
    openOrders,
    transactions,
    buysell,
    transfers,
    userRestrictions,
    nav,
    netcoinsPay,
    dashboard,
}
