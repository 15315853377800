import { Box, Text } from "@chakra-ui/react"
import RadioSelect, { RadioSelectOption } from "@components/ui/radio/radioSelect"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const CryptoSelection: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { selectedCoin, setSelectedCoin, coinRadioOptions, resetErrors } = DrawerWithdrawContext.useContainer()

    useEffect(() => {
        if (!selectedCoin && coinRadioOptions.length > 0) {
            setSelectedCoin(coinRadioOptions.find((coin) => coin.symbol.toUpperCase() === "BTC") || null)
        }
    }, [coinRadioOptions])

    const updateSelectedCoin = (coin: RadioSelectOption | null) => {
        setSelectedCoin(coin)
        resetErrors()
    }

    return (
        <Box mt={7} flex={1} overflow={"auto"} display={"flex"} flexDirection={"column"} height={"100%"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.crypto.title", { ns: "transfer" })}</Text>
            <Box mt={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("withdraw.crypto.selectCrypto", { ns: "transfer" })}</Text>
            </Box>
            <RadioSelect options={coinRadioOptions} onSelect={updateSelectedCoin} selectedOption={selectedCoin} />
        </Box>
    )
}
