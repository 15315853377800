import { Flex } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { BankDetails, IntlBankDetails, USBankDetails } from "@redux/withdraw/types"
import { useTranslation } from "react-i18next"
import AmericanBankDetails from "./americanBankDetails"
import CanadianBankDetails from "./canadianBankDetails"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    focusedBank: BankDetails | IntlBankDetails | USBankDetails | null
    isCA: boolean
    toggleDeleteModal: () => void
    setFocusedBank: (bank: BankDetails | IntlBankDetails | USBankDetails | null) => void
    openEditDetails: () => void
}

export default function ViewBankAccountDetails({
    isOpen,
    toggleSideDrawerOpen,
    isCA,
    toggleDeleteModal,
    focusedBank,
    openEditDetails,
    setFocusedBank,
}: Props) {
    const { t } = useTranslation("settings")

    function handleMainButtonClick() {
        if (isCA) {
            toggleSideDrawerOpen()
            openEditDetails()
        } else {
            toggleDeleteModal()
        }
    }

    function handleClose() {
        toggleSideDrawerOpen()
        setFocusedBank(null)
    }

    return (
        <SideDrawer
            name="viewBankAccountDetails"
            isOpen={isOpen}
            toggleSideDrawerOpen={handleClose}
            size="md"
            header={<StandardDrawerHeader title={t("savedFiatAccount.bankAccountDetails")} onClose={handleClose} />}
            extraFooter={
                isCA ? (
                    <StandardButton
                        variant={ENUM_BUTTON_VARIANTS.RED_GHOST}
                        children={t("savedFiatAccount.deleteAcc")}
                        w="100%"
                        mb="10px"
                        onClick={toggleDeleteModal}
                    />
                ) : null
            }
            footerButton={{
                children: isCA ? t("savedFiatAccount.editDetails") : t("savedFiatAccount.deleteAcc"),
                variant: isCA ? ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE : ENUM_BUTTON_VARIANTS.RED_GHOST,
                type: "button",
                onClick: handleMainButtonClick,
            }}
        >
            {focusedBank === null ? null : (
                <Flex mt={7} gap={6} flexDir="column">
                    {isCA ? (
                        <CanadianBankDetails focusedBank={focusedBank as BankDetails | IntlBankDetails} />
                    ) : (
                        <AmericanBankDetails focusedBank={focusedBank as USBankDetails} />
                    )}
                </Flex>
            )}
        </SideDrawer>
    )
}
