import { Box, BoxProps, Text } from "@chakra-ui/react"

type PillProps = {
    text: string
    isSelected: boolean
} & BoxProps

export const Pill = ({ text, isSelected, ...rest }: PillProps) => {
    return (
        <Box
            data-test-id={`pill-${text}`}
            as={"button"}
            display="inline-block"
            px={3}
            py={1.5}
            transition="all 0.2s"
            borderRadius="full"
            bg={isSelected ? "blue.100" : "grey.light.5"}
            {...rest}
        >
            <Text color={isSelected ? "white" : "grey.light.90"} textStyle="ManropeSemiboldBodySmall">
                {text}
            </Text>
        </Box>
    )
}
