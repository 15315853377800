import { Box, HStack } from "@chakra-ui/react"
import Select from "components/ui/select"
import TextInput from "components/ui/textInput"
import { useTranslation } from "react-i18next"
import { Control } from "react-hook-form"
import { RegionOptions } from "util/optionsFormatted"
import { TCountryType } from "@/types"

export type AddressFormData = {
    street_number: string
    street_name: string
    building_address: string
    city: string
    region: { value: string; label: string }
    country: { value: TCountryType; label: string }
    postal_code: string
}

type Props = {
    control: Control<AddressFormData>
    isCanada: boolean
    areLocationsDisabled: boolean
}

/*
  Note that this screen is designed for CA or USA only.
  Be aware of this if one day someone decides to include all countries.
  Namely, the postal code and region fields will have to be reworked.
*/

export default function AddressForm({ control, isCanada, areLocationsDisabled }: Props) {
    const { t } = useTranslation("onboarding")

    return (
        <Box display="flex" flexDirection={"column"} rowGap="1.5rem">
            <HStack spacing="1.5rem" alignItems="flex-start">
                <TextInput
                    name="street_number"
                    label={t("whereLive.streetNumber")}
                    control={control}
                    maxLength={25}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                />
                <TextInput
                    name="street_name"
                    maxLength={125}
                    label={t("whereLive.streetName")}
                    control={control}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                />
            </HStack>
            <TextInput name="building_address" label={t("whereLive.extraAddress")} control={control} maxLength={125} />
            <HStack spacing="1.5rem" alignItems="flex-start">
                <TextInput
                    name="city"
                    label={t("whereLive.city")}
                    control={control}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                />
                <Select
                    name="region"
                    label={isCanada ? t("whereLive.province") : t("whereLive.state")}
                    control={control}
                    rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                    options={isCanada ? RegionOptions.provinces : RegionOptions.states}
                    disabled={areLocationsDisabled}
                />
            </HStack>
            <HStack spacing="1.5rem" alignItems="flex-start">
                <TextInput
                    name="postal_code"
                    maxLength={isCanada ? 7 : 5}
                    label={isCanada ? t("whereLive.postalCode") : t("whereLive.zipCode")}
                    control={control}
                    placeholder={
                        isCanada ? t("whereLive.placeholder.postalZip.CA") : t("whereLive.placeholder.postalZip.US")
                    }
                    rules={{
                        required: t("error.required", { ns: "common" }),
                        maxLength: {
                            value: isCanada ? 7 : 5,
                            message: isCanada ? t("whereLive.postalCodeInvalid") : t("whereLive.zipCodeInvalid"),
                        },
                        pattern: {
                            value: isCanada ? /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/ : /^\d{5}(-\d{4})?$/,
                            message: isCanada ? t("whereLive.postalCodeInvalid") : t("whereLive.zipCodeInvalid"),
                        },
                    }}
                />
                <Select
                    name="country"
                    label={t("whereLive.country")}
                    control={control}
                    rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                    options={[
                        { value: "CA", label: "Canada" },
                        { value: "US", label: "United States" },
                    ]}
                    disabled={areLocationsDisabled}
                />
            </HStack>
        </Box>
    )
}
