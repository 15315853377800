import SideDrawer, { SIDE_DRAWER_BUTTON_ID } from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex, Text } from "@chakra-ui/react"
import RegisterMenu from "./views/RegisterMenu"
import ConfirmDetails from "./views/ConfirmDetails"
import ReviewTermsAndConditions from "./views/ReviewTermsAndConditions"
import RegistrationComplete from "./views/RegistrationComplete"
import KohoLogo from "@assets/svgs/misc/koho-logo"
import { CardType, InitialState, NPayRegContext } from "./nPayRegistrationContext"
import { set, values } from "lodash"
import useSupport from "@hooks/useSupport"
import { useAddNPayCardMutation, useRegisterNpayMutation } from "@redux/netcoinsPay/apiSlice"
import { TCardType } from "@redux/netcoinsPay/types"
import useGenericToast from "@hooks/useGenericToast"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

export interface nPayRegProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    cardType?: CardType
}

function RegisterForNetcoinsPay({ isOpen, toggleSideDrawerOpen, cardType }: nPayRegProps) {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })
    const { t: ct } = useTranslation("common")

    const [loading, setLoading] = useState(false)
    const { errorMessage, disclaimers, setErrorMessage, resetState, currentCardType } = NPayRegContext.useContainer()

    const nPayActive = useAppSelector(selectAccountDetails)?.features?.ncpay?.active || false

    const { serverErrorToast } = useGenericToast()

    const { supportEmail } = useSupport()

    const handleEmailClick = () => {
        window.location.href = `mailto:${supportEmail}`
    }

    const [view, setView] = useState(1)

    useEffect(() => {
        if (cardType) {
            setView(2)
        }
    }, [cardType])

    const [addCard] = useAddNPayCardMutation()
    const [registerNPay] = useRegisterNpayMutation()

    const closeModal = () => {
        if (cardType) {
            setView(2)
        } else {
            setView(1)
        }
        toggleSideDrawerOpen()
        resetState()
    }

    const handleRegistration = async () => {
        if (values(disclaimers).some((disclaimer) => !disclaimer)) {
            setErrorMessage(ct("error.acceptTerms"))
            return
        }

        const cardTypeMap = {
            all: ["mc-virtual", "mc-physical"],
            virtual: ["mc-virtual"],
            physical: ["mc-physical"],
        } as Record<CardType, TCardType[]>

        const cardsToRegister = cardTypeMap[currentCardType || "all"]

        setLoading(true)

        try {
            if (nPayActive) {
                await addCard({ cardTypes: cardsToRegister }).unwrap()
            } else {
                await registerNPay({ cardTypes: cardsToRegister }).unwrap()
            }
            setView((prev) => {
                return Math.min(prev + 1, 4)
            })
        } catch (error) {
            serverErrorToast()
        } finally {
            setLoading(false)
        }
    }

    const handleCardTypeSelection = () => {
        if (!currentCardType) {
            setErrorMessage(t("selectCardType"))
            return
        }

        setView((prev) => {
            return Math.min(prev + 1, 4)
        })
    }

    const next = () => {
        setErrorMessage("")

        if (view === 1) {
            handleCardTypeSelection()
            return
        }

        if (view === 3) {
            handleRegistration()
            return
        }

        if (view === 4) {
            closeModal()
            return
        }

        setView((prev) => {
            return Math.min(prev + 1, 4)
        })
    }

    const previous = () => setView((prev) => Math.max(prev - 1, 1))

    const currentView = useMemo(() => {
        const views: Record<number, JSX.Element> = {
            1: <RegisterMenu />,
            2: <ConfirmDetails />,
            3: <ReviewTermsAndConditions />,
            4: <RegistrationComplete />,
        }

        return views[view] || null
    }, [view, next])

    const buttonText = useMemo(() => {
        const textMap: Record<number, string> = {
            3: t("completeOrder"),
            4: t("viewDashboard"),
            1: ct("continue"),
        }

        return textMap[view] || ct("continue")
    }, [view, t, ct])

    const extraFooter = useMemo(() => {
        const footers: Record<number, JSX.Element | null> = {
            1: errorMessage ? (
                <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3} textAlign={"center"}>
                    {errorMessage}
                </Text>
            ) : null,
            2: (
                <StandardButton
                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_SOLID}
                    children={t("emailCustomerSupport")}
                    w="100%"
                    mb="10px"
                    onClick={handleEmailClick}
                />
            ),
            3: errorMessage ? (
                <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3} textAlign={"center"}>
                    {errorMessage}
                </Text>
            ) : null,
            4: (
                <Flex flexDir="column" gap={2} mb={7}>
                    <Flex gap={1} alignItems="center">
                        <Text color="grey.light.80" textStyle="InterRegularXSmall">
                            {t("poweredByKOHO")}
                        </Text>
                        <KohoLogo />
                    </Flex>
                    <Text color="grey.light.80" textStyle="InterRegularXSmall">
                        {t("cardIssuedByKOHO")}
                    </Text>
                </Flex>
            ),
        }

        return footers[view] || null
    }, [view, t, errorMessage])

    const showBackButton = useMemo(() => {
        if (cardType && view === 2) {
            return undefined
        }
        return view > 1 && view < 4 ? previous : undefined
    }, [cardType, view])

    return (
        <>
            <SideDrawer
                name="registerForNetcoinsPay"
                isOpen={isOpen}
                toggleSideDrawerOpen={closeModal}
                size="md"
                header={
                    <StandardDrawerHeader
                        title={view !== 5 ? t("registerForNetcoinsPay") : undefined}
                        onClose={closeModal}
                        onBack={showBackButton}
                    />
                }
                footerButton={{
                    children: buttonText,
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    onClick: next,
                    id: SIDE_DRAWER_BUTTON_ID,
                    isLoading: loading,
                }}
                extraFooter={extraFooter}
            >
                <Flex mt={view !== 5 ? 0 : 7} flexDir="column" gap={6} height="100%">
                    {currentView}
                </Flex>
            </SideDrawer>
        </>
    )
}

const NPayRegHOC: React.FC<nPayRegProps> = (props) => (
    <NPayRegContext.Provider initialState={{ ...InitialState, ...props }}>
        <RegisterForNetcoinsPay {...props} />
    </NPayRegContext.Provider>
)

export default NPayRegHOC
