import { useAppSelector } from "@/store/hooks"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import CoinBalanceTable from "@components/dashboard/coinbalance/coinBalanceTable"
import CryptoToCashRatio from "@components/dashboard/cryptoToCashRatio"
import { DonutDashboardChart } from "@components/dashboard/donutDashboardChart"
import Favourites from "@components/dashboard/favourites"
import { DashboardFiatBalance } from "@components/dashboard/fiatBalance"
import DashboardOpenOrders from "@components/dashboard/openOrders/openOrdersTable"
import { PortfolioBalances } from "@components/dashboard/portfolioBalances"
import { PortfolioValue } from "@components/dashboard/portfolioValue"
import { PromoBanners } from "@components/dashboard/promoBanners"
import { DashboardTransactionsTable } from "@components/dashboard/transactions"
import { UserStateBanner } from "@components/dashboard/userStateBanner"
import DashboardFooter from "@components/footer/dashboard"
import Tooltip from "@components/tooltip"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import ScreenContainer from "@components/ui/containers/screen"
import useRegion from "@hooks/useRegion"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { ROUTES } from "@routing/routes"
import TwoFASetupDrawerHOC from "@screens/twoFASetup/drawerTwoFASetup"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaChevronRight } from "react-icons/fa"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { MdAdd, MdArrowDownward, MdArrowUpward, MdRemove } from "react-icons/md"
import DrawerOpenOrder from "../openOrders/drawerOpenOrders"
import DrawerCoinTrade, { BUYSELL_TAB } from "../trade/drawerCoinTrade"
import DrawerTransactionDetails from "../transactions/drawerTransactionDetails"
import FundDrawerHOC from "../transfer/drawerFund"
import WithdrawDrawerHOC from "../transfer/drawerWithdraw"

export default function Home() {
    const { navigate } = useRestrictedNavigation()

    const { t } = useTranslation("app")
    const { t: ct } = useTranslation("common")
    const userData = useAppSelector(selectAccountDetails)
    const isTFASetup = useMemo(() => userData?.tfa, [userData])
    const { data: assetDetails } = useGetAssetsDetailsQuery({})

    const [tab, setTab] = useState(BUYSELL_TAB.BUY)
    const [tradeDrawerOpen, toggleTradeDrawerOpen] = useState(false)
    const [fundingDrawerOpen, toggleFundingDrawerOpen] = useState(false)
    const [twoFASetupDrawerOpen, toggleSetupTwoFADrawerOpen] = useState(false)
    const [withdrawDrawerOpen, toggleWithdrawDrawerOpen] = useState(false)
    const [selectedOpenOrder, setSelectedOpenOrder] = useState<Transaction | null>(null)
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null)
    const { isCAUser } = useRegion()
    const { evaluateRestriction } = useRestrictedNavigation()

    const handleWithdrawDrawerOpen = () => {
        if (!isTFASetup) {
            toggleSetupTwoFADrawerOpen(true)
            return
        }
        evaluateRestriction(RESTRICTED_FLOWS.WITHDRAW, () => toggleWithdrawDrawerOpen(true), { blockWithModal: true })
    }

    const handleFundDrawerOpen = () => {
        if (!isTFASetup) {
            toggleSetupTwoFADrawerOpen(true)
            return
        }
        evaluateRestriction(RESTRICTED_FLOWS.DEPOSIT, () => toggleFundingDrawerOpen(true), { blockWithModal: true })
    }

    const handleTradeDrawerOpen = (tab: BUYSELL_TAB) => {
        if (!isTFASetup) {
            toggleSetupTwoFADrawerOpen(true)
            return
        }
        evaluateRestriction(
            RESTRICTED_FLOWS.TRADE,
            () => {
                toggleTradeDrawerOpen(true)
                setTab(tab)
            },
            { blockWithModal: true }
        )
    }

    const navigateToTransactions = () => {
        navigate(ROUTES.DASHBOARD_TRANSACTIONS)
    }

    return (
        <Box w={"full"}>
            <UserStateBanner />
            <ScreenContainer>
                <Flex gap={6} flexDir="column" mt={4} pb={"1rem"}>
                    <Flex gap={3} justifyContent={"space-between"} flexDir={{ base: "column", lg: "row" }}>
                        <PortfolioValue />

                        <Box
                            display="grid"
                            gridTemplateColumns={{ base: "1fr 1fr", sm: "repeat(4, 1fr)" }}
                            rowGap="10px"
                            columnGap="10px"
                            width={{ base: "100%", lg: "50%" }}
                            justifyContent="center"
                            mt="auto"
                        >
                            <StandardButton
                                flex={1}
                                type="button"
                                leftIcon={MdAdd}
                                variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                onClick={() => handleTradeDrawerOpen(BUYSELL_TAB.BUY)}
                                alignSelf="flex-end"
                                size="xl"
                            >
                                {t("buy", { ns: "common" })}
                            </StandardButton>
                            <StandardButton
                                flex={1}
                                type="button"
                                leftIcon={MdRemove}
                                variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                onClick={() => handleTradeDrawerOpen(BUYSELL_TAB.SELL)}
                                alignSelf="flex-end"
                                size="xl"
                            >
                                {t("sell", { ns: "common" })}
                            </StandardButton>

                            <StandardButton
                                flex={1}
                                type="button"
                                leftIcon={MdArrowDownward}
                                variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                onClick={() => handleFundDrawerOpen()}
                                alignSelf="flex-end"
                                size="xl"
                            >
                                {ct("fund")}
                            </StandardButton>

                            <StandardButton
                                flex={1}
                                type="button"
                                leftIcon={MdArrowUpward}
                                variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                                onClick={() => handleWithdrawDrawerOpen()}
                                alignSelf="flex-end"
                                size="xl"
                            >
                                {ct("withdraw")}
                            </StandardButton>
                        </Box>
                    </Flex>

                    <PortfolioBalances />

                    <Flex gap={6} flexDir={{ base: "column", xl: "row" }} w="full">
                        <Flex flexBasis={"70%"} flexDir="column" width={{ base: "100%" }} gap={6}>
                            <Flex flexDir="column" rowGap={6}>
                                <Flex alignItems={"center"} gap={1}>
                                    <Text textStyle="ManropeSemiboldBody">{t("dashboard.portfolioComposition")}</Text>
                                    <Tooltip
                                        bodyText={t("dashboard.tooltip.donut")}
                                        triggerComponent={
                                            <Flex mt={0.5}>
                                                <ReactIcon
                                                    icon={IoMdInformationCircleOutline}
                                                    size={16}
                                                    color="blue.100"
                                                />
                                            </Flex>
                                        }
                                    />
                                </Flex>

                                <Flex flexDir="column" gap={3}>
                                    <DonutDashboardChart />

                                    <CryptoToCashRatio />
                                </Flex>
                            </Flex>

                            <Flex flexDir="column" gap={3}>
                                <Text textStyle="ManropeSemiboldBody">{t("dashboard.myBalances")}</Text>

                                <DashboardFiatBalance onAddFundsClick={() => handleFundDrawerOpen()} />

                                <CoinBalanceTable />
                            </Flex>
                            {isCAUser && <DashboardOpenOrders onViewMorePress={setSelectedOpenOrder} />}
                        </Flex>

                        <Flex flexDir="column" gap={6} width={{ base: "100%", xl: "35%" }}>
                            <Favourites />
                            <PromoBanners />
                            <Flex flexDir="column" gap={4}>
                                <Flex width="100%" justifyContent="space-between">
                                    <Text textStyle="ManropeSemiboldBody">{t("dashboard.recentTransactions")}</Text>

                                    <Flex
                                        alignItems="center"
                                        cursor="pointer"
                                        gap={1}
                                        ml={{ base: "auto", md: "unset" }}
                                        whiteSpace="nowrap"
                                        sx={{
                                            "&:hover": {
                                                textDecorationColor: "blue.100 !important",
                                                textDecoration: "underline",
                                            },
                                            "&:active": {
                                                textDecorationColor: "blue.70 !important",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={navigateToTransactions}
                                    >
                                        <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                                            {t("dashboard.viewAll")}
                                        </Text>
                                        <Icon as={FaChevronRight} size={16} color="blue.100" />
                                    </Flex>
                                </Flex>

                                <DashboardTransactionsTable onViewMorePress={setSelectedTransaction} />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <DashboardFooter />
                {assetDetails && "BTC" in assetDetails && (
                    <DrawerCoinTrade
                        initialAsset={{ ...assetDetails["BTC"] }}
                        showCoinSelect
                        isOpen={tradeDrawerOpen}
                        toggleSideDrawerOpen={() => toggleTradeDrawerOpen(false)}
                        tab={tab}
                        setTab={setTab}
                        isOverlay
                    />
                )}
                <DrawerTransactionDetails
                    transaction={selectedTransaction}
                    isOpen={!!selectedTransaction}
                    toggleSideDrawerOpen={() => setSelectedTransaction(null)}
                />
                <DrawerOpenOrder
                    order={selectedOpenOrder}
                    isOpen={!!selectedOpenOrder}
                    toggleSideDrawerOpen={() => setSelectedOpenOrder(null)}
                />
                <FundDrawerHOC
                    isOpen={fundingDrawerOpen}
                    toggleSideDrawerOpen={() => toggleFundingDrawerOpen(false)}
                    fundType={undefined}
                />
                <TwoFASetupDrawerHOC
                    isOpen={twoFASetupDrawerOpen}
                    toggleSideDrawerOpen={() => toggleSetupTwoFADrawerOpen(false)}
                />
                <WithdrawDrawerHOC
                    isOpen={withdrawDrawerOpen}
                    toggleSideDrawerOpen={() => toggleWithdrawDrawerOpen(false)}
                    withdrawType={undefined}
                />
            </ScreenContainer>
        </Box>
    )
}
