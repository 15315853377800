export const FIELD_SIZE: Record<string, number> = {
    sm: 24,
    md: 32,
    lg: 48,
}

export const FIELD_ICON_SIZE: Record<string, number> = {
    sm: 14,
    md: 18,
    lg: 24,
    xl: 18,
}

export type Option<T extends string | number> = {
    value: T // use (1,0) for boolean
    label: string
    desc?: string
}
