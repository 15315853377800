import { Box, Flex, Text } from "@chakra-ui/react"
import { Pill } from "@components/ui/pill"
import { commaStringToFloat, formatStringWithCommas } from "@util/numericalFormatting"
import React, { FormEvent, useEffect, useRef, useState } from "react"
import { MdAttachMoney } from "react-icons/md"
import { FormField } from ".."

export type CurrencyData = {
    symbol: string
    precision: string | number
    dollarSign?: boolean
}

type BaseCurrencyFields = {
    balance: { quantity: number; focus: "currencyOne" | "currencyTwo" }
    placeholder?: string
    errorMessage?: string
    label?: string
    required?: boolean
}

type SingleCurrencyMode = {
    mode: "single"
    currencies: [CurrencyData]
    value?: { currencyOne: string }
    onTextChange: (values: { currencyOne: string; focus: "currencyOne" }) => void
}

type DualCurrencyMode = {
    mode: "dual"
    exchangeRate: number
    currencies: [CurrencyData, CurrencyData]
    value?: { currencyOne: string; currencyTwo: string }
    onTextChange: (values: { currencyOne: string; currencyTwo: string; focus: "currencyOne" | "currencyTwo" }) => void
}

export type CurrencySwitchFormFieldProps = BaseCurrencyFields & (SingleCurrencyMode | DualCurrencyMode)

export const CurrencySwitchFormField: React.FC<CurrencySwitchFormFieldProps> = (
    props: CurrencySwitchFormFieldProps
) => {
    const { currencies, balance, label, value, placeholder = "0", errorMessage, onTextChange, required, mode } = props
    const exchangeRate = (props as DualCurrencyMode).exchangeRate ?? 1
    const prevCurrencies = useRef({ currencies }).current

    const [currencyOne, currencyTwo] = currencies
    const [highlightedPill, setHighlightedPill] = useState<number | null>(null)

    useEffect(() => {
        setHighlightedPill(null)
    }, [balance.focus])

    useEffect(() => {
        if (prevCurrencies.currencies.length > 1) {
            if (
                prevCurrencies.currencies[0].symbol !== currencies[0].symbol ||
                prevCurrencies.currencies[1]?.symbol !== currencies[1]?.symbol
            ) {
                setHighlightedPill(null)
            }
        } else {
            if (prevCurrencies.currencies[0].symbol !== currencies[0].symbol) {
                setHighlightedPill(null)
            }
        }

        return () => {
            prevCurrencies.currencies = currencies
        }
    }, [currencies])

    const convertCryptoToDollar = (amount: number) => {
        return amount * exchangeRate
    }

    const convertDollarToCrypto = (amount: number) => {
        return amount / exchangeRate
    }

    const formatFieldText = (value: string, focusField: "currencyOne" | "currencyTwo") => {
        if (value === "") {
            return {
                currencyOne: "",
                ...(mode === "dual" && { currencyTwo: "" }),
            }
        }
        const currency = focusField === "currencyOne" ? currencyOne : currencyTwo
        const parsedVal = commaStringToFloat(value, Number(currency?.precision))

        let newState = {
            currencyOne: "",
            ...(mode === "dual" && { currencyTwo: "" }),
        }

        if (focusField === "currencyOne") {
            const convertedVal = convertCryptoToDollar(parsedVal)
            newState = {
                currencyOne: formatStringWithCommas(value, Number(currencyOne.precision)) ?? "",
                ...(mode === "dual" && {
                    currencyTwo: formatStringWithCommas(String(convertedVal), Number(currencyTwo?.precision)) ?? "",
                }),
            }
        } else {
            const convertedVal = convertDollarToCrypto(parsedVal)
            newState = {
                currencyOne: formatStringWithCommas(String(convertedVal), Number(currencyOne.precision)) ?? "",
                currencyTwo: formatStringWithCommas(value, Number(currencyTwo?.precision)) ?? "",
            }
        }
        return newState
    }

    const handleOnTextChange = (
        newState: { currencyOne: string; currencyTwo?: string },
        field: "currencyOne" | "currencyTwo"
    ) => {
        if (mode === "dual") {
            onTextChange({
                currencyOne: newState.currencyOne,
                currencyTwo: newState.currencyTwo!,
                focus: field,
            })
        } else {
            onTextChange({
                currencyOne: newState.currencyOne,
                focus: "currencyOne",
            })
        }
    }

    const handleChange = (e: FormEvent, field: "currencyOne" | "currencyTwo") => {
        setHighlightedPill(null)
        const element = e.currentTarget as HTMLInputElement
        const val = element.value
        const newState = formatFieldText(val, field)
        handleOnTextChange(newState, field)
    }

    const onPillPress = (percent: number) => {
        setHighlightedPill(percent)
        const amount = balance.quantity * percent
        const newState = formatFieldText(String(amount), balance.focus)
        handleOnTextChange(newState, balance.focus)
    }

    return (
        <Box>
            <FormField
                inputMode="decimal"
                size={"lg"}
                isRequired={required}
                mb={2}
                placeholder={placeholder}
                value={value?.currencyOne}
                leftElement={currencyOne.dollarSign ? <MdAttachMoney /> : undefined}
                label={label}
                onChange={(e) => handleChange(e, "currencyOne")}
                rightElement={
                    <Text as="span" textStyle="ManropeSemiboldBody">
                        {currencyOne.symbol}
                    </Text>
                }
                isInvalid={!!errorMessage}
                errorMessage={mode === "single" && errorMessage !== "" ? errorMessage : undefined}
            />
            <Box h={2} />
            {mode === "dual" && (
                <FormField
                    inputMode="decimal"
                    size={"lg"}
                    placeholder={placeholder}
                    value={mode === "dual" ? value?.currencyTwo : ""}
                    onChange={(e) => handleChange(e, "currencyTwo")}
                    leftElement={<MdAttachMoney />}
                    rightElement={
                        <Text as="span" textStyle="ManropeSemiboldBody">
                            {currencyTwo?.symbol}
                        </Text>
                    }
                    errorMessage={errorMessage}
                />
            )}
            <Flex mt={4} gap={3} justifyContent={"space-between"}>
                <Pill
                    text="25%"
                    width={"100%"}
                    onClick={() => onPillPress(0.25)}
                    isSelected={highlightedPill === 0.25}
                />
                <Pill text="50%" width={"100%"} onClick={() => onPillPress(0.5)} isSelected={highlightedPill === 0.5} />
                <Pill
                    text="75%"
                    width={"100%"}
                    onClick={() => onPillPress(0.75)}
                    isSelected={highlightedPill === 0.75}
                />
                <Pill text="Max" width={"100%"} onClick={() => onPillPress(1)} isSelected={highlightedPill === 1} />
            </Flex>
        </Box>
    )
}
