// Local routes
export const CALLBACK_URL = `${process.env.REACT_APP_URL}/api/auth/callback`
export const REDIRECT_URL = "/api/auth/redirect"
export const REFRESH_URL = "/api/auth/refresh"
export const LOGOUT_ROUTE = "/api/auth/logout"

// OAuth Origin routes
export const OAUTH_ORIGIN = `${process.env.REACT_APP_BASE_URL}`
export const OAUTH_TOKEN_ROUTE = `${OAUTH_ORIGIN}/oauth/token`
export const OAUTH_AUTHORIZATION_ROUTE = `${OAUTH_ORIGIN}/oauth/authorize`
export const REVOKE_ROUTE = `${process.env.REACT_APP_BASE_URL}/oauth/token/revoke`
export const OAUTH_LOGOUT_ROUTE = `${process.env.REACT_APP_BASE_URL}/logout`

export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID as string
export const REFRESH_TOKEN_LIFETIME_MS = 60 * 60 * 1000 // 1 hour
