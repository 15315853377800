import CountriesJSON from "@/json/countries.json"
import RegionsJSON from "@/json/provinces.json"
import { TCountryType } from "@/types"

/*
    These classes are singletons that hold the formatted options for countries and regions
    So they're not recalculated every time they're used
*/

class Countries {
    countries: { value: keyof typeof CountriesJSON; label: string }[]

    constructor() {
        this.countries = Object.entries(CountriesJSON).map((country) => ({
            value: country[0] as keyof typeof CountriesJSON,
            label: country[1],
        }))
    }
}
export const CountryOptions = new Countries()

class Regions {
    provinces: { value: string; label: string }[]
    states: { value: string; label: string }[]
    // permittedStates: { value: string, label: string }[];

    constructor() {
        this.provinces = Object.entries(RegionsJSON.provinces).map((province) => ({
            value: province[0],
            label: province[1],
        }))
        this.states = Object.entries(RegionsJSON.states).map((state) => ({ value: state[0], label: state[1] }))
        // this.permittedStates = Object.entries(RegionsJSON.states).reduce((arr, state) => {
        //     if(state[0] !== 'NY' && state[0] !== 'LA' && state[0] !== 'HI'){
        //         arr.push({ value: state[0], label: state[1] });
        //     }
        //     return arr;
        // }, [] as { value: string, label: string }[]);
    }
}
export const RegionOptions = new Regions()

export function formatDefaultLocationValues(
    country: TCountryType,
    region: string
): { country: { value: TCountryType; label: string }; region: { value: string; label: string } } | undefined {
    if (country && region) {
        const countryLabel = CountriesJSON[country as keyof typeof CountriesJSON]
        const regionCodes = country === "CA" ? RegionsJSON.provinces : RegionsJSON.states
        const regionLabel = regionCodes[region as keyof typeof regionCodes]
        if (countryLabel && regionLabel) {
            return {
                country: {
                    value: country,
                    label: countryLabel,
                },
                region: {
                    value: region,
                    label: regionLabel as string,
                },
            }
        }
    }
    return undefined
}

export function formatDefaultCountry(
    country: keyof typeof CountriesJSON
): { value: keyof typeof CountriesJSON; label: string } | undefined {
    if (country) {
        const countryLabel = CountriesJSON[country as keyof typeof CountriesJSON]
        if (countryLabel) {
            return {
                value: country,
                label: countryLabel,
            }
        }
    }
    return undefined
}

export function formatDefaultRegion<T>(countryCode: T, region: string): { value: string; label: string } | undefined {
    if (region) {
        const regionCodes = countryCode === "CA" ? RegionsJSON.provinces : RegionsJSON.states
        const regionLabel = regionCodes[region as keyof typeof regionCodes]
        if (regionLabel) {
            return {
                value: region,
                label: regionLabel,
            }
        }
    }
    return undefined
}
