import { Box, Flex, HStack, Spacer, Text, theme, VStack } from "@chakra-ui/react"
import Chip, { ChipOptionalVariant } from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import StandardButton, { StandardButtonOptionalVariant } from "@components/ui/buttons/standard"
import { ReactNode } from "react"
import CheckboxComp from "@components/ui/checkbox"

interface CardCheckBoxProps {
    checked: boolean
    label: string
    primaryText?: string
    secondaryText?: string
    leftButton?: StandardButtonOptionalVariant
    rightButton?: StandardButtonOptionalVariant
    chip?: ChipOptionalVariant
    onPress: () => void
    logo?: ReactNode
}

const CardCheckbox: React.FC<CardCheckBoxProps> = ({
    checked,
    label,
    primaryText,
    secondaryText,
    chip,
    onPress,
    logo,
    leftButton,
    rightButton,
}) => {
    return (
        <Box
            borderWidth={checked ? 2 : 1}
            borderColor={checked ? "blue.100" : "grey.light.10"}
            borderRadius={4}
            onClick={onPress}
            overflow={"hidden"}
            _hover={{
                cursor: "pointer",
                borderColor: "transparent",
                borderWidth: checked ? 2 : 1,
                bg: "blue.10",
            }}
        >
            <Box
                w="full"
                borderWidth={checked ? 0 : 1}
                borderColor={checked ? "blue.100" : "transparent"}
                borderRadius={4}
                p={6}
                _hover={{
                    borderWidth: checked ? 0 : 1,
                    borderColor: "transparent",
                }}
            >
                <VStack spacing={3} alignItems={"flex-start"}>
                    <Flex width="100%" align="center" justify="space-between">
                        <CheckboxComp name={label} isChecked={checked} defaultChecked={checked}>
                            <Box flex="1" minW="0">
                                <HStack spacing={1} wrap="wrap">
                                    <Text textStyle="ManropeSemiboldBody" mr={2}>
                                        {label}
                                    </Text>
                                    {chip && (
                                        <Box flexShrink={0} alignItems={"center"}>
                                            <Chip variant={ENUM_CHIP_VARIANTS.GREEN} {...chip} />
                                        </Box>
                                    )}
                                </HStack>
                            </Box>
                        </CheckboxComp>
                        {logo && (
                            <Box
                                width={{ base: "32px", md: "48px" }}
                                height={{ base: "32px", md: "48px" }}
                                bg="transparent"
                                borderRadius="md"
                                flexShrink={0}
                            />
                        )}
                    </Flex>

                    <Text textStyle={"InterRegularBody"} color={"grey.light.80"}>
                        {primaryText}
                    </Text>
                    {secondaryText && (
                        <Text textStyle={"InterRegularBodySmall"} color={"grey.light.80"}>
                            {secondaryText}
                        </Text>
                    )}
                    {(leftButton || rightButton) && (
                        <HStack w={"100%"} flexWrap="wrap">
                            {leftButton?.variant && (
                                <StandardButton variant={leftButton.variant} size="sm" {...leftButton}>
                                    {leftButton.children}
                                </StandardButton>
                            )}
                            <Spacer />
                            {rightButton?.variant && (
                                <StandardButton variant={rightButton.variant} size="sm" {...rightButton}>
                                    {rightButton.children}
                                </StandardButton>
                            )}
                        </HStack>
                    )}
                </VStack>
            </Box>
        </Box>
    )
}

export default CardCheckbox
