import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import AmplitudeClient from "@/sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useAcceptDisclaimerMutation } from "@redux/account/apiSlice"
import { SubScreenProps } from "../useOnboarding"
import useSupport from "@hooks/useSupport"
import Checkbox from "@components/ui/checkbox"

type FormData = {
    zerohash_agreements: boolean
    netcoins_agreements: boolean
    understand: boolean
}

export default function ZeroHashDisclaimer({ submitSubForm, userObj, isLoading }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()
    const [acceptDisclaimer] = useAcceptDisclaimerMutation()
    const { openWindowPrivacy, openWindowTerms } = useSupport()

    function handleAcknowledge() {
        submitSubForm(
            acceptDisclaimer,
            {
                content_key: "zero_hash_service_en_us,zero_hash_privacy_and_regulatory_en_us,zero_hash_risk_en_us",
            },
            () => {
                AmplitudeClient.logRegistrationEvent(
                    REG_SCREENS.ZeroHashDisclaimer,
                    REGISTRATION_STEP.ZeroHashDisclaimer
                )
            }
        )
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("zerohash.title")}
            </Text>
            <form onSubmit={handleSubmit(handleAcknowledge)}>
                <Box w="full" display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="InterRegularBodySmall">{t("zerohash.line1")}</Text>
                    <Text textStyle="InterRegularBodySmall">{t("zerohash.line2")}</Text>
                    <Box>
                        <Checkbox
                            name="netcoins_agreements"
                            control={control}
                            rules={{ required: t("zerohash.errorCheckbox") }}
                            isRightSide
                        >
                            <Text flexGrow={1}>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("zerohash.line3")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterRegularBodySmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() => openWindowTerms(userObj?.country)}
                                >
                                    {t("terms", { ns: "common" })}&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("zerohash.and")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterRegularBodySmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() => openWindowPrivacy(userObj?.country)}
                                >
                                    {t("privacyPolicy", { ns: "common" })}.
                                </Text>
                            </Text>
                        </Checkbox>
                        {errors.netcoins_agreements && (
                            <Text textStyle="InterRegularBodySmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <Box>
                        <Checkbox
                            name="zerohash_agreements"
                            control={control}
                            rules={{ required: t("zerohash.errorCheckbox") }}
                            isRightSide
                        >
                            <Text flexGrow={1}>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("zerohash.line4")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterRegularBodySmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() => window.open("https://zerohash.com/netcoins", "_blank")}
                                >
                                    Zero Hash and Zero Hash Liquidity Services User Agreement,&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("zerohash.line5")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterRegularBodySmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open("https://docs.zerohash.com/page/privacy-policy", "_blank")
                                    }
                                >
                                    Privacy Policy&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("zerohash.and")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterRegularBodySmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open(
                                            "https://docs.zerohash.com/page/us-licenses-and-disclosures",
                                            "_blank"
                                        )
                                    }
                                >
                                    Regulatory Disclosures
                                </Text>
                            </Text>
                        </Checkbox>
                        {errors.zerohash_agreements && (
                            <Text textStyle="InterRegularBodySmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <Box>
                        <Checkbox
                            name="understand"
                            control={control}
                            rules={{ required: t("zerohash.errorCheckbox") }}
                            isRightSide
                        >
                            <Text as="span" textStyle="InterRegularBodySmall" flexGrow={1}>
                                {t("zerohash.line6")}
                            </Text>
                        </Checkbox>
                        {errors.understand && (
                            <Text textStyle="InterRegularBodySmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="3rem"
                    w="full"
                    isLoading={isLoading}
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
