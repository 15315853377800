import { imageMap } from "@assets/svgs/coins"
import { Flex, Image, SkeletonCircle, SkeletonText, Td, Text, Tr } from "@chakra-ui/react"
import MarketChange from "@components/ui/marketChange"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { AssetDailyInsight } from "@redux/insights/types"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { addCommasToNumber, formatDollar, removeTrailingZeros } from "@util/stringFormatting"
import { memo } from "react"

type Props = {
    assetDetails: Pick<AssetDetailsFull, "symbol" | "name" | "price" | "precision" | "24h_percent" | "price_precision">
    balance: string
    value: number
    assetInsights: AssetDailyInsight
}

const SkeletonLineGroup = memo(() => {
    return (
        <Flex gap={2} flexDir="column">
            <SkeletonText w="105px" noOfLines={1} skeletonHeight={3} />
            <SkeletonText w="105px" noOfLines={1} skeletonHeight={3} />
        </Flex>
    )
})

export const CoinBalanceTableRowSkeleton = () => {
    return (
        <Tr
            sx={{
                "& td": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                    height: {
                        base: 18,
                        sm: 14,
                    },
                },
            }}
        >
            <Td>
                <Flex gap={3} alignItems="center">
                    <SkeletonCircle size={"10"} />
                    <SkeletonLineGroup />
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <SkeletonLineGroup />
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <SkeletonLineGroup />
                </Flex>
            </Td>

            <Td>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <SkeletonLineGroup />
                </Flex>
            </Td>
        </Tr>
    )
}

export default function CoinBalanceTableRow({ assetDetails, balance, value, assetInsights }: Props) {
    const image = imageMap[assetDetails.symbol.toLowerCase() as keyof typeof imageMap]

    return (
        <Tr
            sx={{
                "& td": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                    height: {
                        base: 18,
                        sm: 14,
                    },
                },
            }}
        >
            <Td>
                <Flex gap={3} alignItems="center">
                    {image && (
                        <Image
                            src={image}
                            width={{ base: "28px", sm: "42px" }}
                            height={{ base: "28px", sm: "42px" }}
                            alt={`Bitcoin icon`}
                            borderRadius="full"
                        />
                    )}

                    <Flex flexDir="column">
                        <Text textStyle="ManropeSemiboldBodySmall">{assetDetails?.name}</Text>
                        <Text
                            textStyle={{ base: "ManropeSemiboldBodySmall", sm: "ManropeSemiboldXSmall" }}
                            color="grey.light.50"
                        >
                            {assetDetails.symbol}
                        </Text>
                    </Flex>
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <Text textStyle="ManropeSemiboldBodySmall">
                        {addCommasToNumber(removeTrailingZeros(assetDetails.price || "0"), {
                            precision: assetDetails.price_precision,
                            addDollarSign: true,
                            minFiatPrecision: true,
                        })}
                    </Text>
                    <MarketChange hideIcon={false} percent={assetDetails?.["24h_percent"] ?? 0} />
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <Text textStyle="ManropeSemiboldBodySmall">
                        {formatDollar(assetInsights.performance.simpleReturn)}
                    </Text>
                    <MarketChange
                        hideIcon={false}
                        percent={Number(assetInsights.performance.simpleReturnPercent) || 0}
                    />
                </Flex>
            </Td>

            <Td>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end" gap={1}>
                    <Text textStyle="ManropeSemiboldBodySmall">{formatDollar(value)}</Text>
                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                        {formatNumberFixedPrecision(balance, Number(assetDetails?.precision), true)}{" "}
                        {assetDetails.symbol}
                    </Text>
                </Flex>
            </Td>
        </Tr>
    )
}
