import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Flex, FormControl, FormControlProps, FormLabel, HStack, Text } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"
import { IconType } from "react-icons"
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md"
import Chip, { ChipProps } from "../badge"
import ErrorMessage from "../errorMessage"
import { Option } from "../types"

type Props<T extends string | number> = {
    options: (Option<T> & { chip?: Pick<ChipProps, "leftIcon" | "text" | "variant"> })[]
    isHorizontal: boolean
    defaultOption?: Option<T>
    label?: string
    name: string
    hideOverflow?: boolean
    error?: string
    onChangeOption: (value: T) => void
    currentValue: T
} & FormControlProps

type RadioFormProps<T extends string | number> = {
    option: Option<T>
    isChecked: boolean
    hideOverflow?: boolean
    chip?: Pick<ChipProps, "leftIcon" | "text" | "variant">
    onChange: (value: T) => void
}

type RadioGenericItemProps<T extends string | number> = {
    option: Option<T>
    icon: IconType
    isChecked: boolean
    hideOverflow?: boolean
    chip?: Pick<ChipProps, "leftIcon" | "text" | "variant">
    onClick: (value: T) => void
}

const RadioForm = <T extends string | number>({
    option,
    hideOverflow,
    chip,
    isChecked,
    onChange,
}: RadioFormProps<T>) => {
    const CheckIcon = isChecked ? MdOutlineRadioButtonChecked : MdOutlineRadioButtonUnchecked

    return (
        <RadioGenericItem
            option={option}
            hideOverflow={hideOverflow}
            chip={chip}
            isChecked={isChecked}
            onClick={onChange}
            icon={CheckIcon}
        />
    )
}

export const RadioGenericItem = <T extends string | number>({
    option,
    hideOverflow,
    chip,
    isChecked,
    onClick,
    icon: Icon,
}: RadioGenericItemProps<T>) => {
    return (
        <Box
            w={"full"}
            as="label"
            display="flex"
            flexGrow={1}
            position="relative"
            cursor="pointer"
            borderRadius="md"
            bg={isChecked ? "blue.20" : "grey.light.5"}
            borderWidth="4px"
            borderColor="transparent"
            _hover={{
                bg: "blue.100",
                color: "white",
                "& div": {
                    borderColor: "white",
                },
                "& svg": {
                    color: "white !important",
                },
                "& p": {
                    color: "white !important",
                },
            }}
            px={4}
            py={3}
            alignItems="center"
            onClick={() => onClick(option.value)}
        >
            <Flex width="100%" flexWrap="wrap" gap={2}>
                <HStack width="100%" alignItems={"flex-start"} flex="1">
                    <Box mt={0.5} flexShrink={0}>
                        <ReactIcon icon={Icon} size={20} color={isChecked ? "blue.100" : "black"} />
                    </Box>
                    <Flex flexDirection="column" rowGap={2} ml={"10px"} width="100%" overflow="hidden">
                        <Flex
                            width="100%"
                            direction={{ base: "column", md: "row" }}
                            gap={2}
                            justify="space-between"
                            align={{ base: "flex-start", md: "center" }}
                        >
                            <Text textStyle="ManropeMediumBody" whiteSpace="normal">
                                {option.label}
                            </Text>
                            {chip && <Chip {...chip} size="sm" />}
                        </Flex>
                        {option.desc && (
                            <Text
                                textStyle="InterRegularXSmall"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display={hideOverflow ? { base: "-webkit-box", md: "block" } : "block"}
                                whiteSpace={hideOverflow ? { base: "normal", md: "nowrap" } : "normal"}
                                style={
                                    hideOverflow
                                        ? {
                                              WebkitLineClamp: 2,
                                              WebkitBoxOrient: "vertical",
                                          }
                                        : undefined
                                }
                            >
                                {option.desc}
                            </Text>
                        )}
                    </Flex>
                </HStack>
            </Flex>
        </Box>
    )
}

export function RadioGroup<T extends string | number>({
    options,
    defaultOption: defaultValue,
    currentValue,
    onChangeOption,
    name,
    isHorizontal,
    label,
    hideOverflow,
    size = "lg",
    error,
    ...rest
}: Props<T>): JSX.Element {
    return (
        <Box {...rest}>
            {label && (
                <FormLabel fontSize={14} lineHeight={"100%"}>
                    {label}
                </FormLabel>
            )}
            <Flex
                flex={1}
                flexDir={{ base: "column", md: isHorizontal ? "row" : "column" }}
                data-testid={`radio-group-${name}`}
                gap={3}
                w="full"
                role="radiogroup"
            >
                {options.map((option) => (
                    <RadioForm
                        key={option.value}
                        hideOverflow={hideOverflow}
                        option={option}
                        chip={option.chip}
                        isChecked={currentValue === option.value}
                        onChange={onChangeOption}
                    />
                ))}
            </Flex>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
    )
}

type ControlledRadioProps = {
    options: (Option<any> & { chip?: Pick<ChipProps, "leftIcon" | "text" | "variant"> })[]
    isHorizontal: boolean
    defaultOption?: Option<any>
    label?: string
    hideOverflow?: boolean
} & UseControllerProps<any> &
    FormControlProps

const ControlledRadioGroup = ({
    options,
    name: propsName,
    defaultOption: defaultValue,
    isHorizontal,
    control,
    rules,
    label,
    hideOverflow,
    size = "lg",
    ...rest
}: ControlledRadioProps) => {
    const {
        field,
        fieldState: { invalid, error },
    } = useController({
        name: propsName,
        control,
        rules,
        defaultValue: defaultValue || "",
    })

    const getCurrentValue = () => {
        if (typeof field.value === "object" && field.value !== null) {
            return field.value.value
        }
        return field.value
    }

    const handleChange = (newValue: string | number) => {
        const selectedOption = options.find((opt) => opt.value === newValue)
        if (selectedOption) {
            field.onChange(selectedOption)
        }
    }

    const currentValue = getCurrentValue()

    return (
        <FormControl isInvalid={invalid} {...rest}>
            <RadioGroup
                label={label}
                name={propsName}
                currentValue={currentValue}
                isHorizontal={isHorizontal}
                options={options}
                error={error?.message}
                onChangeOption={handleChange}
                {...rest}
            />
        </FormControl>
    )
}
export default ControlledRadioGroup
