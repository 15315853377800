import { Text, Table, Tbody, Tr, Th, Thead, TableContainer, Flex, Box, Spinner } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import AccountActivityRow from "../accountActivityRow"
import { useGetActivityQuery } from "@redux/account/apiSlice"
import TablePaginator from "@components/ui/paginator/tablePaginator"

export default function AccountActivityTable() {
    const { t } = useTranslation(["settings", "common"])

    const [activityPage, setActivityPage] = useState(1)

    const {
        data: activityData,
        isLoading: isActivityLoading,
        refetch: refecthActivityData,
    } = useGetActivityQuery(activityPage)

    useEffect(() => {
        if (activityPage) {
            refecthActivityData()
        }
    }, [activityPage])

    const totalPages = useMemo(() => {
        if (activityData?.meta?.total === undefined || activityData?.meta?.per_page === undefined) return 1
        return Math.ceil(activityData?.meta?.total / activityData?.meta?.per_page)
    }, [activityData])

    async function handlePageChange(num: 1 | -1) {
        const newNum = activityPage + num
        if (newNum === 0 || newNum > totalPages) return

        setActivityPage(newNum)
    }

    return (
        <Flex flexDir="column" flex={1} maxWidth={{ md: "605px" }} w="100%" mx={{ md: "auto" }}>
            <TableContainer flex={1} minHeight="300px">
                <Table>
                    <Thead bg="grey.light.5">
                        <Tr px={2} py={"6px"} borderBottom="1px" borderColor="#EDF2F7">
                            <Th
                                width={{ base: "100%", md: "209px" }}
                                height={{ md: "32px" }}
                                px={0}
                                display="flex"
                                pl={2}
                            >
                                <Text textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("activity.dateAndTime")}
                                </Text>
                            </Th>

                            <Th w="100%" height={{ md: "32px" }} px={0} display={{ base: "none", md: "table-cell" }}>
                                <Text textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("activity.activityAction")}
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {activityData?.data.map((activity, index) => (
                            <AccountActivityRow key={index} activity={activity} />
                        ))}
                    </Tbody>
                </Table>
                {!isActivityLoading && (
                    <Flex h="full" justify="center" align="center">
                        <Spinner />
                    </Flex>
                )}
            </TableContainer>
            <TablePaginator
                name="account-activity"
                currentPage={activityPage}
                totalPages={totalPages}
                onNextPage={() => handlePageChange(1)}
                onPreviousPage={() => handlePageChange(-1)}
            />
        </Flex>
    )
}
