import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { useMemo } from "react"
import { BuySellDetails } from "../transactionDetails/buySellDetails"
import { DepositDetails } from "../transactionDetails/depositDetails"
import { WithdrawDetails } from "../transactionDetails/withdrawDetails"
import { StakingRewardsDetails } from "../transactionDetails/stakeRewardsDetails"
import { RewardDetails } from "../transactionDetails/rewardDetails"
import { StakeUnstakeCreatedDetails } from "../transactionDetails/stakeUnstakeCreated"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    transaction: Transaction | null
    openingTab?: "buy" | "sell"
}

export default function DrawerTransactionDetails({ isOpen, toggleSideDrawerOpen, transaction }: Props) {
    const { t } = useTranslation(["common"])

    const { action } = transaction || {}

    const DetailsComponent = useMemo(() => {
        if (!transaction) return null

        switch (action) {
            case TransactionAction.Buy:
            case TransactionAction.Sell:
                return <BuySellDetails transaction={transaction} />
            case TransactionAction.Deposit:
                return <DepositDetails transaction={transaction} />
            case TransactionAction.Withdraw:
                return <WithdrawDetails transaction={transaction} />
            case TransactionAction.StakeReward:
                return <StakingRewardsDetails transaction={transaction} />
            case TransactionAction.KohoReward:
                return <RewardDetails transaction={transaction} />
            case TransactionAction.Stake:
            case TransactionAction.Unstake:
                return <StakeUnstakeCreatedDetails transaction={transaction} />
            default:
                return <Text>Unkown Type</Text>
        }
    }, [action])

    return (
        <SideDrawer
            name="transactionDetails"
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            header={<StandardDrawerHeader title={"Order Details"} onClose={toggleSideDrawerOpen} />}
            footerButton={{
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_OUTLINE,
                type: "submit",
                onClick: toggleSideDrawerOpen,
                children: t("common:close"),
            }}
        >
            <Box display="flex" flex={1} flexDirection={"column"}>
                <Box py={14}>{DetailsComponent}</Box>
            </Box>
        </SideDrawer>
    )
}
