import { splitApi } from "@redux/splitApi"
import { API_PATH } from "./const"
import { IPricesResponse } from "./types"

export const pricesApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getPrices: builder.query<IPricesResponse, undefined>({
            query: (params) => ({
                url: API_PATH.PRICES,
                method: "GET",
                params,
            }),
        }),
    }),
})

export const { useGetPricesQuery } = pricesApi
export const { getPrices } = pricesApi.endpoints
