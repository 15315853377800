import { Box, Icon, Link, Text } from "@chakra-ui/react"
import { MdChevronRight } from "react-icons/md"

export type BreadCrumbProps = {
    text: string
    onClick?: () => void
    hasPrevious?: boolean
    hasAfter?: boolean
}

export default function BreadCrumb({ hasPrevious = true, hasAfter = true, text, onClick }: BreadCrumbProps) {
    const Component = onClick ? Link : Box
    return (
        <Component
            display="flex"
            alignItems="center"
            onClick={onClick}
            textDecoration="none !important"
            data-test-id={`breadcrumb-${text}`}
        >
            {hasPrevious && <Icon mr="0.5rem" as={MdChevronRight} size={16} color="grey.light.70" />}
            <Text
                color={hasAfter ? "grey.light.70" : "black"}
                textStyle={hasAfter ? "ManropeMediumBodySmall" : "ManropeSemiboldBodySmall"}
            >
                {text}
            </Text>
        </Component>
    )
}
