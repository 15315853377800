import React, { useState } from "react"
import { Box } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useForm } from "react-hook-form"
import NewPass from "./newPass"
import ErrorMessage from "@components/ui/errorMessage"
import SecurityCode from "@screens/twoFASetup/securityCode"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { useChangePasswordMutation, useVerifyPasswordMutation } from "@redux/account/apiSlice"
import { logout } from "@redux/auth/slice"
import { LOGOUT_STATE } from "@util/AuthProvider"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
}

export type ChangePassFormData = {
    currentPass: string
    newPass: string
    confirmNewPass: string
}

export default function ({ isOpen, toggleSideDrawerOpen }: Props) {
    const { t } = useTranslation(["settings", "common"])
    const { control, trigger, watch, getValues, reset } = useForm<ChangePassFormData>()
    const [changePass] = useChangePasswordMutation()
    const [verifyPassword] = useVerifyPasswordMutation()
    const dispatch = useAppDispatch()

    const [view, setView] = useState(1)
    const [err, setErr] = useState("")
    const [pin, setPin] = useState("")
    const [loading, toggleLoading] = useState(false)

    const previous = () => setView((prev) => Math.max(prev - 1, 1))

    function resetFields() {
        setView(1)
        setPin("")
        setErr("")
        reset({
            currentPass: "",
            newPass: "",
            confirmNewPass: "",
        })
    }

    async function verifyAndChangePass(code: string) {
        toggleLoading(true)
        await changePass({
            current_password: getValues("currentPass"),
            password: getValues("newPass"),
            one_time_password: code,
        })
            .unwrap()
            .then(() => dispatch(logout({ logoutState: LOGOUT_STATE.CHANGE_PASSWORD })))
            .catch((e) => {
                // The variety of error formats that can be returned here is atrocious
                // This endpoint really should be standardized
                try {
                    if (e.data?.message) {
                        setErr(e.data.message)
                        return
                    } else {
                        const err = Object.values(e.data?.errors)[0]
                        if (typeof err === "string") {
                            setErr(err)
                        } else if (e.data?.errors?.[0]) {
                            setErr(e.data.errors[0].exception ?? t("error.server", { ns: "common" }))
                        } else {
                            setErr(t("error.server", { ns: "common" }))
                        }
                    }
                } catch (e) {
                    setErr(t("error.server", { ns: "common" }))
                }
            })
            .finally(() => toggleLoading(false))
    }

    async function handleNext() {
        setErr("")
        if (view === 1) {
            const valid = await trigger()
            if (valid) {
                if (getValues("newPass") !== getValues("confirmNewPass")) {
                    setErr(t("settings.passMismatch"))
                    return
                } else {
                    toggleLoading(true)
                    await verifyPassword({ password: btoa(getValues("currentPass")) })
                        .unwrap()
                        .then(() => setView(2))
                        .catch((e) => setErr(e.message))
                        .finally(() => toggleLoading(false))
                }
            }
        } else {
            if (!pin || pin.length !== 6) {
                setErr(t("error.invalidCode", { ns: "common" }))
                return
            }
            await verifyAndChangePass(pin)
        }
    }

    function handleClose() {
        resetFields()
        toggleSideDrawerOpen()
    }

    return (
        <SideDrawer
            name="changePass"
            isOpen={isOpen}
            toggleSideDrawerOpen={handleClose}
            header={
                <StandardDrawerHeader
                    title={t("profile.changePass")}
                    onClose={handleClose}
                    {...(view > 1 && { onBack: previous })}
                />
            }
            extraFooter={
                err && (
                    <Box mb="10px">
                        <ErrorMessage>{err}</ErrorMessage>
                    </Box>
                )
            }
            footerButton={{
                children: view === 1 ? t("continue", { ns: "common" }) : t("profile.changePass"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                type: "submit",
                onClick: handleNext,
                isLoading: loading,
            }}
        >
            {view === 1 ? (
                <NewPass control={control} watch={watch} />
            ) : (
                <SecurityCode
                    onComplete={verifyAndChangePass}
                    pin={pin}
                    handleChange={(val) => setPin(val)}
                    descPrefix={t("profile.toComplete")}
                    err={err}
                />
            )}
        </SideDrawer>
    )
}
