import { useBreakpointValue } from "@chakra-ui/react"
import { DonutChart } from "@components/ui/charts/donutChart"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { useMemo } from "react"
const MAX_ASSETS = 11

export const DonutDashboardChart = () => {
    const { data: assetDetailsData, isLoading: isLoadingAssets } = useGetAssetsDetailsQuery({})
    const { allBalances, isLoadingBalances, restrictedAmountBalance, valueForAsset } = useCalculateBalances()
    const size = useBreakpointValue({ base: 150, xl: 200 })
    const isLoading = useMemo(() => isLoadingAssets || isLoadingBalances, [isLoadingAssets, isLoadingBalances])

    const data = useMemo(() => {
        if (!assetDetailsData || !allBalances) return []
        const assetDetails: Record<string, Pick<AssetDetailsFull, "name">> = { ...assetDetailsData }
        assetDetails["CAD"] = { name: "Canadian Dollar" }
        assetDetails["USD"] = { name: "US Dollar" }
        assetDetails["OTHERS"] = { name: "Others" }
        return restrictedAmountBalance(allBalances, MAX_ASSETS)
            .map((obj) => {
                return {
                    name: obj.symbol.toUpperCase() in assetDetails ? assetDetails[obj.symbol.toUpperCase()].name : "",
                    value: valueForAsset(obj.symbol, obj.balance),
                    symbol: obj.symbol === "Others" ? "" : obj.symbol,
                }
            })
            .sort((a, b) => b.value - a.value)
    }, [assetDetailsData, allBalances])

    return <DonutChart width={size} height={size} data={data} isLoading={isLoading} />
}
