import { Box, Link, Text } from "@chakra-ui/react"
import { SubScreenProps } from "../useOnboarding"
import { useTranslation } from "react-i18next"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { useAppDispatch } from "@/store/hooks"
import { logout } from "@redux/auth/slice"

export default function BeInTouch({ isLoading, userObj }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])
    const dispatch = useAppDispatch()

    const email = userObj?.country === "CA" ? "businessonboarding@netcoins.com" : "usa.onboarding@gonetcoins.com"

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("extra.thankYou")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                {t("extra.inTouch1")}
            </Text>
            <Text whiteSpace="no-wrap">
                <Text as="span" display="inline-block" textStyle="InterRegularBodySmall">
                    {t("extra.inTouch2")}&nbsp;
                </Text>
                <Link
                    as="span"
                    href={`mailto:${email}?subject=Pending Business Onboarding`}
                    textStyle="InterRegularBodySmall"
                    color="blue.100"
                >
                    {email}
                </Link>
            </Text>
            <StandardButton
                data-testid="continue-button"
                isLoading={isLoading}
                onClick={() => dispatch(logout())}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                w="full"
                mt="3rem"
            >
                {t("done", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
