import { Box, Button, Flex, Icon, Menu, MenuButton, MenuList, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import { useState, useEffect, useMemo, MouseEvent } from "react"
import { Option } from "../../types"
import { useTranslation } from "react-i18next"
import { MdChevronRight, MdClose } from "react-icons/md"
import Searchbar from "@components/ui/searchbar"
import OptionCheckBox from "@components/ui/filterOptions/checkBox"
import SideDrawer from "@components/sideDrawer"
import FilterDrawerHeader from "@components/sideDrawer/headers/filterDrawerHeader"
import MobileFilterButton from "../mobileFilterButton"

interface SingleSelectProps<T extends string | number> {
    options: Option<T>[]
    placeholder?: string
    onSelect: (selectedOption: Option<T> | null) => void
    preSelected?: Option<T>
    isLoading?: boolean
    isDisabled?: boolean
    textAlign?: "left" | "center" | "right"
    cancellable?: boolean
    search?: boolean
    title?: string
    categoryName?: string
    shouldIgnoreMobileView?: boolean
    [key: string]: any
}

const SingleSelect = <T extends string | number>({
    options,
    placeholder,
    categoryName,
    onSelect,
    preSelected,
    isLoading,
    isDisabled,
    textAlign = "left",
    cancellable = false,
    title,
    search,
    shouldIgnoreMobileView,
    ...rest
}: SingleSelectProps<T>) => {
    const { t } = useTranslation("common")
    const isMobile = useBreakpointValue({ base: true, sm: false })

    const [selectedOption, setSelectedOption] = useState<Option<T> | null>(preSelected || null)
    const [isOpen, setIsOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string>("")

    useEffect(() => {
        if (preSelected) {
            setSelectedOption(preSelected)
            onSelect(preSelected)
        }
    }, [preSelected, onSelect])

    const handleOptionSelect = (option: Option<T>) => {
        setSelectedOption(option)
        setIsOpen(false)
        onSelect(option)
    }

    const handleClear = () => {
        setSelectedOption(null)
        onSelect(null)
        setIsOpen(false)
    }

    const DisplayText = useMemo(() => {
        if (isLoading) {
            return `${t("loading")}...`
        }
        return selectedOption ? selectedOption.label : (placeholder ?? t("picker.selectPlaceholder"))
    }, [placeholder, selectedOption, isLoading, t])

    const filteredOptions = useMemo(
        () =>
            options.filter(
                (option) =>
                    option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    option.value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [options, searchTerm]
    )

    const doMobileView = useMemo(() => isMobile && !shouldIgnoreMobileView, [isMobile, shouldIgnoreMobileView])

    return (
        <Box {...rest}>
            {doMobileView ? (
                <MobileFilterButton setIsOpen={() => setIsOpen(true)} text={t("filter")} textAlign={textAlign} />
            ) : (
                <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <MenuButton
                        as={Button}
                        textStyle={"ManropeSemiboldBodySmall"}
                        rightIcon={
                            <Icon
                                color="black"
                                height={18}
                                width={18}
                                as={MdChevronRight}
                                style={{ transform: `rotate(${isOpen ? 270 : 90}deg)` }}
                            />
                        }
                        width={"100%"}
                        textAlign="left"
                        variant="outline"
                        borderColor="grey.light.20"
                        isDisabled={isDisabled}
                        onClick={() => setIsOpen(!isOpen)}
                        _active={{ bg: "grey.light.5 !important" }}
                        _hover={{ bg: "unset", bgColor: "unset" }}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                        }}
                    >
                        <Flex align="center" justifyContent={"space-between"} alignItems={"center"}>
                            <Text textStyle={"ManropeMediumBodySmall"} noOfLines={1} textAlign={textAlign}>
                                {doMobileView ? t("filter") : DisplayText}
                            </Text>
                            {cancellable && selectedOption && (
                                <Flex
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleClear()
                                    }}
                                    pointerEvents={"auto"}
                                    ml={2.5}
                                >
                                    <Icon size={16} as={MdClose} />
                                </Flex>
                            )}
                        </Flex>
                    </MenuButton>
                    <MenuList _expanded={{ display: doMobileView ? "none" : "initial" }} padding={0}>
                        <Box paddingBottom={3}>
                            {search && (
                                <Box py="0.75rem">
                                    <Searchbar
                                        size="md"
                                        removeBorder
                                        value={searchTerm}
                                        placeholder={t("search")}
                                        onChange={setSearchTerm}
                                    />
                                </Box>
                            )}
                            <VStack
                                width="300px"
                                align="stretch"
                                spacing={0}
                                maxHeight="200px"
                                overflowY="auto"
                                mt={search ? 0 : "0.75rem"}
                                pt={!search ? 0 : "0.75rem"}
                                borderTopWidth={search ? "1px" : 0}
                                borderTopColor="grey.light.10"
                            >
                                {filteredOptions.map((option) => (
                                    <OptionCheckBox
                                        key={option.value}
                                        option={option}
                                        isChecked={option.value === selectedOption?.value}
                                        onToggle={handleOptionSelect}
                                    />
                                ))}
                            </VStack>
                        </Box>
                    </MenuList>
                </Menu>
            )}
            <SideDrawer
                name="filter"
                isOpen={!!(doMobileView && isOpen)}
                toggleSideDrawerOpen={() => setIsOpen(false)}
                size="full"
                header={
                    <FilterDrawerHeader
                        title={title ?? t("filterBy")}
                        onClose={() => setIsOpen(false)}
                        onClear={cancellable ? handleClear : undefined}
                    />
                }
                contentPadding={4}
            >
                <Box paddingBottom={3}>
                    {categoryName && (
                        <Text textStyle="ManropeSemiboldBodySmall" my="0.75rem">
                            {t("filterByCategory", { category: categoryName })}
                        </Text>
                    )}
                    {search && (
                        <Searchbar size="md" value={searchTerm} placeholder={t("search")} onChange={setSearchTerm} />
                    )}
                    <VStack
                        width="full"
                        align="stretch"
                        spacing={0}
                        mt={search ? 0 : "0.75rem"}
                        pt={!search ? 0 : "0.75rem"}
                    >
                        {filteredOptions.map((option) => (
                            <OptionCheckBox
                                key={option.value}
                                option={option}
                                isChecked={option.value === selectedOption?.value}
                                onToggle={handleOptionSelect}
                            />
                        ))}
                    </VStack>
                </Box>
            </SideDrawer>
        </Box>
    )
}

export default SingleSelect
