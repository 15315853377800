export const TAB_SIZE = {
    md: 45,
    lg: 56,
} as const

export enum ENUM_SLIDING_TAB_VARIANT {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
    p: number
}

type STRUCTURE_TAB_VARIANT = {
    container: STATEFUL_PROPERTIES
    tab: {
        color: string
        activeTextColor: string
        inactiveTextColor: string
    }
}

const SLIDING_TAB_VARIANTS: Record<ENUM_SLIDING_TAB_VARIANT, STRUCTURE_TAB_VARIANT> = {
    PRIMARY: {
        container: {
            p: 0,
            bg: "grey.light.5",
            borderColor: "grey.light.10",
            color: "black",
        },
        tab: {
            color: "blue.100",
            activeTextColor: "white",
            inactiveTextColor: "black",
        },
    },
    SECONDARY: {
        container: {
            bg: "white",
            borderColor: "grey.light.20",
            color: "black",
            p: 6,
        },
        tab: {
            color: "grey.light.5",
            activeTextColor: "black",
            inactiveTextColor: "grey.light.50",
        },
    },
}

export default SLIDING_TAB_VARIANTS
