import { IAccountDetails, IdentityCitizenship } from "@redux/account/types"
import { REG_SCREENS } from "@screens/onboarding/types"

export const establishUpdatingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const needsSSN = data.country === "US" && (!data.identity.citizenship || !data.identity.id_number)
    const riskStatement = data.verification.disclaimers.risk_statement
    const tosStatement = data.verification.disclaimers.terms_of_service
    const overageThirdParty = data.verification.disclaimers?.overage_third_party
    const privacyStatement = data.verification.disclaimers.privacy_policy
    const anyZeroHashDisclaimerNotAccepted =
        !data.verification.disclaimers.zero_hash_service?.accepted_at ||
        !data.verification.disclaimers.zero_hash_privacy_and_regulatory?.accepted_at ||
        !data.verification.disclaimers.zero_hash_risk?.accepted_at

    const screens: (REG_SCREENS | null)[] = [
        tosStatement ? null : REG_SCREENS.UpdateToS,
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        data.identity.first_name && data.identity.last_name && privacyStatement?.accepted_at
            ? null
            : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.citizenship !== IdentityCitizenship.PENDING || data.country !== "CA"
            ? null
            : REG_SCREENS.IsCanadian,
        data.verification.phone.is_verified ? null : REG_SCREENS.WhatPhone,
        data.verification.phone.is_verified ? null : REG_SCREENS.ConfirmPin,
        needsAddress ? REG_SCREENS.WhereLive : null,
        needsSSN ? REG_SCREENS.SSN : null,
        data.identity.occupation ? null : REG_SCREENS.Occupation,
        anyZeroHashDisclaimerNotAccepted && data.country === "US" ? REG_SCREENS.ZeroHashDisclaimer : null,
        surveyScreens > 0 ? REG_SCREENS.SurveyIntro : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        overageThirdParty && overageThirdParty.accepted_at === null && data.country === "CA"
            ? REG_SCREENS.OverageThirdPartyDisclaimer
            : null,
        !!riskStatement?.accepted_at || data.country === "US" ? null : REG_SCREENS.RiskStatement,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}

export const establishBusinessUpdatingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const needsBusinessAddress =
        !data.business?.address?.city || !data.business?.address?.postal_code || !data.business?.address?.street_address
    const needsBusinessAbout =
        !data.business?.government_id ||
        !data.business?.industry ||
        !data.business?.reg_type ||
        !data.business?.projected_quarter?.amount ||
        !data.business?.projected_quarter.currency
    const riskStatement = data.verification.disclaimers.risk_statement
    const tosStatement = data.verification.disclaimers.terms_of_service
    const privacyStatement = data.verification.disclaimers.privacy_policy
    const anyZeroHashDisclaimerNotAccepted =
        !data.verification.disclaimers.zero_hash_service?.accepted_at ||
        !data.verification.disclaimers.zero_hash_privacy_and_regulatory?.accepted_at ||
        !data.verification.disclaimers.zero_hash_risk?.accepted_at

    const screens: (REG_SCREENS | null)[] = [
        tosStatement ? null : REG_SCREENS.UpdateToS,
        data.region ? null : REG_SCREENS.WhereIncorporated,
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        data.identity.first_name && data.identity.last_name && privacyStatement?.accepted_at
            ? null
            : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.phone ? null : REG_SCREENS.WhatPhone,
        needsAddress ? REG_SCREENS.WhereLive : null,
        anyZeroHashDisclaimerNotAccepted && data.country === "US" ? REG_SCREENS.ZeroHashDisclaimer : null,
        data.business?.legal_name ? null : REG_SCREENS.BusinessName,
        data.business?.corporate_role ? null : REG_SCREENS.BusinessRole,
        data.business?.date_incorporated ? null : REG_SCREENS.WhenIncorporated,
        typeof data.business?.is_money_service !== "number" ? REG_SCREENS.IsMoneyService : null,
        needsBusinessAbout ? REG_SCREENS.AboutBusiness : null,
        needsBusinessAddress ? REG_SCREENS.WhereBusinessOffice : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        data.country === "CA" && !riskStatement?.accepted_at ? REG_SCREENS.RiskStatement : null,
        REG_SCREENS.BeInTouch,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}

export const establishOnboardingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const needsSSN = data.country === "US" && (!data.identity.citizenship || !data.identity.id_number)
    const riskStatement = data.verification.disclaimers.risk_statement
    const tosStatement = data.verification.disclaimers.terms_of_service
    const overageThirdParty = data.verification.disclaimers?.overage_third_party
    const privacyStatement = data.verification.disclaimers.privacy_policy
    const anyZeroHashDisclaimerNotAccepted =
        !data.verification.disclaimers.zero_hash_service?.accepted_at ||
        !data.verification.disclaimers.zero_hash_privacy_and_regulatory?.accepted_at ||
        !data.verification.disclaimers.zero_hash_risk?.accepted_at

    const screens: (REG_SCREENS | null)[] = [
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        // no real disclaimer for this one, so this is what we're using as a conditional
        (!data.identity.first_name || !data.identity.last_name) && data.country === "US"
            ? REG_SCREENS.TerrorismDisclaimer
            : null,
        data.identity.first_name &&
        data.identity.last_name &&
        tosStatement?.accepted_at &&
        privacyStatement?.accepted_at
            ? null
            : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.citizenship !== IdentityCitizenship.PENDING || data.country !== "CA"
            ? null
            : REG_SCREENS.IsCanadian,
        data.verification.phone.is_verified ? null : REG_SCREENS.WhatPhone,
        data.verification.phone.is_verified ? null : REG_SCREENS.ConfirmPin,
        needsAddress ? REG_SCREENS.WhereLive : null,
        needsSSN ? REG_SCREENS.SSN : null,
        data.identity.occupation ? null : REG_SCREENS.Occupation,
        anyZeroHashDisclaimerNotAccepted && data.country === "US" ? REG_SCREENS.ZeroHashDisclaimer : null,
        surveyScreens > 0 ? REG_SCREENS.SurveyIntro : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        overageThirdParty && overageThirdParty.accepted_at === null && data.country === "CA"
            ? REG_SCREENS.OverageThirdPartyDisclaimer
            : null,
        !!riskStatement?.accepted_at || data.country === "US" ? null : REG_SCREENS.RiskStatement,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}

export const establishBusinessOnboardingSteps = (data: IAccountDetails, surveyScreens: number): REG_SCREENS[] => {
    const needsAddress =
        !data.identity.address.city || !data.identity.address.postal_code || !data.identity.address.street_address
    const needsBusinessAddress =
        !data.business?.address?.city || !data.business?.address?.postal_code || !data.business?.address?.street_address
    const needsBusinessAbout =
        !data.business?.government_id ||
        !data.business?.industry ||
        !data.business?.reg_type ||
        !data.business?.projected_quarter?.amount ||
        !data.business?.projected_quarter.currency
    const riskStatement = data.verification.disclaimers.risk_statement
    const tosStatement = data.verification.disclaimers.terms_of_service
    const privacyStatement = data.verification.disclaimers.privacy_policy
    const anyZeroHashDisclaimerNotAccepted =
        !data.verification.disclaimers.zero_hash_service?.accepted_at ||
        !data.verification.disclaimers.zero_hash_privacy_and_regulatory?.accepted_at ||
        !data.verification.disclaimers.zero_hash_risk?.accepted_at

    const screens: (REG_SCREENS | null)[] = [
        data.region ? null : REG_SCREENS.WhereIncorporated,
        data.onboarding_survey ? null : REG_SCREENS.HowDidYouHear,
        // no real disclaimer for this one, so this is what we're using as a conditional
        (!data.identity.first_name || !data.identity.last_name) && data.country === "US"
            ? REG_SCREENS.TerrorismDisclaimer
            : null,
        data.identity.first_name &&
        data.identity.last_name &&
        tosStatement?.accepted_at &&
        privacyStatement?.accepted_at
            ? null
            : REG_SCREENS.YourName,
        data.identity.dob ? null : REG_SCREENS.WhenBorn,
        data.identity.phone ? null : REG_SCREENS.WhatPhone,
        needsAddress ? REG_SCREENS.WhereLive : null,
        anyZeroHashDisclaimerNotAccepted && data.country === "US" ? REG_SCREENS.ZeroHashDisclaimer : null,
        data.business?.legal_name ? null : REG_SCREENS.BusinessName,
        data.business?.corporate_role ? null : REG_SCREENS.BusinessRole,
        data.business?.date_incorporated ? null : REG_SCREENS.WhenIncorporated,
        typeof data.business?.is_money_service !== "number" ? REG_SCREENS.IsMoneyService : null,
        needsBusinessAbout ? REG_SCREENS.AboutBusiness : null,
        needsBusinessAddress ? REG_SCREENS.WhereBusinessOffice : null,
        ...Array(surveyScreens).fill(REG_SCREENS.Surveys),
        data.country === "CA" && !riskStatement?.accepted_at ? REG_SCREENS.RiskStatement : null,
        REG_SCREENS.BeInTouch,
    ]
    const regScreens: REG_SCREENS[] = screens.filter((s) => s !== null) as REG_SCREENS[]
    return regScreens
}
