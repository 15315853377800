import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import AmplitudeClient from "@/sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { SubScreenProps } from "../useOnboarding"
import { useUpdateAccountBusinessMutation } from "@redux/account/apiSlice"

type Props = {} & SubScreenProps

type FormData = {
    corporate_role: string
}

export default function BusinessRole({ submitSubForm, isLoading, userObj }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            corporate_role: userObj?.business?.corporate_role || undefined,
        },
    })
    const [updateBusiness] = useUpdateAccountBusinessMutation()

    async function onSubmitForm(values: FormData) {
        await submitSubForm(updateBusiness, values, () => {
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.BusinessRole, REGISTRATION_STEP.BUSINESS_ROLE)
        })
    }

    return (
        <Box w="full">
            <DetailsTypeCard isPersonal={false} />
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("whatRole.title")}
            </Text>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <TextInput
                    name="corporate_role"
                    control={control}
                    label={t("whatRole.role")}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                />
                <StandardButton
                    data-testid="continue-button"
                    isLoading={isLoading}
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    w="full"
                    mt="3rem"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
