import React, { useMemo } from "react"
import { Box, Flex, Icon, Text, useBreakpointValue } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { addCommasToNumber } from "@util/stringFormatting"
import MarketChange from "@components/ui/marketChange"
import { MdInfoOutline, MdSwapVert } from "react-icons/md"
import IconWrapper from "@assets/svgs/IconWrapper"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { formatPrecision } from "@util/numericalFormatting"
import useRegion from "@hooks/useRegion"
import TooltipComp from "@components/tooltip"

type Props = {
    asset: AssetDetailsFull
    canStake: boolean
    balanceAmount: number
    balanceQuantity: number
    availableAmount: number
    availableQuantity: number
    frozenQuantity: number
    frozenAmount: number
    openOrdersTotalAmountSells?: number
    openOrdersTotalQuantitySells?: number
    dayPerf: { amount: number; percentage: number }
    stakedAmount?: number
    stakedQuantity?: number
    isCurrencyOrderSwapped: boolean
    toggleCurrencyOrderSwapped: React.Dispatch<React.SetStateAction<boolean>>
}

const SPACE_BETWEEN_BOXES = 4

export default function HoldingsTab({
    asset,
    canStake,
    balanceAmount,
    balanceQuantity,
    availableAmount,
    availableQuantity,
    frozenAmount,
    frozenQuantity,
    stakedAmount,
    stakedQuantity,
    openOrdersTotalAmountSells,
    openOrdersTotalQuantitySells,
    dayPerf,
    isCurrencyOrderSwapped,
    toggleCurrencyOrderSwapped,
}: Props) {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })
    const { isCAUser } = useRegion()

    const OpenOrdersTotalQuantityFormatted = useMemo(() => {
        if (openOrdersTotalQuantitySells) {
            return `${formatPrecision(openOrdersTotalQuantitySells, asset.precision)} ${asset.symbol}`
        }
        return 0
    }, [openOrdersTotalAmountSells, asset])

    const OpenOrderTotalAmountFormatted = useMemo(() => {
        return openOrdersTotalAmountSells ? `$${addCommasToNumber(openOrdersTotalAmountSells.toFixed(2))}` : 0
    }, [openOrdersTotalAmountSells])

    const NotBalancePercentage = useMemo(() => {
        const notBalanceAmount = (stakedAmount ?? 0) + (openOrdersTotalAmountSells ?? 0) + frozenAmount
        return notBalanceAmount ? (notBalanceAmount / balanceAmount) * 100 : 0
    }, [stakedAmount, balanceAmount, openOrdersTotalAmountSells, frozenAmount])

    const NotBalancePercetangeChippedOff = useMemo(
        () => (SPACE_BETWEEN_BOXES * NotBalancePercentage) / 100,
        [NotBalancePercentage]
    )

    const AvailableAmountFormatted = useMemo(() => {
        return availableAmount.toFixed(2)
    }, [availableAmount, asset])

    const AvailableQuantityFormatted = useMemo(() => {
        return `${formatPrecision(availableQuantity, asset.precision)} ${asset.symbol}`
    }, [availableQuantity, asset])

    return (
        <Box mt={7} w="full">
            <Flex height={"fit-content"} flexDirection={{ base: "column", md: "row" }} w={"100%"} pb={3}>
                <Flex flexDir={"column"} w={{ base: "100%", md: "40%" }} mr={6}>
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                        {t("myBalance")}
                    </Text>
                    <Flex alignItems="center" columnGap="0.5rem" py="0.25rem">
                        <Text textStyle="ManropeSemibold2xLarge">
                            {isCurrencyOrderSwapped
                                ? `${formatPrecision(balanceQuantity, asset.precision)} ${asset.symbol}`
                                : `$${addCommasToNumber(balanceAmount.toFixed(2))}`}
                        </Text>
                        <Box cursor="pointer" onClick={() => toggleCurrencyOrderSwapped(!isCurrencyOrderSwapped)}>
                            <IconWrapper icon={MdSwapVert} color={"blue.100"} size={18} />
                        </Box>
                    </Flex>
                    <Text textStyle="ManropeMediumBodySmall">
                        {!isCurrencyOrderSwapped
                            ? `${formatPrecision(balanceQuantity, asset.precision)} ${asset.symbol}`
                            : `$${addCommasToNumber(balanceAmount.toFixed(2))}`}
                    </Text>
                </Flex>
                <SectionAvailableBalance
                    {...{
                        AvailableAmountFormatted,
                        AvailableQuantityFormatted,
                        NotBalancePercentage,
                        isCurrencyOrderSwapped,
                        NotBalancePercetangeChippedOff,
                    }}
                />
            </Flex>
            <Flex height={"fit-content"} flexDirection={{ base: "column", md: "row" }} w={"100%"}>
                <SectionMarketChange dayPerf={dayPerf} />
                <Box
                    w={{ base: "100%", md: "60%" }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}
                >
                    {isCAUser && (
                        <Box
                            w="full"
                            display="flex"
                            flexDir="column"
                            borderBottomColor="grey.light.10"
                            borderBottomWidth={"1px"}
                            py={3}
                        >
                            <Flex>
                                <Flex flex={1} alignItems={"flex-start"}>
                                    <Flex alignItems={"center"}>
                                        <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50" mr={2}>
                                            {t("amountOpen")}
                                        </Text>
                                        <TooltipComp
                                            triggerComponent={<Icon as={MdInfoOutline} color="blue.100" />}
                                            bodyText={t("tooltipAmountOpen", { symbol: asset.symbol })}
                                        />
                                    </Flex>
                                </Flex>
                                <Box w={"50%"} alignItems={"flex-end"} display={"flex"} flexDirection={"column"}>
                                    <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                                        {isCurrencyOrderSwapped
                                            ? OpenOrdersTotalQuantityFormatted
                                            : OpenOrderTotalAmountFormatted}
                                    </Text>
                                    <Text textAlign="end" textStyle="ManropeMediumXSmall" color="grey.light.50">
                                        {!isCurrencyOrderSwapped
                                            ? OpenOrdersTotalQuantityFormatted
                                            : OpenOrderTotalAmountFormatted}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    )}
                    {isCAUser && canStake ? (
                        <Box
                            w="full"
                            display="flex"
                            flexDir="column"
                            borderBottomColor="grey.light.10"
                            borderBottomWidth={{ base: 0, lg: "1px" }}
                            py={3}
                        >
                            <Flex>
                                <Flex flex={1} alignItems={"flex-start"}>
                                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                                        {t("amountStaked")}
                                    </Text>
                                </Flex>
                                <Box w={"50%"} alignItems={"flex-end"} display={"flex"} flexDirection={"column"}>
                                    <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                                        {isCurrencyOrderSwapped
                                            ? `${formatPrecision(stakedQuantity || 0, stakedQuantity ? asset.precision : 0)} ${asset.symbol}`
                                            : `$${stakedAmount}`}
                                    </Text>
                                    <Text textAlign="end" textStyle="ManropeMediumXSmall" color="grey.light.50">
                                        {!isCurrencyOrderSwapped
                                            ? `${formatPrecision(stakedQuantity || 0, stakedQuantity ? asset.precision : 0)} ${asset.symbol}`
                                            : `$${stakedAmount}`}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    ) : !isCAUser ? (
                        <Box
                            w="full"
                            display="flex"
                            flexDir="column"
                            py={3}
                            borderBottomColor="grey.light.10"
                            borderBottomWidth={{ base: 0, lg: "1px" }}
                        >
                            <Flex w="full" justifyContent={"space-between"}>
                                <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                                    {t("amountProcessing")}
                                </Text>
                                <Text textStyle="ManropeSemiboldBodySmall" fontSize="14px">
                                    {isCurrencyOrderSwapped
                                        ? `${formatPrecision(frozenQuantity || 0, frozenQuantity ? asset.precision : 0)} ${asset.symbol}`
                                        : `$${frozenAmount}`}
                                </Text>
                            </Flex>
                            <Text textAlign="end" textStyle="ManropeMediumBodySmall" color="grey.light.50">
                                {!isCurrencyOrderSwapped
                                    ? `${formatPrecision(frozenQuantity || 0, frozenQuantity ? asset.precision : 0)} ${asset.symbol}`
                                    : `$${frozenAmount}`}
                            </Text>
                        </Box>
                    ) : null}
                </Box>
            </Flex>
        </Box>
    )
}

const SectionMarketChange = ({ dayPerf }: { dayPerf: { amount: number; percentage: number } }) => {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })

    return (
        <Box
            borderColor="grey.light.10"
            borderWidth={"1px"}
            p="1rem"
            borderRadius={"4px"}
            justifyContent={"center"}
            rowGap="0.25rem"
            display="flex"
            flexDir="column"
            mr={{ base: 0, md: 6 }}
            w={{ base: "100%", md: "40%" }}
        >
            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                {t("dayPerf")}
            </Text>
            <Text textStyle="ManropeSemiboldBody">${dayPerf.amount}</Text>
            <MarketChange percent={dayPerf.percentage} />
        </Box>
    )
}

const SectionAvailableBalance = ({
    AvailableAmountFormatted,
    AvailableQuantityFormatted,
    NotBalancePercentage,
    isCurrencyOrderSwapped,
    NotBalancePercetangeChippedOff,
}: any) => {
    const { t } = useTranslation("app", { keyPrefix: "marketDetails" })

    return (
        <Flex
            flexDir={"column"}
            alignItems={{ base: "flex-start", md: "flex-end" }}
            w={{ base: "100%", md: "60%" }}
            mt={{ base: 5, md: 0 }}
        >
            <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50" mb={1}>
                {t("available")}
            </Text>
            <Text textStyle="ManropeSemiboldBody">
                {isCurrencyOrderSwapped
                    ? AvailableQuantityFormatted
                    : `$${addCommasToNumber(AvailableAmountFormatted)}`}
            </Text>
            <Text textStyle="ManropeMediumXSmall" mb="0.25rem">
                {!isCurrencyOrderSwapped
                    ? AvailableQuantityFormatted
                    : `$${addCommasToNumber(AvailableAmountFormatted)}`}
            </Text>
            <Flex alignItems="center" w="full" h="21px">
                <Flex h="6px" w="full">
                    <Box
                        bgColor="grey.light.10"
                        h="full"
                        borderRadius="3px"
                        w={`calc(${NotBalancePercentage}% - ${NotBalancePercetangeChippedOff}px)`}
                    />
                    <Box h="full" w={`${SPACE_BETWEEN_BOXES}px`} />
                    <Box
                        h="full"
                        bgColor="blue.100"
                        borderRadius="3px"
                        w={`calc(${100 - NotBalancePercentage}% - ${SPACE_BETWEEN_BOXES - NotBalancePercetangeChippedOff}px)}`}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
