import { Flex, Box, Text, UnorderedList, ListItem, Image, Grid } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import IconBubble from "@components/ui/iconBubble"
import { useTranslation } from "react-i18next"
import GroceriesImage from "@assets/svgs/misc/groceries.svg"
import OnlineShoppingImage from "@assets/svgs/misc/online-shopping.svg"
import CoffeeImage from "@assets/svgs/misc/coffee.svg"
import NoAnnualAccountFeeImage from "@assets/svgs/misc/no-annual-account-fees.svg"
import CardsImage from "@assets/images/netcoins/npay-banner-card.png"
import nPayHorizontalBg from "@assets/images/background/nPayHorizontalBg.png"
import nPayVerticalBg from "@assets/images/background/nPayVerticalBg.png"
import BitcoinImage from "@assets/svgs/coins/btc.svg"
import { useMemo, useState } from "react"
import KohoLogo from "@assets/svgs/misc/koho-logo"
import { useStableBreakpoint } from "@hooks/useStableBreakpoint"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import NPayRegHOC from "./RegisterForNetcoinsPay"
import TwoFASetupDrawerHOC from "@screens/twoFASetup/drawerTwoFASetup"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"

export default function NetcoinsUnregisteredUserPage({
    toggleNPayDrawerOpen,
    toggleSetupTwoFADrawerOpen,
}: {
    toggleNPayDrawerOpen: (params: any) => void
    toggleSetupTwoFADrawerOpen: (params: any) => void
}) {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })
    const { data: userData } = useGetAccountDetailsQuery()

    const items = useMemo(() => {
        return [t("noAnnualFees"), t("easyFunding"), t("monthlyRewards"), t("physicalOrVirtualCard")]
    }, [])

    const { evaluateRestriction } = useRestrictedNavigation()

    const variant = useStableBreakpoint()
    const extraSmall = useMemo(() => variant === "extra-small", [variant])

    const isTFASetup = useMemo(() => userData?.tfa, [userData])

    const registerNPay = () => {
        if (!isTFASetup) {
            toggleSetupTwoFADrawerOpen(true)
            return
        }
        evaluateRestriction(RESTRICTED_FLOWS.ADD_NC_PAY_CARD, () => toggleNPayDrawerOpen(true), {
            blockWithModal: true,
        })
    }

    return (
        <>
            <Box alignItems={"center"} w="full" display={"flex"} flexDirection={"column"}>
                <Flex flexDir="column" gap={8} p={{ base: 4, sm: 6 }} w="full" maxW={"815px"} alignItems={"center"}>
                    {extraSmall ? (
                        <Flex
                            w="full"
                            height={"376px"}
                            flexDirection={"column"}
                            borderRadius="6px"
                            backgroundImage={nPayVerticalBg}
                            backgroundSize="cover"
                        >
                            <Flex height={"50%"} position={"relative"}>
                                <Flex
                                    position="relative"
                                    width="70%"
                                    bgImage={CardsImage}
                                    backgroundSize={"cover"}
                                    backgroundRepeat={"no-repeat"}
                                    backgroundPosition={"bottom center"}
                                >
                                    <Image
                                        src={BitcoinImage}
                                        alt="bitcoin"
                                        height={"80px"}
                                        width={"80px"}
                                        position="absolute"
                                        top={"calc(40% - 40px)"}
                                        left={"calc(105% - 40px)"}
                                    />
                                </Flex>
                            </Flex>

                            <Flex alignItems="center" p={4}>
                                <Flex gap={{ base: 2, md: 4 }} flexDir="column" justifyContent="start" textAlign="left">
                                    <Text
                                        color="white"
                                        textStyle={{ base: "ManropeSemiboldBody", md: "ManropeMedium4xLarge" }}
                                    >
                                        {t("earnBitcoin")}
                                    </Text>

                                    <Text color="white" textStyle="InterRegularBody">
                                        {t("earnBitcoinText")}
                                    </Text>

                                    <UnorderedList>
                                        {items.map((text, index) => (
                                            <ListItem color="white" key={index}>
                                                <Text color="white" textStyle="InterRegularBodySmall">
                                                    {text}
                                                </Text>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Flex>
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex
                            width="full"
                            height={{ base: "auto", sm: "230px", md: "350px" }}
                            borderRadius="6px"
                            backgroundImage={nPayHorizontalBg}
                            backgroundSize="cover"
                            flexDir={{ base: "column", sm: "row" }}
                        >
                            <Flex w={"50%"} gap={4} justifyContent="start" textAlign="left" position={"relative"}>
                                <Flex
                                    position="relative"
                                    width="80%"
                                    top={0}
                                    bgImage={CardsImage}
                                    backgroundSize={"cover"}
                                    height={{ base: "85%", md: "95%" }}
                                    backgroundRepeat={"no-repeat"}
                                    backgroundPosition={"right bottom"}
                                >
                                    <Image
                                        src={BitcoinImage}
                                        alt="bitcoin"
                                        height={{ base: "54px", md: "80px", lg: "108px" }}
                                        width={{ base: "54px", md: "80px", lg: "108px" }}
                                        position="absolute"
                                        top={{
                                            base: "calc(50% - 28px)",
                                            md: "calc(50% - 40px)",
                                            lg: "calc(50% - 54px)",
                                        }}
                                        left={{
                                            base: "calc(100% - 27px)",
                                            md: "calc(100% - 40px)",
                                            lg: "calc(100% - 54px)",
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                p={4}
                                pl={0}
                                justifyContent={{ base: "center" }}
                                w={{ base: "65%", md: "50%" }}
                            >
                                <Flex gap={{ base: 2, md: 4 }} flexDir="column" justifyContent="start" textAlign="left">
                                    <Text
                                        color="white"
                                        textStyle={{ base: "ManropeSemiboldBody", md: "ManropeMedium4xLarge" }}
                                    >
                                        {t("earnBitcoin")}
                                    </Text>

                                    <Text color="white" textStyle="InterRegularBody">
                                        {t("earnBitcoinText")}
                                    </Text>

                                    <UnorderedList>
                                        {items.map((text, index) => (
                                            <ListItem color="white" key={index}>
                                                <Text color="white" textStyle="InterRegularBodySmall">
                                                    {text}
                                                </Text>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}

                    <StandardButton
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_SOLID}
                        children={t("registerForNetcoinsPay")}
                        width={{ base: "full", sm: "auto" }}
                        mx={{ sm: "auto" }}
                        onClick={registerNPay}
                    />

                    <Flex flexDir="column" gap={6} justifyContent={"center"}>
                        <Text
                            textStyle={{ base: "ManropeMedium2xLarge", sm: "ManropeMedium2xLarge" }}
                            textAlign={"center"}
                        >
                            {t("easyToEarnBitcoin")}
                        </Text>
                        <Grid
                            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                            gap="45px"
                            justifyContent="center"
                            alignItems="start"
                        >
                            <IconBubble image={GroceriesImage} title={t("groceries")} />
                            <IconBubble image={OnlineShoppingImage} title={t("onlineShopping")} />
                            <IconBubble image={CoffeeImage} title={t("coffee")} />
                            <IconBubble image={NoAnnualAccountFeeImage} title={t("noAnnualAccountFees")} />
                        </Grid>
                    </Flex>

                    <Flex
                        flexDir="column"
                        gap={2}
                        width={{ md: "601px" }}
                        mx={{ md: "auto" }}
                        textAlign={{ base: "left", md: "center" }}
                    >
                        <Flex gap={1} textAlign="center" justifyContent="center" alignItems="center">
                            <Text color="grey.light.80" textStyle="InterRegularXSmall">
                                {t("poweredByKOHO")}
                            </Text>
                            <KohoLogo />
                        </Flex>
                        <Text color="grey.light.80" textStyle="InterRegularXSmall">
                            {t("cardIssuedByKOHO")}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
            <DashboardFooter />
        </>
    )
}
