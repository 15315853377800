import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { useMemo, useState } from "react"
import DisableDislcaimer from "./DisableDislcaimer"
import Disable2FA from "./Disable2FA"
import DisableCompleted from "./DisableCompleted"
import StandardButton from "@components/ui/buttons/standard"
import DisableLanding from "./DisableLanding"
import { useDisableTFAMutation } from "@redux/account/apiSlice"
import { useForm } from "react-hook-form"
import { PasswordFormData } from "@screens/dashboard/settings/profile/CloseAccount"
import { Box, Text } from "@chakra-ui/layout"

type Props = {
    isOpen: boolean
    toggleOpen: () => void
    toggleOpenEnable2FA: () => void
}

export default function TwoFADisable({ isOpen, toggleOpen, toggleOpenEnable2FA }: Props) {
    const { t } = useTranslation(["twoFA", "common"])
    const [disableTFA, { isLoading }] = useDisableTFAMutation()
    const { control, getValues, reset, trigger } = useForm<PasswordFormData>()

    const [view, setView] = useState(1)
    const [pin, setPin] = useState("")
    const [isNoPinErr, toggleNoPinErr] = useState(false)
    const [err, setErr] = useState("")

    const previous = () => setView((prev) => Math.max(prev - 1, 1))

    async function handleNext() {
        toggleNoPinErr(false)
        setErr("")
        switch (view) {
            case 3: {
                const valid = await trigger()
                if (pin.length !== 6) toggleNoPinErr(true)

                if (valid && !isNoPinErr) {
                    const res = await disableTFA({ code: pin, password: getValues("password") })
                        .unwrap()
                        .then(() => true)
                        .catch((e) => {
                            if (e.data.errors?.code?.length) {
                                setErr(e.data.errors.code[0])
                            } else if (e.data.errors?.password?.length) {
                                setErr(e.data.errors.password[0])
                            } else {
                                setErr(t("error.server", { ns: "common" }))
                            }
                            return false
                        })
                    if (!res) return
                } else {
                    return
                }
                break
            }
            case 4: {
                toggleOpen()
                toggleOpenEnable2FA()
                break
            }
        }
        setView((prev) => Math.min(prev + 1, 4))
    }

    const renderView = useMemo(() => {
        switch (view) {
            case 1:
                return <DisableLanding />
            case 2:
                return <DisableDislcaimer />
            case 3:
                return <Disable2FA pin={pin} setPin={setPin} control={control} noPinErr={isNoPinErr} />
            case 4:
                return <DisableCompleted />
        }
    }, [view, pin, control, isNoPinErr])

    const buttonText = useMemo(() => {
        switch (view) {
            case 1:
                return t("continue", { ns: "common" })
            case 2:
                return t("yesSure", { ns: "common" })
            case 3:
                return t("twoFASetup.disableTfa")
            case 4:
                return t("twoFASetup.enableTfa")
            default:
                return ""
        }
    }, [view])

    function handleClose() {
        toggleOpen()
        setPin("")
        toggleNoPinErr(false)
        reset({ password: "" })
        setView(1)
    }

    return (
        <SideDrawer
            name="twoFADisable"
            isOpen={isOpen}
            toggleSideDrawerOpen={handleClose}
            header={
                <StandardDrawerHeader
                    title={view === 4 ? t("twoFASetup.disabledTitleCompleted") : t("twoFASetup.disabledTitle")}
                    onClose={handleClose}
                    onBack={view > 1 ? previous : undefined}
                />
            }
            extraFooter={
                <Box>
                    {err && (
                        <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3}>
                            {err}
                        </Text>
                    )}
                    <StandardButton
                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_SOLID}
                        children={view === 4 ? t("done", { ns: "common" }) : t("cancel", { ns: "common" })}
                        onClick={handleClose}
                        w="100%"
                        mb="10px"
                    />
                </Box>
            }
            footerButton={{
                children: buttonText,
                variant: view === 3 ? ENUM_BUTTON_VARIANTS.RED_SOLID : ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                type: "button",
                onClick: handleNext,
                isLoading,
            }}
        >
            {renderView}
        </SideDrawer>
    )
}
