import { RequestCookies, ResponseCookies } from "@edge-runtime/cookies"
import { REFRESH_TOKEN_LIFETIME_MS } from "./const"
import { tokenResponse } from "./oauth"

const ACCESS_TOKEN_COOKIE_NAME = "access_token"
const REFRESH_TOKEN_COOKIE_NAME = "refresh_token"
export const ACCESS_TOKEN_TTL_COOKIE_NAME = "valid_until"
export const REFRESH_TOKEN_TTL_COOKIE_NAME = "refresh_valid_until"
export const DEVICE_ID_COOKIE_NAME = "device_id"

export const getRefreshToken = (headers: Headers): string | null => {
    const cookies = new RequestCookies(headers)
    return cookies.get(REFRESH_TOKEN_COOKIE_NAME)?.value ?? null
}

export const clearRefreshToken = (headers: Headers) => {
    const cookies = new ResponseCookies(headers)
    cookies.delete(REFRESH_TOKEN_COOKIE_NAME)
    cookies.delete(REFRESH_TOKEN_TTL_COOKIE_NAME)
}

export const clearAccessToken = (headers: Headers) => {
    const cookies = new ResponseCookies(headers)
    cookies.delete(ACCESS_TOKEN_COOKIE_NAME)
    cookies.delete(ACCESS_TOKEN_TTL_COOKIE_NAME)
}

export const clearCookies = (headers: Headers, ...cookies: string[]) => {
    const responseCookies = new ResponseCookies(headers)
    cookies.forEach((cookie) => responseCookies.delete(cookie))
}

export const clearTokenCookies = (headers: Headers) => {
    clearRefreshToken(headers)
    clearAccessToken(headers)
}

export const setDeviceIdCookie = (headers: Headers, deviceID: string) => {
    const responseCookies = new ResponseCookies(headers)
    responseCookies.set(DEVICE_ID_COOKIE_NAME, deviceID, {
        secure: true,
        sameSite: "strict",
        maxAge: 60 * 60 * 24 * 365 * 20, // 20yrs aka "forever"
    })
}

export const setTokenCookies = (headers: Headers, data: tokenResponse) => {
    const accessToken = data.access_token
    const refreshToken = data.refresh_token
    const ttlMs = data.expires_in * 1000

    const responseCookies = new ResponseCookies(headers)

    responseCookies.set("access_token", accessToken, {
        httpOnly: true,
        secure: true,
        sameSite: "strict",
        maxAge: ttlMs,
    })
    responseCookies.set("valid_until", new Date(Date.now() + ttlMs).toISOString(), {
        secure: true,
        sameSite: "strict",
        maxAge: ttlMs,
    })

    responseCookies.set("refresh_token", refreshToken, {
        httpOnly: true,
        secure: true,
        sameSite: "strict",
        maxAge: REFRESH_TOKEN_LIFETIME_MS,
    })
    responseCookies.set("refresh_valid_until", new Date(Date.now() + REFRESH_TOKEN_LIFETIME_MS).toISOString(), {
        secure: true,
        sameSite: "strict",
        maxAge: REFRESH_TOKEN_LIFETIME_MS,
    })
}
