import { useAppSelector } from "@/store/hooks"
import { Flex, Spinner, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import useGenericToast from "@hooks/useGenericToast"
import { useDeleteUSBankMutation } from "@redux/account/apiSlice"
import { selectAccountDetails } from "@redux/account/selectors"
import { useDeleteCABankMutation, useGetCABanksQuery, useGetUSBanksQuery } from "@redux/withdraw/apiSlice"
import { BankDetails, IntlBankDetails, USBankDetails } from "@redux/withdraw/types"
import { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoAdd } from "react-icons/io5"
import FiatRow from "../fiatRow"
import DeleteBankModal from "./deleteBank"
import PutCABank from "./putCABank"
import ViewBankAccountDetails from "./viewBankAccountDetails"

type FiatType = {
    bank: string
    accountNumber: string
}

export default function FiatTable() {
    const { t } = useTranslation("settings")
    const account = useAppSelector(selectAccountDetails)
    const { successToast, errorToast } = useGenericToast()
    const cancelRef = useRef<HTMLButtonElement>(null)

    const { data: banksCACAD, isLoading: getBanksCACADLoading } = useGetCABanksQuery("CAD", {
        skip: account?.country !== "CA",
    })
    // International CA banks not allowed for now
    // const { data: banksCAUSD, isLoading: getBanksCAUSDLoading } = useGetCABanksQuery("USD", {
    //     skip: account?.country !== "CA",
    // })
    const { data: banksUS, isLoading: getBanksUSLoading } = useGetUSBanksQuery(undefined, {
        skip: account?.country !== "US",
    })
    const [deleteUSBank] = useDeleteUSBankMutation()
    const [deleteCABank] = useDeleteCABankMutation()

    const [focusedBank, setFocusedBank] = useState<BankDetails | IntlBankDetails | USBankDetails | null>(null)

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const [isPutCABankOpen, setIsPutCABankOpen] = useState(false)
    const [isViewDetailsOpen, setViewDetailsOpen] = useState(false)

    const data = useMemo(() => {
        if (account?.country === "CA") {
            const CACADBanks = banksCACAD
                ? // International CA banks not allowed for now - Filtering them out
                  Object.values(banksCACAD).filter(
                      (b) => b.swift_code === null || b.swift_code === undefined || b.swift_code === ""
                  )
                : []
            // const CAUSDBanks = banksCAUSD ? Object.values(banksCAUSD) : []
            return [...CACADBanks]
        } else {
            return banksUS?.accounts ?? []
        }
        // International CA banks not allowed for now
        // }, [banksCACAD, banksCAUSD, banksUS])
    }, [banksCACAD, banksUS])

    async function deleteBank() {
        setIsDeleteModalOpen(false)
        setViewDetailsOpen(false)
        successToast(t("savedFiatAccount.deletedBankAccount"))
        const focusedBankCopy = { ...focusedBank }
        setFocusedBank(null)
        if (focusedBankCopy) {
            if (account?.country === "US") {
                const bank = focusedBankCopy as USBankDetails
                await deleteUSBank({ account_id: bank.id })
                    .unwrap()
                    .catch(() => errorToast(t("savedFiatAccount.errors.deletedBankAccount")))
            } else {
                const bank = focusedBankCopy as BankDetails | IntlBankDetails
                await deleteCABank({ id: bank.bank_id as number, currency: bank.currency })
                    .unwrap()
                    .catch(() => errorToast(t("savedFiatAccount.errors.deletedBankAccount")))
            }
        }
    }

    function handleDeleteClick(bank: BankDetails | IntlBankDetails | USBankDetails) {
        setFocusedBank(bank)
        setIsDeleteModalOpen(true)
    }

    function handleViewDetailsClick(bank: BankDetails | IntlBankDetails | USBankDetails) {
        setFocusedBank(bank)
        setViewDetailsOpen(true)
    }

    function handleEditDetailsClick(bank: BankDetails | IntlBankDetails) {
        setFocusedBank(bank)
        setIsPutCABankOpen(true)
    }

    const isLoading = useMemo(
        () => getBanksCACADLoading || getBanksUSLoading,
        [getBanksCACADLoading, getBanksUSLoading]
    )

    return (
        <Flex flexDir="column" gap={3} w="full" maxW="810px" mx={{ md: "auto" }} flexGrow={1}>
            {account?.country === "CA" && (
                <StandardButton
                    children={t("savedFiatAccount.addNewBankAccount")}
                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                    leftIcon={IoAdd}
                    size="sm"
                    onClick={() => setIsPutCABankOpen(true)}
                    alignSelf="flex-end"
                />
            )}

            <TableContainer flex={1} minHeight="300px">
                <Table>
                    <Thead bg="grey.light.5" w="full" borderBottom={1} borderColor="#EDF2F7">
                        <Tr py={"6px"} px={2} w="full">
                            <Th w="200px" px={2}>
                                <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("savedFiatAccount.bank")}
                                </Text>
                            </Th>

                            <Th w="calc(100% - 200px - 100px)" px={2} display={{ base: "none", md: "flex" }}>
                                <Text color="grey.light.90" textStyle="ManropeSemiboldXSmall" textTransform="none">
                                    {t("savedFiatAccount.accountNumber")}
                                </Text>
                            </Th>

                            <Th w="100px" />
                        </Tr>
                    </Thead>

                    <Tbody>
                        {data.map((bank, index) => (
                            <FiatRow
                                key={index}
                                isCA={account?.country === "CA"}
                                handleDeleteAccount={handleDeleteClick}
                                handleEditDetails={handleEditDetailsClick}
                                handleViewDetails={handleViewDetailsClick}
                                bank={bank}
                            />
                        ))}
                    </Tbody>
                </Table>
                {(isLoading || data.length === 0) && (
                    <Flex h="full" justify="center" align="center" minHeight="300px">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <Text color="grey.light.50" textStyle="ManropeSemiboldBodySmall">
                                {t("savedFiatAccount.noAccounts")}
                            </Text>
                        )}
                    </Flex>
                )}
            </TableContainer>
            <ViewBankAccountDetails
                isOpen={isViewDetailsOpen}
                toggleSideDrawerOpen={() => setViewDetailsOpen(!isViewDetailsOpen)}
                focusedBank={focusedBank as BankDetails | null}
                isCA={account?.country === "CA"}
                toggleDeleteModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                openEditDetails={() => setIsPutCABankOpen(true)}
                setFocusedBank={setFocusedBank}
            />
            <PutCABank
                isOpen={isPutCABankOpen}
                toggleSideDrawerOpen={() => {
                    setIsPutCABankOpen(!isPutCABankOpen)
                    setFocusedBank(null)
                }}
                editBankFormData={focusedBank as BankDetails | null} // currently can only be BankDetails
            />
            <DeleteBankModal
                isOpen={isDeleteModalOpen}
                toggleOpen={() => {
                    setIsDeleteModalOpen(false)
                    if (!isViewDetailsOpen) {
                        setFocusedBank(null)
                    }
                }}
                onClick={deleteBank}
                cancelRef={cancelRef}
            />
        </Flex>
    )
}
