import { PostSurveyErrorRes, SurveyData } from "@screens/onboarding/surveys/types"
import { API_PATH } from "./const"
import { splitApi } from "@redux/splitApi"

export const surveyApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurvey: builder.query<SurveyData, string>({
            query: (name) => ({
                url: `${API_PATH.SURVEY}/?type=${name}`,
                method: "GET",
            }),
        }),
        postSurvey: builder.mutation<void, { type: string; answers: (number[] | number)[] }>({
            query: (body) => ({
                url: `${API_PATH.SURVEY}`,
                method: "POST",
                body,
            }),
            transformErrorResponse: (response: PostSurveyErrorRes) => response.data,
        }),
    }),
})

export const { useLazyGetSurveyQuery, usePostSurveyMutation } = surveyApi
