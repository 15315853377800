import { useAppSelector } from "@/store/hooks"
import { Flex, Skeleton, Text } from "@chakra-ui/react"
import { selectAccountDetails } from "@redux/account/selectors"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const PortfolioValue = () => {
    const { t } = useTranslation("app")
    const { fiatValue, cryptoValue, isLoadingBalances, isLoadingPrices } = useCalculateBalances()
    const currency = useAppSelector(selectAccountDetails)?.currency || "CAD"
    const isLoading = useMemo(() => isLoadingBalances || isLoadingPrices, [isLoadingBalances, isLoadingPrices])
    return (
        <Flex flexDir="column" gap={2} width={{ base: "100%", lg: "50%" }}>
            <Text textStyle="ManropeSemiboldLarge">{t("dashboard.myPortfolioValue")}</Text>

            <Skeleton
                flex={1}
                isLoaded={!isLoading}
                w={isLoading ? "274px" : undefined}
                h={isLoading ? "30px" : undefined}
            >
                <Text>
                    <Text as="span" textStyle={!isLoading ? "ManropeMedium5xLarge" : undefined} mr="0.5rem">
                        {formatDollar(fiatValue + cryptoValue)}
                    </Text>
                    <Text as="span" textStyle="ManropeSemiboldXLarge">
                        {currency}
                    </Text>
                </Text>
            </Skeleton>
        </Flex>
    )
}
