import React, { useCallback, useMemo } from "react"
import { Text, VStack, Box } from "@chakra-ui/react"
import RadioGroup from "components/ui/radio"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import AmplitudeClient from "sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "../useOnboarding"
import { Option } from "@components/ui/types"
import { IdentityCitizenship } from "@redux/account/types"
import { useUpdateAccountIdentityMutation } from "@redux/account/apiSlice"

type FormData = {
    citizenship: Option<IdentityCitizenship>
}

export default function IsCanadian({ submitSubForm, userObj, isLoading, setIsBackVisible }: SubScreenProps) {
    const [isNotCanadian, setIsNotCanadian] = React.useState(false)
    const { t } = useTranslation(["onboarding", "common"])
    const [updateAccountIdentity] = useUpdateAccountIdentityMutation()

    const identityCitizenshipLabel = useCallback(
        (identity: IdentityCitizenship.CA_CITIZEN | IdentityCitizenship.CA_RESIDENT) => {
            const map = {
                [IdentityCitizenship.CA_CITIZEN]: t("isCanadian.canadian"),
                [IdentityCitizenship.CA_RESIDENT]: t("isCanadian.permanent"),
            }
            return map[identity]
        },
        []
    )

    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            citizenship:
                (userObj?.identity.citizenship && userObj?.identity.citizenship === IdentityCitizenship.CA_CITIZEN) ||
                userObj?.identity.citizenship === IdentityCitizenship.CA_RESIDENT
                    ? {
                          value: userObj?.identity.citizenship,
                          label: identityCitizenshipLabel(userObj?.identity.citizenship),
                      }
                    : undefined,
        },
    })

    async function onSubmit(values: FormData) {
        if (values?.citizenship?.value === IdentityCitizenship.NONE) {
            setIsBackVisible(false)
            setIsNotCanadian(true)
        } else {
            await submitSubForm(updateAccountIdentity, { citizenship: values.citizenship.value }, () => {
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.IsCanadian, REGISTRATION_STEP.CANADA_CITIZENSHIP)
            })
        }
    }

    return (
        <VStack>
            {isNotCanadian ? (
                <Box display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="ManropeMediumXLarge">{t("isCanadian.deniedHeader")}</Text>
                    <Text textStyle="InterRegularBodySmall">{t("isCanadian.deniedTitle")}</Text>
                    <StandardButton
                        data-testid="back-button"
                        onClick={() => {
                            setIsBackVisible(true)
                            setIsNotCanadian(false)
                        }}
                        type="button"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        mt="2.5rem"
                        w="full"
                    >
                        {t("goBack", { ns: "common" })}
                    </StandardButton>
                </Box>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                        {t("isCanadian.header")}
                    </Text>
                    <RadioGroup
                        name="citizenship"
                        options={[
                            {
                                value: IdentityCitizenship.CA_CITIZEN,
                                label: t("isCanadian.canadian"),
                            },
                            {
                                value: IdentityCitizenship.CA_RESIDENT,
                                label: t("isCanadian.permanent"),
                            },
                            {
                                value: IdentityCitizenship.NONE,
                                label: t("isCanadian.neither"),
                            },
                        ]}
                        rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                        isHorizontal={false}
                        control={control}
                    />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        mt="2.5rem"
                        w="full"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </form>
            )}
        </VStack>
    )
}
