import { Flex, Image, Text } from "@chakra-ui/react"

type Props = {
    image: string
    title: string
}

export default function IconBubble({ image, title }: Props) {
    return (
        <Flex
            data-test-id={`bubble-${title}`}
            flexDir="column"
            textAlign="center"
            gap={4}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Flex
                width="99px"
                height="99px"
                borderRadius="50%"
                background="#FFF"
                justifyContent="center"
                alignItems="center"
                boxShadow="0px 4px 9.8px 0px rgba(3, 13, 160, 0.20)"
                borderColor="darkBlue.10"
                borderWidth="1px"
            >
                <Image src={image} width="40px" height="40px" />
            </Flex>

            <Text textStyle="ManropeSemiboldBodySmall">{title}</Text>
        </Flex>
    )
}
