import { Box, Flex, Image, Text } from "@chakra-ui/react"
import ProgressBar from "@components/ui/progressBar"
import useImage from "@hooks/useImage"
import { DrawerCoinTradeContext } from "@screens/dashboard/trade/drawerCoinTrade/DrawerCoinTradeContext"
import { commaStringToFloat, formatCryptoCurrencyPrecision } from "@util/numericalFormatting"
import { addCommasToNumber, formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BUYSELL_TAB, ORDER_TYPE } from "../../trade/drawerCoinTrade"
import { imageMap } from "@assets/svgs/coins"

const FETCH_QUOTE_INTERVAL = 6000

export default function Preview({ tab }: { tab: BUYSELL_TAB }) {
    const {
        previewQuote: quote,
        selectedAsset: asset,
        isQuoteLoading,
        accountDetails,
        orderType,
        fetchQuote,
        currency,
    } = DrawerCoinTradeContext.useContainer()
    const hasFee = useMemo(() => accountDetails?.country === "CA", [accountDetails])
    const { t } = useTranslation(["app", "common"])
    const image = imageMap[asset.symbol.toLowerCase() as keyof typeof imageMap]

    const isLimit = useMemo(() => orderType === ORDER_TYPE.LIMIT, [orderType])
    const isBuying = useMemo(() => tab === BUYSELL_TAB.BUY, [tab])

    const actionText = useMemo(
        () => (isLimit ? t("buysell.youWill", { side: tab }) : t("buysell.youWill", { side: tab })),
        []
    )

    return (
        <Box flex={1} mt="28px">
            <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                <Text textStyle="ManropeSemiboldXLarge">{t("buysell.orderPreview")}</Text>
                {image && <Image src={image} alt={asset.symbol} w="3rem" h="3rem" />}
            </Flex>
            <Flex
                justifyContent={"space-between"}
                alignItems="center"
                pb="0.75rem"
                borderBottomColor="grey.light.10"
                borderBottomWidth={1}
                mb="1.5rem"
                color="grey.light.80"
            >
                <Text textStyle="ManropeSemiboldBody" color="grey.light.80">
                    {actionText} :
                </Text>
                <Text textStyle="ManropeSemiboldBody" color="grey.light.80">
                    {asset.name}
                </Text>
            </Flex>
            <Box pb="1.5rem" borderBottomColor="grey.light.10" borderBottomWidth={1}>
                <Flex
                    textStyle="ManropeSemiboldBody"
                    alignItems="center"
                    justifyContent={"space-between"}
                    mb="0.75rem"
                    columnGap="0.5rem"
                >
                    <Text>{t("common:amount")}</Text>
                    <Text>
                        {formatCryptoCurrencyPrecision(
                            +commaStringToFloat(quote?.quantity ?? "0", +asset.precision),
                            +asset.precision,
                            true,
                            0
                        )}{" "}
                        {asset.symbol}
                    </Text>
                </Flex>
                <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.70">
                        {orderType === ORDER_TYPE.LIMIT
                            ? t("buysell.when1", { asset: asset.symbol })
                            : `1 ${asset.symbol}`}{" "}
                        =
                    </Text>
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        $
                        {addCommasToNumber(`${commaStringToFloat(quote?.price ?? "0", asset.price_precision)}`, {
                            addDecimalZeroes: true,
                            precision: asset.price_precision,
                        })}{" "}
                        {currency}
                    </Text>
                </Flex>
                {orderType === ORDER_TYPE.MARKET && (
                    <Box marginTop={4}>
                        <ProgressBar interval={FETCH_QUOTE_INTERVAL / 1000} onTimerComplete={fetchQuote} />
                    </Box>
                )}
            </Box>
            {isLimit && (
                <Box borderBottomColor="grey.light.10" borderBottomWidth={1} py="1.5rem">
                    <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                        <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                            {t("buysell.orderType")}
                        </Text>
                        <Box bgColor="blue.10" borderRadius="0.25rem" py="0.5rem" px="0.75rem">
                            <Text color="blue.100" textStyle="ManropeSemiboldBodySmall">
                                {t("buysell.limitOrder")}
                            </Text>
                        </Box>
                    </Flex>
                </Box>
            )}
            <Box borderBottomColor="grey.light.10" borderBottomWidth={1} py="1.5rem">
                <Flex
                    alignItems="center"
                    justifyContent={"space-between"}
                    mb={hasFee ? "0.75rem" : 0}
                    columnGap="0.5rem"
                >
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {t("common:subtotal")}
                    </Text>
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {formatDollar(quote?.subtotal ?? "0")} {currency}
                    </Text>
                </Flex>
                {hasFee ? (
                    <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.70">
                            {t("common:netcoinsFee")}
                        </Text>
                        <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                            {formatDollar(quote?.fee ?? "0")} {currency}
                        </Text>
                    </Flex>
                ) : null}
            </Box>
            <Flex
                py="1.5rem"
                alignItems="center"
                justifyContent={"space-between"}
                mb="1.5rem"
                columnGap="0.5rem"
                borderBottomColor="grey.light.10"
                borderBottomWidth={1}
            >
                <Text textStyle="ManropeSemiboldBody">
                    {isBuying ? t("buysell.youWillPay") : t("buysell.youWillReceive")}
                </Text>
                <Text textStyle="ManropeSemiboldBody">
                    {formatDollar(quote?.total ?? "0")} {currency}
                </Text>
            </Flex>
            {isLimit ? (
                <Box
                    borderRadius="4px"
                    w="full"
                    p="1rem"
                    borderColor="grey.light.10"
                    borderWidth={1}
                    bgColor="grey.light.2"
                >
                    <Text>
                        <Text as="span" textStyle="InterRegularXSmall">
                            {t("buysell.a")}
                        </Text>
                        <Text as="span" textStyle="InterRegularXSmall" fontWeight={700}>
                            {t("buysell.limitOrderSm")}&nbsp;
                        </Text>
                        <Text as="span" textStyle="InterRegularXSmall">
                            {t("buysell.disclaimer", {
                                amount: addCommasToNumber(
                                    `${commaStringToFloat(quote?.price ?? "0", asset.price_precision)}`,
                                    {
                                        addDecimalZeroes: true,
                                        precision: asset.price_precision,
                                        addDollarSign: true,
                                    }
                                ),
                                symbol: asset.symbol,
                            })}
                        </Text>
                    </Text>
                </Box>
            ) : null}
        </Box>
    )
}
