import CheckCircle from "@assets/svgs/common/check-circle"
import { Box, Flex, Text } from "@chakra-ui/react"
import { DrawerCoinTradeContext } from "@screens/dashboard/trade/drawerCoinTrade/DrawerCoinTradeContext"
import { commaStringToFloat, formatCryptoCurrencyPrecision } from "@util/numericalFormatting"
import { addCommasToNumber, formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BUYSELL_TAB, ORDER_TYPE } from "../../trade/drawerCoinTrade"

export default function Complete({ tab }: { tab: BUYSELL_TAB }) {
    const {
        selectedAsset: asset,
        orderType,
        accountDetails,
        orderResult,
        currency,
    } = DrawerCoinTradeContext.useContainer()
    const hasFee = useMemo(() => accountDetails?.country === "CA", [accountDetails])
    const isImmediate = useMemo(() => accountDetails?.country === "CA", [orderType])

    const { t } = useTranslation(["app", "common"])

    const IsLimit = useMemo(() => orderType === ORDER_TYPE.LIMIT, [orderType])
    const IsBuying = useMemo(() => tab === BUYSELL_TAB.BUY, [tab])

    const TopText = useMemo(() => {
        if (IsBuying) return IsLimit ? t("buysell.youWillBuy") : t("buysell.youBought")
        return IsLimit ? t("buysell.youWillSell") : t("buysell.youSold")
    }, [])

    const BottomText = useMemo(() => {
        if (IsBuying) return IsLimit ? t("buysell.youWillPay") : t("buysell.youPaid")
        return IsLimit ? t("buysell.youWillReceive") : t("buysell.youReceived")
    }, [])

    return (
        <Box flex={1} mt="28px">
            <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                <Text textStyle="ManropeSemiboldXLarge">
                    {IsLimit ? t("buysell.orderPlaced") : t("buysell.orderComplete")}
                </Text>
                <CheckCircle />
            </Flex>
            <Box pb="0.75rem" borderBottomColor="grey.light.10" borderBottomWidth={1} mb="1.5rem">
                <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.80">
                        {TopText} :
                    </Text>
                </Flex>
            </Box>
            <Box borderBottomColor="grey.light.10" borderBottomWidth={1} pb="1.5rem">
                <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                    <Text textStyle="ManropeSemiboldBody">{t("common:amount")}</Text>
                    <Text textStyle="ManropeSemiboldBody">
                        {formatCryptoCurrencyPrecision(+(orderResult?.quantity ?? "0"), +asset.precision, true, 0)}{" "}
                        {asset.symbol}
                    </Text>
                </Flex>
                <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                    <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.70">
                        {t("buysell.when1", { asset: asset.symbol })} =
                    </Text>
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {addCommasToNumber(`${commaStringToFloat(orderResult?.price ?? "0", asset.price_precision)}`, {
                            addDecimalZeroes: true,
                            precision: asset.price_precision,
                            addDollarSign: true,
                        })}
                        &nbsp;
                        {currency}
                    </Text>
                </Flex>
            </Box>
            <Box borderBottomColor="grey.light.10" borderBottomWidth={1} py="1.5rem">
                <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {t("buysell.orderType")}
                    </Text>
                    <Box bgColor="blue.10" borderRadius="0.25rem" py="0.5rem" px="0.75rem">
                        <Text color="blue.100" textStyle="ManropeSemiboldBodySmall">
                            {IsLimit ? t("buysell.limitOrder") : t("buysell.instantOrder")}
                        </Text>
                    </Box>
                </Flex>
            </Box>
            <Box borderBottomColor="grey.light.10" borderBottomWidth={1} py="1.5rem">
                <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {t("common:subtotal")}
                    </Text>
                    <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                        {formatDollar(String(orderResult?.subtotal ?? "0"))} {currency}
                    </Text>
                </Flex>
                {hasFee ? (
                    <Flex alignItems="center" justifyContent={"space-between"} columnGap="0.5rem">
                        <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.70">
                            {t("common:netcoinsFee")}
                        </Text>
                        <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                            {formatDollar(orderResult?.fee ?? "0")} {currency}
                        </Text>
                    </Flex>
                ) : null}
            </Box>
            <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem" mt="1.5rem">
                <Text textStyle="ManropeSemiboldBody">{BottomText}</Text>
                <Text textStyle="ManropeSemiboldBody">
                    {formatDollar(String(orderResult?.total ?? "0"))} {currency}
                </Text>
            </Flex>
            {!isImmediate ? (
                <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem" mt="1.5rem">
                    <Text textStyle="ManropeSemiboldBody">{t("buysell.orderStatus")}</Text>
                    <Box
                        bgColor="yellow.light.90"
                        color="light.grey.light.100"
                        borderRadius="0.25rem"
                        py="0.5rem"
                        px="0.75rem"
                    >
                        <Text textStyle="ManropeSemiboldNano">{t("buysell.processing")}</Text>
                    </Box>
                </Flex>
            ) : null}
            {IsLimit ? (
                <Box
                    borderRadius="4px"
                    w="full"
                    p="1rem"
                    borderColor="grey.light.10"
                    borderWidth={1}
                    bgColor="grey.light.2"
                >
                    <Text textStyle="InterRegularXSmall">
                        {t("buysell.disclaimer", {
                            amount: addCommasToNumber(
                                `${commaStringToFloat(orderResult?.price ?? "0", asset.price_precision)}`,
                                {
                                    addDecimalZeroes: true,
                                    precision: asset.price_precision,
                                    addDollarSign: true,
                                }
                            ),
                            symbol: asset.symbol,
                        })}
                    </Text>
                </Box>
            ) : null}
        </Box>
    )
}
