import { Box, Text } from "@chakra-ui/react"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import { AccountType } from "@redux/account/types"
import { useAcceptDisclaimerMutation, useUpdateAccountIdentityMutation } from "@redux/account/apiSlice"
import { SubScreenProps } from "../useOnboarding"
import { useState } from "react"
import useSupport from "@hooks/useSupport"
import Checkbox from "@components/ui/checkbox"

type FormData = {
    first_name: string
    last_name: string
    accept_terms: boolean
}

export default function YourName({ submitSubForm, userObj, isLoading }: SubScreenProps) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            first_name: userObj.identity.first_name,
            last_name: userObj.identity.last_name,
        },
    })
    const { t } = useTranslation(["onboarding", "common"])
    const [updateAccountIdentity] = useUpdateAccountIdentityMutation()
    const [acceptDisclaimer] = useAcceptDisclaimerMutation()
    const { openWindowPrivacy, openWindowTerms } = useSupport()

    const [loading, toggleLoading] = useState(false)

    async function onSubmit(values: FormData) {
        toggleLoading(true)
        const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
        }
        const suffix = userObj?.country === "CA" ? "ca" : "us"

        await acceptDisclaimer({
            content_key: `terms_of_service_en_${suffix},privacy_policy_en_${suffix}`,
        })
            .unwrap()
            .then(async () => {
                await submitSubForm(updateAccountIdentity, payload, () => {
                    AmplitudeClient.logRegistrationEvent(REG_SCREENS.YourName, REGISTRATION_STEP.NAME)
                })
            })
            .finally(() => toggleLoading(false))
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("yourName.header")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("yourName.note")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir="column">
                    <TextInput
                        control={control}
                        maxLength={35}
                        name="first_name"
                        label={t("yourName.firstName")}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            maxLength: { value: 35, message: t("yourName.errorLength") },
                        }}
                        size="lg"
                        mb="1.5rem"
                    />
                    <TextInput
                        control={control}
                        name="last_name"
                        maxLength={35}
                        label={t("yourName.lastName")}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            maxLength: { value: 35, message: t("yourName.errorLength") },
                        }}
                        size="lg"
                        mb="1.5rem"
                    />
                    <Box w="full" display="flex" flexDir="column" alignItems="flex-start">
                        <Box
                            display="flex"
                            columnGap="0.5rem"
                            w="full"
                            alignItems="center"
                            justifyContent={"space-between"}
                        >
                            <Checkbox
                                name="accept_terms"
                                control={control}
                                rules={{ required: t("zerohash.errorCheckbox") }}
                                isRightSide
                            >
                                <Text textStyle="InterRegularBodySmall">
                                    {t("yourName.disclaimer")}
                                    <Text
                                        as="span"
                                        color="blue.100"
                                        onClick={() => openWindowTerms(userObj.country)}
                                        cursor="pointer"
                                        display="inline-block"
                                    >
                                        {t("terms", { ns: "common" })}&nbsp;
                                    </Text>
                                    <Text as="span" display="inline-block">
                                        &&nbsp;
                                    </Text>
                                    <Text
                                        as="span"
                                        color="blue.100"
                                        onClick={() => openWindowPrivacy(userObj.country)}
                                        cursor="pointer"
                                        display="inline-block"
                                    >
                                        {t("privacyPolicy", { ns: "common" })}
                                    </Text>
                                </Text>
                            </Checkbox>
                        </Box>
                        {errors.accept_terms && (
                            <Text textStyle="InterRegularBodySmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        isLoading={isLoading || loading}
                        mt="3rem"
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </Box>
            </form>
        </Box>
    )
}
