import { Text } from "@chakra-ui/react"
import {
    FIAT_PRECISION,
    formatStringWithCommas,
    formattedNumToFloat,
    MAX_WHOLE_DIGITS,
    removeCommas,
} from "@util/numericalFormatting"
import React, { FormEvent, useMemo } from "react"
import { MdAttachMoney } from "react-icons/md"
import { FormField, FormFieldProps } from ".."

export type ValueFormFieldProps = {
    currency: string
    dollarSign?: boolean
    precision?: number
    onTextChange?: (val: string) => void
} & FormFieldProps

export const ValueFormField: React.FC<ValueFormFieldProps> = ({
    currency,
    dollarSign,
    precision = FIAT_PRECISION,
    onTextChange,
    value,
    ...rest
}) => {
    const formattedText = useMemo(() => {
        return formatStringWithCommas(typeof value !== "string" ? "" : value, precision)
    }, [value, precision])

    const handleChange = (e: FormEvent) => {
        const element = e.currentTarget as HTMLInputElement

        const val = element.value
        const newVal = removeCommas(val, precision)
        const formatVal = formatStringWithCommas(newVal, precision)
        const valueFormattedToFloat = formattedNumToFloat(newVal)
        const wholeDigits = String(valueFormattedToFloat).split(".")[0]
        if (wholeDigits.length > MAX_WHOLE_DIGITS) {
            return
        }
        onTextChange && onTextChange(formatVal ?? "")
    }

    return (
        <FormField
            value={formattedText}
            onChange={(e) => handleChange(e)}
            leftElement={dollarSign ? <MdAttachMoney /> : undefined}
            rightElement={
                currency ? (
                    <Text as="span" textStyle="ManropeSemiboldBody">
                        {currency}
                    </Text>
                ) : undefined
            }
            {...rest}
        />
    )
}
