import {
    Box,
    FormControl,
    FormControlProps,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Text,
    useTheme,
} from "@chakra-ui/react"
import { FIELD_SIZE } from "@components/ui/types"
import React from "react"
import { Control, useController, UseControllerProps } from "react-hook-form"
import { FiEye, FiEyeOff } from "react-icons/fi"
import useColorFormatConverter from "theme/useColorFormatConverter"
import ErrorMessage from "../errorMessage"
import colors from "./theme.json"

export type TextInputPalette = {
    text: string
    iconContainer: string
    icon: string
    active: string
    border: string
    placeholder: string
    error: string
    errorBg: string
}

interface BaseProps {
    baseColor?: string
    placeholder?: string
    leftIcon?: React.ReactElement
    rightIcon?: React.ReactElement
    type?: "password" | "text"
    label?: string
    labelElement?: React.ReactElement
    maxLength?: number
    disabled?: boolean
    inputSize?: "sm" | "md" | "lg"
}

export interface TextInputControlledProps extends BaseProps {
    value: string
    onChange: (value: string | number) => void
    error?: string
}

export type TextInputFormProps = BaseProps & UseControllerProps<any> & FormControlProps

export type TextInputProps = TextInputControlledProps | TextInputFormProps

export type FormProps = {
    name: string
    control: Control
    rules?: any
}

export const isFormProps = (props: any): props is FormProps => {
    return "control" in props && "name" in props
}

export default function TextInput(props: TextInputProps) {
    const {
        placeholder,
        disabled,
        label,
        labelElement,
        leftIcon,
        rightIcon,
        type,
        maxLength = 150,
        baseColor = "white",
        inputSize = "lg",
    } = props

    const theme = useTheme()
    const colorConverter = useColorFormatConverter()
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const palette = React.useMemo(() => {
        return colors[baseColor as keyof typeof colors]
    }, [baseColor])

    // Handle form control case
    const formControl = isFormProps(props)
        ? useController<any>({
              name: props.name,
              control: props.control,
              rules: props.rules,
          })
        : null

    const { inputProps, formProps, error } = React.useMemo(() => {
        if (formControl) {
            const {
                field,
                fieldState: { invalid, error },
            } = formControl
            const { onChange, value, ref, ...restField } = field
            const { control, name, rules, ...formRest } = props as TextInputFormProps

            return {
                inputProps: {
                    onChange,
                    value: value ?? "",
                    ref,
                    ...restField,
                },
                formProps: {
                    isInvalid: invalid,
                    ...formRest,
                },
                error: error?.message,
            }
        }

        const { onChange, value, error, ...formRest } = props as TextInputControlledProps
        return {
            inputProps: {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                value: value ?? "",
            },
            formProps: {
                isInvalid: !!error,
                ...formRest,
            },
            error,
        }
    }, [props, formControl])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (error) {
                return palette.error
            }
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [error, palette]
    )

    const innerBorderColor = React.useMemo(
        () => (error ? palette.error : theme.colors.blue[100]),
        [error, theme, palette]
    )

    return (
        <FormControl {...formProps}>
            {label && (
                <FormLabel
                    fontSize={14}
                    lineHeight={"100%"}
                    htmlFor={isFormProps(props) ? props.name : undefined}
                    display={"flex"}
                    flexDir={"row"}
                    textStyle={"ManropeRegularBodySmall"}
                >
                    {label}&nbsp;
                    {isFormProps(props) && props.rules?.hasOwnProperty("required") && (
                        <Text as="span" color="blue.100">
                            *
                        </Text>
                    )}
                    {labelElement && labelElement}
                </FormLabel>
            )}
            <InputGroup
                borderColor={error ? "red.light.100" : disabled ? "grey.light.20" : "grey.light.10"}
                borderWidth={error ? "2px" : "1px"}
                borderRadius={"6px"}
                _focusWithin={error ? {} : { borderColor: "blue.100", borderWidth: "2px" }}
                h={`${FIELD_SIZE[inputSize ?? "lg"]}px`}
                overflow={"hidden"}
            >
                {leftIcon && (
                    <InputLeftAddon
                        pointerEvents="none"
                        bgColor={palette?.iconContainer}
                        h="full"
                        borderWidth={0}
                        borderRadius={0}
                    >
                        {leftIcon}
                    </InputLeftAddon>
                )}
                <Input
                    {...inputProps}
                    h="full"
                    data-testid={`input-${isFormProps(props) ? props.name : "controlled"}`}
                    disabled={disabled}
                    placeholder={placeholder}
                    _placeholder={{ color: "grey.light.40" }}
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="150%"
                    maxLength={maxLength}
                    type={!show && type === "password" ? "password" : "text"}
                    bgColor={error ? palette.errorBg : baseColor}
                    color={palette?.text}
                    borderWidth={0}
                    focusBorderColor="transparent"
                    errorBorderColor="transparent"
                    _autofill={{
                        WebkitTextFillColor: colorConverter(palette.text),
                        WebkitBoxShadow: `inset 0 0 20px 20px ${
                            baseColor === "white" || baseColor === "black" ? baseColor : colorConverter(baseColor)
                        }`,
                    }}
                    borderRadius={0}
                />
                {rightIcon && (
                    <InputRightElement
                        pointerEvents="none"
                        bgColor={palette?.iconContainer}
                        h="full"
                        borderWidth={0}
                        borderRadius={0}
                        w="50px"
                    >
                        {rightIcon}
                    </InputRightElement>
                )}
                {type === "password" && (
                    <InputRightElement h="full" onClick={handleClick} cursor="pointer" borderWidth={0} borderRadius={0}>
                        {show ? (
                            <FiEyeOff size={20} color={colorConverter(palette.icon)} />
                        ) : (
                            <FiEye size={20} color={colorConverter(palette.icon)} />
                        )}
                    </InputRightElement>
                )}
            </InputGroup>
            {!!error && <ErrorMessage color={palette?.text}>{error}</ErrorMessage>}
        </FormControl>
    )
}
