import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Flex, Skeleton, Text } from "@chakra-ui/react"
import Tooltip from "@components/tooltip"
import MarketChange from "@components/ui/marketChange"
import { formatDollar } from "@util/stringFormatting"
import { IoMdInformationCircleOutline } from "react-icons/io"

type Props = {
    title: string
    tooltip: string
    value: number
    percent?: number
    isLoading?: boolean
}

export default function ValueCard({ percent, title, tooltip, value, isLoading }: Props) {
    return (
        <Flex
            data-testid={title}
            width="100%"
            height="100%"
            p={6}
            flexDir="column"
            gap={1}
            borderRadius="6px"
            borderColor={"grey.light.10"}
            borderWidth="1px"
        >
            <Flex alignItems={"center"} justifyContent={{ base: "space-between", xl: "flex-start" }} gap={1}>
                <Text color="grey.light.70" textStyle="ManropeSemiboldBodySmall">
                    {title}
                </Text>
                <Tooltip
                    bodyText={tooltip}
                    triggerComponent={
                        <Flex mt={0.5}>
                            <ReactIcon icon={IoMdInformationCircleOutline} size={16} color="blue.100" />
                        </Flex>
                    }
                />
            </Flex>

            <Skeleton h={isLoading ? "18px" : undefined} isLoaded={!isLoading}>
                <Flex
                    alignItems={{ base: "flex-start", lg: "center" }}
                    gap={2}
                    flexDir={{ base: "column", lg: "row" }}
                    justifyContent="flex-start"
                >
                    <Text textStyle="ManropeMedium2xLarge">{formatDollar(value)}</Text>
                    {percent && <MarketChange percent={percent} size="lg" />}
                </Flex>
            </Skeleton>
        </Flex>
    )
}
