import SideDrawer, { SIDE_DRAWER_BUTTON_ID } from "@components/sideDrawer"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Text } from "@chakra-ui/react"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { useForm } from "react-hook-form"
import CloseDisclaimer from "./views/CloseDisclaimer"
import CloseDownloadTransactions from "./views/CloseDownloadTransactions"
import CloseWithPassword from "./views/CloseWithPassword"
import CloseConfirmEmail from "./views/CloseConfirmEmail"
import CloseFeedback from "./views/CloseFeedback"
import ClosePermanent from "./views/ClosePermanent"
import {
    useCloseAccountMutation,
    useSendCloseSurveyMutation,
    useVerify2FACodeMutation,
    useVerifyEmailCodeMutation,
    useVerifyPasswordMutation,
} from "@redux/account/apiSlice"
import { selectAccountDetails } from "@redux/account/selectors"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { logout } from "@redux/auth/slice"

const initialState = { pin: "", survey: "" }

export type PasswordFormData = {
    password: string
}

export default function CloseAccount(): JSX.Element {
    const account = useAppSelector(selectAccountDetails)
    const dispatch = useAppDispatch()

    const [verifyPassword] = useVerifyPasswordMutation()
    const [verify2FA] = useVerify2FACodeMutation()
    const [verifyEmailCode] = useVerifyEmailCodeMutation()
    const [closeAccount] = useCloseAccountMutation()
    const [sendCloseSurvey] = useSendCloseSurveyMutation()

    const [isOpen, setIsOpen] = useState(false)
    const [view, setView] = useState(1)
    const [inputs, setInputs] = useState(initialState)
    const [err, setErr] = useState("")
    const [loading, toggleLoading] = useState(false)

    const { t } = useTranslation(["settings", "common"])
    const { control, reset, getValues, trigger } = useForm<PasswordFormData>()

    const handleInputChange = (name: "pin" | "survey", value: string) => {
        setInputs({
            ...inputs,
            [name]: value,
        })
    }

    const onOpen = () => setIsOpen(true)
    const toggleSideDrawerOpen = () => {
        setView(1)
        reset()
        setInputs(initialState)
        setIsOpen(!isOpen)
        setErr("")
    }

    async function handleSubmittingCode(code: string) {
        setErr("")
        const res = await submitCode(code)
        if (res) {
            setView((prev) => Math.min(prev + 1, 6))
        } else {
            setErr(t("error.invalidCode", { ns: "common" }))
        }
    }

    async function submitCode(code: string): Promise<boolean> {
        toggleLoading(true)
        let res
        if (!account?.tfa) {
            res = await verifyEmailCode({ code })
                .unwrap()
                .catch((e) => {
                    setErr(e.message)
                    return false
                })
                .finally(() => toggleLoading(false))
        } else {
            res = await verify2FA({ code })
                .unwrap()
                .then((res) => res.tfa)
                .catch(() => {
                    setErr(t("error.invalidCode", { ns: "common" }))
                    return false
                })
                .finally(() => toggleLoading(false))
        }
        return !!res
    }

    const currentView = useMemo(() => {
        switch (view) {
            case 1:
                return <CloseDisclaimer />
            case 2:
                return <CloseDownloadTransactions setErr={setErr} />
            case 3:
                return <CloseWithPassword control={control} />
            case 4:
                return (
                    <CloseConfirmEmail
                        value={inputs.pin}
                        handleChange={handleInputChange}
                        submitEmailCode={handleSubmittingCode}
                    />
                )
            case 5:
                return <CloseFeedback value={inputs.survey} handleChange={handleInputChange} />
            case 6:
                return <ClosePermanent />
            default:
                return null
        }
    }, [view, inputs, control, setErr, err, submitCode])

    const next = async () => {
        setErr("")
        switch (view) {
            case 3: {
                const validated = await trigger()
                if (!validated) return

                toggleLoading(true)
                const res = await verifyPassword({ password: btoa(getValues("password")) })
                    .unwrap()
                    .catch((e) => {
                        setErr(e.message)
                        return false
                    })
                    .finally(() => toggleLoading(false))
                if (!res) return
                break
            }
            case 4: {
                const res = await submitCode(inputs.pin)
                if (!res) {
                    setErr(t("error.invalidCode", { ns: "common" }))
                    return
                }
                break
            }
            case 5: {
                if (inputs.survey.length) {
                    toggleLoading(true)
                    await sendCloseSurvey({ survey: inputs.survey }).finally(() => toggleLoading(false))
                }
                break
            }
            case 6: {
                toggleLoading(true)
                await closeAccount()
                    .then(() => dispatch(logout()))
                    .catch((e) => {
                        setErr(t("error.server", { ns: "common" }))
                        toggleLoading(false)
                    })
                break
            }
        }
        setView((prev) => Math.min(prev + 1, 6))
    }

    const previous = () => {
        setErr("")
        setView((prev) => Math.max(prev - 1, 1))
    }

    return (
        <>
            <StandardButton
                onClick={onOpen}
                children={t("profile.closeAccount")}
                variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
            />

            <SideDrawer
                name="closeAccount"
                isOpen={isOpen}
                toggleSideDrawerOpen={toggleSideDrawerOpen}
                size="md"
                header={
                    <StandardDrawerHeader
                        title={t("profile.closeAccount")}
                        onClose={toggleSideDrawerOpen}
                        {...(view !== 1 && view !== 5 && { onBack: previous })}
                    />
                }
                footerButton={{
                    children: t(view === 6 ? "profile.closeAccount" : "profile.continue"),
                    variant: view === 6 ? ENUM_BUTTON_VARIANTS.RED_SOLID : ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    onClick: next,
                    id: SIDE_DRAWER_BUTTON_ID,
                    isLoading: loading,
                }}
                extraFooter={
                    <Box mb={err || view === 1 || view === 6 ? "10px" : 0}>
                        {err && (
                            <Text color={"red.light.100"} textStyle={"InterRegularBody"} textAlign="center">
                                {err}
                            </Text>
                        )}
                        {(view === 1 || view === 6) && (
                            <StandardButton
                                variant={ENUM_BUTTON_VARIANTS.SECONDARY_SOLID}
                                children={t("profile.cancel")}
                                onClick={toggleSideDrawerOpen}
                                w="100%"
                            />
                        )}
                    </Box>
                }
            >
                <Box mt={7}>{currentView}</Box>
            </SideDrawer>
        </>
    )
}
