import { Global } from "@emotion/react"

export default {
    heading: "Manrope",
    body: "Inter",
    // subtitle: 'Inter Subtitle, sans-serif',
    // nano: 'Inter Nano, sans-serif',
}

export const Fonts = () => (
    <Global
        styles={`
            @font-face {
                font-family: 'Manrope';
                src: url('/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
            }
            @font-face {
                font-family: 'Inter';
                src: url('/fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
            }
        `}
    />
)
