import { Textarea as ChakraTextarea, FormControl, FormControlProps, FormLabel, Text, VStack } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../errorMessage"
import { useMemo } from "react"

type BaseProps = {
    placeholder?: string
    size?: "xs" | "sm" | "md" | "lg"
    maxLength: number
    currentLength: number
    label?: string
    disabled?: boolean
}

interface ControlledProps extends BaseProps {
    value: string
    onChange: (value: string) => void
    error?: string
}

type FormProps = BaseProps & UseControllerProps<any> & FormControlProps

const isFormProps = (props: Props): props is FormProps => {
    return "control" in props && "name" in props
}

type Props = ControlledProps | FormProps

export default function TextArea(props: Props) {
    const { placeholder, size, label, maxLength, currentLength, disabled } = props

    // Handle form control case
    const formControl = isFormProps(props)
        ? useController<any>({
              name: props.name,
              control: props.control,
              rules: props.rules,
          })
        : null

    const { inputProps, formProps, error } = useMemo(() => {
        if (formControl) {
            const {
                field,
                fieldState: { invalid, error },
            } = formControl
            const { onChange, value, ref, ...restField } = field
            const { control, name, rules, ...formRest } = props as FormProps

            return {
                inputProps: {
                    onChange,
                    value: value ?? "",
                    ref,
                    ...restField,
                },
                formProps: {
                    isInvalid: invalid,
                    ...formRest,
                },
                error: error?.message,
            }
        }

        const { onChange, value, error, ...formRest } = props as ControlledProps
        return {
            inputProps: {
                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
                value: value ?? "",
            },
            formProps: {
                isInvalid: !!error,
                ...formRest,
            },
            error,
        }
    }, [props, formControl])

    const { t } = useTranslation("common")

    return (
        <FormControl {...formProps}>
            {label && (
                <FormLabel htmlFor={isFormProps(props) ? props.name : undefined} textStyle={"ManropeRegularBodySmall"}>
                    {label}
                </FormLabel>
            )}
            <VStack spacing={1} w="full">
                <ChakraTextarea
                    {...inputProps}
                    data-testid={`textarea-${name}`}
                    placeholder={placeholder}
                    borderRadius={"6px"}
                    _placeholder={{ color: "grey.light.40" }}
                    _active={{ borderColor: "blue.100" }}
                    _focus={{
                        borderColor: "blue.100",
                    }}
                    resize="vertical"
                    disabled={disabled}
                    errorBorderColor="red.light.100"
                    focusBorderColor="blue.100"
                    maxLength={maxLength ?? 500}
                    overflow={"hidden"}
                />
                <Text color="grey.light.60" textStyle="InterRegularXSmall" alignSelf={"end"}>
                    {`${t("characterCount")}: ${currentLength ?? 0}/${maxLength}`}
                </Text>
                {!!error && <ErrorMessage>{error}</ErrorMessage>}
            </VStack>
        </FormControl>
    )
}
