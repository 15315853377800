import React from "react"
import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import { logout } from "@redux/auth/slice"
import { useAppDispatch } from "@/store/hooks"

type Props = {
    errorMessages: (string | string[])[]
    errorCode?: string
    errorHeader?: string
    supportEmail?: string
    resetSurvey?: () => void
}

const SUPPORT_EMAIL_PLACEHOLDER = "<support-email>"

export default function SurveyError({ errorMessages, errorHeader, errorCode, resetSurvey, supportEmail }: Props) {
    const { t } = useTranslation("onboarding", { keyPrefix: "surveys" })
    const dispatch = useAppDispatch()

    const supportString: Array<string> = React.useMemo(() => {
        const str = errorMessages.find(
            (msg: string | string[]) => !Array.isArray(msg) && msg.includes(SUPPORT_EMAIL_PLACEHOLDER)
        ) as string
        if (str && supportEmail) {
            const split = str.split(SUPPORT_EMAIL_PLACEHOLDER)
            const inserted_split = [split[0], SUPPORT_EMAIL_PLACEHOLDER, split[1]]
            return inserted_split
        }
        return []
    }, [])

    return (
        <Box w="full">
            {errorHeader && (
                <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                    {errorHeader}
                </Text>
            )}
            {errorMessages.map((msg: string | string[]) => {
                if (Array.isArray(msg)) {
                    return (
                        <UnorderedList key={msg.toString()}>
                            {msg.map((item) => (
                                <ListItem key={item}>{item}</ListItem>
                            ))}
                        </UnorderedList>
                    )
                } else if (!msg.includes(SUPPORT_EMAIL_PLACEHOLDER)) {
                    return (
                        <Text key={msg} textStyle="InterRegularBodySmall" mb="1rem">
                            {msg}
                        </Text>
                    )
                } else {
                    return (
                        <Text key={msg} textStyle="InterRegularBodySmall" mb="1rem">
                            {supportString.map((msg: string, i: number) => {
                                if (msg == SUPPORT_EMAIL_PLACEHOLDER && supportEmail) {
                                    return (
                                        <Text
                                            key={msg}
                                            as="span"
                                            color="blue.100"
                                            cursor={"pointer"}
                                            onClick={() =>
                                                window.open(
                                                    "mailto:" + supportEmail + "?subject=Failed Survey",
                                                    "_blank"
                                                )
                                            }
                                        >
                                            {/* 2nd part is to not include a space if its follow by punctuation */}
                                            {supportEmail.trim()}
                                            {`${supportString[i + i].length > 1 ? " " : ""}`}
                                        </Text>
                                    )
                                }
                                return (
                                    <Text key={msg} as="span">
                                        {msg.trim() ?? ""}&nbsp;
                                    </Text>
                                )
                            })}
                        </Text>
                    )
                }
            })}
            <StandardButton
                data-testid="restart-button"
                mt="2rem"
                w="full"
                type="button"
                onClick={errorCode === "501" ? resetSurvey : () => dispatch(logout())}
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
            >
                {errorCode === "501" ? t("restart") : t("ok")}
            </StandardButton>
        </Box>
    )
}
