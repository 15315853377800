import whereReside from "./whereReside.json"
import howDidYouHear from "./howDidYouHear.json"
import yourName from "./yourName.json"
import whenBorn from "./whenBorn.json"
import isCanadian from "./isCanadian.json"
import whatPhone from "./whatPhone.json"
import whereLive from "./whereLive.json"
import occupation from "./occupation.json"
import riskStatement from "./riskStatement.json"
import safetyDisclaimer from "./safetyDisclaimer.json"
import extra from "./extra.json"
import surveys from "./surveys.json"
import accountType from "./accountType.json"
import isMoneyService from "./isMoneyService.json"
import aboutBusiness from "./aboutBusiness.json"
import businessName from "./businessName.json"
import whatRole from "./whatRole.json"
import whereIncorporated from "./whereIncorporated.json"
import zerohash from "./zerohash.json"
import terrorism from "./terrorism.json"
import ssn from "./ssn.json"

export default <const>{
    whereReside,
    howDidYouHear,
    yourName,
    whenBorn,
    isCanadian,
    whatPhone,
    whereLive,
    occupation,
    riskStatement,
    safetyDisclaimer,
    extra,
    surveys,
    accountType,
    isMoneyService,
    aboutBusiness,
    businessName,
    whatRole,
    whereIncorporated,
    zerohash,
    terrorism,
    ssn,
}
