import { useAppSelector } from "@/store/hooks"
import { Flex, Text, Box } from "@chakra-ui/react"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { capitalize } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { AiOutlineInfoCircle } from "react-icons/ai"

export default function ConfirmDetails() {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })
    const colorConverter = useColorFormatConverter()
    const userData = useAppSelector(selectAccountDetails)
    const { first_name, last_name, occupation, address } = userData?.identity || {}
    const { building_address, street_address, city, region, postal_code, country } = address || {}

    const { supportPhoneNumberOther, supportEmail } = useSupport()

    const streetAddress = useMemo(() => {
        if (building_address && street_address) {
            return `${building_address} ${street_address}`
        }
        if (building_address) {
            return building_address
        }
        if (street_address) {
            return street_address
        }
        return ""
    }, [building_address, street_address])

    return (
        <Flex gap={6} flexDir="column">
            <Flex flexDir="column">
                <Text textStyle="ManropeSemiboldBody">{t("confirmYourDetails")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("takeAMoment")}</Text>
            </Flex>

            <Flex gap={4} p={4} flexDir="column" border="1px" borderColor="grey.light.10" borderRadius="4px">
                <Flex flexDir="column" gap={2}>
                    <Flex gap={2} justifyContent="space-between" w="full">
                        <Text textStyle="ManropeSemiboldBodySmall">{t("legalFirstName")}</Text>
                        <Text textStyle="ManropeSemiboldBodySmall">{first_name}</Text>
                    </Flex>

                    <Flex gap={2} justifyContent="space-between" w="full">
                        <Text textStyle="ManropeSemiboldBodySmall">{t("legalLastName")}</Text>
                        <Text textStyle="ManropeSemiboldBodySmall">{last_name}</Text>
                    </Flex>

                    <Flex gap={2} justifyContent="space-between" w="full">
                        <Text textStyle="ManropeSemiboldBodySmall">{t("occupation")}</Text>
                        <Text textStyle="ManropeSemiboldBodySmall">{occupation}</Text>
                    </Flex>
                </Flex>

                <Box borderBottomWidth={1} />

                <Flex flexDir="column" gap={2}>
                    <Flex gap={2} justifyContent="space-between" w="full">
                        <Text textStyle="ManropeSemiboldBodySmall">{t("billingAddress")}</Text>
                        <Flex flexDir="column" justifyContent="flex-end" textAlign="right">
                            <Text textStyle="InterRegularBodySmall">{streetAddress}</Text>
                            <Text textStyle="InterRegularBodySmall">{`${capitalize(city)}, ${region}`}</Text>
                            <Text textStyle="InterRegularBodySmall">{`${country}`}</Text>
                            <Text textStyle="InterRegularBodySmall">{`${postal_code}`}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap={2} flexDir="column">
                <InfoAlert
                    variant={ENUM_INFO_ALERT_VARIANT.BLUE}
                    children={t("contactCustomerSupport")}
                    icon={{
                        icon: () => <AiOutlineInfoCircle color={colorConverter("blue.100")} size={24} />,
                    }}
                />

                <Flex flexDir="column">
                    <Text textStyle="InterRegularBodySmall">
                        {t("pleaseContactCustomerSupportText", {
                            supportNum: supportPhoneNumberOther,
                            supportEmail: supportEmail,
                        })}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
