import { Box, Text, VStack } from "@chakra-ui/layout"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import PlainModal from "@components/ui/modals/plain"
import { useTranslation } from "react-i18next"
import { UniversalModalProps } from "../types"

export const UnderReviewModal: React.FC<UniversalModalProps> = ({ isOpen, onClose, cancelRef }) => {
    const { t } = useTranslation(["app", "common", "transfers"])

    return (
        <PlainModal
            name="underReview"
            isOpen={isOpen}
            cancelRef={cancelRef}
            title={t("userRestrictions.underReviewTitle")}
            onClose={onClose}
            separator
            buttons={[
                {
                    children: t("common:close"),
                    onClick: onClose,
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                },
            ]}
        >
            <>
                <Text textStyle={"InterRegularBody"}>{t("userRestrictions.underReview")}</Text>
                <Text textStyle={"InterRegularBody"} mt={4}>
                    {t("userRestrictions.underReviewDesc")}
                </Text>
                <Text textStyle={"InterRegularBody"} mt={4}>
                    {t("userRestrictions.checkBackInBusinessDay")}
                </Text>
            </>
        </PlainModal>
    )
}
