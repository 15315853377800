import { useMemo } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { MdTrendingUp, MdTrendingDown } from "react-icons/md"
import useColorFormatConverter from "@theme/useColorFormatConverter"

type Props = {
    percent: number
    hideBackground?: boolean
    hideIcon?: boolean
    size?: "sm" | "lg"
}

export default function MarketChange({ percent, hideBackground, hideIcon = false, size = "sm" }: Props) {
    const colorConverter = useColorFormatConverter()

    const Icon = useMemo(() => {
        if (percent > 0) {
            return <MdTrendingUp color={colorConverter("green.light.100")} />
        } else if (percent < 0) {
            return <MdTrendingDown color={colorConverter("red.light.100")} />
        } else {
            return null
        }
    }, [percent])

    const TextColor = useMemo(() => {
        if (percent > 0) {
            return "green.light.100"
        } else if (percent < 0) {
            return "red.light.100"
        } else {
            return "grey.light.70"
        }
    }, [percent])

    const BgColor = useMemo(() => {
        if (percent > 0) {
            return "green.light.10"
        } else if (percent < 0) {
            return "red.light.10"
        } else {
            return "grey.light.5"
        }
    }, [percent])

    const Px = useMemo(() => {
        if (percent > 0 || percent < 0) {
            return "6px"
        }
        return "0.5rem"
    }, [percent])

    return (
        <Flex
            w="fit-content"
            bgColor={hideBackground ? "transparent" : BgColor}
            columnGap="0.25rem"
            alignItems={hideBackground ? "baseline" : "center"}
            borderRadius={"4px"}
            px={hideBackground ? 0 : Px}
            py={hideBackground ? 0 : size === "sm" ? "0.125rem" : "0.25rem"}
        >
            <Flex alignSelf="center">{!hideIcon ? Icon : null}</Flex>
            <Text color={TextColor} textStyle={size === "lg" ? "ManropeSemiboldBodySmall" : "ManropeSemiboldNano"}>
                {percent > 0 ? "+" : ""}
                {percent > 0 || percent < 0 ? percent : `0.00`}%
            </Text>
        </Flex>
    )
}
