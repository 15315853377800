import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import MobileDownloads from "@/components/mobileDownloads"
import { logout } from "@redux/auth/slice"
import { useAppDispatch } from "@/store/hooks"

export default function PendingApproval({ isCanada }: { isCanada: boolean }) {
    const { t } = useTranslation(["onboarding", "common"])
    const dispatch = useAppDispatch()

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("extra.pendingTitle")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("extra.pendingDesc")}
            </Text>
            {!isCanada && (
                <Box rowGap="1.5rem">
                    <Text textStyle="InterRegularBodySmall">
                        {t("extra.pendingSupport")}&nbsp;
                        <a
                            href="mailto:businessonboarding@netcoins.com?subject=Account Pending Approval"
                            style={{ color: "#3D65F6" }}
                        >
                            businessonboarding@netcoins.com
                        </a>
                    </Text>
                    <MobileDownloads />
                </Box>
            )}
            <StandardButton
                data-testid="continue-button"
                type="button"
                onClick={() => dispatch(logout())}
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="3rem"
            >
                {t("done", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
