import { Grid } from "@chakra-ui/react"
import ValueCard from "@components/ui/cards/value"
import useRegion from "@hooks/useRegion"
import { useGetDailyInsightsQuery } from "@redux/insights/apiSlice"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const PortfolioBalances = () => {
    const { t } = useTranslation("app")
    const { isCAUser } = useRegion()
    const { fiatValue, cryptoValue, availableValue, isLoadingBalances, isLoadingPrices } = useCalculateBalances()
    const { data: insightsData, isLoading: isLoadingInsights } = useGetDailyInsightsQuery(undefined)
    const dailyAggregate = useMemo(() => {
        if (!insightsData || !insightsData["AGGREGATE"]) return undefined
        return insightsData["AGGREGATE"]
    }, [insightsData])

    const isLoading = useMemo(
        () => isLoadingBalances || isLoadingPrices || isLoadingInsights,
        [isLoadingBalances, isLoadingPrices, isLoadingInsights]
    )
    return (
        <Grid
            gridTemplateColumns={{
                base: "1fr",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
            }}
            gap={3}
            w="full"
            alignItems="stretch"
        >
            <ValueCard
                tooltip={t("dashboard.tooltip.performance24h")}
                title={t("dashboard.twentyFourHourPerformance")}
                percent={
                    dailyAggregate?.performance.simpleReturnPercent
                        ? Number(dailyAggregate?.performance.simpleReturnPercent)
                        : undefined
                }
                value={Number(dailyAggregate?.performance.simpleReturn) || 0}
                isLoading={isLoading}
            />
            <ValueCard
                tooltip={t("dashboard.tooltip.totalCrypto")}
                title={t("dashboard.totalCryptoBalance")}
                value={cryptoValue}
                isLoading={isLoading}
            />
            <ValueCard
                tooltip={t("dashboard.tooltip.totalCash")}
                title={t("dashboard.totalCashBalance")}
                value={fiatValue}
                isLoading={isLoading}
            />
            <ValueCard
                tooltip={t(isCAUser ? "dashboard.tooltip.availableTradeCA" : "dashboard.tooltip.availableTradeUS")}
                title={t("dashboard.availableToTrade")}
                value={availableValue}
                isLoading={isLoading}
            />
        </Grid>
    )
}
