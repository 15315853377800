import SolAndAtomImage from "@assets/images/coins/sol-and-atom.png"
import { Flex, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const stakingLink = "https://www.netcoins.com/staking/"

export const StakingBanner = () => {
    const { t } = useTranslation("app")

    const onClick = () => {
        window.open(stakingLink, "_blank")
    }

    return (
        <Flex
            px={6}
            py={1}
            flexDir="column"
            width="100%"
            height="169px"
            bg="radial-gradient(249.81% 155.93% at -28.36% 105.74%, rgba(14, 152, 183, 0.40) 30.19%, rgba(14, 152, 183, 0.00) 97.18%), radial-gradient(220.73% 140.16% at 100% 0%, rgba(3, 19, 80, 0.32) 0%, rgba(3, 19, 80, 0.00) 100%), #07072E"
        >
            <Flex height="42px" alignItems="center" justifyContent="center" textAlign="center">
                <Text color="white" textStyle="ManropeSemiboldBodySmall">
                    {t("dashboard.coinsAvailableForStaking")}
                </Text>
            </Flex>

            <Flex gap={2} width="100%" alignItems="center">
                <Flex
                    width="101px"
                    minWidth="101px"
                    height="99px"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="4px"
                >
                    <Image src={SolAndAtomImage} width="100%" height="100%" alt="Sol and Atom" objectFit="contain" />
                </Flex>

                <Flex flexDir="column">
                    <Text color="white" textStyle="InterRegularXSmall">
                        {t("dashboard.stakeAvailableCoins")}
                    </Text>

                    <Flex
                        alignItems="center"
                        cursor="pointer"
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                        onClick={onClick}
                    >
                        <Text color="blue.70" textStyle="InterRegularXSmall">
                            {t("learnMore", { ns: "common" })}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
