import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"
import React, { useEffect } from "react"
import SLIDING_TAB_VARIANTS, { ENUM_SLIDING_TAB_VARIANT, TAB_SIZE } from "./types"

const MotionBox = motion(Box)

type SliderOption<T> = {
    value: T
    label: string
    icon?: React.ReactNode
}

type Props<T> = {
    options: SliderOption<T>[]
    activeOption: T
    setActiveOption: (_: T) => void
    size?: keyof typeof TAB_SIZE
    variant?: ENUM_SLIDING_TAB_VARIANT
}

const TabsSliding = <T,>({
    options,
    activeOption,
    setActiveOption,
    size = "lg",
    variant = ENUM_SLIDING_TAB_VARIANT.PRIMARY,
}: Props<T>) => {
    const controls = useAnimation()

    const { container, tab } = SLIDING_TAB_VARIANTS[variant]

    const handleOptionClick = (value: T) => {
        if (value !== activeOption) {
            controls.start({
                x: `${options.findIndex((opt) => opt.value === value) * 100}%`,
            })
        }
        setActiveOption(value)
    }

    // Initialize the animation control with the correct starting position
    useEffect(() => {
        const initialIndex = options.findIndex((opt) => opt.value === activeOption)
        controls.set({ x: `${initialIndex * 100}%` })
    }, [activeOption]) // Empty dependency array ensures this only runs once on mount

    return (
        <Box
            w={"full"}
            p={`${container.p}px`}
            bgColor={container.bg}
            borderColor={container.borderColor}
            borderWidth="1px"
            borderRadius={"6px"}
            height={`${TAB_SIZE[size]}px`}
            width={"100%"}
        >
            <Flex width="full" height={"100%"} position="relative" overflow="hidden">
                <MotionBox
                    position="absolute"
                    top="0"
                    left={"0"}
                    height="100%"
                    width={`${100 / options.length}%`}
                    bg={tab.color}
                    borderRadius="0.5rem"
                    animate={controls}
                    transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 30,
                        mass: 0.5,
                        duration: 0.2,
                    }}
                />
                {options.map((option, i) => (
                    <Button
                        key={i}
                        flex={1}
                        variant="unstyled"
                        onClick={() => handleOptionClick(option.value)}
                        zIndex={1}
                        _hover={{}}
                        _active={{}}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        h="full"
                    >
                        <Box
                            id={`slidertab-${i}-${option.value}`}
                            as="span"
                            color={activeOption === option.value ? tab.activeTextColor : tab.inactiveTextColor}
                            transition="color 0.1s"
                            zIndex={2}
                            display="flex"
                            alignItems="center"
                            columnGap="1rem"
                        >
                            {option.icon && option.icon}
                            <Text textStyle="ManropeMediumBodySmall" color={"currentcolor"}>
                                {option.label}
                            </Text>
                        </Box>
                    </Button>
                ))}
            </Flex>
        </Box>
    )
}

export default TabsSliding
