import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import Link from "@components/ui/link"
import { TERMS_OF_SERVICE_URL_CA, TERMS_OF_SERVICE_URL_US } from "@util/config"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { SubScreenProps } from "@screens/onboarding/useOnboarding"
import { useAcceptDisclaimerMutation } from "@redux/account/apiSlice"

export default function UpdateToS({ submitSubForm, userObj }: SubScreenProps) {
    const { t } = useTranslation("legal", { keyPrefix: "tos" })
    const [acceptDisclaimer] = useAcceptDisclaimerMutation()

    function handleSubmit() {
        submitSubForm(acceptDisclaimer, {
            content_key: `terms_of_service_en_${userObj.country.toLowerCase()}`,
        })
    }
    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("title")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                {t("body")}
            </Text>
            <Link
                text={t("link")}
                href={userObj?.country === "CA" ? TERMS_OF_SERVICE_URL_CA : TERMS_OF_SERVICE_URL_US}
                hasIcon
                textStyle="ManropeSemiboldBody"
                mb="3rem"
            />
            <StandardButton
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                children={t("button")}
                onClick={handleSubmit}
            />
        </Box>
    )
}
