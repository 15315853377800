import { Box, Spinner, Text } from "@chakra-ui/react"
import { REG_SCREENS } from "@screens/onboarding/types"
import Header from "components/header"
import React, { useRef } from "react"
import AmplitudeClient from "sdks/amplitude"
import { LARGE_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "theme/consts"
import { establishBusinessOnboardingSteps, establishOnboardingSteps } from "util/verificationSteps"
import useOnboarding, { BIG_MAX_WIDTH_SCREENS } from "./useOnboarding"
import { useTranslation } from "react-i18next"
import { IAccountDetails } from "@redux/account/types"
import { useLazyGetAccountDetailsQuery } from "@redux/account/apiSlice"

type OnboardingComponentProps = {
    isBackVisible: boolean
    goBack: () => void
    progressValue: number
    renderedScreen: JSX.Element
    currentPhase: Partial<{ name: REG_SCREENS; num: number }>
    renderBlockedFromOnboardingScreen: JSX.Element | null
}

export default function OnBoarding() {
    const [getAccountDetails, { data }] = useLazyGetAccountDetailsQuery()
    const {
        isBackVisible,
        progressValue,
        renderedScreen,
        currentPhase,
        setPhases,
        phases,
        setCurrentPhase,
        doSurveyData,
        countSurveyScreens,
        renderBlockedFromOnboardingScreen,
        setIsBackVisible,
    } = useOnboarding()

    /* 
        Due to the useAppSelector(selectAccountDetails) in the useOnboarding hook, the cache data from the lazy query will continue to update.
        Having this ref here allows us to establish a baseline only once and not update
    */
    const baselineUserObj = useRef<IAccountDetails>()

    // analytics
    React.useEffect(() => {
        if (currentPhase.name) {
            AmplitudeClient.logScreenEvent(currentPhase.name)
        }
    }, [currentPhase])

    React.useEffect(() => {
        if (data && !baselineUserObj.current) {
            baselineUserObj.current = data
            initOnboardingData(data)
        } else {
            getAccountDetails()
        }
    }, [data])

    async function initOnboardingData(account: IAccountDetails) {
        // First time going to screen
        if (!account.type) {
            setPhases([REG_SCREENS.AccountType])
            setCurrentPhase({ name: REG_SCREENS.AccountType, num: 0 })
            // Returning to onboarding and only completed the REG_SCREENS.AccountType screen
        } else if (!account.country || !account.region) {
            const countryScreen =
                account.type === "individual" ? REG_SCREENS.WhereReside : REG_SCREENS.WhereIncorporated
            setPhases([countryScreen])
            setCurrentPhase({ name: countryScreen, num: 0 })
            // Anything else
        } else {
            const _surveys = await doSurveyData(account)
            const surveyCount = countSurveyScreens(_surveys)
            const phases: REG_SCREENS[] =
                account.type === "individual"
                    ? establishOnboardingSteps(account, surveyCount)
                    : establishBusinessOnboardingSteps(account, surveyCount)
            setPhases(phases)
            setCurrentPhase({ name: phases[0], num: 0 })
        }
    }

    function goBack() {
        if (currentPhase.num === 1 || currentPhase.num === 0) {
            setIsBackVisible(false)
        }

        // Due to the dynamic nature of the survey components, pressing back on any of those screens
        // will result in returning to the beginning of all surveys
        if (currentPhase.name === REG_SCREENS.Surveys) {
            setCurrentPhase({
                name: REG_SCREENS.SurveyIntro,
                num: phases.findIndex((phase) => phase === REG_SCREENS.SurveyIntro),
            })
            // Standard behaviour
        } else {
            setCurrentPhase((prev) => {
                if (typeof prev.num !== "number" || prev.num === 0) return prev
                return { name: phases[prev.num - 1], num: prev.num - 1 }
            })
        }
    }

    return (
        <OnboardingScreenComponent
            isBackVisible={isBackVisible}
            goBack={goBack}
            progressValue={progressValue}
            currentPhase={currentPhase}
            renderBlockedFromOnboardingScreen={renderBlockedFromOnboardingScreen}
            renderedScreen={renderedScreen}
        />
    )
}

export const OnboardingScreenComponent = ({
    isBackVisible,
    goBack,
    progressValue,
    currentPhase,
    renderBlockedFromOnboardingScreen,
    renderedScreen,
}: OnboardingComponentProps) => {
    const { t } = useTranslation("common")

    return (
        <Box h="100%" w="100%" display="flex" flexDir="column">
            <Header isBackVisible={isBackVisible} goBack={goBack} progress={progressValue} />
            <Box w="full" h="full" px="0.75rem">
                <Box
                    maxW={
                        currentPhase?.name && BIG_MAX_WIDTH_SCREENS.includes(currentPhase.name)
                            ? LARGE_SCREEN_WIDTH
                            : SMALL_SCREEN_WIDTH
                    }
                    w="100%"
                    mx="auto"
                    mt={{ base: "0", md: "4rem" }}
                    pb="0.75rem"
                >
                    {renderBlockedFromOnboardingScreen || (
                        <>
                            {currentPhase.name ? (
                                renderedScreen
                            ) : (
                                <Box rowGap="1.5rem" display="flex" flexDir="column" alignItems="center">
                                    <Spinner color="black" />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
