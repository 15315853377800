import { CurrencyFullName, TCurrencyType } from "@/types"
import { imageMap } from "@assets/svgs/coins"
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react"
import { formatDollar } from "@util/stringFormatting"
import { useTranslation } from "react-i18next"
type FiatBalanceCardProps = {
    balance: string
    currency: TCurrencyType
    isLoading?: boolean
    onAddFundsClick: () => void
}
export const FiatBalanceCard = ({ balance, currency, onAddFundsClick, isLoading }: FiatBalanceCardProps) => {
    const { t } = useTranslation(["app"])
    const image = imageMap[currency.toLowerCase() as keyof typeof imageMap]
    return (
        <Flex
            py={6}
            px={3}
            gap={3}
            justifyContent="space-between"
            alignItems="center"
            borderRadius="6px"
            borderWidth="1px"
            borderColor="grey.light.10"
        >
            <Flex alignItems="center" gap={3} w={{ sm: "50%" }}>
                <Flex borderRadius="50%" justifyContent="center" alignItems="center" height="42px" width="42px">
                    <Image src={image} alt="Canadian Fiat" w="3rem" h="3rem" />
                </Flex>

                <Flex alignItems="center" gap={1}>
                    <Text textStyle="ManropeSemiboldBodySmall" display={{ base: "none", sm: "flex" }}>
                        {CurrencyFullName[currency]}
                    </Text>
                    <Text
                        textStyle={{
                            base: "ManropeSemiboldBodySmall",
                            sm: "ManropeSemiboldXSmall",
                        }}
                        color="grey.light.50"
                    >
                        {currency}
                    </Text>
                </Flex>
            </Flex>

            <Flex alignItems="center" w={{ sm: "50%" }} flexDir={{ base: "column-reverse", sm: "row" }}>
                <Flex
                    alignItems="center"
                    gap={1}
                    ml={{ base: "auto", md: "unset" }}
                    whiteSpace="nowrap"
                    justifyContent="flex-end"
                    w={{ base: "100%", sm: "50%" }}
                >
                    <Text
                        color={"blue.100"}
                        cursor="pointer"
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                        textStyle={{
                            base: "ManropeSemiboldXSmall",
                            sm: "ManropeSemiboldBodySmall",
                        }}
                        onClick={onAddFundsClick}
                    >
                        {t("dashboard.clickToAddFunds")}
                    </Text>
                </Flex>

                <Flex justifyContent="flex-end" w={{ base: "100%", sm: "50%" }}>
                    <Skeleton justifyContent="flex-end" minW={"150px"} isLoaded={!isLoading}>
                        <Text textAlign={"end"} textStyle="ManropeSemiboldBody">
                            {formatDollar(balance)}
                        </Text>
                    </Skeleton>
                </Flex>
            </Flex>
        </Flex>
    )
}
