import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import { selectAssetPrice } from "@redux/prices/selectors"
import { IPrice } from "@redux/prices/types"
import Decimal from "decimal.js"
import { useMemo } from "react"

export const getMedianCryptoPriceForPrice = (price: IPrice): Decimal => {
    const low = new Decimal(price.sell)
    const high = new Decimal(price.buy)

    return Decimal.sum(low.isNaN() ? 0 : low, high.isNaN() ? 0 : high).dividedBy(2)
}

export const useCryptoDollarMedianConversions = (crypto: string, fiat?: string) => {
    const { data: balances } = useGetBalancesQuery(undefined)
    const baseCurrency = useAppSelector(selectAccountDetails)?.currency

    const assetPricePair = useAppSelector((state) => selectAssetPrice(state, crypto, fiat || baseCurrency))

    const assetPrice = useMemo(
        () => (assetPricePair ? getMedianCryptoPriceForPrice(assetPricePair) : new Decimal(1)),
        [assetPricePair]
    )

    const cryptoBalance = useMemo(() => {
        if (!balances?.balances || !balances?.balances[crypto]) {
            return new Decimal(0)
        }
        return new Decimal(balances.balances[crypto])
    }, [balances, crypto])

    const convertCryptoToDollar = (amount: number | string) => {
        return assetPrice.mul(amount)
    }

    const convertDollarToCrypto = (amount: number | string, ignoreUserBalance = false) => {
        const cryptoAmount = new Decimal(amount).dividedBy(assetPrice)
        const availableBalance = new Decimal(cryptoBalance)
        if (new Decimal(availableBalance) < cryptoAmount && !ignoreUserBalance) {
            return availableBalance
        }
        return cryptoAmount
    }

    const getCryptoBalancesInDollars = (balance: number | string, price?: number) =>
        price && typeof balance === "number" ? balance * price : convertCryptoToDollar(balance)

    return {
        assetPrice,
        cryptoBalance,
        getCryptoBalancesInDollars,
        convertCryptoToDollar,
        convertDollarToCrypto,
    }
}
