// Helper function to replace params
export function buildRouteWithParams(route: string, params: Record<string, string>): string {
    return Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key]), route)
}

export function buildRouteWithQueryParams(route: string, params: Record<string, string>): string {
    const queryParams = new URLSearchParams(params).toString()
    return `${route}?${queryParams}`
}

export function getRelativePathAfterSegment(fullRoute: string, segment: string): string {
    // Normalize the input by removing leading and trailing slashes
    const normalizedRoute = fullRoute.replace(/^\/|\/$/g, "")
    const parts = normalizedRoute.split("/").filter(Boolean)

    // Find the index of the segment
    const segmentIndex = parts.indexOf(segment)

    if (segmentIndex === -1) {
        throw new Error(`Segment "${segment}" not found in route "${fullRoute}"`)
    }

    // Extract the relative path after the segment and remove the leading slash
    const relativeParts = parts.slice(segmentIndex + 1)
    return relativeParts.join("/")
}
