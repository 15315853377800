import { Box, Flex, TableColumnHeaderProps, Text, Th } from "@chakra-ui/react"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { MdChevronLeft, MdOutlineUnfoldMore } from "react-icons/md"

export type Props = {
    onClick: () => void
    isSelected: boolean
    isAsc: boolean
    isDesc: boolean
    text: string
} & TableColumnHeaderProps

export const SORTING_ICON_SIZE = 24
export const TH_SORTING_COLUMN_GAP = 8

export default function ThSorting({ text, onClick, isSelected, isAsc, isDesc, ...rest }: Props) {
    const colorConverter = useColorFormatConverter()

    return (
        <Th {...rest}>
            <Text
                mr={TH_SORTING_COLUMN_GAP + "px"}
                whiteSpace={"normal"}
                display="inline-block"
                color="grey.light.70"
                verticalAlign={"middle"}
                textAlign="right"
            >
                {text}
            </Text>
            <Box
                data-test-id={`table-header-sorting-${text}`}
                onClick={onClick}
                height={SORTING_ICON_SIZE + "px"}
                width={SORTING_ICON_SIZE + "px"}
                borderRadius="4px"
                display="inline-block"
                verticalAlign={"middle"}
                pos={"relative"}
                cursor="pointer"
                _hover={{ bgColor: isSelected ? "bluePurple.30" : "bluePurple.10" }}
                _active={{ bgColor: "bluePurple.20" }}
                bgColor={isSelected ? "bluePurple.10" : "transparent"}
            >
                <Box pos={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
                    {isAsc || isDesc ? (
                        <Box style={{ transform: `rotate(${isAsc ? "90" : "270"}deg)` }}>
                            <MdChevronLeft size={18} color={colorConverter("bluePurple.80")} />
                        </Box>
                    ) : (
                        <MdOutlineUnfoldMore size={18} color={colorConverter("grey.light.80")} />
                    )}
                </Box>
            </Box>
        </Th>
    )
}
