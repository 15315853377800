import { Box, Text, Flex, VStack, useBreakpointValue, Skeleton, SkeletonText } from "@chakra-ui/react"
import { useGetUSWireDepositDetailsQuery } from "@redux/fund/apiSlice"
import { useTranslation } from "react-i18next"

export const USWireInstructions: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { data: fundBankDetails, isLoading } = useGetUSWireDepositDetailsQuery(undefined)

    const { beneficiary, bank, transit_number, account_number, swift, memo, intermediary_bank, institution_number } =
        fundBankDetails || {}

    const isMini = useBreakpointValue({ base: true, sm: false })

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithWire", { ns: "transfer" })}</Text>
            <Text textStyle={"ManropeSemiboldBody"} mt={7}>
                {t("fund.useFollowingInfoTransfer", { ns: "transfer" })}
            </Text>
            <Text textStyle={"ManropeRegularBodySmall"} mt={2}>
                {t("fund.currencyFundsOnly", { ns: "transfer", currency: "USD" })}
            </Text>
            <Flex my={6} flexDirection={isMini ? "column" : "row"}>
                <SkeletonText
                    isLoaded={!isLoading}
                    padding={4}
                    borderWidth={1}
                    borderRadius={"md"}
                    borderColor={"grey.light.10"}
                    display={"flex"}
                    flex={1}
                    noOfLines={4}
                    spacing={2}
                    skeletonHeight={"16px"}
                    flexDirection={"column"}
                    mr={isMini ? 0 : 3}
                >
                    <Text textStyle={"ManropeSemiboldBody"} mb={2}>
                        {t("fund.beneficiaryBank", { ns: "transfer" })} :
                    </Text>
                    <Text textStyle={"InterRegularBodySmall"}>{bank}</Text>
                </SkeletonText>
                <SkeletonText
                    isLoaded={!isLoading}
                    padding={4}
                    borderWidth={1}
                    borderRadius={"md"}
                    borderColor={"grey.light.10"}
                    display={"flex"}
                    flex={1}
                    noOfLines={4}
                    spacing={2}
                    skeletonHeight={"16px"}
                    flexDirection={"column"}
                    mr={isMini ? 0 : 3}
                >
                    <Text textStyle={"ManropeSemiboldBody"} mb={2}>
                        {t("fund.recipient", { ns: "transfer" })} :
                    </Text>
                    <Text textStyle={"InterRegularBodySmall"}>{beneficiary}</Text>
                </SkeletonText>
            </Flex>
            <Box pb={4} borderBottomWidth={1} borderBottomColor={"light.grey.10"}>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.routingNo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{transit_number}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.accountNo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{account_number}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.swiftBic", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{swift}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.recipientInst", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{memo}</Text>
                </Flex>
            </Box>
            <Box my={4}>
                <Text textStyle={"InterRegularBody"}>{t("fund.intlBankWire", { ns: "transfer" })}</Text>
                <Box
                    mt={3}
                    p={4}
                    borderRadius={"md"}
                    backgroundColor={"grey.light.2"}
                    borderWidth={1}
                    borderColor={"grey.light.10"}
                >
                    <Flex justifyContent={"space-between"}>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>
                            {t("fund.intermediaryBank", { ns: "transfer" })} :
                        </Text>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>{intermediary_bank?.bank_name}</Text>
                    </Flex>
                    <Flex justifyContent={"space-between"} mt={2}>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>
                            {t("fund.intermediarySwift", { ns: "transfer" })} :
                        </Text>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>{intermediary_bank?.swift_code}</Text>
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}
