import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import Select from "@/components/ui/select"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { Option } from "@components/ui/types"
import { SubScreenProps } from "../useOnboarding"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { useUpdateAccountIdentityMutation } from "@redux/account/apiSlice"
import { useLazyGetOccupationOptionsQuery } from "@redux/options/apiSlice"
import { Occuptation } from "@redux/options/types"
import useGenericToast from "@hooks/useGenericToast"

type FormData = {
    occupation: Option<string>
}

export default function Occupation({ submitSubForm, isLoading, userObj }: SubScreenProps) {
    const { handleSubmit, control, setValue } = useForm<FormData>()
    const { t } = useTranslation(["onboarding", "common"])
    const { serverErrorToast } = useGenericToast()
    const [updateAccountIdentity] = useUpdateAccountIdentityMutation()
    const [getOccupationOptions] = useLazyGetOccupationOptionsQuery()

    const [occupationOptions, setOccupationOptions] = React.useState<Option<string>[]>([])

    React.useEffect(() => {
        ;(async () => {
            await getOccupationOptions({ country: userObj.country, region: userObj.region })
                .unwrap()
                .then((res: Record<string, Occuptation>) => {
                    const arr: Option<string>[] = []
                    for (const key in res) {
                        if (key !== "success") {
                            const value = res[key].code ?? res[key].noc
                            const label = res[key].occupation ?? res[key].title
                            arr.push({ value, label })
                        }
                    }
                    setOccupationOptions(arr)
                    setDefaultValue(arr)
                })
                .catch(serverErrorToast)
        })()
    }, [userObj.country, userObj.region])

    function setDefaultValue(arr: Option<string>[]) {
        const userOcc = userObj.identity.occupation
        if (!userOcc) return

        const _option = arr.find((occ) => userOcc === occ.label)
        if (_option) {
            setValue("occupation", { value: _option.value, label: _option.label })
        }
    }

    async function onSubmit(values: FormData) {
        const { label, value } = values.occupation
        await submitSubForm(
            updateAccountIdentity,
            {
                occupation_title: label,
                occupation_type: value,
            },
            () => {
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.Occupation, REGISTRATION_STEP.OCCUPATION)
            }
        )
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("occupation.header")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Select
                    name="occupation"
                    control={control}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                    label={t("occupation.occupation")}
                    placeholder={occupationOptions.length ? undefined : `${t("loading", { ns: "common" })}...`}
                    disabled={!occupationOptions.length}
                    options={occupationOptions}
                    size="lg"
                />
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="2.5rem"
                    w="full"
                    isLoading={isLoading}
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
