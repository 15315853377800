import { Flex, Text } from "@chakra-ui/react"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import CardCheckbox from "@components/ui/checkbox/cardCheckbox"
import { CardType, NPayRegContext } from "../nPayRegistrationContext"

export default function RegisterMenu() {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })

    const { currentCardType, setCurrentCardType } = NPayRegContext.useContainer()

    const options = useMemo(() => {
        return [
            {
                label: t("virtualCardOnly"),
                value: "virtual",
                title: t("availableToUse"),
                text: t("canBeUsedForOnlinePurchase"),
            },
            {
                label: t("physicalCardOnly"),
                value: "physical",
                title: t("deliveredToNetcoinsAddress"),
                text: t("canBeUsedForOnlinePurchase"),
            },
            {
                label: t("virtualPlusPhysical"),
                value: "all",
                title: t("virtualCardWillBeDelivered"),
            },
        ]
    }, [])

    return (
        <Flex flexDir="column" gap={6}>
            <Flex flexDir="column">
                <Text textStyle="ManropeSemiboldBody">{t("selectCard")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("eachAccount")}</Text>
            </Flex>

            <Flex flexDir="column" gap={3}>
                {options.map(({ label, value, text, title }) => (
                    <CardCheckbox
                        key={value}
                        checked={currentCardType === value}
                        label={label}
                        onPress={() => setCurrentCardType(value as CardType)}
                        primaryText={title}
                        secondaryText={text}
                    />
                ))}
            </Flex>
        </Flex>
    )
}
