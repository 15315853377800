import { Option } from "@components/ui/types"
import { API_PATH } from "./const"
import { splitApi } from "@redux/splitApi"
import { Occuptation } from "./types"

export const optionsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getOccupationOptions: builder.query<Record<string, Occuptation>, { country: string; region: string }>({
            query: ({ country, region }) => `${API_PATH.occupations}?country=${country}&state=${region}`,
        }),
        getSurveyDetailsOptions: builder.query<Record<string, { source: string }>, { country: string; region: string }>(
            {
                query: ({ country, region }) =>
                    `${API_PATH.onboarding_survey_details}?country=${country}&state=${region}`,
            }
        ),
    }),
})

export const { useLazyGetOccupationOptionsQuery, useLazyGetSurveyDetailsOptionsQuery } = optionsApi
