import React from "react"
import { Box, Text } from "@chakra-ui/react"
import PlainModal from "@components/ui/modals/plain"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"

type Props = {
    isOpen: boolean
    toggleOpen: () => void
    onClick: () => void
    cancelRef: React.RefObject<HTMLButtonElement>
}

export default function DeleteBankModal({ isOpen, toggleOpen, onClick, cancelRef }: Props) {
    const { t } = useTranslation(["settings", "common"])

    return (
        <PlainModal
            name="deleteBank"
            title={t("areYouSure", { ns: "common" })}
            isOpen={isOpen}
            cancelRef={cancelRef}
            onClose={toggleOpen}
            buttons={[
                {
                    variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    children: t("savedWalletAddress.deleteButton"),
                    onClick,
                },
            ]}
            showErrIcon
        >
            <Text textStyle="InterRegularBody">{t("savedWalletAddress.deleteConfirm")}</Text>
        </PlainModal>
    )
}
