import { createSelector } from "@reduxjs/toolkit"
import { API_PATH } from "./const"
import { AssetDetailsFull, AssetDetailsParams, MarketDetails, MarketDetailsParams } from "./types"
import { splitApi } from "@redux/splitApi"

export const assetDetailsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssetsDetails: builder.query<Record<string, AssetDetailsFull>, AssetDetailsParams | undefined>({
            query: (params) => ({
                url: API_PATH.ASSETS_DETAILS,
                params,
            }),
        }),
        getAssetMarketData: builder.query<MarketDetails, MarketDetailsParams>({
            query: (params) => ({
                url: API_PATH.ASSETS_DETAILS,
                params,
            }),
            keepUnusedDataFor: 0, //This API provides too many data points to save in the store
        }),
    }),
})

export const { useGetAssetsDetailsQuery, useLazyGetAssetMarketDataQuery } = assetDetailsApi

export const { getAssetsDetails } = assetDetailsApi.endpoints
