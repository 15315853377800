import { useAppSelector } from "@/store/hooks"
import { TCurrencyType } from "@/types"
import { imageMap } from "@assets/svgs/coins"
import { RadioSelectOption } from "@components/ui/radio/radioSelect"
import useRegion from "@hooks/useRegion"
import { useCreateWalletMutation, useGetWalletsQuery } from "@redux/account/apiSlice"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import { useGetLimitsQuery } from "@redux/limits/apiSlice"
import {
    useGetBoundariesQuery,
    useGetFeesQuery,
    useLazyValidateAddressQuery,
    usePostInteracMutation,
    useWithdrawCryptoMutation,
    useWithdrawWireCAMutation,
    useWithdrawWireUSMutation,
} from "@redux/withdraw/apiSlice"
import { formatNumberFixedPrecision, removeCommasFromString } from "@util/numericalFormatting"
import { createContainer } from "@util/UnstatedContext"
import Decimal from "decimal.js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { WithdrawType } from "."

type InitialState = {
    withdrawType?: WithdrawType
}

export type AddNewBankFormData = {
    type: { value: "domestic" | "international" | "canadian"; label: string }
    account: string
    nickname?: string
    bank_name: string
    beneficiary: string
    routing?: string
    swift?: string
    instructions?: string
    institution?: string
    transit?: string
    bank_address: string
    bank_country: { value: string; label: string }
    bank_province: { value: string; label: string }
    bank_zip_code: string
    bank_city: string
}

export type AddNewBankFormDataExtra = {
    extra_type?: { value: "intermediary" | "correspondent"; label: string }
    extra_name?: string
    extra_account?: string
    extra_swift?: string
}

export type AddWalletFormData = {
    label: string
    address: string
    memo?: string
    note?: string
}

const initialCAWireDisclaimers = {
    disclaimer1: false,
    disclaimer2: false,
    disclaimer3: false,
    disclaimer4: false,
}
const initialUSWireDisclaimers = {
    disclaimer1: false,
    disclaimer2: false,
    disclaimer3: false,
}

const useDrawerWithdrawContext = ({ withdrawType }: InitialState) => {
    const [errorMessage, setErrorMessage] = useState<{ textField?: string; button?: string }>({
        textField: "",
        button: "",
    })

    const { t } = useTranslation(["transfer", "twoFa"])
    const { isCAUser, currency } = useRegion()
    const [view, setView] = useState<number>(0)
    const [currentWithdrawType, setCurrentWithdrawType] = useState<WithdrawType | undefined>(withdrawType)
    const [selectedCoin, setSelectedCoin] = useState<RadioSelectOption | null>(null)
    const [selectedBank, setSelectedBank] = useState<
        (AddNewBankFormData & AddNewBankFormDataExtra & { bank_id?: string }) | undefined
    >(undefined)
    const [tfaCode, setTFACode] = useState("")
    const [selectedWalletId, setSelectedWalletId] = useState<number | undefined>(undefined)
    const [previewConfirm, setPreviewConfirm] = useState<boolean>(false)
    const [skipCheckAddr, setSkipCheckAddr] = useState<boolean>(false)
    const [showSkipCheckAddr, setShowSkipCheckAddr] = useState<boolean>(false)
    const [caWireDisclaimers, setCAWireDisclaimers] = useState(initialCAWireDisclaimers)
    const [usWireDisclaimers, setUSWireDisclaimers] = useState(initialUSWireDisclaimers)
    const accountDetails = useAppSelector(selectAccountDetails)
    const walletForm = useForm<AddWalletFormData>()

    const {
        control,
        watch,
        reset: resetAddNewBankForm,
        handleSubmit,
        trigger: triggerAddBankForm,
        getValues: getAddBankFormValues,
        setValue: setAddBankFormValue,
    } = useForm<AddNewBankFormData>({
        defaultValues: { type: isCAUser ? { value: "canadian", label: t("withdraw.wire.canadian") } : undefined },
    })
    const {
        control: controlExtra,
        watch: watchExtra,
        reset: resetAddNewBankFormExtra,
        handleSubmit: handleSubmitExtra,
        trigger: triggerAddBankFormExtra,
        getValues: getAddBankFormValuesExtra,
    } = useForm<AddNewBankFormDataExtra>({
        defaultValues: {
            extra_type: { value: "intermediary", label: t("withdraw.wire.intermediary") },
        },
    })
    const [withdrawAmount, setWithdrawAmount] = useState<string | undefined>(undefined)

    const { data: assetDetailsData, isLoading: isLoadingAssets } = useGetAssetsDetailsQuery(undefined)
    const { data: balances, isLoading: isLoadingBalances } = useGetBalancesQuery(undefined)
    const { data: fees, isLoading: isLoadingFees } = useGetFeesQuery(undefined)
    const { data: boundaries, isLoading: isLoadingBoundaries } = useGetBoundariesQuery(undefined)
    const { data: wallets, isLoading: isLoadingWallets } = useGetWalletsQuery()
    const { data: limits, isFetching: isLoadingLimits } = useGetLimitsQuery({
        currency: accountDetails?.currency || "CAD",
    })

    const [createWalletMutation, { isLoading: isCreateWalletLoading }] = useCreateWalletMutation()
    const [validateAddressQuery, { isFetching: isValidateAddressLoading }] = useLazyValidateAddressQuery()
    const [withdrawCryptoMutation, { isLoading: isWithdrawCryptoLoading }] = useWithdrawCryptoMutation()
    const [withdrawWireCAMutation, { isLoading: isWithdrawWireCaLoading, data: wireCaResponse }] =
        useWithdrawWireCAMutation()
    const [withdrawWireUSMutation, { isLoading: isWithdrawWireUsLoading, data: wireUsResponse }] =
        useWithdrawWireUSMutation()
    const [postWithdrawInterac, { isLoading: isInteracLoading }] = usePostInteracMutation()

    useEffect(() => {
        // Reset the withdraw coin data when the coin changes
        setWithdrawAmount(undefined)
        resetErrors()
        setTFACode("")
        setSelectedWalletId(undefined)
        setSkipCheckAddr(false)
        setShowSkipCheckAddr(false)
        walletForm.reset()
    }, [selectedCoin])

    const assetDetailsAsArray = useMemo(() => Object.values(assetDetailsData || {}), [assetDetailsData])

    const filteredAssets = useMemo(
        () => assetDetailsAsArray.filter((asset) => asset.restrictions?.deposit === true),
        [assetDetailsAsArray]
    )

    const selectedAssetDetails = useMemo(() => {
        if (!selectedCoin || !assetDetailsData) {
            return undefined
        }
        return assetDetailsData[selectedCoin.symbol]
    }, [selectedCoin, assetDetailsData])

    const coinWallets = useMemo(() => {
        if (!selectedAssetDetails || !wallets || !wallets.data) {
            return []
        }
        return wallets.data.filter((wallet) => wallet.coin.toUpperCase() === selectedAssetDetails.symbol.toUpperCase())
    }, [wallets, selectedAssetDetails])

    const cryptoFees = useMemo(() => {
        if (!selectedAssetDetails || !fees) {
            return formatNumberFixedPrecision("0", 8)
        }
        return formatNumberFixedPrecision(
            fees[selectedAssetDetails.symbol.toUpperCase()] as string,
            Number(selectedAssetDetails.precision)
        )
    }, [selectedAssetDetails, fees])

    const assetBalance = useMemo(() => {
        return parseFloat(balances?.balances?.[selectedAssetDetails?.symbol ?? "BTC"] ?? "0")
    }, [balances, selectedAssetDetails])

    const cadBalance = useMemo(() => {
        return parseFloat(balances?.withdrawable?.["CAD"] ?? "0")
    }, [balances])

    const withdrawableBalance = useMemo(() => {
        return parseFloat(balances?.withdrawable?.[accountDetails?.currency ?? "CAD"] ?? "0")
    }, [balances, selectedAssetDetails])

    const minWithdrawal = useMemo(() => {
        if (!selectedAssetDetails || !boundaries) {
            return formatNumberFixedPrecision("0", 8)
        }
        return formatNumberFixedPrecision(
            boundaries[selectedAssetDetails.symbol.toUpperCase()]?.min_withdrawal as string,
            Number(selectedAssetDetails.precision)
        )
    }, [boundaries, selectedAssetDetails])

    const getNetWithdrawCryptoAmount = useCallback(
        (withdrawAmount: string) => {
            if (!selectedAssetDetails || !fees || !withdrawAmount) {
                return formatNumberFixedPrecision("0", 8)
            }

            const cryptoFees = new Decimal(fees ? Number(fees[selectedAssetDetails.symbol.toUpperCase()]) : 0)
            const inputAmount = new Decimal(removeCommasFromString(withdrawAmount))
            const amountResult = inputAmount.minus(cryptoFees)

            if (amountResult.isNegative() || amountResult.isZero()) {
                return formatNumberFixedPrecision("0", Number(selectedAssetDetails.precision))
            }

            return formatNumberFixedPrecision(amountResult.toString(), Number(selectedAssetDetails.precision))
        },
        [selectedAssetDetails, fees]
    )

    const isBelowMinWithdrawal = useMemo(() => {
        if (!selectedAssetDetails || !withdrawAmount) {
            return true
        }
        return new Decimal(removeCommasFromString(withdrawAmount)).lessThan(new Decimal(minWithdrawal))
    }, [selectedAssetDetails, withdrawAmount, minWithdrawal, cryptoFees])

    const isAboveBalance = useMemo(() => {
        if (!selectedAssetDetails || !withdrawAmount) {
            return true
        }
        return new Decimal(removeCommasFromString(withdrawAmount)).greaterThan(new Decimal(assetBalance))
    }, [selectedAssetDetails, withdrawAmount, assetBalance])

    const coinRadioOptions = useMemo(
        () =>
            filteredAssets.map((asset) => ({
                name: asset.name,
                symbol: asset.symbol,
                imageUrl: imageMap[asset.symbol.toLowerCase() as keyof typeof imageMap] || imageMap["btc"],
            })),
        [filteredAssets]
    )

    const resetErrors = () => {
        setErrorMessage({ textField: "", button: "" })
    }

    const resetState = () => {
        resetAddNewBankForm()
        resetAddNewBankFormExtra()
        resetErrors()
        setSelectedCoin(null)
        setSelectedBank(undefined)
        setTFACode("")
        setWithdrawAmount(undefined)
        setSelectedWalletId(undefined)
        setPreviewConfirm(false)
        setSkipCheckAddr(false)
        setShowSkipCheckAddr(false)
        setCAWireDisclaimers(initialCAWireDisclaimers)
        setUSWireDisclaimers(initialUSWireDisclaimers)
        walletForm.reset()
    }

    const isButtonLoading = useMemo(
        () =>
            isCreateWalletLoading ||
            isValidateAddressLoading ||
            isWithdrawCryptoLoading ||
            isWithdrawWireCaLoading ||
            isWithdrawWireUsLoading ||
            isInteracLoading,
        [
            isCreateWalletLoading,
            isValidateAddressLoading,
            isWithdrawCryptoLoading,
            isWithdrawWireCaLoading,
            isWithdrawWireUsLoading,
            isInteracLoading,
        ]
    )

    const createWallet = async () => {
        const submitCreateWalletForm: () => Promise<AddWalletFormData> = () => {
            return new Promise((resolve, reject) => {
                walletForm
                    .handleSubmit((values) => {
                        resolve(values)
                    })()
                    .catch(reject)
            })
        }
        if (!selectedAssetDetails) {
            throw new Error("No selected asset details")
        }
        const { address, label, memo } = await submitCreateWalletForm()

        try {
            if (!skipCheckAddr) {
                const response = await validateAddressQuery({
                    address,
                    currency: selectedAssetDetails?.symbol.toUpperCase(),
                }).unwrap()
                if (!response.data.isValid) {
                    setShowSkipCheckAddr(true)
                    throw new Error("Wallet not validated")
                }
            }
        } catch (e: any) {
            setShowSkipCheckAddr(true)
            throw new Error("Wallet not validated")
        }
        const createWalletResponse = await createWalletMutation({
            coin: selectedAssetDetails.symbol,
            label,
            address,
            memo,
            override: true,
        }).unwrap()
        setSelectedWalletId(createWalletResponse.wallet.id)
    }

    const selectedWalletDetails = useMemo(() => {
        if (!selectedWalletId || !coinWallets) {
            return undefined
        }
        return coinWallets.find((wallet) => wallet.id === selectedWalletId)
    }, [selectedWalletId, coinWallets])

    const withdrawCrypto = async (code: string) => {
        const formValues = walletForm.getValues()
        if (!selectedAssetDetails || !selectedWalletDetails || !withdrawAmount || !code || !formValues) {
            throw new Error("Missing required fields")
        }
        await withdrawCryptoMutation({
            asset: selectedAssetDetails.symbol,
            quantity: withdrawAmount,
            address: selectedWalletDetails.address,
            notes: formValues.note || "",
            memo: selectedWalletDetails.memo || "",
            code: code,
        }).unwrap()
    }

    const netFiatWithdrawAmount = useMemo(() => {
        if (!withdrawAmount || !limits) {
            return 0
        }
        const withdrawNum = Number(removeCommasFromString(withdrawAmount ?? ""))
        return withdrawNum - Number(limits?.wire.withdraw.fee ?? 0)
    }, [withdrawAmount, selectedAssetDetails, cryptoFees])

    const setBankFromForm = (formValues: AddNewBankFormData & Partial<AddNewBankFormDataExtra>) => {
        if (!accountDetails) {
            return
        }

        setSelectedBank({
            ...formValues,
        })
    }

    const withdrawWire = async (code: string) => {
        if (!selectedBank || !withdrawAmount || !code) {
            throw new Error("Missing required fields")
        }
        const amount = removeCommasFromString(withdrawAmount)
        if (isCAUser) {
            const regionData = selectedBank?.bank_province?.value.split("-")
            const bankRegion = regionData && regionData.length > 1 ? regionData[1] : ""

            if (
                !selectedBank.bank_country ||
                !selectedBank.bank_address ||
                !selectedBank.bank_city ||
                !selectedBank.bank_zip_code
            ) {
                return false
            }
            await withdrawWireCAMutation({
                amount: amount,
                currency: currency || "CAD",
                bank_country: selectedBank.bank_country?.value,
                bank_id: selectedBank.bank_id,
                account_number: selectedBank.account,
                bank_name: selectedBank.bank_name,
                bank_address: selectedBank.bank_address,
                bank_city: selectedBank.bank_city,
                bank_zip_code: selectedBank.bank_zip_code,
                institution_number: selectedBank.institution,
                transit_number: selectedBank.transit,
                swift_code: selectedBank.swift,
                routing_number: selectedBank.routing,
                bank_province: bankRegion,
                nickname: selectedBank.nickname,
                code: code,
            }).unwrap()
        } else {
            if (!selectedBank.account || !selectedBank.swift) {
                return false
            }
            await withdrawWireUSMutation({
                currency: "USD",
                amount: amount,
                account_id: selectedBank.bank_id ? selectedBank.bank_id.toString() : undefined,
                bank_name: selectedBank.bank_name,
                account_number: selectedBank.account,
                routing_number: selectedBank.routing,
                swift_code: selectedBank.swift,
                account_type: selectedBank.type.value,
                account_name: selectedBank.nickname,
                tfa_code: code,
                sub_account_type: selectedBank.extra_type?.value,
                sub_account_bank_name: selectedBank.extra_name,
                sub_account_bank_code_type: selectedBank.extra_type ? "swift" : undefined,
                sub_account_bank_code: selectedBank.extra_swift,
                sub_account_number: selectedBank.extra_account,
                recipient_instructions: selectedBank.instructions,
            }).unwrap()
        }
    }

    const executeInteracWithdraw = async (code: string) => {
        const withdrawNum = Number(removeCommasFromString(withdrawAmount ?? "0"))
        try {
            const res = await postWithdrawInterac({
                amount: withdrawNum.toString(),
                currency: accountDetails?.currency.toLowerCase() as TCurrencyType,
                code,
            })

            if (res && "error" in res && res.error && "data" in res.error) {
                const errorData = res.error.data as { message: string; error: boolean }
                setErrorMessage({ button: errorData.message })
                throw new Error(errorData.message)
            }
        } catch (error: any) {
            if (
                error?.data?.message === "2FA code invalid." ||
                error?.data?.message === "Withdraw code not found or incorrect."
            ) {
                setErrorMessage({ button: t("withdraw.errors.invalidTFA") })
                throw new Error("Invalid TFA code")
            }
            setErrorMessage({ button: t("common:error.message") })
            throw new Error("Error")
        }
    }

    const executeCryptoWithdraw = async (code: string) => {
        try {
            await withdrawCrypto(code)
        } catch (error: any) {
            if (error?.data?.message === "Withdraw code not found or incorrect.") {
                setErrorMessage({ button: t("withdraw.errors.invalidTFA") })
                throw new Error("Invalid TFA code")
            }
            setErrorMessage({ button: t("common:error.message") })
            throw error
        }
    }

    const executeWireWithdraw = async (code: string) => {
        try {
            await withdrawWire(code)
        } catch (error: any) {
            if (
                error?.data?.message === "2FA code invalid." ||
                error?.data?.message === "Withdraw code not found or incorrect."
            ) {
                setErrorMessage({ button: t("withdraw.errors.invalidTFA") })
                throw new Error("Invalid TFA code")
            }
            setErrorMessage({ button: t("common:error.message") })
            throw error
        }
    }

    return {
        view,
        setView,

        selectedAssetDetails,
        selectedWalletDetails,
        coinRadioOptions,
        currentWithdrawType,
        setCurrentWithdrawType,
        withdrawCrypto,
        withdrawWire,

        selectedCoin,
        setSelectedCoin,

        withdrawAmount,
        setWithdrawAmount,

        selectedWalletId,
        setSelectedWalletId,

        previewConfirm,
        setPreviewConfirm,

        errorMessage,
        setErrorMessage,
        resetErrors,

        limits,
        assetBalance,
        cadBalance,
        withdrawableBalance,
        cryptoFees,
        minWithdrawal,
        isBelowMinWithdrawal,
        isAboveBalance,
        getNetWithdrawCryptoAmount,
        netFiatWithdrawAmount,

        walletForm,
        coinWallets,
        createWallet,

        skipCheckAddr,
        setSkipCheckAddr,
        showSkipCheckAddr,
        setShowSkipCheckAddr,

        isLoadingBalances,
        isLoadingBoundaries,
        isLoadingFees,
        isLoadingLimits,
        isLoadingWallets,

        isButtonLoading,

        selectedBank,
        setSelectedBank,

        tfaCode,
        setTFACode,

        controlAddBank: control,
        watchAddBank: watch,
        handleSubmitAddBank: handleSubmit,
        triggerAddBankForm,
        getAddBankFormValues,

        caWireDisclaimers,
        setCAWireDisclaimers,
        usWireDisclaimers,
        setUSWireDisclaimers,

        setBankFromForm,
        resetState,
        setAddBankFormValue,
        controlAddBankExtra: controlExtra,
        watchAddBankExtra: watchExtra,
        handleSubmitAddBankExtra: handleSubmitExtra,
        triggerAddBankFormExtra,
        getAddBankFormValuesExtra,
        wireResponse: isCAUser ? wireCaResponse : wireUsResponse,

        executeInteracWithdraw,
        executeCryptoWithdraw,
        executeWireWithdraw,
    }
}

export const DrawerWithdrawContext = createContainer(useDrawerWithdrawContext)
