import { REG_SCREENS } from "@screens/onboarding/types"
import { useEffect, useRef } from "react"
import { establishBusinessUpdatingSteps, establishUpdatingSteps } from "util/verificationSteps"
import useOnboarding from "@screens/onboarding/useOnboarding"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { IAccountDetails } from "@redux/account/types"
import { OnboardingScreenComponent } from "@screens/onboarding"
import { useLazyGetAccountDetailsQuery } from "@redux/account/apiSlice"

export default function UpdateDetails() {
    const { navigate } = useRestrictedNavigation()
    const [getAccountDetails, { data }] = useLazyGetAccountDetailsQuery()

    const {
        isBackVisible,
        progressValue,
        renderedScreen,
        currentPhase,
        setPhases,
        phases,
        setCurrentPhase,
        doSurveyData,
        countSurveyScreens,
        renderBlockedFromOnboardingScreen,
    } = useOnboarding()

    /* 
        Due to the useAppSelector(selectAccountDetails) in the useOnboarding hook, the cache data from the lazy query will continue to update.
        Having this ref here allows us to establish a baseline only once and not update
    */
    const baselineUserObj = useRef<IAccountDetails>()

    useEffect(() => {
        if (data && !baselineUserObj.current) {
            baselineUserObj.current = data
            initOnboardingData(data)
        } else {
            getAccountDetails()
        }
    }, [data])

    async function initOnboardingData(account: IAccountDetails) {
        if (!account.country || !account.region) {
            const countryScreen =
                account.type === "individual" ? REG_SCREENS.WhereReside : REG_SCREENS.WhereIncorporated
            setPhases([countryScreen])
            setCurrentPhase({ name: countryScreen, num: 0 })
            // Anything else
        } else {
            const _surveys = await doSurveyData(account)
            const surveyCount = countSurveyScreens(_surveys)
            const phases: REG_SCREENS[] =
                account.type === "individual"
                    ? establishUpdatingSteps(account, surveyCount)
                    : establishBusinessUpdatingSteps(account, surveyCount)
            setPhases(phases)
            setCurrentPhase({ name: phases[0], num: 0 })
        }
    }

    function goBack() {
        if (currentPhase.num === 0) {
            navigate(ROUTES.DASHBOARD)
        }

        // Due to the dynamic nature of the survey components, pressing back on any of those screens
        // will result in returning to the beginning of all surveys
        if (currentPhase.name === REG_SCREENS.Surveys) {
            setCurrentPhase({
                name: REG_SCREENS.SurveyIntro,
                num: phases.findIndex((phase) => phase === REG_SCREENS.SurveyIntro),
            })
            // Standard behaviour
        } else {
            setCurrentPhase((prev) => {
                if (typeof prev.num !== "number" || prev.num === 0) return prev
                return { name: phases[prev.num - 1], num: prev.num - 1 }
            })
        }
    }

    return (
        <OnboardingScreenComponent
            isBackVisible={isBackVisible}
            goBack={goBack}
            progressValue={progressValue}
            currentPhase={currentPhase}
            renderBlockedFromOnboardingScreen={renderBlockedFromOnboardingScreen}
            renderedScreen={renderedScreen}
        />
    )
}
