import { Box, Flex, Icon, Text, VStack } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import { MdOutlineVerifiedUser, MdVerifiedUser } from "react-icons/md"
import CopyComponent from "@components/ui/copyField"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"

export default function WithdrawInteracPreview() {
    const { withdrawAmount } = DrawerWithdrawContext.useContainer()
    const { t } = useTranslation(["transfer", "common"])
    const { data: accountData } = useGetAccountDetailsQuery()

    return (
        <Flex flex={1} flexDir="column">
            <Flex align="center" h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.interac.title")}
                </Text>
            </Flex>
            <Flex flexDir="column" rowGap="1.5rem">
                <Text textStyle="ManropeSemiboldBody" color="black">
                    {t("withdraw.interac.prevReview")}
                </Text>
                <Box>
                    <Text textStyle="InterRegularBody" color="black" mb="0.75rem">
                        {t("withdraw.interac.prevTo")}
                    </Text>
                    <Flex
                        align="center"
                        px="1rem"
                        w="full"
                        borderWidth="1px"
                        minH="48px"
                        borderColor="grey.light.10"
                        borderRadius="6px"
                    >
                        <Text textStyle="ManropeMediumBody" wordBreak={"break-word"}>
                            {accountData?.email}
                        </Text>
                    </Flex>
                </Box>
                <Flex align="center" justifyContent={"space-between"}>
                    <Text textStyle="ManropeSemiboldBody" color="black">
                        {t("withdraw.interac.howMuch")}:
                    </Text>
                    <Text textStyle="ManropeSemiboldBody" color="black" ml="0.75rem">
                        ${withdrawAmount} CAD
                    </Text>
                </Flex>
                <Box pt="1.5rem" borderTopWidth="1px" borderColor="grey.light.10">
                    <Flex
                        align="center"
                        justifyContent={"center"}
                        flexDir="column"
                        textAlign="center"
                        borderRadius="4px"
                        bgColor="blue.10"
                        py="1rem"
                    >
                        <Text textStyle="InterRegularBody">{t("withdraw.interac.prevFrom")}</Text>
                        <Text textStyle="InterBoldBody">support@gonetcoins.com</Text>
                    </Flex>
                </Box>
                <Box p="1.5rem" borderRadius="0.25rem" borderColor="grey.light.10" borderWidth="1px">
                    <Flex columnGap="0.75rem" mb="1.5rem">
                        <Flex
                            borderRadius={"md"}
                            alignSelf={"stretch"}
                            bg={"green.light.10"}
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                            minW={"72px"}
                            mr={3}
                        >
                            <Icon as={MdOutlineVerifiedUser} color="green.light.100" h="30px" w="30px" />
                        </Flex>
                        <Flex flexDir="column">
                            <Box>
                                <Text textStyle="ManropeSemiboldBody">{t("withdraw.interac.prevQuestion")}</Text>
                                <Text textStyle="InterRegularBodySmall">{t("withdraw.interac.prevQuestionBtm")}</Text>
                            </Box>
                        </Flex>
                    </Flex>
                    <Text textStyle="InterRegularBody" mb="0.75rem">
                        {t("withdraw.interac.prevAnswer")}
                    </Text>
                    <CopyComponent text={accountData?.features?.interac.secret_answer ?? ""} />
                </Box>
            </Flex>
        </Flex>
    )
}
