import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { useAcceptDisclaimerMutation, useGetDisclaimerQuery } from "@redux/account/apiSlice"
import { SubScreenProps } from "../useOnboarding"
import { REG_SCREENS, REGISTRATION_STEP } from "../types"

const name = "risk_statement"

export default function RiskStatement({ submitSubForm, isLoading, userObj }: SubScreenProps) {
    const { t } = useTranslation("onboarding", { keyPrefix: "riskStatement" })
    const [acceptDisclaimer] = useAcceptDisclaimerMutation()
    const contentKey = userObj.verification?.disclaimers?.[name]?.content_key ?? "risk_statement_en_ca"
    const { data: disclaimerContent } = useGetDisclaimerQuery(contentKey)

    function handleAcknowledge() {
        submitSubForm(
            acceptDisclaimer,
            {
                content_key: contentKey,
            },
            () => {
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.RiskStatement, REGISTRATION_STEP.RISK_STATEMENT)
            }
        )
    }

    return (
        <Box>
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("header")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                {t("title")}
            </Text>
            <Flex
                rowGap="0.75rem"
                overflowY={"auto"}
                height="500px"
                borderRadius={"6px"}
                borderWidth={"1px"}
                borderColor={"gray.20"}
                py="1.25rem"
                px="1.75rem"
            >
                {disclaimerContent ? (
                    <div
                        dangerouslySetInnerHTML={{ __html: disclaimerContent }}
                        className="disclaimers-text"
                        style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            listStylePosition: "outside",
                        }}
                    />
                ) : (
                    <Flex flex={1} align="center" justify="center">
                        <Spinner />
                    </Flex>
                )}
            </Flex>
            <StandardButton
                data-testid="continue-button"
                onClick={handleAcknowledge}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="3rem"
                w="full"
                isLoading={isLoading}
            >
                {t("acknowledge")}
            </StandardButton>
        </Box>
    )
}
