export enum REG_SCREENS {
    HowDidYouHear = "HowDidYouHear",
    YourName = "YourName",
    WhenBorn = "WhenBorn",
    IsCanadian = "IsCanadian",
    WhatPhone = "WhatPhone",
    ConfirmPin = "ConfirmPin",
    Occupation = "Occupation",
    SurveyIntro = "SurveyIntro",
    RiskStatement = "RiskStatement",
    OverageThirdPartyDisclaimer = "OverageThirdPartyDisclaimer",
    WhereReside = "WhereReside",
    WhereLive = "WhereLive",
    Surveys = "Surveys",
    AccountType = "AccountType",
    WhereIncorporated = "WhereIncorporated",
    BusinessName = "BusinessName",
    BusinessRole = "BusinessRole",
    WhereBusinessOffice = "WhereBusinessOffice",
    WhenIncorporated = "WhenIncorporated",
    IsMoneyService = "IsMoneyService",
    AboutBusiness = "AboutBusiness",
    BeInTouch = "BeInTouch",
    PendingApproval = "PendingApproval",
    SSN = "ssn",
    ZeroHashDisclaimer = "ZeroHashDisclaimer",
    TerrorismDisclaimer = "TerrorismDisclaimer",
    UpdateToS = "UpdateToS",
}

export enum LOGIN_SCREENS {
    Login = "Login",
    TwoFA = "TWoFA",
    ForgotPassword = "ForgotPassword",
    SignUp = "SignUp",
    EmailVerification = "RegisterEmailVerification",
}

// in order to maintain the same event names in amplitude, we need to map the web screen names to the mobile screen names
// TODO coordinate with mobile that these are all in sync
export const SCREEN_TO_MOBILE_EVENT_REGISTRY: Record<LOGIN_SCREENS | REG_SCREENS, string> = {
    [REG_SCREENS.HowDidYouHear]: "RegisterReferral",
    [REG_SCREENS.YourName]: "RegisterName",
    [REG_SCREENS.WhenBorn]: "RegisterDOB",
    [REG_SCREENS.IsCanadian]: "RegisterCanadaCitizenship",
    [REG_SCREENS.WhatPhone]: "RegisterPhoneNumber",
    [REG_SCREENS.ConfirmPin]: "RegisterPhoneNumberVerification",
    [REG_SCREENS.Occupation]: "RegisterOccupation",
    [REG_SCREENS.SurveyIntro]: "RegisterScamAlert",
    [REG_SCREENS.RiskStatement]: "RegisterRiskStatement",
    [REG_SCREENS.OverageThirdPartyDisclaimer]: "RegisterOverageThirdPartyDisclaimer",
    [REG_SCREENS.WhereReside]: "RegisterResidence",
    [REG_SCREENS.WhereLive]: "RegisterAddress",
    [REG_SCREENS.Surveys]: "SurveyScreens/Question",
    [REG_SCREENS.AccountType]: "RegisterAccountType",
    [REG_SCREENS.WhereIncorporated]: "RegisterIncorporation",
    [REG_SCREENS.BusinessName]: "RegisterBusinessName",
    [REG_SCREENS.BusinessRole]: "RegisterBusinessRole",
    [REG_SCREENS.WhereBusinessOffice]: "RegisterBusinessAddress",
    [REG_SCREENS.WhenIncorporated]: "RegisterIncorporationDate",
    [REG_SCREENS.IsMoneyService]: "RegisterMoneyService",
    [REG_SCREENS.AboutBusiness]: "RegisterBusiness",
    [REG_SCREENS.BeInTouch]: "RegisterBeInTouch",
    [REG_SCREENS.PendingApproval]: "PendingApproval",
    [REG_SCREENS.SSN]: "RegisterSSN",
    [REG_SCREENS.ZeroHashDisclaimer]: "RegisterZeroHashDisclaimer",
    [REG_SCREENS.TerrorismDisclaimer]: "RegisterTerrorismDisclaimer",
    [REG_SCREENS.UpdateToS]: "UpdateTOS",
    [LOGIN_SCREENS.Login]: "LoginScreens/Login",
    [LOGIN_SCREENS.TwoFA]: "LoginScreens/TFA",
    [LOGIN_SCREENS.ForgotPassword]: "LoginScreens/PasswordRecovery",
    [LOGIN_SCREENS.SignUp]: "LoginScreens/SignUp",
    [LOGIN_SCREENS.EmailVerification]: "RegisterEmailVerification",
}

export enum REGISTRATION_STEP {
    CREATE_ACCOUNT = "create_account",
    PHONE = "phone",
    PHONE_VERIFICATION = "phone_verification",
    SURVEY_INTRO = "survey_splash",
    SAFETY_DISCLAIMER = "disclaimer",
    OCCUPATION = "occupation",
    RESIDENCE_SELECTION = "residence_selection",
    DOB = "date_of_birth",
    VERIFY_EMAIL = "verify_email",
    REFERRAL = "referral",
    ACCOUNT_TYPE = "account_type",
    NAME = "name",
    CANADA_CITIZENSHIP = "canada_citizenship",
    ADDRESS = "address",
    RISK_STATEMENT = "risk_statement",
    RESEND_CODE = "resend_code",
    UNDER_18 = "under_18",
    BUSINESS_INVALID_STATE = "business_invalid_state",
    BUSINESS_MAILING_LIST = "business_mailing_list",
    BUSINESS_NAME = "business_name",
    BUSINESS_ROLE = "business_role",
    BUSINESS_IS_MONEY_SERVICE = "business_is_money_service",
    BUSINESS_ABOUT = "business_about",
    BUSINESS_ADDRESS = "business_address",
    BUSINESS_WHERE_INCORPORATED = "business_where_incorporated",
    SSN = "ssn",
    ZeroHashDisclaimer = "zerohash_disclaimer",
    OverageDisclaimer = "overage_disclaimer",
}

export enum AccountType {
    INDIVIDUAL = "individual",
    BUSINESS = "business",
}

export enum SSNTypes {
    DRIVERS_LICENSE = "DRIVING_LICENSE",
    PASSPORT = "PASSPORT",
    SSN = "SSN",
}
