import { splitApi } from "@redux/splitApi"
import { API_PATH } from "./const"
import { AccountDailyInsights, AccountInsights } from "./types"

export const insightsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDailyInsights: builder.query<AccountDailyInsights, undefined>({
            query: (params) => ({
                url: API_PATH.DAILY_INSIGHTS,
                method: "GET",
                params,
            }),
        }),
        getAccountInsights: builder.query<AccountInsights, undefined>({
            query: (params) => ({
                url: API_PATH.INSIGHTS,
                method: "GET",
                params,
            }),
        }),
    }),
})

export const { useGetAccountInsightsQuery, useGetDailyInsightsQuery } = insightsApi
