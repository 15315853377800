import CountriesJSON from "./json/countries.json"

export type TCurrencyType = "CAD" | "USD"
export type TCountryType = "US" | "CA" | "other" | "XX" | keyof typeof CountriesJSON
export enum EnumCurrency {
    CAD = "CAD",
    USD = "USD",
}

export enum CurrencyFullName {
    CAD = "Canadian Dollar",
    USD = "US Dollar",
}
