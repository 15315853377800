import { Box, Flex } from "@chakra-ui/react"
import { cloneElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"

type CarouselDotsProps = {
    activeIndex: number
    totalSlides: number
}

function CarouselDots({ activeIndex, totalSlides }: CarouselDotsProps) {
    const normalizedIndex = activeIndex % totalSlides

    return (
        <Flex
            position="absolute"
            bottom="4px"
            left="50%"
            transform="translateX(-50%)"
            justifyContent="center"
            zIndex="200"
            gap={1}
            py={2}
        >
            {Array.from({ length: totalSlides }).map((_, index) => (
                <Flex
                    key={index}
                    borderRadius="50%"
                    width="4px"
                    height="4px"
                    bg={normalizedIndex === index ? "bluePurple.10" : "bluePurple.50"}
                    transition="background 0.3s ease-in-out"
                />
            ))}
        </Flex>
    )
}

type Props = {
    components: ReactNode[]
    frequency: number
}

export default function Carousel({ components, frequency }: Props) {
    const displayedSlides = useMemo(() => [...components, components[0]], [components])
    const [activeIndex, setActiveIndex] = useState(0)
    const [isTransitioning, setIsTransitioning] = useState(false)
    const [isVisible, setIsVisible] = useState(true)

    const intervalRef = useRef<number | null>(null)
    const startX = useRef(0)
    const isDragging = useRef(false)

    const handleVisibilityChange = useCallback(() => {
        const visible = document.visibilityState === "visible"
        setIsVisible(visible)

        if (visible) {
            // Reset the carousel state when tab becomes visible again
            setIsTransitioning(false)
            resetTimer()
        } else if (intervalRef.current !== null) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [])

    const resetTimer = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }

        if (isVisible) {
            intervalRef.current = window.setInterval(() => {
                handleNext()
            }, frequency)
        }
    }, [frequency, isVisible])

    useEffect(() => {
        // Add visibility change listener
        document.addEventListener("visibilitychange", handleVisibilityChange)

        // Initial timer setup
        resetTimer()

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [resetTimer, handleVisibilityChange])

    const handleTransitionEnd = () => {
        if (activeIndex === components.length) {
            setIsTransitioning(false)
            setActiveIndex(0)
        } else {
            setIsTransitioning(false)
        }
    }

    const handleNext = useCallback(() => {
        if (isTransitioning || !isVisible) return

        setIsTransitioning(true)
        setActiveIndex((prev) => prev + 1)
        resetTimer()
    }, [isTransitioning, resetTimer, isVisible])

    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault()
        if (!isDragging.current) {
            handleNext()
        }
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        startX.current = e.touches[0].clientX
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        const deltaX = e.touches[0].clientX - startX.current

        if (Math.abs(deltaX) > 50) {
            handleNext()
        }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true
        startX.current = e.clientX
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return

        const deltaX = e.clientX - startX.current
        if (Math.abs(deltaX) > 50) {
            handleNext()
            isDragging.current = false
        }
    }

    const handleMouseUp = () => {
        isDragging.current = false
    }

    const handleLinkClick = (event: React.MouseEvent) => {
        event.stopPropagation()
    }

    if (components.length === 0) return null

    return (
        <Box width="100%" borderRadius="8px" position="relative" overflow="hidden" marginX="auto">
            <Flex
                transition={isTransitioning ? "transform 0.5s ease-in-out" : "none"}
                transform={`translateX(-${activeIndex * 100}%)`}
                w="full"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                cursor="grab"
                onTransitionEnd={handleTransitionEnd}
            >
                {displayedSlides.map((component, index) => (
                    <Flex
                        key={index}
                        flex="none"
                        width="100%"
                        opacity={1}
                        transition="opacity 0.5s ease-in-out"
                        onClick={handleClick}
                    >
                        {cloneElement(component as React.ReactElement, {
                            onClick: handleLinkClick,
                        })}
                    </Flex>
                ))}
            </Flex>

            <CarouselDots activeIndex={activeIndex} totalSlides={components.length} />
        </Box>
    )
}
