import { useAppSelector } from "@/store/hooks"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Flex, IconButton, Text } from "@chakra-ui/react"
import { SIDEBAR_WIDTH_LARGE } from "@components/sidebar"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import PlainModal from "@components/ui/modals/plain"
import { InternalUserStatus, useAssessUserStatus } from "@hooks/useAssessUserStatus"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { ROUTES } from "@routing/routes"
import { BreakPoints } from "@theme/breakpoints"
import { StorageKeys } from "@util/storage"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdInfoOutline, MdOutlineQueryBuilder } from "react-icons/md"
import { useNavigate } from "react-router-dom"

export const UserStateBanner = () => {
    const { t } = useTranslation(["app"])
    const { status } = useAssessUserStatus()
    const navigateReactRouter = useNavigate()
    const { supportEmail } = useSupport()
    const account = useAppSelector(selectAccountDetails)?.email
    const [isOpen, setIsOpen] = useState(false)
    const [showBanner, setShowBanner] = useState(false)
    const cancelRef = useRef<HTMLButtonElement>(null)
    const accountList = useMemo(
        () => localStorage.getItem(StorageKeys.SHOW_WELCOME_BANNER)?.split(",") || [],
        [showBanner]
    )

    //Only show the banner once for every user that logs in
    useEffect(() => {
        if (!account || !status) {
            return
        }
        if (status === InternalUserStatus.FULL_ACCESS) {
            if (accountList.includes(account)) {
                return
            }
            accountList.push(account)
            localStorage.setItem(StorageKeys.SHOW_WELCOME_BANNER, accountList.join(","))
            setShowBanner(true)
        }
    }, [status])

    const content = useMemo(() => {
        if (!status) {
            return {
                title: "",
                bannerColor: "",
                contentColor: "",
                buttonTitle: "",
                alertTitle: "",
                alertDescription: "",
                alertAction: "",
                iconBgColor: "",
            }
        }
        switch (status) {
            case InternalUserStatus.FULL_ACCESS:
                return {
                    title: t("dashboard.verification.fullAccess"),
                    bannerColor: "green.light.100",
                    contentColor: "white",
                    buttonTitle: "",
                    alertTitle: "",
                    alertDescription: "",
                    alertAction: "",
                    iconBgColor: "",
                }

            case InternalUserStatus.REQUIRES_UPDATE:
                return {
                    title: t("dashboard.verification.updateInformation"),
                    bannerColor: "yellow.light.100",
                    contentColor: "black",
                    iconBgColor: "yellow.light.10",
                    buttonTitle: t("dashboard.verification.updateInformationButton"),
                    alertTitle: t("dashboard.verification.updateInformationTitle"),
                    alertDescription: t("dashboard.verification.updateInformationDescription"),
                    alertAction: t("dashboard.verification.updateInformationAction"),
                }
            case InternalUserStatus.PENDING_REVIEW:
                return {
                    title: t("dashboard.verification.pendingReview"),
                    bannerColor: "yellow.light.100",
                    contentColor: "black",
                    iconBgColor: "yellow.light.10",
                    buttonTitle: t("dashboard.verification.pendingReviewButton"),
                    alertTitle: t("dashboard.verification.pendingReviewTitle"),
                    alertDescription: t("dashboard.verification.pendingReviewDescription", { email: supportEmail }),
                    alertAction: t("dashboard.verification.pendingReviewAction"),
                }
            case InternalUserStatus.RESTRICTED:
                return {
                    title: t("dashboard.verification.restrictedAccount"),
                    bannerColor: "red.light.100",
                    contentColor: "white",
                    iconBgColor: "red.light.10",
                    buttonTitle: t("dashboard.verification.restrictedAccountButton"),
                    alertTitle: t("dashboard.verification.restrictedAccountTitle"),
                    alertDescription: t("dashboard.verification.restrictedAccountDescription", { email: supportEmail }),
                    alertAction: t("dashboard.verification.restrictedAccountAction"),
                }
        }
    }, [status])

    const icon = useMemo(() => {
        if (!status) {
            return undefined
        }
        switch (status) {
            case InternalUserStatus.FULL_ACCESS:
                return undefined
            case InternalUserStatus.RESTRICTED:
                return MdInfoOutline
            case InternalUserStatus.REQUIRES_UPDATE:
                return MdInfoOutline
            case InternalUserStatus.PENDING_REVIEW:
                return MdOutlineQueryBuilder
            default:
                return undefined
        }
    }, [status])

    const onButtonClick = () => {
        switch (status) {
            case InternalUserStatus.FULL_ACCESS:
                setShowBanner(false)
                return
            default:
                setIsOpen(true)
        }
    }

    const onModalButtonClick = () => {
        switch (status) {
            case InternalUserStatus.REQUIRES_UPDATE:
                navigateReactRouter(ROUTES.UPDATE_DETAILS)
                return setIsOpen(false)
            case InternalUserStatus.PENDING_REVIEW:
            case InternalUserStatus.RESTRICTED:
                window.location.href = `mailto:${supportEmail}`
                return setIsOpen(false)
            default:
                setIsOpen(false)
        }
    }

    //Dont show a banner unless we have all information, don't delay loading of the page while we fetch data
    if (!status) {
        return null
    }

    return (
        <>
            {showBanner && (
                <Box pos="relative">
                    <Flex backgroundColor={content.bannerColor} w="full" alignItems="center" py="6px">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                            w="full"
                            maxW={`${parseFloat(BreakPoints["2xl"]) * 16 - SIDEBAR_WIDTH_LARGE}px`}
                            px={{ base: "1rem", lg: "1.5rem" }}
                            margin="0 auto"
                        >
                            <Flex flexDirection={"row"} alignItems={"center"}>
                                {icon && <ReactIcon color={content.contentColor} icon={icon} size={20} />}
                                <Text
                                    ml={icon && 2}
                                    textStyle={"ManropeSemiboldBodySmall"}
                                    color={content.contentColor}
                                >
                                    {content.title}
                                </Text>
                            </Flex>
                            <Flex>
                                {status === InternalUserStatus.FULL_ACCESS ? (
                                    <IconButton
                                        onClick={onButtonClick}
                                        variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_GHOST}
                                        color={content.contentColor}
                                        icon={<ReactIcon icon={MdClose} size={18} />}
                                        aria-label={"banner-close"}
                                    />
                                ) : (
                                    <StandardButton
                                        size={"sm"}
                                        variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                                        onClick={onButtonClick}
                                        children={content.buttonTitle}
                                    />
                                )}
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            )}
            <PlainModal
                name="userStateModal"
                isOpen={isOpen}
                title={content.alertTitle}
                cancelRef={cancelRef}
                onClose={() => setIsOpen(false)}
                icon={
                    icon && {
                        name: icon,
                        color: content.bannerColor,
                        bgColor: content.iconBgColor,
                    }
                }
                buttons={[
                    {
                        children: content.buttonTitle,
                        onClick: onModalButtonClick,
                        variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                    },
                ]}
            >
                <Text textStyle="InterRegularBody">{content.alertDescription}</Text>
            </PlainModal>
        </>
    )
}
