import { Box, Icon, Input, InputGroup, InputLeftAddon, InputRightAddon } from "@chakra-ui/react"
import { FIELD_ICON_SIZE, FIELD_SIZE } from "@components/ui/types"
import React, { useMemo } from "react"
import useColorFormatConverter from "theme/useColorFormatConverter"
import SEARCHBAR_VARIANTS, { ENUM_SEARCHBAR_VARIANTS } from "./types"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import ReactIcon from "@assets/svgs/ReactIconWrapper"

export type Props = {
    value?: string
    onChange: (_: string) => void
    variant?: ENUM_SEARCHBAR_VARIANTS
    placeholder?: string
    removeBorder?: boolean
    disabled?: boolean
    [rest: string]: any
}

export function Searchbar({
    value,
    onChange,
    variant = ENUM_SEARCHBAR_VARIANTS.PRIMARY,
    placeholder,
    disabled,
    removeBorder,
    ...rest
}: Props) {
    const colorConverter = useColorFormatConverter()

    const palette = useMemo(() => SEARCHBAR_VARIANTS[variant], [variant])

    return (
        <InputGroup
            _focusWithin={{
                borderColor: "blue.100",
                borderWidth: "2px",
            }}
            borderColor="grey.light.10"
            borderWidth={removeBorder ? 0 : "1px"}
            borderRadius="8px"
            alignItems={"center"}
            overflow={"hidden"}
            height={"40px"}
            {...rest}
        >
            <InputLeftAddon
                pointerEvents="none"
                borderWidth={0}
                bgColor={palette.normal.bg}
                paddingRight={"0 !important"}
                height={"100%"}
            >
                <ReactIcon icon={MdOutlineSearch} size={22} color="black" />
            </InputLeftAddon>
            <Input
                data-testid={`input-${name}`}
                height={"100%"}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                placeholder={placeholder ?? "Search"}
                _placeholder={{ color: palette?.normal.placeholder }}
                fontFamily="Manrope"
                fontSize={"14px"}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="150%"
                letterSpacing="0.15px"
                borderWidth={0}
                bgColor={palette.normal.bg}
                color={palette.normal.color}
                focusBorderColor="transparent"
                errorBorderColor="transparent"
                _autofill={{
                    WebkitTextFillColor: colorConverter(palette.normal.color),
                    WebkitBoxShadow: `inset 0 0 20px 20px ${
                        palette.normal.bg === "white" || palette.normal.bg === "black"
                            ? palette.normal.bg
                            : colorConverter(palette.normal.bg)
                    }`,
                }}
            />
            {!!value?.length && (
                <InputRightAddon
                    borderWidth={0}
                    bgColor={palette.normal.bg}
                    paddingLeft={"0 !important"}
                    onClick={() => onChange("")}
                    cursor="pointer"
                    height={"100%"}
                >
                    <ReactIcon icon={MdClose} size={22} color="black" />
                </InputRightAddon>
            )}
        </InputGroup>
    )
}

export default Searchbar
