import React from "react"
import { Box, Text } from "@chakra-ui/react"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { AccountType } from "@redux/account/types"
import { SubScreenProps } from "../useOnboarding"
import { useUpdateAccountIdentityMutation } from "@redux/account/apiSlice"
import { SurveyData } from "../surveys/types"
import { findLastIndexOf } from "@util/arrays"

type FormData = {
    day: number
    month: number
    year: number
}

type Props = {
    setPhases: React.Dispatch<React.SetStateAction<REG_SCREENS[]>>
    setSurveys: React.Dispatch<React.SetStateAction<{ name: string; data: SurveyData }[]>>
} & SubScreenProps

function formatDefaultValue(dob: string) {
    if (dob) {
        const _dob = dob.split("-")
        return {
            defaultValues: {
                day: parseInt(_dob[2]),
                month: parseInt(_dob[1]),
                year: parseInt(_dob[0]),
            },
        }
    }
    return undefined
}

function determineIfChild(year: number, month: number, day: number) {
    const today = new Date()
    const birthDate = new Date(year, month - 1, day)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age < 18
}

export function isSixty(birthday: string) {
    const currentDate = new Date()
    const birthDate = new Date(birthday)
    let age = currentDate.getFullYear() - birthDate.getFullYear()
    const monthDifference = currentDate.getMonth() - birthDate.getMonth()

    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--
    }

    return age >= 60
}

export default function WhenBorn({
    submitSubForm,
    userObj,
    isLoading,
    setIsBackVisible,
    setPhases,
    setSurveys,
}: Props) {
    const { handleSubmit, control } = useForm<FormData>(formatDefaultValue(userObj.identity.dob))
    const { t } = useTranslation(["onboarding", "common"])
    const [updateAccountIdentity] = useUpdateAccountIdentityMutation()

    const [isUnder18, setIsUnder18] = React.useState(false)

    async function onSubmit(values: FormData) {
        const { year, month, day } = values
        if (determineIfChild(year, month, day)) {
            setIsUnder18(true)
            setIsBackVisible(false)
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhenBorn, REGISTRATION_STEP.UNDER_18)
        } else {
            const formattedDay = day < 10 && day.toString().substring(0, 1) !== "0" ? `0${day}` : day
            const formattedMonth = month < 10 && month.toString().substring(0, 1) !== "0" ? `0${month}` : month
            const dob = `${year}-${formattedMonth}-${formattedDay}`
            await submitSubForm(updateAccountIdentity, { dob }, () => {
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhenBorn, REGISTRATION_STEP.DOB)

                // if the user is a Canadian individual over 60, then a dislcaimer should be swapped out for the third_party survey
                if (
                    userObj.type === AccountType.INDIVIDUAL &&
                    userObj.country === "CA" &&
                    isSixty(`${year}-${formattedMonth}-${formattedDay}`)
                ) {
                    setPhases((prev: REG_SCREENS[]) => {
                        const lastSurveyIndex = findLastIndexOf(prev, REG_SCREENS.Surveys)
                        if (lastSurveyIndex !== -1) {
                            const copy = [...prev]
                            copy.splice(lastSurveyIndex, 1, REG_SCREENS.OverageThirdPartyDisclaimer)
                            return copy
                        }
                        return prev
                    })
                    setSurveys((prev: { name: string; data: SurveyData }[]) =>
                        prev.filter((survey) => survey.name !== "third_party")
                    )
                }
            })
        }
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            {isUnder18 ? (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                        {t("whenBorn.under18.title")}
                    </Text>
                    <Text textStyle="InterRegularBody">{t("whenBorn.under18.mainText")}</Text>
                    <StandardButton
                        data-testid="under-18-support-button"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        onClick={() => window.open("mailto:support@gonetcoins.com?subject=Under 18 Account", "_blank")}
                        type="button"
                        w="full"
                        mt="3rem"
                        mb="0.75rem"
                    >
                        {t("contactSupport", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        data-testid="under-18-button"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_OUTLINE}
                        onClick={() => {
                            setIsBackVisible(true)
                            setIsUnder18(false)
                        }}
                        type="button"
                        w="full"
                    >
                        {t("goBack", { ns: "common" })}
                    </StandardButton>
                </>
            ) : (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                        {t("whenBorn.header")}
                    </Text>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box w="full" display="flex" flexDir={{ base: "column", lg: "row" }} gap="1.5rem">
                            <TextInput
                                name="day"
                                label={t("day", { ns: "common" })}
                                placeholder="DD"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: { value: 1, message: t("whenBorn.dayZero") },
                                    max: { value: 31, message: t("whenBorn.day31") },
                                    maxLength: { value: 2, message: t("whenBorn.day31") },
                                }}
                                maxLength={2}
                                size="lg"
                            />
                            <TextInput
                                name="month"
                                label={t("month", { ns: "common" })}
                                placeholder="MM"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: { value: 1, message: t("whenBorn.monthZero") },
                                    max: { value: 12, message: t("whenBorn.month12") },
                                    maxLength: { value: 2, message: t("whenBorn.month12") },
                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: t("error.numbersOnly", { ns: "common" }),
                                    },
                                }}
                                maxLength={2}
                                size="lg"
                            />
                            <TextInput
                                name="year"
                                label={t("year", { ns: "common" })}
                                placeholder="YYYY"
                                control={control}
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    min: {
                                        value: 1900,
                                        message: t("extra.past"),
                                    },
                                    max: {
                                        value: new Date().getFullYear(),
                                        message: t("extra.future"),
                                    },
                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: t("error.numbersOnly", { ns: "common" }),
                                    },
                                }}
                                size="lg"
                            />
                        </Box>
                        <StandardButton
                            data-testid="continue-button"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            mt="2.5rem"
                            w="full"
                            isLoading={isLoading}
                        >
                            {t("continue", { ns: "common" })}
                        </StandardButton>
                    </form>
                </>
            )}
        </Box>
    )
}
