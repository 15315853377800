import { Box, Flex, Text } from "@chakra-ui/react"
import INFO_ALERT_VARIANTS, { ENUM_INFO_ALERT_VARIANT } from "./types"
import ReactIcon, { ReactIconWrapperProps } from "@assets/svgs/ReactIconWrapper"
import { MdCheck } from "react-icons/md"
import React from "react"

export type InfoAlertProps = {
    children: React.ReactNode | string
    variant?: ENUM_INFO_ALERT_VARIANT
    icon?: ReactIconWrapperProps
}

const InfoAlert = ({ children, variant = ENUM_INFO_ALERT_VARIANT.DEFAULT, icon }: InfoAlertProps) => {
    const styles = INFO_ALERT_VARIANTS[variant]

    return (
        <Flex w="full" borderRadius="md" alignItems="center" gap={3}>
            <Box
                h="42px"
                w="42px"
                p={2}
                bg={styles.bg}
                borderRadius="md"
                display="flex"
                alignItems="center"
                alignSelf="stretch"
                justifyContent={"center"}
            >
                <ReactIcon icon={MdCheck} size={24} color={styles.color} {...icon} />
            </Box>
            {React.isValidElement(children) ? (
                children
            ) : (
                <Text whiteSpace="pre-line" textStyle="ManropeSemiboldBodySmall">
                    {children}
                </Text>
            )}
        </Flex>
    )
}

export default InfoAlert
