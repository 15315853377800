import { REG_SCREENS } from "./types"
import HowDidYouHear from "@/screens/onboarding/howDidYouHear"
import IsCanadian from "@/screens/onboarding/isCanadian"
import YourName from "@/screens/onboarding/yourName"
import WhenBorn from "@/screens/onboarding/whenBorn"
import WhatPhone from "@/screens/onboarding/whatPhone"
import ConfirmPin from "@/screens/onboarding/confirmPin"
import Occupation from "@/screens/onboarding/occupation"
import SurveyIntro from "@/screens/onboarding/surveyIntro"
import RiskStatement from "@/screens/onboarding/riskStatement"
import WhereLive from "@/screens/onboarding/whereLive"
import WhereReside from "@/screens/onboarding/whereReside"
import Surveys from "@/screens/onboarding/surveys"
import AccountType from "@/screens/onboarding/accountType"
import WhereIncorporated from "@/screens/onboarding/whereIncorporated"
import BusinessName from "@/screens/onboarding/businessName"
import BusinessRole from "@/screens/onboarding/role"
import WhenIncorporated from "@/screens/onboarding/whenIncorporated"
import IsMoneyService from "@/screens/onboarding/isMoneyService"
import AboutBusiness from "@/screens/onboarding/aboutBusiness"
import BeInTouch from "@/screens/onboarding/beInTouch"
import WhereBusinessOffice from "@/screens/onboarding/whereBusinessOffice"
import PendingApproval from "@/screens/onboarding/pendingApproval"
import SSN from "@/screens/onboarding/ssn"
import ZeroHashDisclaimer from "@/screens/onboarding/zerohashDisclaimer"
import TerrorismDisclaimer from "@/screens/onboarding/terrorismDisclaimer"
import OverageDisclaimer from "./overageDisclaimer"
import UpdateToS from "@components/updateTos"

export const SCREEN_MAPPING: Record<REG_SCREENS, any> = {
    [REG_SCREENS.HowDidYouHear]: HowDidYouHear,
    [REG_SCREENS.YourName]: YourName,
    [REG_SCREENS.WhenBorn]: WhenBorn,
    [REG_SCREENS.IsCanadian]: IsCanadian,
    [REG_SCREENS.WhatPhone]: WhatPhone,
    [REG_SCREENS.ConfirmPin]: ConfirmPin,
    [REG_SCREENS.Occupation]: Occupation,
    [REG_SCREENS.SurveyIntro]: SurveyIntro,
    [REG_SCREENS.RiskStatement]: RiskStatement,
    [REG_SCREENS.WhereReside]: WhereReside,
    [REG_SCREENS.WhereLive]: WhereLive,
    [REG_SCREENS.Surveys]: Surveys,
    [REG_SCREENS.AccountType]: AccountType,
    [REG_SCREENS.WhereIncorporated]: WhereIncorporated,
    [REG_SCREENS.BusinessName]: BusinessName,
    [REG_SCREENS.BusinessRole]: BusinessRole,
    [REG_SCREENS.WhereBusinessOffice]: WhereBusinessOffice,
    [REG_SCREENS.WhenIncorporated]: WhenIncorporated,
    [REG_SCREENS.IsMoneyService]: IsMoneyService,
    [REG_SCREENS.AboutBusiness]: AboutBusiness,
    [REG_SCREENS.BeInTouch]: BeInTouch,
    [REG_SCREENS.PendingApproval]: PendingApproval,
    [REG_SCREENS.SSN]: SSN,
    [REG_SCREENS.ZeroHashDisclaimer]: ZeroHashDisclaimer,
    [REG_SCREENS.TerrorismDisclaimer]: TerrorismDisclaimer,
    [REG_SCREENS.OverageThirdPartyDisclaimer]: OverageDisclaimer,

    // Only used during 'UpdateDetails'. Not actually in onboarding registration
    [REG_SCREENS.UpdateToS]: UpdateToS,
}
