import { assetDetailsApi, useLazyGetAssetMarketDataQuery } from "@redux/assetsDetails/apiSlice"
import { DatafeedConfiguration, IBasicDataFeed, ResolutionString, Timezone } from "./charting_library/charting_library"
import { AssetDetailsFull, graphPeriods, MarketDetails } from "@redux/assetsDetails/types"
import { API_PATH } from "@redux/assetsDetails/const"

export const resolutions = ["1", "10", "60", "240", "1D"].map((res) => res as ResolutionString)

const pricePrecisionToPriceScale = (precision: number): number => {
    return Math.pow(10, precision)
}

const configurationData: DatafeedConfiguration = {
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,
    exchanges: [],
    symbols_types: [],
    supported_resolutions: resolutions,
}

export const resolutionToPeriod: Record<string, string> = {
    "1": "1m",
    "10": "10m",
    "60": "1H",
    "240": "4H",
    "1D": "1D",
}

//File to review
export const createDatafeed = (assetDetails: Record<string, AssetDetailsFull>) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const datafeed: IBasicDataFeed = {
        onReady: (callback) => {
            setTimeout(() => callback(configurationData), 0)
        },

        resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
            setTimeout(() => {
                const coinSymbol = symbolName.split("/")[0].toLocaleUpperCase()
                const pricePrecision = assetDetails[coinSymbol]?.price_precision || 2
                const precisionScale = pricePrecisionToPriceScale(pricePrecision)

                onSymbolResolvedCallback({
                    name: symbolName,
                    description: symbolName,
                    exchange: "",
                    listed_exchange: "",
                    type: "crypto",
                    session: "24x7",
                    timezone: userTimezone as Timezone,
                    format: "price",
                    minmov: 1,
                    pricescale: precisionScale,
                    has_intraday: true,
                    intraday_multipliers: ["1", "10", "60", "240", "1D"],
                    supported_resolutions: resolutions,
                })
            }, 0)
        },

        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
            setTimeout(async () => {
                const period = resolutionToPeriod[resolution]
                const coinParams = symbolInfo.ticker ? symbolInfo.ticker.split("/") : []

                if (!period) {
                    onErrorCallback("Unsupported resolution")
                    return
                }

                try {
                    //Can't implement redux hooks here
                    const response = await fetch(
                        `/api/${API_PATH.MARKET_DETAILS}?asset=${coinParams[0]}&period=${period.toLocaleLowerCase()}&counter_asset=${coinParams[1]}`,
                        {
                            method: "GET",
                        }
                    )
                    const fetchData: MarketDetails = await response.json()

                    const data = fetchData.intervals

                    if (!data || !data.length) {
                        onHistoryCallback([], { noData: true })
                        return
                    }

                    let bars = data.map((item) => ({
                        time: item.time * 1000,
                        open: Number(item.open),
                        high: Number(item.high),
                        low: Number(item.low),
                        close: Number(item.close),
                        volume: 0,
                    }))

                    const { firstDataRequest } = periodParams

                    if (firstDataRequest) {
                        onHistoryCallback(bars)
                    } else {
                        onHistoryCallback([], { noData: true })
                    }
                } catch (error) {
                    onErrorCallback("Error fetching market data")
                }
            }, 0)
        },

        searchSymbols: async (searchString, exchange, symbolType, onResult) => {},
        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {},
        unsubscribeBars: (subscriberUID) => {},
    }
    return datafeed
}
