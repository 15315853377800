import { IconButton, IconButtonProps, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { FiMoreVertical } from "react-icons/fi"

type MenuItemData = {
    text: string
    onClick: () => void
}

type Props = {
    items: MenuItemData[]
    buttonProps?: Partial<IconButtonProps>
}

export default function IconMenu({ items, buttonProps }: Props) {
    return (
        <Menu isLazy>
            <MenuButton
                as={IconButton}
                variant="ghost"
                icon={<FiMoreVertical size={15} />}
                p={11}
                _hover={{
                    bg: "bluePurple.10",
                }}
                {...buttonProps}
            />
            <MenuList padding={0} borderRadius={8}>
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={item.onClick}
                        sx={{
                            padding: "0.75rem 1rem",
                            margin: "0.25rem 0.25rem",
                            width: "calc(100% - 0.5rem)",
                        }}
                        borderRadius={"6px"}
                    >
                        {item.text}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
