import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import { resolveUserOrLogin } from "@redux/auth/slice"
import React, { PropsWithChildren, useEffect } from "react"

/*
    currently this enum data (LOGOUT_STATE) is received by the current web app (not this one)
    later on when this web app has a dedicated backend, this data should be received here to create toasts.
    You'll get these from the url params
*/
export enum LOGOUT_STATE {
    CHANGE_PASSWORD = "1",
    END_SESSION = "2",
    CLOSE_ACCOUNT = "3",
}

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state) => state.auth.user)
    const loading = useAppSelector((state) => state.auth.loading)

    const { refetch: refetchAccountData } = useGetAccountDetailsQuery(undefined, {
        skip: !user, // Skip the query if the user is not logged in
    })

    useEffect(() => {
        if (user) {
            //Add additional api's to fetch data here after user logs in
            refetchAccountData()
        }
    }, [user])

    useEffect(() => {
        dispatch(resolveUserOrLogin())
    }, [])

    if (loading) {
        // For future purposes to add a loading screen while authentication resolves
        return <></>
    }

    return <>{user ? children : <></>}</>
}

export default AuthProvider
