import {
    NumberInput as ChakraNumberInput,
    FormControl,
    FormLabel,
    InputGroup,
    NumberInputField,
    Text,
    useTheme,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react"
import { FIELD_SIZE } from "@components/ui/types"
import React from "react"
import { useController } from "react-hook-form"
import useColorFormatConverter from "theme/useColorFormatConverter"
import ErrorMessage from "../errorMessage"
import colors from "../textInput/theme.json"
import { TextInputControlledProps, TextInputFormProps, TextInputProps, isFormProps } from "../textInput"

export default function NumberInput(props: TextInputProps) {
    const {
        placeholder,
        disabled,
        label,
        labelElement,
        leftIcon,
        rightIcon,
        baseColor = "white",
        inputSize = "lg",
    } = props

    const theme = useTheme()

    // Handle form control case
    const formControl = isFormProps(props)
        ? useController<any>({
              name: props.name,
              control: props.control,
              rules: props.rules,
          })
        : null

    const { inputProps, formProps, error } = React.useMemo(() => {
        if (formControl) {
            const {
                field,
                fieldState: { invalid, error },
            } = formControl
            const { onChange, value, ref, ...restField } = field
            const { control, name, rules, ...formRest } = props as TextInputFormProps

            return {
                inputProps: {
                    onChange: (valueAsString: string, valueAsNumber: number) => onChange(valueAsNumber),
                    value: value ?? 0,
                    ref,
                    ...restField,
                },
                formProps: {
                    isInvalid: invalid,
                    ...formRest,
                },
                error: error?.message,
            }
        }

        const { onChange, value, error, ...formRest } = props as TextInputControlledProps
        return {
            inputProps: {
                onChange: (_: string, valueAsNumber: number) => onChange(valueAsNumber),
                value: value ?? 0,
            },
            formProps: {
                isInvalid: !!error,
                ...formRest,
            },
            error,
        }
    }, [props, formControl])

    const colorConverter = useColorFormatConverter()

    const palette = React.useMemo(() => {
        return colors[baseColor as keyof typeof colors]
    }, [])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (error) {
                return palette.error
            }
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [error, palette]
    )

    const innerBorderColor = React.useMemo(() => (error ? palette.error : theme.colors.blue[100]), [error, theme])

    return (
        <FormControl data-test-id={`numberInput-${label}`} {...formProps}>
            {label && (
                <FormLabel
                    fontSize={14}
                    lineHeight={"100%"}
                    htmlFor={isFormProps(props) ? props.name : undefined}
                    display={"flex"}
                    flexDir={"row"}
                >
                    {label}&nbsp;
                    {isFormProps(props) && props.rules?.hasOwnProperty("required") && (
                        <Text as="span" color="blue.100">
                            *
                        </Text>
                    )}
                    {labelElement && labelElement}
                </FormLabel>
            )}
            <InputGroup
                _focusWithin={{
                    borderColor: getBorderColor(true),
                    ".chakra-input__left-addon": {
                        borderColor: `${innerBorderColor} !important`,
                    },
                    ".chakra-input": {
                        borderTopColor: `${innerBorderColor} !important`,
                        borderBottomColor: `${innerBorderColor} !important`,
                        borderLeftColor: `${leftIcon ? "transparent" : innerBorderColor} !important`,
                        borderRightColor: `${rightIcon ? "transparent" : innerBorderColor} !important`,
                    },
                }}
                borderColor={getBorderColor(false)}
                borderWidth="1px"
                borderRadius="8px"
                h={`${FIELD_SIZE[inputSize ?? "lg"]}px`}
                overflow="hidden"
            >
                {leftIcon && (
                    <InputLeftElement
                        h="full"
                        pointerEvents="none"
                        borderColor={error ? palette.error : palette?.iconContainer}
                        borderTopWidth="1px"
                        borderBottomWidth="1px"
                        borderLeftWidth={"1px"}
                        borderRightWidth={0}
                    >
                        {leftIcon}
                    </InputLeftElement>
                )}
                <ChakraNumberInput
                    {...inputProps}
                    paddingStart={leftIcon ? 6 : 0}
                    paddingEnd={4}
                    h="full"
                    w="full"
                    display="flex"
                    alignItems={"center"}
                    data-testid={`input-${name}`}
                    bgColor={error ? palette.errorBg : baseColor}
                    color={palette?.text}
                    borderColor={
                        error
                            ? colorConverter(palette.error) + " " + "!important"
                            : !error && baseColor === "white"
                              ? palette?.border
                              : "transparent"
                    }
                    _hover={{
                        borderColor: !error && baseColor === "white" ? palette?.border : "transparent",
                    }}
                    _disabled={{
                        borderColor: palette?.border,
                    }}
                    borderTopWidth="1px"
                    borderBottomWidth="1px"
                    borderLeftWidth={leftIcon ? 0 : "1px"}
                    borderRightWidth={rightIcon ? 0 : "1px"}
                    focusBorderColor="transparent"
                    errorBorderColor="transparent"
                    _autofill={{
                        WebkitTextFillColor: colorConverter(palette.text),
                        WebkitBoxShadow: `inset 0 0 20px 20px ${
                            baseColor === "white" || baseColor === "black" ? baseColor : colorConverter(baseColor)
                        }`,
                    }}
                >
                    <NumberInputField
                        fontFamily="Inter"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="400"
                        _placeholder={{ color: "grey.light.40" }}
                        lineHeight="150%"
                        disabled={disabled}
                        placeholder={placeholder}
                        border="none"
                        h="full"
                        w="full"
                    />

                    {rightIcon && (
                        <InputRightElement
                            pointerEvents="none"
                            borderColor={error ? palette.error : palette?.iconContainer}
                            bgColor={palette?.iconContainer}
                            h={"calc(100% - 2px)"}
                            top={"1px"}
                            borderTopWidth="1px"
                            borderBottomWidth="1px"
                            borderRightWidth={"1px"}
                            borderLeftWidth={0}
                            w="50px"
                        >
                            {rightIcon}
                        </InputRightElement>
                    )}
                </ChakraNumberInput>
            </InputGroup>
            {!!error && <ErrorMessage color={palette?.text}>{error}</ErrorMessage>}
        </FormControl>
    )
}
