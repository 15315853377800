import { useAppSelector } from "@/store/hooks"
import { imageMap } from "@assets/svgs/coins"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Flex, Icon, Image, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react"
import MarketChange from "@components/ui/marketChange"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetAssetFavouritesQuery } from "@redux/asset/apiSlice"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { buildRouteWithQueryParams } from "@routing/route-utils"
import { ROUTES } from "@routing/routes"
import { addCommasToNumber, removeTrailingZeros } from "@util/stringFormatting"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiFillStar } from "react-icons/ai"
import { FaChevronRight } from "react-icons/fa"
import { IoChevronDown, IoChevronUp } from "react-icons/io5"

type FavouriteItemProps = Pick<AssetDetailsFull, "symbol" | "name" | "price" | "price_precision" | "24h_percent">

const FavouriteItemSkeleton = () => {
    return (
        <Flex alignItems="center" width="100%" justifyContent="space-between" pb={2} py="6px" borderBottomWidth="1px">
            <Flex gap={3} alignItems="center" height="56px">
                <Flex alignItems="center">
                    <Flex width="32px" height="100%" justifyContent="center" alignItems="center">
                        <ReactIcon icon={AiFillStar} size={20} color="grey.light.10" />
                    </Flex>
                    <SkeletonCircle size={"10"} />
                </Flex>

                <Flex flexDir="column" gap={2}>
                    <Skeleton height="12px" width="112px" />
                    <Skeleton height="12px" width="112px" />
                </Flex>
            </Flex>

            <Flex justifyContent="flex-end" flexDir="column" gap={2}>
                <Skeleton height="12px" width="112px" />
                <Skeleton height="12px" width="112px" />
            </Flex>
        </Flex>
    )
}

function FavouriteItem({ symbol, name, price, ["24h_percent"]: percent, price_precision }: FavouriteItemProps) {
    const image = imageMap[symbol.toLowerCase() as keyof typeof imageMap]

    return (
        <Flex alignItems="center" width="100%" justifyContent="space-between" pb={2} py="6px" borderBottomWidth="1px">
            <Flex gap={3} alignItems="center" height="56px">
                <Flex alignItems="center">
                    <Flex width="32px" height="100%" justifyContent="center" alignItems="center">
                        <ReactIcon icon={AiFillStar} size={20} color="yellow.light.100" />
                    </Flex>
                    {image && (
                        <Image
                            src={image}
                            width={"42px"}
                            height={"42px"}
                            mr={3}
                            alt={`${name} icon`}
                            borderRadius="full"
                        />
                    )}
                </Flex>

                <Flex flexDir="column">
                    <Text textStyle="ManropeSemiboldBodySmall">{name}</Text>
                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.50">
                        {symbol}
                    </Text>
                </Flex>
            </Flex>

            <Flex justifyContent={"center"} alignItems="flex-end" flexDir="column" gap={1} height="56px">
                <Text textAlign={"right"} textStyle="ManropeSemiboldBodySmall">
                    {addCommasToNumber(removeTrailingZeros(price || "0"), {
                        precision: price_precision,
                        addDollarSign: true,
                        minFiatPrecision: true,
                    })}
                </Text>
                <MarketChange percent={percent} hideIcon={false} />
            </Flex>
        </Flex>
    )
}

export default function Favourites() {
    const { t } = useTranslation("app")
    const { t: ct } = useTranslation("common")
    const { navigate } = useRestrictedNavigation()

    const currency = useAppSelector(selectAccountDetails)?.currency
    const { data: assetFavourites, isLoading: isLoadingFavourites } = useGetAssetFavouritesQuery(undefined)
    const { data: assetDetails, isLoading: isLoadingAssets } = useGetAssetsDetailsQuery({ currency: currency })

    const hasData = useMemo(() => (assetFavourites?.data.length ?? 0) > 0, [assetFavourites])
    const isLoading = useMemo(() => isLoadingFavourites || isLoadingAssets, [isLoadingFavourites, isLoadingAssets])
    const [isMoreShown, setIsMoreShown] = useState(false)

    const navigateToTrade = () => {
        navigate(buildRouteWithQueryParams(ROUTES.DASHBOARD_TRADE, { filter: "favourites" }))
    }

    return (
        <Flex flexDir="column" gap={hasData ? 2 : 3}>
            <Flex justifyContent="space-between" gap={2}>
                <Flex gap={2} alignItems="center">
                    <Text textStyle="ManropeSemiboldBody">{t("dashboard.myFavourites")}</Text>
                    {!hasData && <ReactIcon icon={AiFillStar} size={24} color="yellow.light.100" />}
                </Flex>

                {(isLoading || (hasData && assetFavourites && assetFavourites.data?.length > 5)) && (
                    <Flex
                        alignItems="center"
                        cursor="pointer"
                        gap={{
                            base: 1,
                            sm: 3,
                        }}
                        ml={{ base: "auto", md: "unset" }}
                        flex="0 0 auto"
                        whiteSpace="nowrap"
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                        onClick={() => setIsMoreShown(!isMoreShown)}
                    >
                        <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                            {ct(isMoreShown ? "viewLess" : "viewMore")}
                        </Text>
                        <Icon as={isMoreShown ? IoChevronUp : IoChevronDown} size={16} color="blue.100" />
                    </Flex>
                )}
            </Flex>
            {isLoading ? (
                [1, 2, 3, 4].map((index) => <FavouriteItemSkeleton key={index} />)
            ) : (
                <>
                    {!hasData && (
                        <Flex flexDir="column" gap={3} py={2}>
                            <Text textStyle="ManropeSemiboldBodySmall" color="grey.light.50">
                                {t("dashboard.clickTheStar")}
                            </Text>

                            <Flex
                                alignItems="center"
                                cursor="pointer"
                                gap={{
                                    base: 1,
                                    sm: 3,
                                }}
                                ml={{ base: "auto", md: "unset" }}
                                flex="0 0 auto"
                                whiteSpace="nowrap"
                                sx={{
                                    "&:hover": {
                                        textDecorationColor: "blue.100 !important",
                                        textDecoration: "underline",
                                    },
                                    "&:active": {
                                        textDecorationColor: "blue.70 !important",
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={navigateToTrade}
                            >
                                <Text
                                    color={"blue.100"}
                                    textStyle={{
                                        md: "ManropeSemiboldBody",
                                        base: "ManropeSemiboldBodySmall",
                                    }}
                                >
                                    {t("dashboard.browseCoins")}
                                </Text>
                                <Icon as={FaChevronRight} size={24} color="blue.100" />
                            </Flex>
                        </Flex>
                    )}

                    {hasData && assetDetails && (
                        <>
                            <Flex flexDir="column" width="100%">
                                {assetFavourites?.data
                                    .slice(0, isMoreShown ? 10 : 5)
                                    .map(
                                        ({ asset }, index) =>
                                            asset.symbol in assetDetails && (
                                                <FavouriteItem key={index} {...assetDetails[asset.symbol]} />
                                            )
                                    )}
                            </Flex>
                            <>
                                {((hasData && assetFavourites && assetFavourites.data?.length <= 5) || isMoreShown) && (
                                    <Flex
                                        alignItems="center"
                                        cursor="pointer"
                                        gap={{
                                            base: 1,
                                            sm: 3,
                                        }}
                                        mx="auto"
                                        mt="6px"
                                        py={2}
                                        flex="0 0 auto"
                                        whiteSpace="nowrap"
                                        sx={{
                                            "&:hover": {
                                                textDecorationColor: "blue.100 !important",
                                                textDecoration: "underline",
                                            },
                                            "&:active": {
                                                textDecorationColor: "blue.70 !important",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={navigateToTrade}
                                    >
                                        <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                                            {t("dashboard.viewAllFavourites")}
                                        </Text>
                                        <Icon as={FaChevronRight} size={16} color="blue.100" />
                                    </Flex>
                                )}
                            </>
                        </>
                    )}
                </>
            )}
        </Flex>
    )
}
