import { Table, Tbody } from "@chakra-ui/react"
import { TransactionRowSkeleton } from "@components/transactions/transactionsRow"
import { useGetTransactionsQuery } from "@redux/transactions/apiSlice"
import { Transaction } from "@redux/transactions/types"
import TransactionCard from "@screens/dashboard/transactions/transactionCard"
import { useMemo } from "react"

type DashboardTransactionsTableProps = {
    onViewMorePress: (t: Transaction) => void
}

export const DashboardTransactionsTable = ({ onViewMorePress }: DashboardTransactionsTableProps) => {
    const { isLoading, data: transactionsData } = useGetTransactionsQuery({ types: "", limit: 5, page: 1 })

    const renderedTransactions = useMemo(() => {
        return transactionsData?.transactions.map((transaction, index) => (
            <TransactionCard
                forcedSizeVariant="small"
                key={`${transaction.created_at}-${index}`}
                transaction={transaction}
                viewMore={() => onViewMorePress(transaction)}
                isLast={index === transactionsData.transactions.length - 1}
            />
        ))
    }, [transactionsData?.transactions])
    return (
        <Table>
            <Tbody w="full" h="full">
                {isLoading
                    ? [1, 2, 3].map((i) => <TransactionRowSkeleton forcedSizeVariant="small" key={i} />)
                    : renderedTransactions}
            </Tbody>
        </Table>
    )
}
