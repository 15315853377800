import { Box, FormControl, FormHelperText, FormLabel, Icon, Indicator } from "@chakra-ui/react"
import {
    chakraComponents,
    Select as ChakraReactSelect,
    DropdownIndicatorProps,
    GroupBase,
    OptionBase,
    OptionProps,
    Props,
} from "chakra-react-select"
import { useController, UseControllerProps } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { MdCheck, MdChevronRight } from "react-icons/md"
import ErrorMessage from "../errorMessage"

export interface SelectOption extends OptionBase {
    value: any
    label: string
}
type SelectProps = UseControllerProps<any> &
    Props & {
        options: Array<SelectOption>
        size?: "sm" | "md" | "lg"
        label?: string
        hint?: string
        isMulti?: boolean
        placeholder?: string
    }

export const CustomComponents = {
    Option: ({ children, ...props }: OptionProps<SelectOption, true, GroupBase<SelectOption>>) => (
        <chakraComponents.Option {...props}>
            <Box
                display="flex"
                flexDir="row"
                flexGrow={1}
                alignItems="center"
                justifyContent={"flex-start"}
                data-testid={`select-option-${props.data.value}`}
            >
                {children}
            </Box>
            {props.isSelected && <MdCheck size={20} />}
        </chakraComponents.Option>
    ),
    DropdownIndicator: (props: DropdownIndicatorProps<SelectOption, true, GroupBase<SelectOption>>) => {
        const { selectProps } = props

        return (
            <chakraComponents.DropdownIndicator {...props}>
                <Icon
                    color="black"
                    height={18}
                    width={18}
                    as={MdChevronRight}
                    style={{ transform: `rotate(${selectProps.menuIsOpen ? 270 : 90}deg)` }}
                />
            </chakraComponents.DropdownIndicator>
        )
    },
}

export default function Select({
    options,
    name,
    size,
    rules,
    control,
    disabled,
    label,
    hint,
    defaultValue,
    isMulti,
    placeholder,
}: SelectProps) {
    const {
        field,
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        rules,
    })

    const { t } = useTranslation("common")

    return (
        <FormControl
            key={field?.value?.value}
            isDisabled={disabled}
            isInvalid={invalid}
            isRequired={rules?.hasOwnProperty("required") && rules.required !== false}
        >
            {label && (
                <FormLabel
                    fontSize={14}
                    lineHeight={"100%"}
                    htmlFor={name}
                    mb={hint ? 0 : 2}
                    _disabled={{ opacity: 1 }}
                    textStyle={"ManropeRegularBodySmall"}
                >
                    {label}
                </FormLabel>
            )}
            {hint && (
                <FormHelperText mt={0} mb={2} textStyle="InterRegularNano" fontSize="xs">
                    {hint}
                </FormHelperText>
            )}
            <ChakraReactSelect<SelectOption, true, GroupBase<SelectOption>>
                data-testid={`select-${name}`}
                {...field}
                {...(isMulti ? { isMulti: true } : {})}
                name={name}
                options={options}
                placeholder={placeholder ?? t("picker.selectOne")}
                components={CustomComponents}
                errorBorderColor="transparent"
                focusBorderColor="transparent"
                selectedOptionStyle="color"
                defaultValue={defaultValue}
                size={size ?? "lg"}
                chakraStyles={{
                    control: (provided) => ({
                        ...provided,
                        cursor: "pointer",
                        opacity: "1 !important",
                        fontSize: "16px",
                        fontFamily: "Inter",
                        _hover: {
                            borderColor: error ? "red.light.100" : disabled ? "grey.light.20" : "grey.light.10",
                            borderWidth: error ? "2px" : "1px",
                        },
                        _focusWithin: {
                            borderColor: "blue.100",
                            borderWidth: "2px",
                        },
                    }),
                    indicatorsContainer: (provided) => ({
                        ...provided,
                        border: "1px solid transparent !important",
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        backgroundColor: "white",
                        visibility: disabled ? "hidden" : "visible",
                    }),
                    menuList: (provided) => ({
                        ...provided,
                        px: "0.25rem",
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        color: "grey.light.40",
                    }),
                    option: (provided, _state) => ({
                        ...provided,
                        backgroundColor: _state.isSelected ? "blue.100" : "white",
                        color: "black",
                        cursor: "pointer",
                        borderRadius: "6px",
                        fontSize: "16px",
                        fontFamily: "Inter",
                        px: "0.25rem",
                        "& svg": {
                            fill: "black",
                        },
                        _selected: {
                            backgroundColor: "blue.100",
                            color: "white",
                            "& svg": {
                                fill: "white",
                            },
                        },
                        _hover: {
                            backgroundColor: "blue.100",
                            color: "white",
                            "& svg": {
                                fill: "white",
                            },
                        },
                    }),
                }}
            />
            {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
        </FormControl>
    )
}
