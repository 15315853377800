import ReactIcon from "@assets/svgs/ReactIconWrapper"
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Flex,
    Text,
} from "@chakra-ui/react"
import StandardButton, { StandardButtonProps } from "@components/ui/buttons/standard"
import { ColorKeys } from "@theme/chakra"
import theme from "@theme/index"
import React from "react"
import { IconType } from "react-icons"
import { MdInfoOutline } from "react-icons/md"

export type PlainModalProps = {
    name: string
    isOpen: boolean
    onClose: () => void
    title: string
    children: React.ReactElement
    cancelRef: React.RefObject<HTMLButtonElement>
    buttons: StandardButtonProps[]
    separator?: boolean
    err?: string
    icon?: {
        name: IconType
        color: ColorKeys
        bgColor: ColorKeys
    }
    showErrIcon?: boolean
}

export default function PlainModal({
    name,
    isOpen,
    onClose,
    title,
    cancelRef,
    buttons,
    children,
    err,
    separator,
    icon,
    showErrIcon,
}: PlainModalProps) {
    const renderedIcon = showErrIcon
        ? { name: MdInfoOutline, color: theme.colors.red.light["100"], bgColor: "red.light.10" }
        : icon
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
            <AlertDialogOverlay bg="rgba(0, 0, 0, 0.20)">
                <AlertDialogContent
                    data-test-id={name}
                    maxW="sm"
                    borderRadius="md"
                    p={3}
                    boxShadow={"0px 4px 12px 0px rgba(0, 0, 0, 0.12)"}
                    borderWidth={1}
                    borderColor="grey.light.20"
                >
                    <AlertDialogHeader padding={3} paddingBottom={0}>
                        <Flex alignItems="center" gap={2}>
                            {renderedIcon && (
                                <Box
                                    bg={renderedIcon.bgColor}
                                    p={2.5}
                                    borderRadius="md"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <ReactIcon icon={renderedIcon.name} color={renderedIcon.color} size={20} />
                                </Box>
                            )}
                            <Text textStyle={"ManropeSemiboldBody"}>{title}</Text>
                        </Flex>
                    </AlertDialogHeader>

                    {separator && <Box borderBottomWidth={1} borderBottomColor={"grey.light.10"} mb={5} />}

                    <AlertDialogBody p={3}>
                        {err ? (
                            <Text w="full" textStyle={"InterRegularBody"} color={"red.light.100"}>
                                {err}
                            </Text>
                        ) : (
                            children
                        )}
                    </AlertDialogBody>

                    {separator && <Box borderBottomWidth={1} borderBottomColor={"grey.light.10"} mt={5} />}

                    <AlertDialogFooter p={3} w="full" flexDir="column">
                        <Flex
                            w="full"
                            justifyContent={buttons.length === 1 ? "flex-end" : "space-between"}
                            alignItems="center"
                        >
                            {buttons.map((button, index) => (
                                <StandardButton
                                    key={index}
                                    size={"md"}
                                    variant={button.variant}
                                    onClick={button.onClick}
                                    type="button"
                                    isLoading={button.isLoading}
                                    isNoPadding={button.isNoPadding}
                                >
                                    {button.children}
                                </StandardButton>
                            ))}
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
