import APXLendingImage from "@assets/images/brands/apx-lending.png"
import { Flex, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const apxPartnerLink = "https://app.apxlending.com/register?partner=netcoins"

export function APXBanner() {
    const { t } = useTranslation("app")

    const onClick = () => {
        window.open(apxPartnerLink, "_blank")
    }

    return (
        <Flex
            px={6}
            py={1}
            flexDir="column"
            width="100%"
            height="169px"
            bg="linear-gradient(99deg, rgba(57, 57, 88, 0.56) 10.72%, rgba(123, 123, 190, 0.00) 82.34%), #07072E"
        >
            <Flex height="42px" alignItems="center">
                <Text color="white" textStyle="ManropeSemiboldBodySmall">
                    {t("dashboard.cryptoBackedLoans")}
                </Text>
            </Flex>

            <Flex gap={2} width="100%" alignItems="center">
                <Flex
                    width="101px"
                    minWidth="101px"
                    height="99px"
                    alignItems="center"
                    justifyContent="center"
                    bg="bluePurple.10"
                    borderRadius="4px"
                >
                    <Image src={APXLendingImage} width="95px" height="46px" alt="APX lending" objectFit="contain" />
                </Flex>

                <Flex flexDir="column">
                    <Text color="white" textStyle="InterRegularXSmall">
                        {t("dashboard.netcoinsPartnerAPX")}
                    </Text>

                    <Flex
                        alignItems="center"
                        cursor="pointer"
                        onClick={onClick}
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <Text color="blue.70" textStyle="InterRegularXSmall">
                            {t("dashboard.clickToLearnMore")}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
