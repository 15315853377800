import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import useFetchWrapper, { Method } from "@/hooks/useFetchWrapper"
import AmplitudeClient from "@/sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import { logout } from "@redux/auth/slice"
import { useAppDispatch } from "@/store/hooks"

type Props = {
    email: string
    goBack: () => void
    region: "NY" | "LA" | "HI"
    toastServerError: () => void
}

type FormData = {
    email: string
}

export default function NotPermittedState({ email, toastServerError, region, goBack }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            email,
        },
    })
    const { fetchWrapper } = useFetchWrapper()
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccessScreen, toggleSuccessScreen] = React.useState(false)

    async function onSubmitForm(values: FormData) {
        fetchWrapper("/api/v2/account", Method.POST, { country: "US", region })
            .then(() => {
                toggleSuccessScreen(true)
                AmplitudeClient.logEvent(
                    REG_SCREENS.WhereIncorporated, // TODO fix when we redo amplitude events
                    REGISTRATION_STEP.BUSINESS_MAILING_LIST
                )
            })
            .catch(toastServerError)
            .finally(() => setIsLoading(false))
    }

    return (
        <Box w="full">
            {isSuccessScreen ? (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                        {t("success", { ns: "common" })}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                        {t("whereIncorporated.mailingAdded")}
                    </Text>
                    <StandardButton
                        data-testid="done-button"
                        type="button"
                        onClick={() => dispatch(logout())}
                        isLoading={isLoading}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        mt="3rem"
                        w="full"
                    >
                        {t("done", { ns: "common" })}
                    </StandardButton>
                </>
            ) : (
                <>
                    <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                        {t("comingSoon", { ns: "common" })}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                        {t("whereIncorporated.notInState")}
                    </Text>
                    <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                        {t("whereIncorporated.wantToBeNotified")}
                    </Text>
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <TextInput label={t("whereIncorporated.yourEmail")} name="email" control={control} disabled />
                        <StandardButton
                            data-testid="join-mailing-button"
                            type="submit"
                            isLoading={isLoading}
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                            mt="3rem"
                            w="full"
                        >
                            {t("joinMailingList", { ns: "common" })}
                        </StandardButton>
                    </form>
                    <StandardButton
                        data-testid="back-button"
                        type="button"
                        onClick={goBack}
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_GHOST}
                        mt="0.75rem"
                        w="full"
                    >
                        {t("goBack", { ns: "common" })}
                    </StandardButton>
                </>
            )}
        </Box>
    )
}
