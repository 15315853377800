import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdOpenInNew, MdOutlineVerifiedUser } from "react-icons/md"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import useSupport from "@hooks/useSupport"

export default function TwoFASetupRoot() {
    const { t } = useTranslation(["app", "common"])

    const { tfaInfoLink } = useSupport()

    const onButtonPress = () => {
        window.open(tfaInfoLink, "_blank")
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"} mb={8}>
                {t("twoFASetup.setupTwoFA", { ns: "twoFA" })}
            </Text>
            <Box w="fit-content" px={10} py={5} borderRadius={"md"} bgColor={"green.light.10"}>
                <ReactIcon icon={MdOutlineVerifiedUser} color="green.light.100" size={40} />
            </Box>
            <Text mt={6} textStyle={"ManropeSemiboldBody"}>
                {t("twoFASetup.requiredForFundingWithdrawal", { ns: "twoFA" })}
            </Text>
            <Text mt={2} textStyle={"InterRegularBodySmall"} mb={6}>
                {t("twoFASetup.extraSecurity", { ns: "twoFA" })}
            </Text>
            <StandardButton
                children={t("twoFASetup.learnMore2FA", { ns: "twoFA" })}
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_LINK}
                rightIcon={MdOpenInNew}
                onClick={onButtonPress}
                p={0}
            />
        </Box>
    )
}
