import { Stack, StackProps } from "@chakra-ui/react"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import BreadCrumb, { BreadCrumbProps } from ".."

type Props = {
    breadCrumbs: Omit<BreadCrumbProps, "hasPrevious">[]
} & StackProps

export default function BreadCrumbGroup({ breadCrumbs, ...rest }: Props) {
    return (
        <Stack h="56px" diplay="flex" alignItems="center" flexWrap={"wrap"} direction={"row"} {...rest}>
            {breadCrumbs.map((breadCrumb, index) => (
                <BreadCrumb
                    key={index + breadCrumb.text}
                    {...breadCrumb}
                    hasPrevious={index !== 0}
                    hasAfter={index !== breadCrumbs.length - 1}
                />
            ))}
        </Stack>
    )
}

export const generateRouteBreadcrumbs = (includeRoot?: boolean) => {
    const { t } = useTranslation("app")
    const location = useLocation()
    const { navigate } = useRestrictedNavigation()
    const navigateToPath = useCallback(
        (path: string) => {
            return navigate(path)
        },
        [navigate]
    )
    const locations = location.pathname.split("/")
    let acc = ""
    if (!includeRoot) {
        locations.shift()
    }

    const res = useMemo(
        () =>
            locations
                .filter((x) => x)
                .map((path) => {
                    acc += `/${path}`
                    const navRoute = acc
                    return {
                        text: t(`nav.breadcrumbRoutes.${path}`),
                        onClick: () => navigateToPath(navRoute),
                    }
                }) as Omit<BreadCrumbProps, "hasPrevious">[],
        [locations]
    )
    return res
}
