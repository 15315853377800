import { Flex, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Option } from "@components/ui/types"
import CoinSelect from "@components/ui/select/coinSelect"
import { useForm, Control, FieldValues, Controller } from "react-hook-form"
import Select from "@components/ui/select"
import { MdAttachMoney } from "react-icons/md"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { FormField } from "@components/ui/formFields"
import { commaStringToFloat, formatStringWithCommas } from "@util/numericalFormatting"
import { useCreatePriceAlertMutation, useEditPriceAlertMutation } from "@redux/account/apiSlice"
import useGenericToast from "@hooks/useGenericToast"
import { IPriceAlertParams, PriceAlert } from "@redux/account/types"

const percentageChangeOptions = [
    {
        label: "2.5%",
        value: "2.5",
    },
    {
        label: "5%",
        value: "5",
    },
    {
        label: "10%",
        value: "10",
    },
]

type HitTargetPriceProps = {
    frequencyOptions: Option<string>[]
    control: Control<FieldValues>
    selectedAssetDetails?: AssetDetailsFull
}

enum TargetOptions {
    HitTargetPrice = "hitsTargetPrice",
    MakePercentageMove = "makesPercentageMove",
}

function HitTargetPrice({ frequencyOptions, control, selectedAssetDetails }: HitTargetPriceProps) {
    const { t } = useTranslation("settings")
    const { t: ct } = useTranslation("common")

    const currency = useAppSelector(selectAccountDetails)?.currency ?? "CAD"

    return (
        <>
            <Flex flexDir="column">
                <Controller
                    control={control}
                    rules={{ required: ct("error.required", { ns: "common" }) }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormField
                            inputMode="decimal"
                            size="lg"
                            mb={2}
                            placeholder={"0"}
                            leftElement={<MdAttachMoney />}
                            label={t("notifications.targetPrice")}
                            rightElement={
                                <Text as="span" textStyle="ManropeBoldBody">
                                    {currency}
                                </Text>
                            }
                            value={value}
                            onChange={(e) => {
                                const val = e.target.value
                                const formattedVal = formatStringWithCommas(
                                    String(val),
                                    Number(selectedAssetDetails?.price_precision)
                                )
                                onChange(formattedVal)
                            }}
                            errorMessage={error?.message}
                        />
                    )}
                    name={"targetPrice"}
                />
            </Flex>

            <Select
                name="frequency"
                control={control}
                label={t("notifications.frequency")}
                rules={{ required: ct("error.requiredOption", { ns: "common" }) }}
                options={frequencyOptions}
            />
        </>
    )
}

type MakePercentageMoveProps = {
    control: Control<FieldValues>
    timeWindowOptions: Option<string>[]
}

function MakePercentageMove({ control, timeWindowOptions }: MakePercentageMoveProps) {
    const { t } = useTranslation("settings")
    const { t: ct } = useTranslation("common")

    return (
        <>
            <Select
                name="percentageChange"
                control={control}
                label={t("notifications.percentageChange")}
                rules={{ required: ct("error.requiredOption", { ns: "common" }) }}
                options={percentageChangeOptions}
            />

            <Select
                name="timeWindow"
                control={control}
                label={t("notifications.timeWindow")}
                rules={{ required: ct("error.requiredOption", { ns: "common" }) }}
                options={timeWindowOptions}
            />
        </>
    )
}

export interface AddEditPriceAlertDrawerProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    priceAlert?: PriceAlert | null
}

export default function AddEditPriceAlertDrawer({
    isOpen,
    toggleSideDrawerOpen,
    priceAlert,
}: AddEditPriceAlertDrawerProps): JSX.Element {
    const { t } = useTranslation("settings")
    const { t: ct } = useTranslation("common")
    const isNewPriceAlert = priceAlert ? false : true
    const { data } = useGetAssetsDetailsQuery(undefined)
    const currency = useAppSelector(selectAccountDetails)?.currency
    const [isLoading, setIsLoading] = useState(false)

    const coinOptions = useMemo(() => {
        if (data) {
            return Object.values(data).map((asset) => ({
                value: asset.symbol,
                label: asset.name,
                desc: asset.symbol,
            })) as Option<string>[]
        }
        return []
    }, [data])

    const targetOptions = [
        {
            label: t("notifications.hitTargetPrice"),
            value: TargetOptions.HitTargetPrice,
        },
        {
            label: t("notifications.makePercentageMove"),
            value: TargetOptions.MakePercentageMove,
        },
    ]

    const frequencyOptions = [
        {
            label: t("notifications.once"),
            value: "1",
        },
        {
            label: t("notifications.everyTime"),
            value: "24",
        },
    ]

    const timeWindowOptions = [
        {
            label: t("notifications.oneHour"),
            value: "1",
        },
        {
            label: t("notifications.fourHours"),
            value: "4",
        },
        {
            label: t("notifications.24Hours"),
            value: "24",
        },
    ]

    const [createPriceAlert] = useCreatePriceAlertMutation()
    const [updatePriceAlert] = useEditPriceAlertMutation()

    const { control, watch, reset, trigger } = useForm<FieldValues>({
        defaultValues: {
            target: priceAlert ? (priceAlert.target_price ? targetOptions[0] : targetOptions[1]) : targetOptions[0],

            frequency: priceAlert
                ? frequencyOptions.find((option) => option.value === String(priceAlert.frequency))
                : frequencyOptions[0],

            targetPrice: formatStringWithCommas(String(priceAlert?.target_price), 2),

            percentageChange: priceAlert?.change_percentage
                ? percentageChangeOptions.find((option) => option.value === String(priceAlert.change_percentage))
                : percentageChangeOptions[0],

            timeWindow: priceAlert?.time_window
                ? timeWindowOptions.find((option) => option.value === String(priceAlert.time_window))
                : timeWindowOptions[0],
        },
        shouldUnregister: true,
    })

    const targetOption = watch("target")?.value

    const [coinOption, setCoinOption] = useState<Option<string> | null>(coinOptions?.[0] || null)

    useEffect(() => {
        if (coinOptions.length <= 0) {
            return
        }

        if (priceAlert) {
            const selectedCoin = coinOptions.find((option) => option.value === priceAlert.coin)
            if (selectedCoin) {
                setCoinOption(selectedCoin)
            }
            return
        }

        setCoinOption(coinOptions[0])
    }, [coinOptions])

    const { successToast, serverErrorToast, errorToast } = useGenericToast()

    const submit = async () => {
        const isValid = await trigger()

        if (!isValid) {
            return
        }

        let objectToSend: IPriceAlertParams = {
            coin: coinOption?.value || "",
            currency: currency || "CAD",
            target_price: null,
            frequency: null,
            change_percentage: null,
            time_window: null,
        }

        if (targetOption === TargetOptions.HitTargetPrice) {
            const selectedAssetPrecision = Number(coinOption?.value ? data?.[coinOption.value]?.price_precision : 0)
            objectToSend = {
                ...objectToSend,
                target_price: Number(commaStringToFloat(watch("targetPrice"), Number(selectedAssetPrecision))),
                frequency: Number(watch("frequency").value),
            }
        }

        if (targetOption === TargetOptions.MakePercentageMove) {
            objectToSend = {
                ...objectToSend,
                change_percentage: Number(watch("percentageChange").value),
                time_window: Number(watch("timeWindow").value),
            }
        }

        try {
            setIsLoading(true)
            if (priceAlert) {
                await updatePriceAlert({
                    alertId: String(priceAlert.id),
                    ...objectToSend,
                }).unwrap()
            } else {
                await createPriceAlert(objectToSend).unwrap()
            }
            successToast(t(isNewPriceAlert ? "notifications.priceAlertAdded" : "notifications.priceAlertEdited"))
            reset()
            toggleSideDrawerOpen()
        } catch (error: any) {
            const message = error.data.message
            errorToast(t("common:error.server"), message ?? t("common:error.serverDesc"))
        } finally {
            setIsLoading(false)
        }
    }

    const currentView = useMemo(() => {
        switch (targetOption) {
            case TargetOptions.HitTargetPrice:
                return (
                    <HitTargetPrice
                        frequencyOptions={frequencyOptions}
                        control={control}
                        selectedAssetDetails={coinOption ? data?.[coinOption.value] : undefined}
                    />
                )
            case TargetOptions.MakePercentageMove:
                return <MakePercentageMove control={control} timeWindowOptions={timeWindowOptions} />

            default:
                return null
        }
    }, [targetOption, frequencyOptions, control])

    return (
        <SideDrawer
            name="addEditPriceAlert"
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            size="md"
            header={
                <StandardDrawerHeader
                    title={t(isNewPriceAlert ? "notifications.addNewPriceAlert" : "notifications.editPriceAlert")}
                    onClose={toggleSideDrawerOpen}
                />
            }
            footerButton={{
                children: t(isNewPriceAlert ? "notifications.addPriceAlert" : "notifications.saveChanges"),
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                onClick: submit,
                type: "submit",
                isLoading,
            }}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Flex mt={7} flexDir="column" gap={6}>
                    <CoinSelect
                        options={coinOptions}
                        selectedOption={coinOption || undefined}
                        setSelectedOption={setCoinOption}
                        search={false}
                    />

                    <Select
                        name="target"
                        control={control}
                        label={t("notifications.notifyMeWhenIt")}
                        rules={{ required: ct("error.requiredOption", { ns: "common" }) }}
                        options={targetOptions}
                    />
                    {currentView}
                </Flex>
            </form>
        </SideDrawer>
    )
}
