import { Box, Icon, Link, Text } from "@chakra-ui/react"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import useRoutingUtils from "@routing/useRoutingUtils"
import ChartUp from "assets/svgs/chart-up"
import { useMemo } from "react"
import { IconType } from "react-icons"
import {
    MdDashboard,
    MdOutlineDashboard,
    MdOutlinePayment,
    MdOutlineSwapVert,
    MdPayment,
    MdSwapVert,
} from "react-icons/md"
import { NavLink } from "react-router-dom"
import { ROUTES } from "routing/routes"

export type NavButton = {
    label: string
    path: string
    icon: IconType
    iconOutline: IconType
    isCAOnly?: boolean
}

export const SIDEBAR_WIDTH_LARGE = 253
export const SIDEBAR_WIDTH_SMALL = 72.5

export const SIDE_BAR_ROUTES: NavButton[] = [
    {
        label: "Dashboard",
        path: ROUTES.DASHBOARD,
        icon: MdDashboard,
        iconOutline: MdOutlineDashboard,
    },
    {
        label: "Trade",
        path: ROUTES.DASHBOARD_TRADE,
        // Exception required for ROUTES.DASHBOARD_TRADE here because the icon isn't part of the react-icons library
        icon: ChartUp as IconType,
        iconOutline: ChartUp as IconType,
    },
    {
        label: "Transfer",
        path: ROUTES.DASHBOARD_TRANSFER,
        icon: MdSwapVert,
        iconOutline: MdOutlineSwapVert,
    },
    {
        label: "Netcoins Pay",
        path: ROUTES.DASHBOARD_NETCOINS_PAY,
        icon: MdPayment,
        iconOutline: MdOutlinePayment,
        isCAOnly: true,
    },
]

export default function Sidebar() {
    const { isActive } = useRoutingUtils()
    const { data: userData } = useGetAccountDetailsQuery()
    const isNPayAccessible = userData?.features?.ncpay?.accessible || false

    const filteredRoutes = useMemo(
        () =>
            SIDE_BAR_ROUTES.filter((route) => {
                if (route.path === ROUTES.DASHBOARD_NETCOINS_PAY) {
                    return isNPayAccessible
                }
                if (route.isCAOnly) {
                    return userData?.country === "CA"
                }
                return true
            }),
        [userData, isNPayAccessible]
    )

    return (
        <Box
            display={{ base: "none", md: "inline-block" }}
            h="full"
            p="2rem 0.75rem"
            width={{ base: `${SIDEBAR_WIDTH_SMALL}px`, xl: `${SIDEBAR_WIDTH_LARGE}px` }}
            borderRight="1px solid #AEAEB280"
        >
            {filteredRoutes.map((route: NavButton) => (
                <Link
                    data-test-id={"sidebar-nav" + route.label}
                    as={NavLink}
                    end
                    key={route.path}
                    _hover={{
                        bgColor: isActive(route.path) ? "bluePurple.100" : "blue.10",
                    }}
                    to={route.path}
                    display="flex"
                    alignItems="center"
                    columnGap="1.5rem"
                    p={{ base: "0.75rem", xl: "0.5rem 0.75rem" }}
                    borderRadius={{ base: "8px", xl: "4px" }}
                    mb="0.75rem"
                    bgColor={isActive(route.path) ? "bluePurple.100" : "white"}
                    w="full"
                    justifyContent={{ base: "center", xl: "flex-start" }}
                >
                    {/* Exception required for ROUTES.DASHBOARD_TRADE here because the icon isn't part of the react-icons library */}
                    {route.path === ROUTES.DASHBOARD_TRADE ? (
                        <ChartUp color={isActive(route.path) ? "white" : "#07072E"} size={24} />
                    ) : (
                        <Icon
                            as={isActive(route.path) ? route.icon : route.iconOutline}
                            color={isActive(route.path) ? "white" : "bluePurple.100"}
                            h="24px"
                            w="24px"
                        />
                    )}
                    <Text
                        textStyle="ManropeSemiboldBodySmall"
                        display={{ base: "none", xl: "inline-block" }}
                        color={isActive(route.path) ? "white" : "bluePurple.100"}
                    >
                        {route.label}
                    </Text>
                </Link>
            ))}
        </Box>
    )
}
