import { Box, Text } from "@chakra-ui/react"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { SubScreenProps } from "../useOnboarding"

import { useTranslation } from "react-i18next"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"

export default function SurveyIntro({ advancePhase }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])

    function handleContinue() {
        AmplitudeClient.logRegistrationEvent(REG_SCREENS.SurveyIntro, REGISTRATION_STEP.SURVEY_INTRO)
        advancePhase()
    }

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("surveys.introTitle")}
            </Text>
            <Box rowGap="1.5rem" display="flex" flexDir={"column"} alignItems={"flex-start"}>
                <Text textStyle="InterRegularBodySmall">{t("surveys.introLine1")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("surveys.introLine2")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("surveys.introLine3")}</Text>
                <StandardButton
                    data-testid="continue-button"
                    onClick={handleContinue}
                    type="button"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="1.5rem"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </Box>
        </Box>
    )
}
