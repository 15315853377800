import React, { useEffect } from "react"
import { Box, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Select from "@/components/ui/select"
import TextInput from "@/components/ui/textInput"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { CountryOptions, RegionOptions } from "@/util/optionsFormatted"
import CountriesJSON from "../../../json/countries.json"
import RegionsJSON from "../../../json/provinces.json"
import { Option } from "@components/ui/types"
import { REGISTRATION_STEP, REG_SCREENS, SSNTypes } from "../types"
import { SubScreenProps } from "../useOnboarding"
import { IdentityCitizenship } from "@redux/account/types"
import { useUpdateAccountIdentityMutation } from "@redux/account/apiSlice"

type FormData = {
    citizenship: Option<keyof typeof CountriesJSON>
    type: Option<SSNTypes>
    id_number?: string
    state?: Option<keyof typeof RegionsJSON.states>
    ssn: string
}

const WHY_PROVIDE_LINK =
    "https://netcoinsusa.zendesk.com/hc/en-us/articles/10867367731220-Why-do-I-need-to-provid[…]-digits-of-my-SSN-or-full-number-of-other-identification-"

export default function SSN({ submitSubForm, isLoading, userObj }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control, watch, getValues, resetField } = useForm<FormData>({
        defaultValues: {
            citizenship: formatDefaultCitizenship(),
        },
    })
    const [updateAccountIdentity] = useUpdateAccountIdentityMutation()

    const [isLocalLoading, toggleLocalLoading] = React.useState(false)

    function formatDefaultCitizenship() {
        if (userObj?.identity.citizenship) {
            if (userObj?.identity.citizenship === IdentityCitizenship.US_CITIZEN) {
                return { value: "US" as keyof typeof CountriesJSON, label: "United States" }
            } else {
                const option = CountryOptions.countries.find(
                    (country) => country.value === userObj?.identity.citizenship
                )
                if (option) return option
                else return undefined
            }
        }
        return undefined
    }

    function formatIdType(
        type: SSNTypes,
        id_number?: string,
        state?: Option<keyof typeof RegionsJSON.states>,
        countryCode?: keyof typeof CountriesJSON
    ) {
        switch (type) {
            case SSNTypes.SSN:
                return {} // empty obj here because it is mandatory to add it directly now
            case SSNTypes.DRIVERS_LICENSE:
                return { drivers_license: { number: id_number, state: state?.value } }
            case SSNTypes.PASSPORT:
                return { passport: { number: id_number, country: countryCode } }
        }
    }

    async function onSubmit(values: FormData) {
        toggleLocalLoading(true)
        const citizenship = values.citizenship.value

        const payload = {
            citizenship,
            id: {
                ...formatIdType(values.type.value, values.id_number, values.state, values.citizenship.value),
                ssn: values.ssn,
            },
        }

        await submitSubForm(updateAccountIdentity, payload, () => {
            AmplitudeClient.logEvent(REG_SCREENS.SSN, REGISTRATION_STEP.SSN)
        })
    }

    const ID_NUMBER_MIN_MAX = React.useMemo(() => {
        switch (getValues("type")?.value) {
            case SSNTypes.PASSPORT:
                return { minLength: 6, maxLength: 10 }
            default:
                return { minLength: 1, maxLength: 99 } // placeholder when no type or drivers licence is selected
        }
    }, [getValues("type")])

    const SORTED_COUNTRIES_OPTIONS = React.useMemo(() => {
        const arr = CountryOptions.countries.filter((option) => option.value !== "US")
        arr.unshift({ value: "US", label: "United States" })
        return arr
    }, [CountryOptions.countries])

    const ID_OPTIONS = React.useMemo(() => {
        const usOptions = [
            { value: SSNTypes.SSN, label: t("ssn.SSN") },
            { value: SSNTypes.DRIVERS_LICENSE, label: t("ssn.license") },
            { value: SSNTypes.PASSPORT, label: t("ssn.passport") },
        ]
        return getValues("citizenship")?.value === "US"
            ? usOptions
            : usOptions.filter((option) => option.value !== SSNTypes.SSN)
    }, [getValues("citizenship")])

    // clear type when citizenship changes (this can cascade to below useEffect)
    useEffect(() => {
        resetField("type")
    }, [getValues("citizenship")])

    // clear fields when changing identification type
    useEffect(() => {
        resetField("id_number")
        resetField("state")
    }, [getValues("type")])

    return (
        <Box w="full">
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("ssn.title")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir={"column"} rowGap="1.5rem">
                    <Select
                        name="citizenship"
                        options={SORTED_COUNTRIES_OPTIONS}
                        control={control}
                        rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                        placeholder="Select one"
                        label={t("ssn.citizenship")}
                    />
                    <Select
                        name="type"
                        label={t("ssn.identificationType")}
                        rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                        control={control}
                        placeholder="Select one"
                        disabled={!watch("citizenship")?.value}
                        options={ID_OPTIONS}
                    />
                    {watch("type")?.value === SSNTypes.PASSPORT && (
                        <Select
                            name="citizenship"
                            options={CountryOptions.countries}
                            control={control}
                            rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                            label={t("ssn.passportCountry")}
                            disabled
                        />
                    )}
                    {watch("type")?.value === SSNTypes.DRIVERS_LICENSE && (
                        <Select
                            name="state"
                            options={RegionOptions.states}
                            control={control}
                            rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                            label={t("ssn.jurisdiction")}
                        />
                    )}
                    <Text>
                        <Text as="span" textStyle="InterRegularBodySmall">
                            {watch("type")?.value === SSNTypes.DRIVERS_LICENSE ||
                            watch("type")?.value === SSNTypes.PASSPORT
                                ? t("ssn.idTexts")
                                : t("ssn.idText")}
                            &nbsp;
                        </Text>
                        <Text
                            as="span"
                            textStyle="InterRegularBodySmall"
                            color="blue.100"
                            cursor="pointer"
                            onClick={() => window.open(WHY_PROVIDE_LINK, "_blank")}
                        >
                            {t("ssn.ssnWhy")}
                        </Text>
                    </Text>
                    <TextInput
                        control={control}
                        name="ssn"
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            pattern: {
                                value: /^\d{9}$/,
                                message: t("ssn.validSsn"),
                            },
                        }}
                        placeholder={"123456789"}
                        label={t("ssn.number", { type: "Social Security" })}
                        type="password"
                    />
                    <TextInput
                        key={`id_number_${watch("type")?.value}`}
                        display={
                            watch("type")?.value === SSNTypes.DRIVERS_LICENSE ||
                            watch("type")?.value === SSNTypes.PASSPORT
                                ? "block"
                                : "none"
                        }
                        control={control}
                        name="id_number"
                        rules={{
                            ...(getValues("type")?.value === SSNTypes.SSN
                                ? { required: false }
                                : { required: t("error.required", { ns: "common" }) }),
                            minLength: {
                                value: ID_NUMBER_MIN_MAX.minLength,
                                message: t("error.minLength", { ns: "common", len: ID_NUMBER_MIN_MAX.minLength }),
                            },
                            maxLength: {
                                value: ID_NUMBER_MIN_MAX.maxLength,
                                message: t("error.maxLength", { ns: "common", len: ID_NUMBER_MIN_MAX.maxLength }),
                            },
                        }}
                        placeholder={watch("type")?.value === SSNTypes.PASSPORT ? "AA555555" : "123456789"}
                        label={t("ssn.number", { type: watch("type")?.label })}
                    />
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="2.5rem"
                    w="full"
                    isLoading={isLoading || isLocalLoading}
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
