import { imageMap } from "@assets/svgs/coins"
import { Box, Flex, Image, SkeletonCircle, SkeletonText, Td, Text, Tr, useBreakpointValue } from "@chakra-ui/react"
import IconMenu from "@components/ui/menus/icon"
import { memo } from "react"
import { useTranslation } from "react-i18next"

type OpenOrderRowProps = {
    name: string
    symbol: string
    orderType: string
    price: string
    onViewMorePress: () => void
}

const SkeletonLineGroup = memo(() => {
    return (
        <Flex gap={2} flexDir="column">
            <SkeletonText w="105px" noOfLines={1} skeletonHeight={3} />
            <SkeletonText w="105px" noOfLines={1} skeletonHeight={3} />
        </Flex>
    )
})

export const SkeletonOpenOrdersRow = () => {
    return (
        <Tr
            w="full"
            sx={{
                "& td": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                },
            }}
        >
            <Td>
                <Flex gap={3} alignItems="center">
                    <SkeletonCircle size={"10"} />
                    <SkeletonLineGroup />
                </Flex>
            </Td>

            <Td>
                <Flex
                    textAlign={{ sm: "left", base: "right" }}
                    flexDir="row"
                    justifyContent={{ sm: "flex-start", base: "flex-end" }}
                    alignItems={{ sm: "flex-start", base: "center" }}
                >
                    <SkeletonLineGroup />
                    <Box display={{ sm: "none", base: "inline-block" }}>
                        <IconMenu buttonProps={{ isDisabled: true }} items={[]} />
                    </Box>
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" gap={2} justifyContent="flex-end" alignItems="flex-end">
                    <SkeletonLineGroup />
                </Flex>
            </Td>

            <Td w="40px" display={{ base: "none", sm: "table-cell" }}>
                <Box display="inline-block" verticalAlign="middle" ml={2}>
                    <IconMenu buttonProps={{ isDisabled: true }} items={[]} />
                </Box>
            </Td>
        </Tr>
    )
}

export const OpenOrdersRow = ({ symbol, price, name, orderType, onViewMorePress }: OpenOrderRowProps) => {
    const image = imageMap[symbol.toLowerCase() as keyof typeof imageMap]
    const isMobile = useBreakpointValue({ base: true, sm: false })

    const { t } = useTranslation(["app", "common"])

    return (
        <Tr
            w="full"
            sx={{
                "& td": {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 2.5,
                    paddingBottom: 2.5,
                },
            }}
        >
            <Td>
                <Flex gap={3} alignItems="center">
                    {image && (
                        <Image
                            src={image}
                            width={{ base: "28px", sm: "42px" }}
                            height={{ base: "28px", sm: "42px" }}
                            alt={`Bitcoin icon`}
                            borderRadius="full"
                        />
                    )}

                    <Flex flexDir="column">
                        <Text textStyle="ManropeSemiboldBodySmall">{name}</Text>
                        <Text
                            textStyle={{ base: "ManropeSemiboldBodySmall", sm: "ManropeSemiboldXSmall" }}
                            color="grey.light.50"
                        >
                            {symbol}
                        </Text>
                    </Flex>
                </Flex>
            </Td>

            <Td>
                <Flex
                    textAlign={{ sm: "left", base: "right" }}
                    flexDir="row"
                    justifyContent={{ sm: "flex-start", base: "flex-end" }}
                    alignItems={{ sm: "flex-start", base: "center" }}
                >
                    <Text textStyle="ManropeSemiboldBodySmall">{isMobile ? orderType + " " + price : orderType}</Text>
                    <Box display={{ sm: "none", base: "inline-block" }}>
                        <IconMenu items={[{ text: t("viewMore", { ns: "common" }), onClick: onViewMorePress }]} />
                    </Box>
                </Flex>
            </Td>

            <Td display={{ base: "none", sm: "table-cell" }}>
                <Flex flexDir="column" justifyContent="flex-end" alignItems="flex-end">
                    <Text textStyle="ManropeSemiboldBodySmall">{price}</Text>
                </Flex>
            </Td>

            <Td w="40px" display={{ base: "none", sm: "table-cell" }}>
                <Box display="inline-block" verticalAlign="middle" ml={2}>
                    <IconMenu items={[{ text: t("viewMore", { ns: "common" }), onClick: onViewMorePress }]} />
                </Box>
            </Td>
        </Tr>
    )
}
