import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import useImage from "@hooks/useImage"
import QRCode from "react-qr-code"

const googleAuthPlayStoreLink = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
const googleAuthAppStoreLink = "https://apps.apple.com/us/app/google-authenticator/id388497605"

export default function TwoFAAuthyInfo() {
    const { t } = useTranslation(["app", "common"])

    const { image } = useImage("common/google-auth.png")

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"} mb={8}>
                {t("twoFASetup.setupTwoFA", { ns: "twoFA" })}
            </Text>
            <Flex alignItems={"center"}>
                {image && (
                    <Box
                        h="68px"
                        w="68px"
                        borderRadius="md"
                        overflow="hidden"
                        borderWidth={1}
                        borderColor={"grey.light.10"}
                        p={1}
                        mr={3}
                    >
                        <Image src={image} alt={"google-auth"} height={"100%"} width={"100%"} />
                    </Box>
                )}
                <Text textStyle={"ManropeSemiboldBody"} flex={1}>
                    {t("twoFASetup.downloadGoogleAuthenticator", { ns: "twoFA" })}
                </Text>
            </Flex>
            <Text mt={2} textStyle={"InterRegularBodySmall"}>
                {t("twoFASetup.haveOneAlready", { ns: "twoFA" })}
            </Text>
            <HStack spacing={6} mt={8} justifyContent={"space-evenly"}>
                <Box h="fit-content" display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <QRCode value={googleAuthAppStoreLink} size={128} />
                    <Text mt={3} textStyle={"ManropeSemiboldBody"}>
                        {t("appStore", { ns: "common" })}
                    </Text>
                    <Text mt={1} textStyle={"InterRegularBodySmall"}>
                        {t("forIPhones", { ns: "common" })}
                    </Text>
                </Box>
                <Box h="fit-content" display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <QRCode value={googleAuthPlayStoreLink} size={128} />
                    <Text mt={3} textStyle={"ManropeSemiboldBody"}>
                        {t("playStore", { ns: "common" })}
                    </Text>
                    <Text mt={1} textStyle={"InterRegularBodySmall"}>
                        {t("forAndroid", { ns: "common" })}
                    </Text>
                </Box>
            </HStack>
        </Box>
    )
}
