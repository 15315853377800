import { Box, Text } from "@chakra-ui/react"
import RadioSelect, { RadioSelectOption } from "@components/ui/radio/radioSelect"
import { DrawerFundContext } from "@screens/dashboard/transfer/drawerFund/DrawerFundContext"
import { useTranslation } from "react-i18next"

export const CryptoSelection: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { selectedCoin, setSelectedCoin, coinRadioOptions, setErrorMessage } = DrawerFundContext.useContainer()

    const updateSelectedCoin = (coin: RadioSelectOption | null) => {
        setSelectedCoin(coin)
        setErrorMessage("")
    }

    return (
        <Box mt={7} flex={1} display={"flex"} flexDirection={"column"} overflow={"auto"} height={"100%"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithCrypto", { ns: "transfer" })}</Text>
            <Box mt={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("fund.selectCryptoCurrency", { ns: "transfer" })}</Text>
            </Box>
            <RadioSelect options={coinRadioOptions} onSelect={updateSelectedCoin} selectedOption={selectedCoin} />
        </Box>
    )
}
