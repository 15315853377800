import { useAppSelector } from "@/store/hooks"
import Carousel from "@components/carousel"
import { selectAccountDetails } from "@redux/account/selectors"
import { ReactNode, useMemo } from "react"
import { APXBanner } from "./banners/APXBanner"
import { NPayBanner } from "./banners/NPayBanner"
import { StakingBanner } from "./banners/StakingBanner"
import { TokenEyesBanner } from "./banners/TokenEyesBanner"

export const PromoBanners = () => {
    const account = useAppSelector(selectAccountDetails)
    const isCAUser = account?.country === "CA"
    const canAccessNPay = account?.features.ncpay.accessible
    const banners = useMemo(() => {
        const res: ReactNode[] = []
        if (isCAUser) {
            res.push(<StakingBanner />)
        }
        if (isCAUser && new Date() < new Date("2025-03-31")) {
            res.push(<TokenEyesBanner />)
        }
        if (canAccessNPay) {
            res.push(<NPayBanner />)
        }
        if (!isCAUser) {
            res.push(<APXBanner />)
        }

        return res
    }, [account, isCAUser, canAccessNPay])

    return <Carousel frequency={10000} components={banners} />
}
