import { Flex, Text } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import Link from "@components/ui/link"
import { useTranslation } from "react-i18next"
import { NPayRegContext } from "../nPayRegistrationContext"
import { ROUTES } from "@routing/routes"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { MdOpenInNew } from "react-icons/md"

export default function ReviewTermsAndConditions() {
    const { t } = useTranslation("app", { keyPrefix: "netcoinsPay" })

    const { disclaimers, setDisclaimers } = NPayRegContext.useContainer()

    return (
        <Flex gap={6} flexDir="column">
            <Text textStyle="ManropeSemiboldBody">{t("reviewTermsAndConditions")}</Text>

            <Flex borderRadius="4px" border="1px" borderColor="grey.light.10">
                <Flex flexDir="column" w="full">
                    <Flex borderBottom="1px" borderColor="grey.light.10" py={3} px={1}>
                        <StandardButton
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                            rightIcon={MdOpenInNew}
                            onClick={() => window.open(ROUTES.MC_CARDHOLDER_AGREEMENT, "_blank")}
                        >
                            {t("cardholderAgreement")}
                        </StandardButton>
                    </Flex>
                    <Flex py={3} px={1}>
                        <StandardButton
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_GHOST}
                            rightIcon={MdOpenInNew}
                            onClick={() => window.open(ROUTES.MC_REWARDS_TERMS, "_blank")}
                            sx={{
                                whiteSpace: "normal",
                                textAlign: "left",
                            }}
                        >
                            {t("rewardsAndTermsAndConditions")}
                        </StandardButton>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap={2} flexDir="column">
                <Text textStyle="ManropeSemiboldBody">{t("cardholderTerms")}</Text>

                <Checkbox
                    name="disclaimer1"
                    text={t("IAgreeToTheTermsAndConditions")}
                    isChecked={disclaimers.disclaimer1}
                    setValue={(checked) => setDisclaimers({ ...disclaimers, disclaimer1: checked })}
                />
            </Flex>

            <Flex gap={2} flexDir="column">
                <Text textStyle="ManropeSemiboldBody">{t("foreignTaxDisclaimer")}</Text>

                <Checkbox
                    name="disclaimer2"
                    text={t("noTaxFilingObligation")}
                    isChecked={disclaimers.disclaimer2}
                    setValue={(checked) => setDisclaimers({ ...disclaimers, disclaimer2: checked })}
                />
            </Flex>

            <Flex gap={2} flexDir="column">
                <Text textStyle="ManropeSemiboldBody">{t("thirdPartyDisclaimer")}</Text>

                <Checkbox
                    name="disclaimer3"
                    text={t("cardForMyself")}
                    isChecked={disclaimers.disclaimer3}
                    setValue={(checked) => setDisclaimers({ ...disclaimers, disclaimer3: checked })}
                />
            </Flex>
        </Flex>
    )
}
