import { API_PATH, NPAY_TAG_TYPES } from "./const"
import { splitApi } from "@redux/splitApi"
import { INPayTransactionsResponse, nPayResponse, nPayTransactionsParams, TCardType } from "./types"
import { ACCOUNT_TAG_TYPES } from "@redux/account/const"

export const nPayApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getNPayOverView: builder.query<nPayResponse, void>({
            query: () => ({
                url: `${API_PATH.NPAY_OVERVIEW}`,
                method: "GET",
            }),
            providesTags: [NPAY_TAG_TYPES.NPAY_OVERVIEW],
        }),
        toggleCardLock: builder.mutation<void, { id: number }>({
            query: (params) => ({
                url: `${API_PATH.LOCK_NPAY}`,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [NPAY_TAG_TYPES.NPAY_OVERVIEW],
        }),
        viewCard: builder.mutation<{ imgSrc: string }, { id: number }>({
            query: (params) => ({
                url: `${API_PATH.VIEW_NPAY_CARD}`,
                method: "POST",
                body: params,
            }),
        }),
        nPayTransactions: builder.query<INPayTransactionsResponse, nPayTransactionsParams>({
            query: ({ page = 1, limit = 10, sort = "desc", range = "", cardTypes = "" }) => {
                let url = `${API_PATH.NPAY_TRANSACTIONS}?page=${page}&limit=${limit}&sort=${sort}&range=${range}&cards=${cardTypes}`

                return {
                    url: url,
                    method: "GET",
                }
            },
            providesTags: [NPAY_TAG_TYPES.NPAY_TRANSACTIONS],
        }),
        registerNpay: builder.mutation<void, { cardTypes: Array<TCardType> }>({
            query: (params) => ({
                url: `${API_PATH.REGISTER_NPAY}`,
                method: "POST",
                body: {
                    cardTypes: params.cardTypes,
                    isDetailsCorrect: true,
                    disclaimers: {
                        thirdParty: true,
                        foreignTax: true,
                    },
                    noc: null,
                    occupation: null,
                },
            }),
            invalidatesTags: [
                NPAY_TAG_TYPES.NPAY_OVERVIEW,
                NPAY_TAG_TYPES.NPAY_TRANSACTIONS,
                ACCOUNT_TAG_TYPES.accountDetails,
            ],
        }),
        addNPayCard: builder.mutation<void, { cardTypes: Array<TCardType> }>({
            query: (params) => ({
                url: `${API_PATH.ADD_NPAY_PRODUCT}`,
                method: "POST",
                body: {
                    cardTypes: params.cardTypes,
                    disclaimers: {
                        thirdParty: true,
                        foreignTax: true,
                    },
                },
            }),
            invalidatesTags: [
                NPAY_TAG_TYPES.NPAY_OVERVIEW,
                NPAY_TAG_TYPES.NPAY_TRANSACTIONS,
                ACCOUNT_TAG_TYPES.accountDetails,
            ],
        }),
    }),
})

export const {
    useGetNPayOverViewQuery,
    useToggleCardLockMutation,
    useViewCardMutation,
    useNPayTransactionsQuery,
    useAddNPayCardMutation,
    useRegisterNpayMutation,
} = nPayApi
