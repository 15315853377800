import { Box, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { useMemo } from "react"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "../useOnboarding"
import { REG_SCREENS, REGISTRATION_STEP } from "../types"
import { useAcceptDisclaimerMutation, useGetDisclaimerQuery } from "@redux/account/apiSlice"

// todo: generalize as disclaimer component
const name = "overage_third_party"

export default function OverageDisclaimer({ submitSubForm, isLoading, userObj }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])

    const contentKey = useMemo(
        () => userObj.verification?.disclaimers?.[name]?.content_key ?? "overage_third_party_en_ca",
        []
    )
    const { data: disclaimerContent } = useGetDisclaimerQuery(contentKey)
    const [acceptDisclaimer] = useAcceptDisclaimerMutation()

    function handleAcknowledge() {
        submitSubForm(
            acceptDisclaimer,
            {
                content_key: contentKey,
            },
            () => {
                AmplitudeClient.logRegistrationEvent(
                    REG_SCREENS.OverageThirdPartyDisclaimer,
                    REGISTRATION_STEP.OverageDisclaimer
                )
            }
        )
    }

    return (
        <Box>
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("safetyDisclaimer.overageTitle")}
            </Text>
            <Flex rowGap="0.75rem" overflowY={"auto"} height="500px" borderRadius={"6px"} py="1.25rem" px="1.75rem">
                {disclaimerContent ? (
                    <div
                        dangerouslySetInnerHTML={{ __html: disclaimerContent }}
                        className="disclaimers-text"
                        style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "150%",
                            listStylePosition: "outside",
                        }}
                    />
                ) : (
                    <Flex flex={1} align="center" justify="center">
                        <Spinner />
                    </Flex>
                )}
            </Flex>
            <StandardButton
                data-testid="continue-button"
                onClick={handleAcknowledge}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                mt="1rem"
                w="full"
                isLoading={isLoading}
            >
                {t("continue", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
