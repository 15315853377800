import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import { useCalculateBalances } from "@screens/dashboard/balances/useCalculateBalances"
import { useMemo } from "react"
import { FiatBalanceCard } from "./fiatBalanceCard"

type DashboardFiatBalanceProps = {
    onAddFundsClick: () => void
}

export const DashboardFiatBalance = ({ onAddFundsClick }: DashboardFiatBalanceProps) => {
    const { fiatBalances, isLoadingBalances } = useCalculateBalances()
    const country = useAppSelector(selectAccountDetails)?.country
    const currency = useMemo(() => (country === "CA" ? "CAD" : "USD"), [country])
    return (
        <FiatBalanceCard
            key={currency}
            currency={currency}
            balance={fiatBalances && currency in fiatBalances ? fiatBalances[currency] : "0"}
            isLoading={isLoadingBalances}
            onAddFundsClick={onAddFundsClick}
        />
    )
}
