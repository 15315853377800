import { Box, Flex, Icon, Table, Tbody, Text, Th, Thead, Tr, useBreakpointValue } from "@chakra-ui/react"
import { OpenOrdersRow, SkeletonOpenOrdersRow } from "@components/dashboard/openOrders/openOrdersRow"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useGetTransactionsQuery } from "@redux/transactions/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { ROUTES } from "@routing/routes"
import { getSideText } from "@screens/dashboard/marketDetails/assetStats/OpenOrders"
import { addCommasToNumber } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FaAngleRight, FaChevronRight } from "react-icons/fa"

type DashboardOpenOrdersProps = {
    onViewMorePress: (t: Transaction) => void
}

export default function DashboardOpenOrders({ onViewMorePress }: DashboardOpenOrdersProps) {
    const { navigate } = useRestrictedNavigation()
    const { data: openOrdersData, isLoading: isLoadingOpenOrders } = useGetTransactionsQuery({
        types: "",
        page: 1,
        statuses: "open,cancelling",
        limit: 5,
        sort: "desc",
    })
    const { data: assetDetailsData, isLoading: isLoadingAssetDetails } = useGetAssetsDetailsQuery({})
    const transactions = useMemo(() => {
        if (!openOrdersData || !assetDetailsData) return []

        return openOrdersData?.transactions
            .map((transaction) => {
                if (!(transaction.asset.toUpperCase() in assetDetailsData)) {
                    return undefined
                }
                const asset = assetDetailsData[transaction.asset.toUpperCase()]
                return {
                    ...transaction,
                    name: asset.name,
                    orderType: getSideText(transaction),
                    price: addCommasToNumber(transaction.rate, {
                        precision: asset.price_precision,
                        addDollarSign: true,
                        minFiatPrecision: true,
                    }),
                    symbol: transaction.asset,
                }
            })
            .filter((t) => t !== undefined)
    }, [openOrdersData, assetDetailsData])
    const isLoading = useMemo(
        () => isLoadingAssetDetails || isLoadingOpenOrders,
        [isLoadingAssetDetails, isLoadingOpenOrders]
    )

    const navigateToOpenOrders = () => {
        navigate(ROUTES.DASHBOARD_OPEN_ORDERS)
    }

    const navigateToTrade = () => {
        navigate(ROUTES.DASHBOARD_TRADE)
    }

    return (
        <OpenOrdersTable
            isLoading={isLoading}
            transactions={transactions}
            onOpenOrdersClick={navigateToOpenOrders}
            onBrowseCoinsClick={navigateToTrade}
            onViewMorePress={onViewMorePress}
        />
    )
}

type OpenOrdersTableProps = {
    isLoading?: boolean
    transactions: ((Transaction & { name: string; price: string; orderType: string }) | undefined)[]
    onOpenOrdersClick: () => void
    onBrowseCoinsClick: () => void
    onViewMorePress: (t: Transaction) => void
}

export const OpenOrdersTable = ({
    isLoading,
    transactions,
    onOpenOrdersClick,
    onBrowseCoinsClick,
    onViewMorePress,
}: OpenOrdersTableProps) => {
    const { t } = useTranslation("app")
    const isMobile = useBreakpointValue({ base: true, sm: false })

    return (
        <Flex flexDir="column" gap={2}>
            <Flex alignItems="center" justifyContent="space-between" gap={2}>
                <Text textStyle="ManropeSemiboldBody">{t("dashboard.myOpenOrders")}</Text>
                {(isLoading || transactions.length > 0) && (
                    <Flex
                        alignItems="center"
                        justifyContent="flex-end"
                        cursor="pointer"
                        gap={1}
                        ml={{ base: "auto", md: "unset" }}
                        flex="0 0 auto"
                        whiteSpace="nowrap"
                        sx={{
                            "&:hover": {
                                textDecorationColor: "blue.100 !important",
                                textDecoration: "underline",
                            },
                            "&:active": {
                                textDecorationColor: "blue.70 !important",
                                textDecoration: "underline",
                            },
                        }}
                        onClick={onOpenOrdersClick}
                    >
                        <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                            {t("dashboard.viewAll")}
                        </Text>
                        <Icon as={FaAngleRight} size={16} color="blue.100" />
                    </Flex>
                )}
            </Flex>

            {isLoading || transactions.length > 0 ? (
                <Flex flexDir="column" w="100%">
                    <Table>
                        <Thead bg="grey.light.5">
                            <Tr
                                sx={{
                                    "& th": {
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                    },
                                }}
                                borderBottom="1px solid #636366"
                                pr={{ base: "0.5rem", md: "1rem" }}
                                pl={0}
                            >
                                <Th px={0} pl={2}>
                                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                        {t("dashboard.cryptoAsset")}
                                    </Text>
                                </Th>

                                <Th px={0} textAlign={{ sm: "left", base: "right" }} pr={{ sm: 0, base: 2 }}>
                                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                        {t(isMobile ? "dashboard.orderTypeAndPrice" : "dashboard.orderType")}
                                    </Text>
                                </Th>

                                <Th px={0} textAlign="right" display={{ base: "none", sm: "table-cell" }}>
                                    <Text textStyle="ManropeSemiboldXSmall" color="grey.light.90" textTransform="none">
                                        {t("dashboard.price")}
                                    </Text>
                                </Th>

                                <Th display={{ base: "none", sm: "table-cell" }} width="40px" />
                            </Tr>
                        </Thead>

                        <Tbody py={1}>
                            {isLoading ? (
                                <SkeletonOpenOrdersRow />
                            ) : (
                                transactions?.map(
                                    (t) =>
                                        t && (
                                            <OpenOrdersRow
                                                key={t.order_id}
                                                name={t.name}
                                                symbol={t.symbol}
                                                price={t.price}
                                                orderType={t.orderType}
                                                onViewMorePress={() => onViewMorePress(t)}
                                            />
                                        )
                                )
                            )}
                        </Tbody>
                    </Table>
                </Flex>
            ) : (
                <Box borderWidth="1px" borderColor="grey.light.5" borderRadius="0px 0px 6px 6px">
                    <Flex flexDir="column" px={6} pt={{ base: 6, sm: 9 }} pb={6} w="full" gap={6}>
                        <Text color="grey.light.50" textStyle="ManropeSemiboldBodySmall">
                            {t("dashboard.openOrdersPlaceholder")}
                        </Text>

                        <Flex
                            alignItems="center"
                            cursor="pointer"
                            gap={1}
                            sx={{
                                "&:hover": {
                                    textDecorationColor: "blue.100 !important",
                                    textDecoration: "underline",
                                },
                                "&:active": {
                                    textDecorationColor: "blue.70 !important",
                                    textDecoration: "underline",
                                },
                            }}
                            onClick={onBrowseCoinsClick}
                        >
                            <Text color={"blue.100"} textStyle="ManropeSemiboldBodySmall">
                                {t("dashboard.browseCoins")}
                            </Text>
                            <Icon as={FaChevronRight} size={16} color="blue.100" />
                        </Flex>
                    </Flex>
                </Box>
            )}
        </Flex>
    )
}
