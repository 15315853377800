import { Text, Flex, Box } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { FaAngleRight } from "react-icons/fa"
import { MdAdd } from "react-icons/md"
import IconButton from "@components/ui/buttons/icon"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { useMemo, useState } from "react"
import SideDrawer, { SIDE_DRAWER_BUTTON_ID } from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import AppleWallet from "./views/AppleWallet"
import GoogleWallet from "./views/GoogleWallet"
import SamsungWallet from "./views/SamsungWallet"
import Menu from "./views/Menu"

export default function AddToWallet({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }) {
    const { t } = useTranslation("app")
    const { t: ct } = useTranslation("common")

    const [view, setView] = useState(1)

    const updateView = (value: number) => {
        setView(value)
    }

    const currentView = useMemo(() => {
        switch (view) {
            case 1:
                return <Menu updateView={updateView} />
            case 2:
                return <AppleWallet />
            case 3:
                return <GoogleWallet />
            case 4:
                return <SamsungWallet />
        }
    }, [view])

    return (
        <SideDrawer
            name="addToWallet"
            isOpen={isOpen}
            toggleSideDrawerOpen={() => {
                setIsOpen(!isOpen)
            }}
            header={
                <StandardDrawerHeader
                    title={t("netcoinsPay.addToWallet")}
                    onClose={() => {
                        setView(1)
                        setIsOpen(!isOpen)
                    }}
                    {...(view > 1 && { onBack: () => setView(1) })}
                />
            }
            footerButton={
                view > 1
                    ? undefined
                    : {
                          children: ct("close"),
                          variant: ENUM_BUTTON_VARIANTS.PRIMARY_OUTLINE,
                          id: SIDE_DRAWER_BUTTON_ID,
                          onClick: () => setIsOpen(false),
                      }
            }
        >
            <Box mt={7}>{currentView}</Box>
        </SideDrawer>
    )
}
