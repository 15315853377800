import TransactionsRow from "@components/transactions/transactionsRow"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { isFiatCurrency } from "@util/currencyHelpers"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { TransactionCardProps } from ".."

export const StakeReward: React.FC<TransactionCardProps> = (
    { transaction, viewMore, isLast, forcedSizeVariant },
    props
) => {
    const { increase_currency, increase_amount, asset, created_at_pst, decrease_amount, decrease_currency, status } =
        transaction || {}

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const incAmount = useMemo(() => {
        if (isFiatCurrency(increase_currency)) {
            return formatDollar(increase_amount)
        }

        const precisionToUse = data?.precision

        return formatNumberFixedPrecision(increase_amount, Number(precisionToUse), true)
    }, [increase_amount, increase_currency, data])

    return (
        <TransactionsRow
            forcedSizeVariant={forcedSizeVariant}
            transaction={{
                date: created_at_pst,
                description: `Staking Rewards Earned`,
                status: status,
                mainCurrency: increase_currency,
                mainAmount: incAmount,
                secAmount: decrease_amount,
                secCurrency: decrease_amount && decrease_currency ? decrease_currency : undefined,
            }}
            isReward
            handleViewMore={viewMore}
            isLast={isLast}
            {...props}
        />
    )
}
