import { createContainer } from "@util/UnstatedContext"
import { useEffect, useState } from "react"

export type CardType = "virtual" | "physical" | "all"

type InitialStateType = {
    cardType?: CardType
    disclaimers: {
        disclaimer1: boolean
        disclaimer2: boolean
        disclaimer3: boolean
    }
}
export const InitialState: InitialStateType = {
    disclaimers: {
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    },
}

const useNPayRegContext = ({ cardType, disclaimers: initialDisclaimers }: InitialStateType) => {
    const [errorMessage, setErrorMessage] = useState<string>("")

    const [currentCardType, setCurrentCardType] = useState<CardType | undefined>(cardType || "all")

    useEffect(() => {
        setCurrentCardType(cardType)
    }, [cardType])

    const [disclaimers, setDisclaimers] = useState(initialDisclaimers)

    const resetState = () => {
        setDisclaimers(initialDisclaimers)
        setErrorMessage("")
    }

    return {
        currentCardType,
        setCurrentCardType,
        disclaimers,
        setDisclaimers,
        resetState,
        errorMessage,
        setErrorMessage,
    }
}

export const NPayRegContext = createContainer(useNPayRegContext)
