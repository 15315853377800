import React from "react"
import { Box } from "@chakra-ui/react"
import RadioGroup from "components/ui/radio"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { REG_SCREENS } from "@screens/onboarding/types"
import AmplitudeClient from "sdks/amplitude"
import { SubScreenProps } from "../useOnboarding"
import { Option } from "@components/ui/types"
import { IAccountDetails, AccountType as AccountTypeEnum } from "@redux/account/types"
import useGenericToast from "@hooks/useGenericToast"
import { useUpdateAccountMutation } from "@redux/account/apiSlice"

type FormData = {
    type: Option<AccountTypeEnum>
}

type Props = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setPhases: React.Dispatch<React.SetStateAction<REG_SCREENS[]>>
    phases: REG_SCREENS[]
    setCurrentPhase: React.Dispatch<React.SetStateAction<{ name?: REG_SCREENS; num?: number }>>
} & SubScreenProps

export default function AccountType({ setIsLoading, isLoading, setPhases, setCurrentPhase }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            type: {
                value: AccountTypeEnum.INDIVIDUAL,
                label: `${t("accountType.personal")}`,
                desc: `${t("accountType.personalDesc")}`,
            },
        },
    })
    const { serverErrorToast } = useGenericToast()
    const [updateAccount] = useUpdateAccountMutation()

    async function onSubmitForm(values: FormData) {
        const { type } = values
        setIsLoading(true)
        await updateAccount({ type: type.value })
            .unwrap()
            .then(async (data: IAccountDetails) => {
                const nextScreen =
                    data.type === AccountTypeEnum.INDIVIDUAL ? REG_SCREENS.WhereReside : REG_SCREENS.WhereIncorporated
                setPhases((prev) => [...prev, nextScreen])
                setTimeout(() => {
                    setCurrentPhase({ name: nextScreen, num: 1 })
                }, 0)
                AmplitudeClient.logEvent(REG_SCREENS.AccountType)
            })
            .catch(() => serverErrorToast())
            .finally(() => setIsLoading(false))
    }

    return (
        <Box w="full">
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <RadioGroup
                    name="type"
                    isHorizontal={false}
                    control={control}
                    options={[
                        {
                            value: AccountTypeEnum.INDIVIDUAL,
                            label: `${t("accountType.personal")}`,
                            desc: `${t("accountType.personalDesc")}`,
                        },
                        {
                            value: AccountTypeEnum.BUSINESS,
                            label: `${t("accountType.business")}`,
                            desc: `${t("accountType.businessDesc")}`,
                        },
                    ]}
                />
                <StandardButton
                    data-testid="continue-button"
                    isLoading={isLoading}
                    type="submit"
                    w="full"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="3rem"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
