import { Box, Image, Text } from "@chakra-ui/react"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { FC, useMemo } from "react"
import { isProcessingState } from "../../helper"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import { useTranslation } from "react-i18next"
import { formatShortDateTime } from "@util/dateFormating"
import { imageMap } from "@assets/svgs/coins"

export const BuySellDetails: FC<{ transaction: Transaction }> = ({ transaction }) => {
    const {
        asset,
        status,
        action,
        order_id,
        created_at_pst,
        increase_currency,
        decrease_currency,
        increase_amount,
        decrease_amount,
        rate,
        fee_currency,
        subtotal,
        fee,
        total,
    } = transaction

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const { symbol } = selectedAsset || {}

    const image = imageMap[symbol?.toLowerCase() as keyof typeof imageMap]

    const processing = isProcessingState(status)

    const isBuy = action === TransactionAction.Buy
    const currency = isBuy ? increase_currency : decrease_currency
    const amount = isBuy ? increase_amount : decrease_amount

    const { t } = useTranslation(["common", "transactions"])

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Text textStyle={"ManropeSemiboldBody"}>
                {`${isBuy ? t("transactions:transactionDetails.youBought") : t("transactions:transactionDetails.youSold")}`}{" "}
                :
            </Text>
            <Box borderBottom={"1px solid"} borderColor={"grey.light.10"} paddingTop={3} />
            <Box
                display={"flex"}
                flexDirection={"row"}
                py={6}
                alignItems={"center"}
                borderBottom={"1px solid"}
                borderColor={"grey.light.10"}
            >
                {image && (
                    <Box h="62px" w="62px" borderRadius="31px" overflow="hidden">
                        <Image src={image} alt={"coin" + symbol?.toLowerCase()} height={"100%"} width={"100%"} />
                    </Box>
                )}
                <Box display={"flex"} flexDirection={"column"} flex={1} mx={3}>
                    <Text textStyle={"ManropeMediumXLarge"}>{selectedAsset?.name}</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {symbol}
                    </Text>
                </Box>
                {processing && (
                    <Box alignSelf={"center"}>
                        <Chip variant={ENUM_CHIP_VARIANTS.YELLOW} text="Processing" />
                    </Box>
                )}
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.light.10"}
            >
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.amount")}</Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                        {`${formatNumberFixedPrecision(amount, Number(selectedAsset?.precision), true)} ${currency}`}
                    </Text>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} marginTop={3}>
                    <Text
                        textStyle={"ManropeSemiboldBodySmall"}
                        textColor={"grey.light.70"}
                    >{`When 1 ${symbol} =`}</Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBodySmall"} textColor={"grey.light.70"}>
                        {`${formatNumberFixedPrecision(rate, Number(selectedAsset?.precision), true)} ${fee_currency}`}
                    </Text>
                </Box>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.light.10"}
            >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box display={"flex"} flexDirection={"column"} paddingBottom={6}>
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                            {t("transactions:transactionDetails.subTotal")}
                        </Text>
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                            {t("transactions:transactionDetails.netcoinsFee")}
                        </Text>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        borderBottom={"1px solid"}
                        borderColor={"grey.light.10"}
                        paddingBottom={6}
                        textAlign={"right"}
                    >
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                            {`${formatDollar(subtotal)} ${fee_currency}`}
                        </Text>
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                            {`${formatDollar(fee)} ${fee_currency}`}
                        </Text>
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} paddingTop={6}>
                    <Text textStyle={"ManropeSemiboldBody"}>
                        {isBuy
                            ? t("transactions:transactionDetails.youPaid")
                            : t("transactions:transactionDetails.youReceived")}
                    </Text>
                    <Text
                        textAlign={"right"}
                        textStyle={"ManropeSemiboldBody"}
                    >{`${formatDollar(total)} ${fee_currency}`}</Text>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} py={6}>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("transactions:transactionDetails.orderNumber")}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} textAlign={"right"}>
                        {order_id}
                    </Text>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} mt={3}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("common:date")}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} textAlign={"right"}>
                        {formatShortDateTime(new Date(created_at_pst))}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
