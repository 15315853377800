import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import { CurrencySwitchFormField } from "@components/ui/formFields/currencySwitchFormField"
import { ValueFormField } from "@components/ui/formFields/valueFormField"
import CoinSelect from "@components/ui/select/coinSelect"
import SelectFormless from "@components/ui/select/formless"
import TabsSliding from "@components/ui/tabs/sliding"
import { DrawerCoinTradeContext } from "@screens/dashboard/trade/drawerCoinTrade/DrawerCoinTradeContext"
import { ZENDESK_CA_LINKS } from "@util/config"
import { formatCryptoCurrencyPrecision } from "@util/numericalFormatting"
import { addCommasToNumber } from "@util/stringFormatting"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MdAdd, MdRemove } from "react-icons/md"
import { BUYSELL_TAB, ORDER_TYPE } from "../../trade/drawerCoinTrade"

type Props = {
    showCoinSelect: boolean
    tab: BUYSELL_TAB
    setTab: (tab: BUYSELL_TAB) => void
    [rest: string]: any
}

export default function Landing({ showCoinSelect, tab, setTab, ...rest }: Props) {
    const { t } = useTranslation(["app", "common"])
    const {
        accountDetails,
        accountBalanceRestrictions,
        currency,
        assets,
        limitFieldState,
        fieldStates,
        onCurrencyInputTextChange,
        onLimitPriceTextChange,
        balances,
        isBalancesLoading,
        selectedAsset,
        setSelectedAsset,
        currentQuote,
        quoteParams,
        orderType,
        setOrderType,
        error,
    } = DrawerCoinTradeContext.useContainer()

    const showOrderTypeSelect = useMemo(() => {
        return accountDetails?.country === "CA"
    }, [])

    const openLearnMoreRestrictions = useCallback(() => {
        window.open(ZENDESK_CA_LINKS.ACQUISITION_LIMITS, "_blank")
    }, [])

    const balance = useMemo(
        () =>
            parseFloat(
                tab === BUYSELL_TAB.BUY
                    ? (balances?.balances?.[currency ?? "CAD"] ?? "0")
                    : (balances?.balances?.[selectedAsset.symbol.toUpperCase()] ?? "0")
            ),
        [tab, balances, currency, selectedAsset]
    )

    function handleLearnClick() {
        window.open(
            `${process.env.REACT_APP_BASE_URL}/crypto-asset-statement/${selectedAsset.symbol.toLowerCase()}`,
            "_blank"
        )
    }

    return (
        <Flex flexDir="column" {...rest}>
            <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                columnGap="0.5rem"
                mb={showOrderTypeSelect || !showCoinSelect ? "1.5rem" : "42px"}
            >
                <TabsSliding
                    activeOption={tab}
                    setActiveOption={setTab}
                    options={[
                        {
                            label: t("buy", { ns: "common" }),
                            value: BUYSELL_TAB.BUY,
                            icon: <MdAdd size={"14px"} />,
                        },
                        {
                            label: t("sell", { ns: "common" }),
                            value: BUYSELL_TAB.SELL,
                            icon: <MdRemove size={"14px"} />,
                        },
                    ]}
                />
            </Flex>
            <Box flex={1} overflowY={"auto"}>
                {showCoinSelect && (
                    <Box mb={8}>
                        <CoinSelect
                            search
                            options={assets.map((asset) => ({
                                label: asset.name,
                                value: asset.symbol,
                                desc: asset.symbol,
                            }))}
                            selectedOption={{
                                label: selectedAsset?.name,
                                value: selectedAsset?.symbol,
                                desc: selectedAsset?.symbol,
                            }}
                            setSelectedOption={(option) => setSelectedAsset({ symbol: option.value })}
                        />
                    </Box>
                )}
                {showOrderTypeSelect && (
                    <Box mb={6}>
                        <Text mb="0.25rem" textStyle="ManropeMediumBodySmall" color="black">
                            {t("buysell.orderType")}
                        </Text>
                        <SelectFormless
                            name="orderType"
                            size="lg"
                            value={{ label: orderType, value: orderType }}
                            onChange={(item) => setOrderType(item.value)}
                            options={[
                                { label: ORDER_TYPE.MARKET, value: ORDER_TYPE.MARKET },
                                { label: ORDER_TYPE.LIMIT, value: ORDER_TYPE.LIMIT },
                            ]}
                        />
                    </Box>
                )}

                {orderType === ORDER_TYPE.LIMIT && (
                    <Box mb={6}>
                        <ValueFormField
                            label={t("buysell.limitInputLabel", { action: tab })}
                            dollarSign
                            placeholder="0"
                            size={"lg"}
                            precision={selectedAsset?.price_precision}
                            value={limitFieldState}
                            currency={currency}
                            errorMessage={error.limitField}
                            onTextChange={onLimitPriceTextChange}
                        />
                        <Box mt={2} bgColor="blue.10" borderRadius="0.25rem" p={4}>
                            <Text lineHeight={"normal"}>
                                <Text as="span" textStyle={"InterRegularXSmall"}>
                                    {t("buysell.a")}
                                </Text>
                                <Text as="span" textStyle={"InterRegularXSmall"} fontWeight={700}>
                                    {t("buysell.limitOrderSm")}&nbsp;
                                </Text>
                                <Text as="span" textStyle={"InterRegularXSmall"}>
                                    {t("buysell.limitInfo")}
                                </Text>
                            </Text>
                        </Box>
                    </Box>
                )}
                <CurrencySwitchFormField
                    required
                    mode="dual"
                    balance={{
                        quantity: balance,
                        focus: tab === BUYSELL_TAB.SELL ? "currencyOne" : "currencyTwo",
                    }}
                    label={t("buysell.inputLabel", { action: tab })}
                    value={fieldStates}
                    currencies={[selectedAsset, { symbol: currency, precision: "2" }]}
                    errorMessage={error.currencyField}
                    exchangeRate={Number(
                        orderType === ORDER_TYPE.MARKET
                            ? ((currentQuote && "spot_price" in currentQuote && currentQuote.spot_price) ??
                                  selectedAsset.price)
                            : (quoteParams.price ?? selectedAsset.price)
                    )}
                    onTextChange={onCurrencyInputTextChange}
                />
                <Box
                    textStyle="ManropeSemiboldBodySmall"
                    borderBottomColor="grey.light.10"
                    borderBottomWidth={1}
                    borderTopWidth={1}
                    py={"1rem"}
                    mb="1rem"
                    mt="1.5rem"
                >
                    <Flex alignItems="center" justifyContent={"space-between"} mb="0.25rem">
                        <Text textStyle="ManropeSemiboldBodySmall">
                            {t("buysell.availableBalance", { symbol: selectedAsset?.symbol })}
                        </Text>
                        {isBalancesLoading || !selectedAsset ? (
                            <Spinner size="sm" />
                        ) : (
                            <Text textStyle="ManropeSemiboldBodySmall">
                                {formatCryptoCurrencyPrecision(
                                    +(balances?.balances?.[selectedAsset.symbol.toUpperCase()] ?? "0"),
                                    +selectedAsset.precision,
                                    true,
                                    0
                                )}{" "}
                                {selectedAsset.symbol}
                            </Text>
                        )}
                    </Flex>
                    <Flex alignItems="center" justifyContent={"space-between"}>
                        <Text textStyle="ManropeSemiboldBodySmall">
                            {t("buysell.availableBalance", { symbol: currency ?? "CAD" })}
                        </Text>
                        {isBalancesLoading ? (
                            <Spinner size="sm" />
                        ) : (
                            <Text textStyle="ManropeSemiboldBodySmall">
                                ${addCommasToNumber(Number(balances?.balances?.[currency ?? "CAD"]))}
                            </Text>
                        )}
                    </Flex>
                </Box>
                {accountDetails?.country === "CA" &&
                    accountBalanceRestrictions &&
                    accountBalanceRestrictions.restrictedCoins?.includes(selectedAsset.symbol) && (
                        <Box
                            borderRadius="4px"
                            w="full"
                            p="1rem"
                            borderColor="grey.light.10"
                            borderWidth={1}
                            bgColor="grey.light.2"
                            mb={4}
                        >
                            <Text textStyle="InterRegularBodySmall">
                                {t("buysell.balanceLimit", {
                                    limit: addCommasToNumber(accountBalanceRestrictions?.limit ?? 0, {
                                        addDollarSign: true,
                                    }),
                                    currency: currency,
                                    asset: selectedAsset.symbol,
                                })}
                            </Text>
                            <Text
                                textStyle="InterRegularBodySmall"
                                as="span"
                                color="blue.100"
                                onClick={openLearnMoreRestrictions}
                                cursor="pointer"
                            >
                                {t("buysell.learnMore")}
                            </Text>
                            <Flex mt={1.5} alignItems="center" justifyContent={"space-between"}>
                                <Text textStyle={"ManropeSemiboldBodySmall"}>{t("buysell.currentStatus")}</Text>
                                <Text textStyle={"ManropeSemiboldBodySmall"}>
                                    ${addCommasToNumber(accountBalanceRestrictions?.usage ?? 0)} / $
                                    {addCommasToNumber(accountBalanceRestrictions?.limit ?? 0)}
                                </Text>
                            </Flex>
                        </Box>
                    )}
                {accountDetails?.country === "CA" && (
                    <Text textStyle="InterRegularXSmall">
                        <Text
                            cursor="pointer"
                            onClick={handleLearnClick}
                            as="span"
                            textStyle="InterRegularXSmall"
                            color="blue.100"
                        >
                            {t("marketDetails.learn", { asset: selectedAsset?.name })}
                        </Text>
                        <Text color="grey.light.90" as="span">
                            &nbsp;{t("buysell.learnAbout")}
                        </Text>
                    </Text>
                )}
            </Box>
        </Flex>
    )
}
